import * as Yup from "yup";
import { ErrorMessage, Field, Form, Formik, FormikHelpers } from "formik";
import type { SalesTaxMutationData } from "../apis/interfaces/common";
import { Modal } from "react-bootstrap";

interface SalesTaxFormProps {
  initialValues: SalesTaxMutationData;
  onSubmit: any;
  onCancel: any;
}

const validationSchema = Yup.object({
  sales_tax: Yup.string().required("Name is required"),
  sales_tax_code: Yup.string().required("Code is required"),
  sales_tax_percentage: Yup.number()
    .positive("Rate must be a positive number.")
    .moreThan(0, "Percentage is required.")
    .required("Percentage is required"),
  state: Yup.string().required("State is required"),
});
const SalesTaxForm = (props: SalesTaxFormProps) => {
  const { initialValues, onSubmit, onCancel } = props;
  const handleSubmit = (
    values: SalesTaxMutationData,
    { setStatus }: FormikHelpers<SalesTaxMutationData>
  ) => {
    try {
      validationSchema.validateSync(values, { abortEarly: false });
      onSubmit(values);
    } catch (errors) {
      setStatus("An error occurred during form submission.");
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({
        handleChange,
        handleSubmit,
        isSubmitting,
        isValid,
        status,

      }) => (
        <>
          <Form onSubmit={handleSubmit}>
            <Modal.Body>
              <div className="form-group mb-2">
                <label htmlFor="sales_tax">Sales Tax Name:</label>
                <Field
                  type="text"
                  id="sales_tax"
                  name="sales_tax"
                  className="form-control"
                  onChange={handleChange}
                />
                <ErrorMessage
                  name="sales_tax"
                  component="div"
                  className="error-message text-danger"
                />
              </div>

              <div className="form-group mb-2">
                <label htmlFor="sales_tax">Code:</label>
                <Field
                  type="text"
                  id="sales_tax_code"
                  name="sales_tax_code"
                  className="form-control"
                  onChange={handleChange}
                />
                <ErrorMessage
                  name="sales_tax_code"
                  component="div"
                  className="error-message text-danger"
                />
              </div>

              <div className="form-group mb-2">
                <label htmlFor="sales_tax">Percentage:</label>
                <Field
                  type="number"
                  id="sales_tax_percentage"
                  name="sales_tax_percentage"
                  className="form-control"
                  onChange={handleChange}
                />
                <ErrorMessage
                  name="sales_tax_percentage"
                  component="div"
                  className="error-message text-danger"
                />
              </div>

              <div className="form-group mb-2">
                <label htmlFor="state">State:</label>
                <Field
                  type="text"
                  id="state"
                  name="state"
                  className="form-control"
                  onChange={handleChange}
                />
                <ErrorMessage
                  name="state"
                  component="div"
                  className="error-message text-danger"
                />
              </div>

              <div className="form-check mb-2">
                <Field
                  type="checkbox"
                  id="active"
                  name="active"
                  className="form-check-input"
                  onChange={handleChange}
                />
                <label className="form-check-label" htmlFor="active">
                  Active
                </label>
              </div>
              {/* Error message section */}
              {status && (
                <div className="alert alert-danger" role="alert">
                  {status}
                </div>
              )}
            </Modal.Body>
            <Modal.Footer>
              <div className="text-right">
                <button
                  onClick={onCancel}
                  className="btn btn-sm btn-danger mx-3"
                  type="button"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-sm btn-success"
                  disabled={!(!isSubmitting && isValid)}
                >
                  Save
                </button>
              </div>
            </Modal.Footer>
          </Form>
        </>
      )}
    </Formik>
  );
};

export default SalesTaxForm;
