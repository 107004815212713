import { apiClient } from "../client";

export interface loginMutationData {
  email: string;
  password: string;
}

export interface requestResetPasswordMutationData {
  email: string;
  confirm_email: string;
}

export interface changePasswordMutationData {
  new_password: string;
  confirm_password: string;
}

export interface updateProfileInfoMutationData {
  first_name: string;
  last_name: string;
  send_admin_emails?: boolean;
}
export interface updateEmailMutationData {
  new_email: string;
  confirm_email: string;
}
export interface performResetPasswordMutationData {
  new_password: string;
  email: string;
  token: string;
}
export interface performEmailChangeMutationData {
  uidb64: string;
  new_email_hash: string;
  token: string;
}

export const requestResetPasswordMutation = {
  mutationFn: (rrpData: requestResetPasswordMutationData) => {
    return apiClient.post("/auth/v1/reset_password/", rrpData);
  },
};

export const performResetPasswordMutation = {
  mutationFn: (prpData: performResetPasswordMutationData) => {
    return apiClient.post("/auth/v1/perform_reset/", prpData);
  },
};
export const loginMutation = {
  queryKey: ["testData"],
  mutationFn: (loginData: loginMutationData) => {
    return apiClient.post("/auth/v1/login/", loginData);
  },
};
export const updateProfileMutation = {
  queryKey: ["updateProfile"],
  mutationFn: (profileData: updateProfileInfoMutationData) => {
    return apiClient.post("/auth/v1/update_profile/", profileData);
  },
};
export const requestEmailChangeMutation = {
  queryKey: ["requestEmailChange"],
  mutationFn: (emailData: updateEmailMutationData) => {
    return apiClient.post("/auth/v1/request_email_change/", emailData);
  },
};
export const changePasswordMutation = {
  queryKey: ["changePassword"],
  mutationFn: (passData: changePasswordMutationData) => {
    return apiClient.post("/auth/v1/change_password/", passData);
  },
};
