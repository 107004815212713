import React, { useEffect, useState } from "react";
import { apiClient } from "../../apis/client";
import Layout from "../../components/Layout";
import BasicFilterTable from "../../components/BasicFilterTable";
import "../../styles/reports.css";
import { useJobQueryAll } from "../Tickets/Logic/MutationsAndQueries";
import {
  CompanyQueryData,
  DepartmentQueryData,
  JobQueryData,
} from "../../apis/interfaces/common";
import { companyQueryAll, departmentQueryAll } from "../../apis/queries/common";
import { useQuery } from "@tanstack/react-query";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../styles/print.css";
import Select from "react-select";

const { DateTime } = require("luxon");

interface JobData {
  job_id: number;
  job_number: string;
  job_name: string;
  total_stock_issue_amount: number;
  total_stock_issue_items: number;
  stock_issue_nontaxable: number;
  total_stock_issue_tax: number;
  total_stock_charge_tax: number;
  total_stock_charge_amount: number;
  stock_charge_nontaxable: number;
  total_stock_charge_rent_tax: number;
  total_stock_charge_rent_amount: number;
  stock_charge_rent_nontaxable: number;
}

interface CompanyData {
  company_id: number;
  company_code: string;
  company_name: string;
  jobs: Record<number, JobData>;
}

type ReportData = CompanyData;

function MonthlyIssuesAndCreditsSummary() {
  const [reportData, setReportData] = useState<ReportData[] | null>(null);
  const [jobs, setJobs] = useState<JobQueryData[]>([]);
  const [companies, setCompanies] = useState<CompanyQueryData[]>([]);
  const [depts, setDepts] = useState<DepartmentQueryData[]>([]);
  const [job, setJob] = useState(0);
  const [company, setCompany] = useState(0);
  const [, setDept] = useState(0);
  const [monthEnding, setMonthEnding] = useState<Date>(DateTime.now());
  const jobQuery = useJobQueryAll();
  const companyQuery = useQuery(companyQueryAll());
  const departmentQuery = useQuery(departmentQueryAll());

  useEffect(() => {
    if (!jobQuery.isLoading) {
      setJobs(jobQuery.data);
    }
    if (!companyQuery.isLoading) {
      setCompanies(companyQuery.data);
    }
    if (!departmentQuery.isLoading) {
      setDepts(departmentQuery.data);
    }
  }, [
    companyQuery.data,
    companyQuery.isLoading,
    departmentQuery.data,
    departmentQuery.isLoading,
    jobQuery.data,
    jobQuery.isLoading,
  ]);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    toast.info("Generating report");

    const data = {
      company: company,
      month_ending: monthEnding,
      job: job,
    };

    try {
      const response = await apiClient.post(
        "/tickets/reports/MonthlyChargesSummary/filter/",
        data
      );
      const cleanedData = Object.values(response.data).filter(
        (company: any) => company.company_id !== null
      );
      setReportData(cleanedData as ReportData[]);
      toast.success("Report loaded.");
    } catch (error) {
      console.error("Error submitting report:", error);
    }
  };

  const handleExportExcel = async (event: React.FormEvent) => {
    event.preventDefault();
    toast.info("Generating report");

    const data = {
      company: company,
      month_ending: monthEnding,
    };

    try {
      const response = await apiClient.post(
        "/tickets/reports/MonthlyChargesSummary/export_excel/",
        data,
        { responseType: "blob" }
      );

      const blob = await response.data;
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = `Monthly Issues and Charges Summary Report - ${new Date().toLocaleString()}.xlsx`;
      link.click();
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error submitting report:", error);
    }
  };

  const filterFn = (keyword: string, item: JobData) => {
    const lowercaseKeyword = keyword.toLowerCase();
    const itemValues = Object.values(item);

    for (const value of itemValues) {
      if (typeof value === "string") {
        const lowercaseValue = value.toLowerCase();

        if (lowercaseValue.includes(lowercaseKeyword)) {
          return true;
        }
      }
    }

    return false;
  };

  const filterButtons = <></>;

  const columns = [
    {
      name: "Job",
      sortable: true,
      selector: (row: JobData) => row.job_number,
      width: '12%',
      cell: (row: JobData) => <>{row.job_number}<br />{row.job_name}</>,
      cellStyle: {
        whiteSpace: 'normal',
        overflow: 'visible',
      },
    },
    {
      name: <>Amt<br />(taxable)</>,
      sortable: true,
      selector: (row: JobData) =>
        "$" +
        Number(row.total_stock_issue_items ?? 0).toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
        width: '8%',

    },
    {
      name: <>Amt<br />(nontaxable)</>,
      sortable: true,
      selector: (row: JobData) =>
        "$" +
        Number(row.stock_issue_nontaxable ?? 0).toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
        width: '8%',

    },
    {
      name: "Tax",
      sortable: true,
      selector: (row: JobData) =>
        "$" +
        Number(row.total_stock_issue_tax ?? 0).toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
        width: '8%',

    },
    {
      name: "Total",
      sortable: true,
      selector: (row: JobData) =>
        "$" +
        Number(row.total_stock_issue_amount ?? 0).toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
        width: '8%',

    },
    {
      name: <>Misc Amt<br />(taxable)</>,
      sortable: true,
      selector: (row: JobData) =>
        "$" +
        (
          Number(row.total_stock_charge_amount ?? 0) -
          Number(row.total_stock_charge_tax ?? 0) - Number(row.stock_charge_nontaxable ?? 0)
        ).toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
        width: '7%',

    },
    {
      name: <>Misc Amt<br />(non-taxable)</>,
      sortable: true,
      selector: (row: JobData) =>
        "$" +
        Number(row.stock_charge_nontaxable ?? 0).toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
        width: '7%',

    },
    {
      name: "Misc Tax",
      sortable: true,
      selector: (row: JobData) =>
        "$" +
        Number(row.total_stock_charge_tax ?? 0).toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
        width: '7%',
      },
    {
      name: "Misc Total",
      sortable: true,
      selector: (row: JobData) =>
        "$" +
        Number(row.total_stock_charge_amount ?? 0).toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
        width: '7%',
        whiteSpace: 'normal',
        overflow: 'visible',
    },
    {
      name: <>Rent Amt<br />(taxable)</>,
      sortable: true,
      selector: (row: JobData) =>
        "$" +
        (
          Number(row.total_stock_charge_rent_amount ?? 0) -
          Number(row.total_stock_charge_rent_tax ?? 0) - Number(row.stock_charge_rent_nontaxable ?? 0)
        ).toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
        width: '7%',

    },
    {
      name: <>Rent Amt<br />(non-taxable)</>,
      sortable: true,
      selector: (row: JobData) =>
        "$" +
        Number(row.stock_charge_rent_nontaxable ?? 0).toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
        width: '7%',

    },
    {
      name: "Rent Tax",
      sortable: true,
      selector: (row: JobData) =>
        "$" +
        Number(row.total_stock_charge_rent_tax ?? 0).toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
        width: '7%',
      },
    {
      name: "Rent Total",
      sortable: true,
      selector: (row: JobData) =>
        "$" +
        Number(row.total_stock_charge_rent_amount ?? 0).toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
        width: '7%',
        whiteSpace: 'normal',
        overflow: 'visible',
    },
  ];

  const calculateTotals = (jobs: Record<number, JobData>) => {
    const totals = {
      issueTotal: 0,
      chargeTotal: 0,
      issueTax: 0,
      issueNonTax: 0,
      issueItems: 0,
      chargeTax: 0,
      chargeNonTax: 0,
      rentTotal: 0,
      rentTax: 0,
      rentNonTax: 0,
    };

    Object.values(jobs).forEach((job) => {
      totals.issueTotal += Number(job.total_stock_issue_amount ?? 0);
      totals.issueTax += Number(job.total_stock_issue_tax ?? 0);
      totals.issueNonTax += Number(job.stock_issue_nontaxable ?? 0);
      totals.issueItems += Number(job.total_stock_issue_items ?? 0);
      totals.chargeTax += Number(job.total_stock_charge_tax ?? 0);
      totals.chargeNonTax += Number(job.stock_charge_nontaxable ?? 0);
      totals.chargeTotal += Number(job.total_stock_charge_amount ?? 0);
      totals.rentTax += Number(job.total_stock_charge_rent_tax ?? 0);
      totals.rentNonTax += Number(job.stock_charge_rent_nontaxable ?? 0);
      totals.rentTotal += Number(job.total_stock_charge_rent_amount ?? 0);
    });

    return totals;
  };

  const renderSummary = (jobs: JobData[], company: string) => {
    const totals = calculateTotals(jobs);

    return (
      <div>
        <br />
        <div className="ticket-summary text-end d-flex justify-content-between">
          <h5>Totals for {company}:</h5>
          <div className="stock-issues">
          <p>Stock Issues: </p>
          <h6 className="summary-text">
            Amount: $
            {Number(totals.issueItems ?? 0).toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </h6>
          <h6 className="summary-text">
            Non-Taxable: $
            {Number(totals.issueNonTax ?? 0).toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </h6>
          <h6 className="summary-text">
            Tax: $
            {Number(totals.issueTax ?? 0).toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </h6>

          <h6 className="summary-text">
            Total: $
            {Number(totals.issueTotal ?? 0).toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </h6>
          </div>

          <div className="stock-charges">

            <p>Misc Charges: </p>
            <h6 className="summary-text">
              Amount: $
              {(
                Number(totals.chargeTotal ?? 0) - Number(totals.chargeTax ?? 0)
              ).toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </h6>
            <h6 className="summary-text">
              Non-Taxable: $
              {Number(totals.chargeNonTax ?? 0).toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </h6>
            <h6 className="summary-text">
              Tax: $
              {Number(totals.chargeTax ??0).toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </h6>

            <h6 className="summary-text">
              Total: $
              {Number(totals.chargeTotal ?? 0).toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </h6>
          </div>

          <div className="stock-charges">

            <p>Rent Charges: </p>
            <h6 className="summary-text">
              Amount: $
              {(
                Number(totals.rentTotal ?? 0) - Number(totals.rentTax ?? 0)
              ).toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </h6>
            <h6 className="summary-text">
              Non-Taxable: $
              {Number(totals.rentNonTax ?? 0).toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </h6>
            <h6 className="summary-text">
              Tax: $
              {Number(totals.rentTax ??0).toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </h6>

            <h6 className="summary-text">
              Total: $
              {Number(totals.rentTotal ?? 0).toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </h6>
          </div>

          <div className="stock-charges">

          <p>Grand Total: </p>
          <h6 className="summary-text">
            Amount: $
            {Number(
              (Number(totals.chargeTotal ?? 0) - Number(totals.chargeTax ?? 0) - Number(totals.chargeNonTax ?? 0)) +
              (Number(totals.rentTotal ?? 0) - Number(totals.rentTax ?? 0) - Number(totals.rentNonTax ?? 0)) + 
              Number(totals.issueItems ?? 0)
            ).toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </h6>
          <h6 className="summary-text">
            Non-Taxable: $
            {Number(Number(totals.chargeNonTax ?? 0) + Number(totals.rentNonTax ?? 0) + Number(totals.issueNonTax??0)).toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </h6>
          <h6 className="summary-text">
            Tax: $
            {Number(Number(totals.chargeTax ?? 0) + Number(totals.rentTax ?? 0) + Number(totals.issueTax ??0)).toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </h6>

          <h6 className="summary-text">
            Total: $
            {Number(Number(totals.chargeTotal ?? 0) + Number(totals.rentTotal ?? 0) + Number(totals.issueTotal ?? 0)).toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </h6>
          </div>
        </div>
        <br />
        <br />
      </div>
    );
  };
  const options = jobs
  ?.filter((jobElem: JobQueryData) => jobElem.active)
  .map((jobElem: JobQueryData) => ({
    value: jobElem.id,
    label: `${jobElem.job_number} - ${jobElem.job}`,
  })) ?? 0;

const hndSelectJob = (
  selectedOption: { value: number | undefined; label: string } | null
) => {
  const job = selectedOption
    ? jobs.find(
        (jobItem: JobQueryData) => jobItem.id === selectedOption.value
      )
    : null;
  setJob(job?.id ?? 0);
};
function customFilter(option:any , rawInput: any) {
  const searchTerm = rawInput.toLowerCase();
  return option.label.toLowerCase().includes(searchTerm);
}
  const renderForm = () => {
    return (
      <div>
        <h4>REPORT PARAMETERS</h4>
        <form onSubmit={handleSubmit}>
          <label>Job:</label> <br />
          <Select
                  name="job"
                  className="form-control"
                  onChange={hndSelectJob}
                  isClearable
                  options={options}
                  filterOption={customFilter}
                />
          <label>Company:</label> <br />
          <select
            name="company"
            className="form-control"
            onChange={(e) => setCompany(Number(e.target.value))}
          >
            <option value="">All Companies</option>
            {companies.filter(Boolean).map((item: CompanyQueryData) => (
              <option key={item.id} value={item.id}>
                {item.company_code} - {item.company}
              </option>
            ))}
          </select>
          <label>Department:</label> <br />
          <select
            name="dept"
            className="form-control"
            onChange={(e) => setDept(Number(e.target.value))}
          >
            <option value="">All Departments</option>
            {depts.filter(Boolean).map((item: DepartmentQueryData) => (
              <option key={item.id} value={item.id}>
                {item.department_number} - {item.department}
              </option>
            ))}
          </select>
          <label>Month Ending:</label> <br />
          <input
            name="month_ending"
            type="date"
            className="form-control"
            defaultValue={DateTime.local().endOf("month").toISODate()}
            onChange={(e) => setMonthEnding(new Date(e.target.value))}
          ></input>
          <button
            type="submit"
            className="btn btn-sm btn-success mt-2 button-margin"
          >
            Generate Filtered Report
          </button>
          <button
            type="submit"
            className="btn btn-sm btn-success mt-2 button-margin"
            onClick={handleExportExcel}
          >
            Export to Excel
          </button>
          <button
            className="btn btn-sm btn-success mt-2  button-margin"
            onClick={() => window.print()}
          >
            Print/Export PDF
          </button>
        </form>
      </div>
    );
  };
  const renderTable = (jobs: JobData[]) => {
    return (
      <div>
        <BasicFilterTable
          tableKey="MonthlyIssuesAndCreditsSummary"
          title="Monthly Issues and Credits Summary Report Results"
          dataSource={Object.values(jobs)}
          columns={columns}
          filterFn={filterFn}
          filterButtons={filterButtons}
          exportFileName={"Monthly Issues and Credits Summary Data.csv"}
        />
      </div>
    );
  };
  return (
    <div>
      <Layout>
        <div id="report-title-container" className="hide-printable">
          <ToastContainer />
          <h2>Monthly Issues And Credit Summary Report</h2>
          <br />
          {renderForm()}
          <br />
          <br />
        </div>

        <div className="report-data-printable">
        {reportData &&
          reportData.map((company: CompanyData) => (
            <div key={company.company_id}>
              <h3>Company: {company.company_name}</h3>
              {renderTable(Object.values(company.jobs))}
              {renderSummary(Object.values(company.jobs), company.company_name)}
            </div>
          ))}
        </div>
      </Layout>
    </div>
  );
}

export default MonthlyIssuesAndCreditsSummary;
