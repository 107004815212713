import React, { useEffect, useState } from "react";
import { apiClient } from "../../apis/client";
import Layout from "../../components/Layout";
import BasicFilterTable from "../../components/BasicFilterTable";
import { VendorQueryData } from "../../apis/interfaces/common";
import { vendorQueryAll } from "../../apis/queries/common";
import { useQuery } from "@tanstack/react-query";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../styles/print.css";

const { DateTime } = require("luxon");

interface ReportData {
  id: number;
  stock_order__date_ordered: Date;
  stock__id: number;
  stock__stock: string;
  stock__quantity_on_hand: number;
  quantity_ordered: number;
  created_at: Date;
  stock_order__vendor__vendor_name: string;
  stock_order__vendor__id: number;
}

function OrderExistingReport() {
  const [reportData, setReportData] = useState<ReportData[] | null>(null);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [vendor, setVendor] = useState("");
  const [vendors, setVendors] = useState<VendorQueryData[]>([]);
  const vendorQuery = useQuery(vendorQueryAll());
  useEffect(() => {
    if (vendorQuery.isSuccess) {
      setVendors(vendorQuery.data);
    }
  }, [vendorQuery.data, vendorQuery.isSuccess]);
  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    toast.info("Generating report");

    const data = {
      start_date: startDate,
      end_date: endDate,
      vendor: vendor,
    };
    try {
      const response = await apiClient.post(
        "/invitems/reports/OrderExisting/filter/",
        data
      );
      setReportData(response.data);
      toast.success("Report loaded.");
    } catch (error) {
      console.error("Error submitting report:", error);
    }
  };
  const filterFn = (keyword: string, item: ReportData) => {
    const lowercaseKeyword = keyword.toLowerCase();
    const itemValues = Object.values(item);

    for (const value of itemValues) {
      if (typeof value === "string") {
        const lowercaseValue = value.toLowerCase();

        if (lowercaseValue.includes(lowercaseKeyword)) {
          return true;
        }
      }
    }

    return false;
  };
  const handleExportExcel = async (event: React.FormEvent) => {
    event.preventDefault();
    toast.info("Generating report");

    const data = {
      start_date: startDate,
      end_date: endDate,
      vendor: vendor,
    };
    try {
      const response = await apiClient.post(
        "/invitems/reports/OrderExisting/export_excel/",
        data,
        { responseType: "blob" } // Set the responseType to 'blob' to receive a Blob object in the response
      );
      const blob = await response.data;
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = `Order Existing Report - ${new Date().toLocaleString()}.xlsx`;
      link.click();
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error submitting report:", error);
    }
  };

  const filterButtons = <></>;
  const columns = [
    {
      name: "Date Ordered",
      sortable: true,
      selector: (row: ReportData) =>
        row.stock_order__date_ordered.toLocaleString().slice(0, 10),
      width: "12%",
      wrap:true,
    },
    {
      name: "Item Number",
      sortable: true,
      selector: (row: ReportData) => row.stock__id,
      width: "15%",
      wrap:true,
    },
    {
      name: "Item Description",
      sortable: true,
      selector: (row: ReportData) => row.stock__stock,
      wrap: true,
    },
    {
      name: "Qty Ordered",
      sortable: true,
      selector: (row: ReportData) => row.quantity_ordered,
      width: '15vw',
      wrap:true,

    },
    {
      name: "Qty on Hand",
      sortable: true,
      selector: (row: ReportData) => row.stock__quantity_on_hand,
      width: '15vw',
      wrap:true,

    },
    {
      name: "Vendor",
      sortable: true,
      selector: (row: ReportData) => row.stock_order__vendor__vendor_name,
      wrap: true,
    },
  ];

  return (
    <div>
      <Layout>
      <div id="report-title-container" className="hide-printable">

        <ToastContainer />
        <h2>Order Existing Report</h2>
        <br></br>
        <h4>REPORT PARAMETERS</h4>
        <form onSubmit={handleSubmit}>
          <label>Start Date:</label> <br />
          <input
            name="start_date"
            type="date"
            className="form-control"
            defaultValue={DateTime.local().startOf("month").toISODate()}
            onChange={(e) => setStartDate(new Date(e.target.value))}
          ></input>
          <label>End Date:</label> <br />
          <input
            name="end_date"
            type="date"
            className="form-control"
            defaultValue={DateTime.local().endOf("month").toISODate()}
            onChange={(e) => setEndDate(new Date(e.target.value))}
          ></input>
          <label>Vendor:</label> <br />
          <select
            name="vendor"
            className="form-control"
            onChange={(e) => setVendor(e.target.value)}
          >
            <option value="">All Vendors</option>
            {vendors.filter(Boolean).map((item: VendorQueryData) => (
              <option key={item.id} value={item.id}>
                {item.vendor_name}
              </option>
            ))}
          </select>
          <button
            type="submit"
            className="btn btn-sm btn-success mt-2 button-margin"
          >
            Generate Filtered Report
          </button>
          <button
            type="submit"
            className="btn btn-sm btn-success mt-2 button-margin"
            onClick={handleExportExcel}
          >
            Export to Excel
          </button>
          <button
            className="btn btn-sm btn-success mt-2  button-margin"
            onClick={() => window.print()}
          >
            Print/Export PDF
          </button>
        </form>

        <br />
        <br />
        </div>

        <div className="report-data-printable">

        {reportData && (
          <div>
            <BasicFilterTable
              tableKey="OrderExisting"
              title="Order Existing Report Results"
              dataSource={reportData as ReportData[]}
              columns={columns}
              filterFn={filterFn}
              filterButtons={filterButtons}
              exportFileName={"Order Existing Report.csv"}
            />
          </div>
        )}
        </div>
      </Layout>
    </div>
  );
}

export default OrderExistingReport;
