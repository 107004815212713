import React from "react";
import { StockMetaData } from "../../../apis/interfaces/invitems";
import { toast } from "react-toastify";
import { useCreateStockIssueDetailMutation } from "./MutationsAndQueries";
import { UseQueryResult } from "@tanstack/react-query";
import * as Sentry from '@sentry/react';

const useAddItem = () => {
  const createStockIssueDetail = useCreateStockIssueDetailMutation();

  const addItem = async (
    event: React.FormEvent,
    stockIssue: UseQueryResult<any>,
    selectedInvItem: StockMetaData,
    addItemQuantity: number,
    notes: string,
    costcode: number,
  ) => {
    event.preventDefault();
    
    const data = {
      charged: false,
      date_charged: null,
      stock_issue: stockIssue.data.id,
      quantity: addItemQuantity,
      stock: selectedInvItem.id,
      unit_price: Number(selectedInvItem?.unit_price),
      freight_charge: stockIssue.data.stock_issue_details.freight_charge,
      note: notes,
      sales_tax: stockIssue.data.job.sales_tax,
      sales_tax_code: stockIssue.data.job.sales_tax.sales_tax_code,
      sales_tax_percentage: stockIssue.data.job.sales_tax.sales_tax_percentage,
      state: stockIssue.data.job.sales_tax.state,
      sales_tax_stored: new Date(),
      cost_code: costcode
    };
    const response = await createStockIssueDetail.mutateAsync(data);
    if (response.status === 201) {
      toast.success("Item added to ticket.");
    } else if (response.status === 400 || response.status === 500) {
      toast.error("An error has occurred. Please try again.");
      Sentry.captureException(response);

    }
    await stockIssue.refetch();
  };
  return { addItem };
};
export default useAddItem;
