import { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Modal } from "react-bootstrap";
import type {
  StockCategoryMutationData,
  StockCategoryQueryData,
} from "../../apis/interfaces/invitems";
import { stockCategoryQueryAll } from "../../apis/queries/invitems";
import {
  createStockCategoryMutation,
  updateStockCategoryMutation,
} from "../../apis/mutations/invitems";

import StockCategoryForm from "../../forms/StockCategoryForm";

import Layout from "../../components/Layout";
import Loader from "../../components/Loader";
import BasicFilterTable from "../../components/BasicFilterTable";
import { toast, ToastContainer } from "react-toastify";
import ErrorFallback from "../../components/Error";
import * as Sentry from "@sentry/react";

interface StockCategoryTableProps {
  onAddNew: any;
  onEdit: any;
  stockCategories: Array<StockCategoryQueryData>;
  setStockCategoryInitialValues: any;
  setShowStockCategoryModal: any;
  edit: boolean;
  setEdit: any;
}

interface StockCategoryModalProps {
  show: boolean;
  onCancel: Function;
  onOk: Function;
  initialValues: StockCategoryMutationData;
  setShowStockCategoryModal: any;
  setStockCategoryInitialValues: any;
  stockCategories: any;
  edit: boolean;
  setEdit: any;
}

const StockCategoryModal = (props: StockCategoryModalProps) => {
  const {
    show,
    onCancel,
    onOk,
    initialValues,
    stockCategories,
    setEdit,
    edit,
  } = props;
  const queryClient = useQueryClient();
  const createStockCategory = useMutation({
    ...createStockCategoryMutation,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["stockCategoryQueryAll"] });
      onOk();
    },
  });
  const updateStockCategoryMutationFn = async (
    params: [number, StockCategoryMutationData]
  ) => {
    const [id, data] = params;
    try {
      await updateStockCategoryMutation.mutationFn(id, data);
      onOk();
    } catch (error) {
      console.log(error);
    }
  };

  const updateStockCategory = useMutation(updateStockCategoryMutationFn);

  const hndSubmit = async (values: StockCategoryMutationData) => {
    try {
      if (!values.id) {
        await createStockCategory.mutateAsync(values);
        toast.success("Stock Category Created");
      } else {
        await updateStockCategory.mutateAsync([values.id as number, values]);
        setEdit(false);
        toast.success("Stock Category Updated");
      }
    } catch (err) {
      console.log(err);
      toast.error("An error occurred while updating the stock category");
    }
  };
  const handleModalClose = () => {
    setEdit(false);
    onCancel();
  };

  return (
    <Modal show={show} onCancel={handleModalClose}>
      {edit && (
        <Modal.Header closeButton onClick={handleModalClose}>
          Edit Stock Category
        </Modal.Header>
      )}
      {!edit && (
        <Modal.Header closeButton onClick={handleModalClose}>
          Add Stock Category
        </Modal.Header>
      )}

      <StockCategoryForm
        initialValues={initialValues}
        onCancel={handleModalClose}
        onSubmit={hndSubmit}
        stockCategories={stockCategories}
      />
    </Modal>
  );
};

const StockCategoryTable = (props: StockCategoryTableProps) => {
  const {
    onAddNew,
    stockCategories,
    setShowStockCategoryModal,
    setStockCategoryInitialValues,
    setEdit,
  } = props;

  const fireOnEdit = (row: StockCategoryQueryData) => {
    setEdit(true);
    setStockCategoryInitialValues({
      id: row.id,
      stock_category: row.stock_category,
      parent_category: row.parent_category && row.parent_category.id,
      active: row.active,
    });
    setShowStockCategoryModal(true);
  };

  const columns = [
    {
      name: "Name",
      sortable: true,
      selector: (row: StockCategoryQueryData) => row.stock_category,
    },
    {
      name: "Parent Category",
      sortable: true,
      cell: (row: StockCategoryQueryData) =>
        row.parent_category?.stock_category,
    },
    {
      name: "Active",
      sortable: true,
      selector: (row: StockCategoryQueryData) => row.active,
      cell: (row: StockCategoryQueryData) => (
        <>
          {row.active ? (
            <span className="text-success">&#x2714;</span> // Checkmark symbol
          ) : (
            <span className="text-danger">&#x2718;</span> // Cross symbol
          )}
        </>
      ),
    },
    {
      name: "Actions",
      cell: (row: StockCategoryQueryData) => (
        <>
          <button
            className="btn btn-sm btn-link"
            onClick={fireOnEdit.bind(this, row)}
          >
            Edit
          </button>
        </>
      ),
    },
  ];

  const filterFn = (keyword: string, item: StockCategoryQueryData) => {
    const lowercaseKeyword = keyword.toLowerCase();

    if (item.stock_category.toLowerCase().includes(lowercaseKeyword) || (item.parent_category && item.parent_category.stock_category.toLowerCase().includes(lowercaseKeyword))) {
      return true;
    }

    return false;
  };

  const filterButtons = (
    <>
      <button className="btn btn-link" onClick={onAddNew}>
        Add New
      </button>
    </>
  );
  return (
    <div className="row">
      <div className="col-12">
        <div className="border border-secondary-subtle p-0 d-flex">
          <div className="align-self-center mr-2"></div>
        </div>
        <div className="p-2">
          <BasicFilterTable
            tableKey="stock_category"
            title="Stock Categories"
            dataSource={stockCategories}
            columns={columns}
            filterFn={filterFn}
            filterButtons={filterButtons}
            exportFileName={"Stock Category Export Data.csv"}
          />
        </div>
      </div>
    </div>
  );
};

const blankStockCategoryForm = () => {
  return {
    stock_category: "",
    parent_category: 0,
    active: true,
  };
};

const StockCategory = () => {
  const [showStockCategoryModal, setShowStockCategoryModal] = useState(false);

  const [stockCategoryInitialValues, setStockCategoryInitialValues] = useState(
    blankStockCategoryForm()
  );
  const [categories, setStockCategories] = useState<
    Array<StockCategoryQueryData>
  >([]); // Initialize with an empty array
  const [edit, setEdit] = useState(false);
  const stockCategories = useQuery(stockCategoryQueryAll());
  useEffect(() => {
    if (!stockCategories.isLoading) {
      setStockCategories(stockCategories.data);
    }
  }, [stockCategories]);

  const hndAddNew = () => {
    setStockCategoryInitialValues(blankStockCategoryForm());
    setShowStockCategoryModal(true);
  };

  const hndEdit = () => {
    setEdit(true);
    setShowStockCategoryModal(true);
    setStockCategoryInitialValues({
      ...stockCategoryInitialValues,
      stock_category: "",
      parent_category: 0,
    });
  };

  const hndModelClose = () => {
    setEdit(false);
    setShowStockCategoryModal(false);
    stockCategories.refetch();
  };
  let sortedCategories = stockCategories.data;
  if (sortedCategories) {
    sortedCategories = [...sortedCategories].sort(
      (a, b) => b.active - a.active
    );
  }

  return (
    <Sentry.ErrorBoundary fallback={ErrorFallback}>
     
        <Layout>
          <Loader
            isLoading={
              stockCategories.isLoading && stockCategories.data !== undefined
            }
          >
            <StockCategoryTable
              onAddNew={hndAddNew}
              onEdit={hndEdit}
              stockCategories={sortedCategories}
              setStockCategoryInitialValues={setStockCategoryInitialValues}
              setShowStockCategoryModal={setShowStockCategoryModal}
              setEdit={setEdit}
              edit={edit}
            />
          </Loader>
          <ToastContainer />
          <StockCategoryModal
            show={showStockCategoryModal}
            stockCategories={categories}
            onOk={hndModelClose}
            onCancel={hndModelClose}
            initialValues={stockCategoryInitialValues}
            setShowStockCategoryModal={setShowStockCategoryModal}
            setStockCategoryInitialValues={setStockCategoryInitialValues}
            setEdit={setEdit}
            edit={edit}
          />
        </Layout>
      
    </Sentry.ErrorBoundary>
  );
};

export default StockCategory;
