import { ReactElement, useState, useEffect } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Button, Form, Modal } from "react-bootstrap";
import type {
  CostCodeMutationData,
  JobQueryData
} from "../../apis/interfaces/common";
import { costCodeQueryAll, jobQueryAll } from "../../apis/queries/common";
import {
  createCostCodeMutation,
  updateCostCodeMutation,
} from "../../apis/mutations/common";

import CostCodeTable from "../../components/CostCodeTable";

import CostCodeForm from "../../forms/CostCodeForm";

import Layout from "../../components/Layout";
import { toast, ToastContainer } from "react-toastify";
import ErrorFallback from "../../components/Error";
import * as Sentry from "@sentry/react";

interface CostCodeModalProps {
  show: boolean;
  onCancel: Function;
  onOk: Function;
  initialValues: CostCodeMutationData;
  setShowCostCodeModal: any;
  setCostCodeInitialValues: any;
  edit: boolean;
  setEdit: any;
}

interface FilterModalProps {
  setShowFilterModal: any;
  show: boolean;
  currentJob: string;
  jobs: Array<JobQueryData>;
  onClose: () => void;
  onApply: (filterData: any) => void;
  onClear: () => void;
}

const CostCodeModal = (props: CostCodeModalProps) => {
  const { show, onCancel, onOk, initialValues, edit, setEdit } = props;
  const queryClient = useQueryClient();
  const createCostCode = useMutation({
    ...createCostCodeMutation,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["costCodeQueryAll"] });
      onOk();
    },
  });
  const updateCostCodeMutationFn = async (
    params: [number, CostCodeMutationData]
  ) => {
    const [id, data] = params;
    try {
      await updateCostCodeMutation.mutationFn(id, data);
      onOk();
    } catch (error) {
      console.log(error);
    }
  };

  const updateCostCode = useMutation(updateCostCodeMutationFn);

  const hndSubmit = async (values: CostCodeMutationData) => {
    try {
      if (!values.id) {
        await createCostCode.mutateAsync(values);
        toast.success("Cost Code created");
      } else {
        await updateCostCode.mutateAsync([values.id as number, values]);
        setEdit(false);
        toast.success("Cost Code updated");
      }
    } catch (err) {
      console.log(err);
      toast.error("An error occurred while updating the cost code.");
    }
  };
  const handleModalClose = () => {
    setEdit(false);
    onCancel();
  };

  return (
    <Modal show={show} onCancel={handleModalClose}>
      {edit && (
        <Modal.Header closeButton onClick={handleModalClose}>
          Edit Cost Code
        </Modal.Header>
      )}
      {!edit && (
        <Modal.Header closeButton onClick={handleModalClose}>
          Add Cost Code
        </Modal.Header>
      )}
      <CostCodeForm
        initialValues={initialValues}
        onCancel={handleModalClose}
        onSubmit={hndSubmit}
      />
    </Modal>
  );
};

const CostCodeFilterModal = (props: FilterModalProps) => {
  const { show, onClose, onApply, onClear, currentJob, jobs } = props;
  const [ jobFilter, setJobFilter ] = useState<number|null>(null);

  const handleCloseFilterModal = () => {
    onClose();
  };

  const handleClearFilter = () => {
    onClear();
  };

  const handleApplyFilter = () => {
    onApply(jobFilter);
  };

  const hndSetJob = (value: string|null) => {
    if (value) {
      setJobFilter(Number(value));
    }
  };

  return (
    <>
      <Modal show={show} onHide={handleCloseFilterModal}>
        <Modal.Header closeButton>
          <Modal.Title>Filter Results</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-2">
              <Form.Label>Job:</Form.Label>
              <Form.Select
                defaultValue={currentJob}
                onChange={(e) => hndSetJob(e.target.value)}
              >
                <option value="">Select job...</option>
                {jobs && jobs.map((elem: JobQueryData) => <>
                  <option value={elem.id}>{elem.job_number} - {elem.job}</option>
                </>)}
              </Form.Select>
            </Form.Group>
            </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClearFilter}>
            Clear
          </Button>
          <Button variant="primary" onClick={handleApplyFilter}>
            Apply
          </Button>
          <Button variant="secondary" onClick={handleCloseFilterModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const blankCostCodeForm = () => {
  return {
    cost_code: "",
    cost_type: "",
    description: "",
    short_name: "",
    active: true,
  };
};

const CostCode = () => {
  const [showCostCodeModal, setShowCostCodeModal] = useState(false);
  const [costCodeInitialValues, setCostCodeInitialValues] = useState(
    blankCostCodeForm()
  );
  const [edit, setEdit] = useState(false);
  const [limit, setLimit] = useState<number>(25);
  const [offset, setOffset] = useState<number>(0);
  const [sortField, setSortField] = useState<string>("");
  const [sortDirection, setSortDirection] = useState<string>("");
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [searchJob, setSearchJob] = useState<string>("");
  const [filterExplainer, setFilterExplainer] = useState<
    ReactElement | undefined
  >(undefined);
  const [showFilter, setShowFilter] = useState(false);

  const costcodes = useQuery(costCodeQueryAll(limit, offset, searchTerm, sortField, sortDirection, searchJob));
  const jobs = useQuery(jobQueryAll());

  const formatFilterExplainer = () => {
    setFilterExplainer(undefined);
    let filterString = [];

    if (String(searchJob).trim().length > 0 && jobs.data) {
      const jobData = jobs.data.find((job: JobQueryData) => job.id === parseInt(searchJob));

      if (jobData) {
        filterString.push(`Job ${jobData.job_number} - ${jobData.job}`);
      }
    }

    if (searchTerm.trim().length > 0) {
      filterString.push(`Text: ${searchTerm}`);
    }

    if (filterString.length > 0) {
      setFilterExplainer(<>
        {filterString.join(' - ')}
      </>);
    }
  };

  const hndAddNew = () => {
    setCostCodeInitialValues(blankCostCodeForm());
    setShowCostCodeModal(true);
  };

  const hndEdit = (id: number) => {
    console.log(id);
    setShowCostCodeModal(true);
    setEdit(true);
  };

  const hndModelClose = () => {
    setShowCostCodeModal(false);
    setEdit(false);
    costcodes.refetch();
  };

  const hndSetOffset = (newOffset: number) => {
    setOffset(newOffset);
  };

  const hndSetLimit = (newLimit: number) => {
    setLimit(newLimit);
  };

  const hndSetSort = (field: any, direction: string) => {
    console.log(field, direction);
    setSortField(field.sortField ? field.sortField : "");
    setSortDirection(direction);
  };

  const hndSetSearch = (term: string) => {
    setSearchTerm(term);
    formatFilterExplainer();
  };

  const hndFilterClose = () => {
    setShowFilter(false);
  };

  const hndFilterApply = (job: string) => {
    setSearchJob(job);
    formatFilterExplainer();
    setShowFilter(false);
  };
  
  const hndFilterClear = () => {
    setSearchJob('');
    formatFilterExplainer();
    setShowFilter(false);
  };

  const hndShowFilter = () => {
    setShowFilter(true);
  };

  useEffect(() => {
    costcodes.refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, offset, searchTerm, sortField, sortDirection, searchJob]);

  return (
    <Layout>
      <ToastContainer />
      <Sentry.ErrorBoundary fallback={ErrorFallback}>
        {!costcodes.isLoading ?
          <CostCodeTable
            onAddNew={hndAddNew}
            onEdit={hndEdit}
            costcodes={costcodes}
            setCostCodeInitialValues={setCostCodeInitialValues}
            setShowCostCodeModal={setShowCostCodeModal}
            edit={edit}
            setEdit={setEdit}
            tableCount={costcodes.data?.count ? costcodes.data.count : 0}
            limit={limit}
            offset={offset}
            onShowFilter={hndShowFilter}
            onSetOffset={hndSetOffset.bind(this)}
            onSetLimit={hndSetLimit.bind(this)}
            onSetSort={hndSetSort.bind(this)}
            onSetSearch={hndSetSearch.bind(this)}
            filterExplainer={filterExplainer}
          /> : null}
        <CostCodeModal
          show={showCostCodeModal}
          onOk={hndModelClose}
          onCancel={hndModelClose}
          initialValues={costCodeInitialValues}
          setShowCostCodeModal={setShowCostCodeModal}
          setCostCodeInitialValues={setCostCodeInitialValues}
          edit={edit}
          setEdit={setEdit}
        />
        {jobs.data && <CostCodeFilterModal
          show={showFilter}
          setShowFilterModal={setShowFilter}
          currentJob={searchJob}
          onClose={hndFilterClose}
          onApply={hndFilterApply}
          onClear={hndFilterClear}
          jobs={jobs.data}
        />}
      </Sentry.ErrorBoundary>
    </Layout>
  );
};

export default CostCode;
