import React, { useEffect, useState } from "react";
import { apiClient } from "../../apis/client";
import Layout from "../../components/Layout";
import BasicFilterTable from "../../components/BasicFilterTable";
import { useQuery } from "@tanstack/react-query";
import { toast, ToastContainer } from "react-toastify";
import Select from "react-select";

import "../../styles/reports.css";
import "react-toastify/dist/ReactToastify.css";
import "../../styles/print.css";

import { CompanyQueryData, JobQueryData } from "../../apis/interfaces/common";
import { GLReportRow } from "../../apis/interfaces/tickets";

import { useJobQueryAll } from "../Tickets/Logic/MutationsAndQueries";
import { companyQueryAll } from "../../apis/queries/common";

const { DateTime } = require("luxon");

type ReportData = GLReportRow;

function GLReport() {
  const [reportData, setReportData] = useState<ReportData[] | null>(null);
  const [companies, setCompanies] = useState<CompanyQueryData[]>([]);
  const [jobs, setJobs] = useState<JobQueryData[]>([]);

  const [job, setJob] = useState<number | null>(null);
  const [company, setCompany] = useState<number | null>(null);

  const [monthEnding, setMonthEnding] = useState<Date>(DateTime.now());

  const jobQuery = useJobQueryAll();
  const companyQuery = useQuery(companyQueryAll());

  useEffect(() => {
    if (!jobQuery.isLoading) {
      setJobs(jobQuery.data);
    }
    if (!companyQuery.isLoading) {
      setCompanies(companyQuery.data);
    }
  }, [
    companyQuery.data,
    companyQuery.isLoading,
    jobQuery.data,
    jobQuery.isLoading,
  ]);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    toast.info("Generating report");

    const data = {
      company: company,
      month_ending: monthEnding,
      job: job,
    };

    try {
      const response = await apiClient.get(
        "/tickets/reports/GL_Report/filter/",
        { params: data }
      );
      const cleanedData = Object.values(response.data).filter(
        (company: any) => company.company_id !== null
      );
      setReportData(cleanedData as ReportData[]);
      toast.success("Report loaded.");
    } catch (error) {
      console.error("Error submitting report:", error);
    }
  };

  const handleExportExcel = async (event: React.FormEvent) => {
    event.preventDefault();
    toast.info("Generating report");

    const data = {
      company: company,
      month_ending: monthEnding,
    };

    try {
      const response = await apiClient.post(
        "/tickets/reports/GL_Report/export_excel/",
        data,
        { responseType: "blob" }
      );

      const blob = await response.data;
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = `General Ledger Report - ${new Date().toLocaleString()}.xlsx`;
      link.click();
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error submitting report:", error);
    }
  };

  const filterFn = (keyword: string, item: GLReportRow) => {
    const lowercaseKeyword = keyword.toLowerCase();
    const itemValues = Object.values(item);

    for (const value of itemValues) {
      if (typeof value === "string") {
        const lowercaseValue = value.toLowerCase();

        if (lowercaseValue.includes(lowercaseKeyword)) {
          return true;
        }
      }
    }

    return false;
  };

  const filterButtons = <></>;

  const columns = [
    {
      name: "Company",
      sortable: true,
      selector: (row: GLReportRow) => row.company,
      width: "auto",
    },
    {
      name: "Job Number",
      sortable: true,
      selector: (row: GLReportRow) => row.job,
      width: "auto",
    },
    {
      name: "Cost Code",
      sortable: true,
      selector: (row: GLReportRow) => row.cost_code,
      width: "auto",
      cellStyle: {
        whiteSpace: "normal",
        overflow: "visible",
      },
    },
    {
      name: "Cost Code",
      sortable: true,
      selector: (row: GLReportRow) => row.cost_type,
      width: "auto",
      cellStyle: {
        whiteSpace: "normal",
        overflow: "visible",
      },
    },
    {
      name: "Value",
      sortable: true,
      selector: (row: GLReportRow) =>
        "$" +
        Number(row.value ?? 0).toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
      width: "auto",
    },
    {
      name: "Tax",
      sortable: true,
      selector: (row: GLReportRow) =>
        "$" +
        Number(row.tax ?? 0).toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
      width: "auto",
    },
    {
      name: "Grand Total",
      sortable: true,
      selector: (row: GLReportRow) =>
        "$" +
        Number(row.amount ?? 0).toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
      width: "auto",
    },
  ];

  const renderSummary = (rows: GLReportRow[], company: string) => {
    const initialValue = 0;
    const totalGl = rows.reduce(
      (accumulator, currentValue) => accumulator + currentValue.amount,
      initialValue
    );
    const totalVal = rows.reduce(
        (accumulator, currentValue) => accumulator + currentValue.value,
        initialValue
      );
      const totalTax = rows.reduce(
        (accumulator, currentValue) => accumulator + currentValue.tax,
        initialValue
      );

    return (
      <div>
        <br />
        <div className="ticket-summary text-end d-flex justify-content-between">
          <h5>Total for {company}:</h5>
          <div className="stock-issues">
          <h6 className="summary-text">
              Value: $
              {Number(totalVal ?? 0).toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </h6>
            <h6 className="summary-text">
              Tax: $
              {Number(totalTax ?? 0).toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </h6>
            <h6 className="summary-text">
              Grand Total: $
              {Number(totalGl ?? 0).toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </h6>
          </div>
        </div>
        <br />
        <br />
      </div>
    );
  };

  const options =
    jobs
      ?.filter((jobElem: JobQueryData) => jobElem.active)
      .map((jobElem: JobQueryData) => ({
        value: jobElem.id,
        label: `${jobElem.job_number} - ${jobElem.job}`,
      })) ?? 0;

  const hndSelectJob = (
    selectedOption: { value: number | undefined; label: string } | null
  ) => {
    const job = selectedOption
      ? jobs.find(
          (jobItem: JobQueryData) => jobItem.id === selectedOption.value
        )
      : null;
    setJob(job?.id ?? null);
  };

  function customFilter(option: any, rawInput: any) {
    const searchTerm = rawInput.toLowerCase();
    return option.label.toLowerCase().includes(searchTerm);
  }

  const renderForm = () => {
    return (
      <div>
        <h4>REPORT PARAMETERS</h4>
        <form onSubmit={handleSubmit}>
          <label>Job:</label> <br />
          <Select
            name="job"
            className="form-control"
            onChange={hndSelectJob}
            isClearable
            options={options}
            filterOption={customFilter}
          />
          <label>Company:</label> <br />
          <select
            name="company"
            className="form-control"
            onChange={(e) => setCompany(Number(e.target.value))}
          >
            <option value="">All Companies</option>
            {companies.filter(Boolean).map((item: CompanyQueryData) => (
              <option key={item.id} value={item.id}>
                {item.company_code} - {item.company}
              </option>
            ))}
          </select>
          <label>Month Ending:</label> <br />
          <input
            name="month_ending"
            type="date"
            className="form-control"
            defaultValue={DateTime.local().endOf("month").toISODate()}
            onChange={(e) => setMonthEnding(new Date(e.target.value))}
          ></input>
          <button
            type="submit"
            className="btn btn-sm btn-success mt-2 button-margin"
          >
            Generate Filtered Report
          </button>
          <button
            type="submit"
            className="btn btn-sm btn-success mt-2 button-margin"
            onClick={handleExportExcel}
          >
            Export to Excel
          </button>
          <button
            className="btn btn-sm btn-success mt-2  button-margin"
            onClick={() => window.print()}
          >
            Print/Export PDF
          </button>
        </form>
      </div>
    );
  };

  const findCompanies = (rows: GLReportRow[]) => {
    const companyOut: Array<any> = [];

    rows.forEach((elem: GLReportRow) => {
      if (!companyOut.includes(elem.company)) {
        companyOut.push(elem.company);
      }
    });

    return companyOut;
  };

  const renderTable = (rows: GLReportRow[]) => {
    const companies = findCompanies(rows);

    return companies.map((elem: string) => {
      const companyRows = rows.filter(
        (row: GLReportRow) => row.company === elem
      );

      return (
        <div key={elem}>
          <h3>Company: {elem}</h3>
          <BasicFilterTable
            tableKey="GLReport"
            title={`GL - ${elem}`}
            dataSource={companyRows}
            columns={columns}
            filterFn={filterFn}
            filterButtons={filterButtons}
            exportFileName={`General Ledger Data - ${elem}.csv`}
          />

          {renderSummary(companyRows, elem)}
        </div>
      );
    });
  };

  return (
    <div>
      <Layout>
        <div id="report-title-container" className="hide-printable">
          <ToastContainer />
          <h2>General Ledger Report</h2>
          <br />
          {renderForm()}
          <br />
          <br />
        </div>

        <div className="report-data-printable">
          {reportData && renderTable(reportData)}
        </div>
      </Layout>
    </div>
  );
}

export default GLReport;
