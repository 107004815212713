import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Modal } from "react-bootstrap";
import type {
  CompanyMutationData,
  CompanyQueryData,
} from "../../apis/interfaces/common";
import { companyQueryAll } from "../../apis/queries/common";
import {
  createCompanyMutation,
  updateCompanyMutation,
} from "../../apis/mutations/common";

import CompanyForm from "../../forms/CompanyForm";

import Layout from "../../components/Layout";
import ErrorFallback from "../../components/Error";
import Loader from "../../components/Loader";
import BasicFilterTable from "../../components/BasicFilterTable";
import { toast, ToastContainer } from "react-toastify";
import * as Sentry from "@sentry/react";

interface CompanyTableProps {
  onAddNew: any;
  onEdit: any;
  companies: Array<CompanyQueryData>;
  setCompanyInitialValues: any;
  setShowCompanyModal: any;
  edit: boolean;
  setEdit: any;
}

interface CompanyModalProps {
  show: boolean;
  onCancel: Function;
  onOk: Function;
  initialValues: CompanyMutationData;
  setShowCompanyModal: any;
  setCompanyInitialValues: any;
  edit: boolean;
  setEdit: any;
}

const CompanyModal = (props: CompanyModalProps) => {
  const { show, onCancel, onOk, initialValues, setEdit, edit } = props;
  const queryClient = useQueryClient();
  const createCompany = useMutation({
    ...createCompanyMutation,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["companyQueryAll"] });
      onOk();
    },
  });
  const updateCompanyMutationFn = async (
    params: [number, CompanyMutationData]
  ) => {
    const [id, data] = params;
    try {
      await updateCompanyMutation.mutationFn(id, data);
      onOk();
    } catch (error) {
      // Handle error
    }
  };

  const updateCompany = useMutation(updateCompanyMutationFn);

  const hndSubmit = async (values: CompanyMutationData) => {
    try {
      if (!values.id) {
        await createCompany.mutateAsync(values);
        toast.success("Company created");
      } else {
        await updateCompany.mutateAsync([values.id as number, values]);
        setEdit(false);
        toast.success("Company updated");
      }
    } catch (err) {
      console.log(err);
      toast.error("An error occurred while updating the company");
    }
  };
  const handleModalClose = () => {
    onCancel();
    setEdit(false);
  };

  return (
    <Modal show={show} onCancel={handleModalClose}>
      {edit && (
        <Modal.Header closeButton onClick={handleModalClose}>
          Edit Company
        </Modal.Header>
      )}
      {!edit && (
        <Modal.Header closeButton onClick={handleModalClose}>
          Add Company
        </Modal.Header>
      )}
      <CompanyForm
        initialValues={initialValues}
        onCancel={handleModalClose}
        onSubmit={hndSubmit}
      />
    </Modal>
  );
};

const CompanyTable = (props: CompanyTableProps) => {
  const {
    onAddNew,
    companies,
    setShowCompanyModal,
    setCompanyInitialValues,
    setEdit,
  } = props;

  const fireOnEdit = (row: CompanyQueryData) => {
    setCompanyInitialValues({
      id: row.id,
      company: row.company,
      company_code: row.company_code,
      active: row.active,
    });
    setEdit(true);
    setShowCompanyModal(true);
  };

  const columns = [
    {
      name: "Name",
      sortable: true,
      selector: (row: CompanyQueryData) => row.company,
    },
    {
      name: "Company Code",
      sortable: true,
      selector: (row: CompanyQueryData) => row.company_code,
    },
    {
      name: "Active",
      sortable: true,
      selector: (row: CompanyQueryData) => row.active,
      cell: (row: CompanyQueryData) => (
        <>
          {row.active ? (
            <span className="text-success">&#x2714;</span> // Checkmark symbol
          ) : (
            <span className="text-danger">&#x2718;</span> // Cross symbol
          )}
        </>
      ),
    },
    {
      name: "Actions",
      cell: (row: CompanyQueryData) => (
        <>
          <button
            className="btn btn-sm btn-link"
            onClick={fireOnEdit.bind(this, row)}
          >
            Edit
          </button>
        </>
      ),
    },
  ];
  const filterFn = (keyword: string, item: CompanyQueryData) => {
    const lowercaseKeyword = keyword.toLowerCase();
    const itemValues = Object.values(item);

    for (const value of itemValues) {
      if (typeof value === "string") {
        const lowercaseValue = value.toLowerCase();

        if (lowercaseValue.includes(lowercaseKeyword)) {
          return true;
        }
      }
    }

    return false;
  };

  const filterButtons = (
    <>
      <button className="btn btn-link" onClick={onAddNew}>
        Add New
      </button>
    </>
  );
  return (
    <div className="row">
      <div className="col-12">
        <div className="border border-secondary-subtle p-0 d-flex">
          <div className="align-self-center mr-2"></div>
        </div>
        <div className="p-2">
          <BasicFilterTable
            tableKey="companies"
            title="Companies"
            dataSource={companies}
            columns={columns}
            filterFn={filterFn}
            filterButtons={filterButtons}
            exportFileName={"Company Export Data.csv"}
          />
        </div>
      </div>
    </div>
  );
};

const blankCompanyForm = () => {
  return {
    company: "",
    company_code: "",
    active: true,
  };
};

const Company = () => {
  const [showCompanyModal, setShowCompanyModal] = useState(false);
  const [companyInitialValues, setCompanyInitialValues] = useState(
    blankCompanyForm()
  );
  const [edit, setEdit] = useState(false);

  const companies = useQuery(companyQueryAll());

  const sortedCompanies =
    companies.data?.sort((a: CompanyQueryData, b: CompanyQueryData) => {
      return (b.active ? 1 : 0) - (a.active ? 1 : 0); // This will ensure active companies come first
    }) || [];

  const hndAddNew = () => {
    setCompanyInitialValues(blankCompanyForm());
    setShowCompanyModal(true);
  };

  const hndModelClose = () => {
    setShowCompanyModal(false);
    setEdit(false);
    companies.refetch();
  };

  return (
    <Layout>
      <Loader isLoading={companies.isLoading && companies.data === undefined}>
        <Sentry.ErrorBoundary fallback={ErrorFallback}>
         
            <CompanyTable
              onAddNew={hndAddNew}
              onEdit={hndModelClose}
              companies={sortedCompanies}
              setCompanyInitialValues={setCompanyInitialValues}
              setShowCompanyModal={setShowCompanyModal}
              edit={edit}
              setEdit={setEdit}
            />
          
        </Sentry.ErrorBoundary>
      </Loader>
      <ToastContainer />
      <CompanyModal
        show={showCompanyModal}
        onOk={hndModelClose}
        onCancel={hndModelClose}
        initialValues={companyInitialValues}
        setShowCompanyModal={setShowCompanyModal}
        setCompanyInitialValues={setCompanyInitialValues}
        edit={edit}
        setEdit={setEdit}
      />
    </Layout>
  );
};

export default Company;
