import React, { useEffect, useState } from "react";
import { apiClient } from "../../apis/client";
import Layout from "../../components/Layout";
import BasicFilterTable from "../../components/BasicFilterTable";
import { useStockCategoryQueryAll } from "../Tickets/Logic/MutationsAndQueries";
import { StockCategoryQueryData } from "../../apis/interfaces/invitems";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../styles/print.css";

interface FormData {
  category: string;
}

interface ReportData {
  id: number;
  stock_category__stock_category: string;
  stock_type: string;
  stock: string;
  quantity_on_hand: number;
}

function StockedItemsReport() {
  const [formData, setFormData] = useState<FormData | null>(null);
  const [reportData, setReportData] = useState<ReportData[] | null>(null);
  const [categories, setCategories] = useState<StockCategoryQueryData[]>([]);
  const stockCategories = useStockCategoryQueryAll();
  useEffect(() => {
    
    if (!stockCategories.isLoading) {
      setCategories(stockCategories.data);
    }
  }, [stockCategories.data, stockCategories.isLoading]);
  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    toast.info("Generating report");

    const data = {
      category: formData?.category,
    };
    try {
      const response = await apiClient.post(
        "/invitems/reports/StockedItems/filter/",
        data
      );
      setReportData(response.data);
      toast.success("Report loaded.");
    } catch (error) {
      console.error("Error submitting report:", error);
    }
  };
  const handleExportExcel = async (event: React.FormEvent) => {
    event.preventDefault();
    toast.info("Generating report");

    try {
      const response = await apiClient.post(
        "/invitems/reports/StockedItems/export_excel/",
        { category: formData?.category },
        { responseType: "blob" } // Set the responseType to 'blob' to receive a Blob object in the response
      );
      const blob = await response.data;
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = `Stocked Items Report - ${new Date().toLocaleString()}.xlsx`;
      link.click();
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error submitting report:", error);
    }
  };
  const filterFn = (keyword: string, item: ReportData) => {
    const lowercaseKeyword = keyword.toLowerCase();
    const itemValues = Object.values(item);

    for (const value of itemValues) {
      if (typeof value === "string") {
        const lowercaseValue = value.toLowerCase();

        if (lowercaseValue.includes(lowercaseKeyword)) {
          return true;
        }
      }
    }

    return false;
  };

  const filterButtons = <></>;
  const columns = [
    {
        name: "ID",
        sortable: true,
        selector: (row: ReportData) => row.id,
        width: "15%",
    },
    {
        name: "Name",
        sortable: true,
        selector: (row: ReportData) => row.stock,
        wrap: true,
        width: "20%",
    },
    {
        name: "Stock Category",
        sortable: true,
        selector: (row: ReportData) => row.stock_category__stock_category,
        wrap: true,
        width: "20%",
    },
    {
        name: "Stock Type",
        sortable: true,
        selector: (row: ReportData) => row.stock_type,
        width: "30%",
    },
    {
        name: "Quantity",
        sortable: true,
        selector: (row: ReportData) => row.quantity_on_hand,
        width: "15%",
    },
];

  return (
    <div>
      <Layout>
      <div id="report-title-container" className="hide-printable">

        <ToastContainer />
        <h2>Stocked Items Report</h2>
        <br></br>
        <h4>REPORT PARAMETERS</h4>
        <form onSubmit={handleSubmit}>
          <label>Stock Category:</label> <br />
          <select
            name="department"
            className="form-control"
            onChange={(e) => setFormData({ category: e.target.value })}
          >
            <option value="">All Stock Categories</option>
            {categories.filter(Boolean).map((item: StockCategoryQueryData) => (
              <option key={item.id} value={item.id}>
                {item.stock_category}
              </option>
            ))}
          </select>
          <button type="submit" className="btn btn-sm btn-success mt-2 button-margin">
            Generate Filtered Report
          </button>
          <button
            type="submit"
            className="btn btn-sm btn-success mt-2 button-margin"
            onClick={handleExportExcel}
          >
            Export to Excel
          </button>
          <button
            className="btn btn-sm btn-success mt-2  button-margin"
            onClick={() => window.print()}
          >
            Print/Export PDF
          </button>
        </form>

        <br />
        <br />
        </div>

        <div className="report-data-printable">
        {reportData && (
          <BasicFilterTable
            tableKey="StockedItems"
            title="Stocked Items Report Results"
            dataSource={reportData as ReportData[]}
            columns={columns}
            filterFn={filterFn}
            filterButtons={filterButtons}
            exportFileName={"Job Export Data.csv"}
          />
        )}
        </div>

      </Layout>
    </div>
  );
}

export default StockedItemsReport;
