import React, { useEffect, useState } from "react";
import { apiClient } from "../../apis/client";
import Layout from "../../components/Layout";
import "../../styles/reports.css";
import { useJobQueryAll } from "../Tickets/Logic/MutationsAndQueries";
import {
  CompanyQueryData,
  DepartmentQueryData,
  JobQueryData,
} from "../../apis/interfaces/common";
import { companyQueryAll, departmentQueryAll } from "../../apis/queries/common";
import { useQuery } from "@tanstack/react-query";
import DataTable from "react-data-table-component";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../styles/print.css";
import Select from "react-select";
import type { StockChargeQueryData } from "../../apis/interfaces/tickets";

const { DateTime } = require("luxon");

interface JobAggregate {
  job: string;
  job_number: string;

  company: string;
  company_code: string;
  company_id: number;

  total_tickets: number;
  total_charges: number;
  total_rent: number;

  total_consumables: number;
  total_small_tools: number;

  total_value: number;
  total_freight: number;
  total_tax: number;
  total_grand: number;

  total_charge_value: number;
  total_charge_tax: number;
  total_charge_nontaxable: number;
  total_rent_value: number;

  consumables: Array<StockAggregate>;
  charges: Array<StockChargeQueryData>;
  
  small_tools: Array<StockAggregate>;
}

interface StockAggregate {
  id: number;
  stock: string;
  quantity: number;
  ycc_serial: string;
  unit_price: number;
  total_value: number;
  total_freight: number;
  total_tax: number;
  total_grand: number;
}

interface StockIssueDetail {
  id: number;
  created_at: string;
  quantity: number;
  stock: string;
  stock_type: string;
  unit_price: number;
  sales_tax_percentage: number;
  item_amt: number;
  total_amt: number;
  tax_amt: number;
  freight_charge: number;
}

interface Job {
  job_id: number;
  job_number: string;
  job: string;
  company__id: number;
  company__company_code: string;
  company__company: string;
  department__id: number;
  stock_issue_details: StockIssueDetail[];
}

interface Data {
  [key: string]: JobAggregate;
}

function StockIssueJTD() {
  const [data, setData] = useState<Data>({});
  const [jobs, setJobs] = useState<JobQueryData[]>([]);
  const [companies, setCompanies] = useState<CompanyQueryData[]>([]);
  const [depts, setDepts] = useState<DepartmentQueryData[]>([]);
  const [job, setJob] = useState(0);
  const [company, setCompany] = useState(0);
  const [dept, setDept] = useState(0);
  const [monthEnding, setMonthEnding] = useState<Date>(DateTime.now());
  const jobQuery = useJobQueryAll();
  const companyQuery = useQuery(companyQueryAll());
  const departmentQuery = useQuery(departmentQueryAll());

  useEffect(() => {
    if (!jobQuery.isLoading) {
      setJobs(jobQuery.data);
    }
    if (!companyQuery.isLoading) {
      setCompanies(companyQuery.data);
    }
    if (!departmentQuery.isLoading) {
      setDepts(departmentQuery.data);
    }
  }, [
    companyQuery.data,
    companyQuery.isLoading,
    departmentQuery.data,
    departmentQuery.isLoading,
    jobQuery.data,
    jobQuery.isLoading,
  ]);
  
  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    toast.info("Generating report");

    const data = {
      job: job,
      company: company,
      department: dept,
      enddate: monthEnding,
    };
    try {
      const response = await apiClient.get(
        "tickets/reports/StockIssueJTD/filter/",
        { params: data },
      );

      setData(response.data);
      toast.success("Report loaded.");
    } catch (error) {
      console.error("Error fetching report data:", error);
      toast.error("An error occurred generating the report");
    }
  };

  const handleExport = async (event: React.FormEvent) => {
    event.preventDefault();
    toast.info("Generating report");

    const data = {
      job: job,
      company: company,
      department: dept,
      enddate: monthEnding,
    };
    try {
      const response = await apiClient.get(
        "/tickets/reports/StockIssueJTD/filter/?output=xlsx",
        { params: data, responseType: "blob" } // Set the responseType to 'blob' to receive a Blob object in the response
      );
      const blob = await response.data;
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = `JTD Values - ${monthEnding.toLocaleString()}.xlsx`;
      link.click();
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error submitting report:", error);
    }
  };

  const stockAggregateColumns = [
    { name: "ID", selector: (row: StockAggregate) => row.id, width: "80px" },
    {
      name: "Stock",
      selector: (row: StockAggregate) => row.stock,
      wrap: true,
    },

    {
      name: "JTDQty",
      selector: (row: StockAggregate) => Number(row.quantity),
      width: "80px",
    },

    {
      name: "JTDValue (Taxable)",
      selector: (row: StockAggregate) =>
        Number(row.total_value).toLocaleString("en-US", { style: "currency", currency: "USD" }),
    },
    {
      name: "Freight (non-taxable)",
      selector: (row: StockAggregate) =>
        Number(row.total_freight).toLocaleString("en-US", { style: "currency", currency: "USD" }),
    },
    {
      name: "JTDTax",
      selector: (row: StockAggregate) =>
        Number(row.total_tax).toLocaleString("en-US", { style: "currency", currency: "USD" }),
    },

    {
      name: "JTDTotal",
      selector: (row: StockAggregate) =>
        Number(row.total_grand).toLocaleString("en-US", { style: "currency", currency: "USD" }),
    },
  ];

  const chargesColumns = [
    {
      name: "ID",
      selector: (row: any) => row.id,
      width: "80px"
    },
    {
      name: "Ticket",
      selector: (row: any) => row.stock_issue,
      width: "80px",
    },
    {
      name: "Type",
      selector: (row: any) => row.charge ? row.charge.charge_number : "UNKNOWN",
      width: "80px",
    },
    {
      name: "Charge Date",
      selector: (row: any) => DateTime.fromISO(row.charge_date).toLocaleString(),
    },
    {
      name: "Charge Amt",
      selector: (row: any) =>
        Number(row.charge_amount).toLocaleString("en-US", { style: "currency", currency: "USD" }),
    },
    {
      name: "Tax",
      selector: (row: any) =>
        Number(row.charge_tax).toLocaleString("en-US", { style: "currency", currency: "USD" }),
    },
    {
      name: "Nontaxable",
      selector: (row: any) =>
        Number(row.nontaxable).toLocaleString("en-US", { style: "currency", currency: "USD" }),
    },
    {
      name: "Comments",
      selector: (row: any) => row.comments,
      wrap: true,
      width: "50%",
    },
  ];


  const options = jobs
  ?.filter((jobElem: JobQueryData) => jobElem.active)
  .map((jobElem: JobQueryData) => ({
    value: jobElem.id,
    label: `${jobElem.job_number} - ${jobElem.job}`,
  })) ?? 0;

const hndSelectJob = (
  selectedOption: { value: number | undefined; label: string } | null
) => {
  const job = selectedOption
    ? jobs.find(
        (jobItem: JobQueryData) => jobItem.id === selectedOption.value
      )
    : null;
  setJob(job?.id ?? 0);
};

function customFilter(option:any , rawInput: any) {
  const searchTerm = rawInput.toLowerCase();
  return option.label.toLowerCase().includes(searchTerm);
}

return (
    <div>
      <Layout>
      <div id="report-title-container" className="hide-printable">

        <ToastContainer />
        <h2>Monthly JTD Value Report</h2>
        <br></br>
        <h4>REPORT PARAMETERS</h4>
        <form onSubmit={handleSubmit}>
          <label>Job:</label> <br />
          <Select
                  name="job"
                  className="form-control"
                  onChange={hndSelectJob}
                  options={options}
                  isClearable
                  filterOption={customFilter}
                />
          <label>Company:</label> <br />
          <select
            name="company"
            className="form-control"
            onChange={(e) => setCompany(Number(e.target.value))}
          >
            <option value="">All Companies</option>
            {companies.filter(Boolean).map((item: CompanyQueryData) => (
              <option key={item.id} value={item.id}>
                {item.company_code} - {item.company}
              </option>
            ))}
          </select>
          <label>Department:</label> <br />
          <select
            name="dept"
            className="form-control"
            onChange={(e) => setDept(Number(e.target.value))}
          >
            <option value="">All Departments</option>
            {depts.filter(Boolean).map((item: DepartmentQueryData) => (
              <option key={item.id} value={item.id}>
                {item.department_number} - {item.department}
              </option>
            ))}
          </select>
          <label>Month Ending:</label> <br />
          <input
            name="month_ending"
            type="date"
            className="form-control"
            defaultValue={DateTime.local().endOf("month").toISODate()}
            onChange={(e) => setMonthEnding(new Date(e.target.value))}
          ></input>
          <button
            type="submit"
            className="btn btn-sm btn-success mt-2 button-margin"
          >
            Generate Filtered Report
          </button>
          <button
            type="submit"
            className="btn btn-sm btn-success mt-2 button-margin"
            onClick={handleExport}
          >
            Export to Excel
          </button>
          <button
            className="btn btn-sm btn-success mt-2  button-margin"
            onClick={() => window.print()}
          >
            Print/Export PDF
          </button>
        </form>

        <br />
        <br />
        </div>

        <div className="report-data-printable">

        {Object.values(data).map((job: JobAggregate) => (<div key={job.job}>
            <h2>
              { job && job.company ? job.company : "no company"} <br />
            </h2>
            <h3>
              {" "}
              Job: {job.job_number} - {job.job}
            </h3>

            <DataTable
              data={job.consumables}
              columns={stockAggregateColumns}
              title={`Job ${job.job_number} Consumable Values`}
            />

            <div className="summary-section">
              <strong>Consumables Summary for {job.job}:</strong>
              <div>
                Total JTDValue (Taxable): 
                {" "} {Number(job.total_value).toLocaleString("en-US", { style: "currency", currency: "USD" })}
              </div>
              <div>
                Total Freight (non-taxable): 
                {" "} {Number(job.total_freight).toLocaleString("en-US", { style: "currency", currency: "USD" })}
              </div>
              <div>
                Total JTDTax: 
                {" "} {Number(job.total_tax).toLocaleString("en-US", { style: "currency", currency: "USD" })}
              </div>
              <div>
                Total JTDTotal: 
                {" "} {Number(job.total_grand).toLocaleString("en-US", { style: "currency", currency: "USD" })}
              </div>
            </div>
            <br />
            <br />
            
            <DataTable
              data={job.charges}
              columns={chargesColumns}
              title={`Job ${job.job_number} Charges`}
            />

            <div className="summary-section">
              <strong>Charges Summary for {job.job}:</strong>
              <div>
                Total Misc Charge: 
                {" "} {Number(job.total_charge_value).toLocaleString("en-US", { style: "currency", currency: "USD" })}
              </div>
              <div>
                Total Misc Charge Nontaxable: 
                {" "} {Number(job.total_charge_nontaxable).toLocaleString("en-US", { style: "currency", currency: "USD" })}
              </div>
              <div>
                Total Misc Charge Taxable: 
                {" "} {Number(job.total_charge_tax).toLocaleString("en-US", { style: "currency", currency: "USD" })}
              </div>
              <div>
                Total Rent Charge: 
                {" "} {Number(job.total_rent_value).toLocaleString("en-US", { style: "currency", currency: "USD" })}
              </div>
            </div>
            <br />
            <br />

            <div className="summary-section">
              <strong>JTD Summary for {job.job}:</strong>
              <div>
                Total Tickets: 
                {" "} {job.total_tickets}
              </div>
              <div>
                Total Charges: 
                {" "} {(job.total_charges - job.total_rent)}
              </div>
              <div>
                Total Rent Charges: 
                {" "} {job.total_rent}
              </div>
              <div>
                Total Consumables: 
                {" "} {job.total_consumables}
              </div>
              <div>
                Total Small Tools: 
                {" "} {job.total_small_tools}
              </div>
            </div>
            <br />
            <br />
          </div>
        ))}
        </div>
      </Layout>
    </div>
  );
}

export default StockIssueJTD;
