import React, { useEffect, useState } from "react";
import { apiClient } from "../../apis/client";
import Layout from "../../components/Layout";
import {
  CompanyQueryData,
  SalesTaxQueryData,
} from "../../apis/interfaces/common";
import { companyQueryAll, salesTaxQueryAll } from "../../apis/queries/common";
import { useQuery } from "@tanstack/react-query";
import DataTable from "react-data-table-component";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../styles/print.css";

const { DateTime } = require("luxon");

interface ReportData {
  id: number;
  issue_date: Date;
  department_id: null | number;
  job_id: number;
  job_number: string;
  issue_total: number;
  tax_total: number;
  item_total: number;
  tax_entity: string;
  tax_id: number;
}

interface CompanyStockIssues {
  [id: string]: ReportData;
}

interface CompanyData {
  company_id: number;
  company__company_code: string;
  company__company: string;
  stock_issues: CompanyStockIssues;
  stock_charges: CompanyStockIssues;
}

function MonthlySalesTaxReport() {
  const [reportData, setReportData] = useState<CompanyData[]>([]);
  const [companies, setCompanies] = useState<CompanyQueryData[]>([]);
  const [salesTaxes, setSalesTaxes] = useState<SalesTaxQueryData[]>([]);
  const [monthEnding, setMonthEnding] = useState(DateTime.now());
  const [location, setLocation] = useState("");
  const [company, setCompany] = useState("");

  const companyQuery = useQuery(companyQueryAll());
  const taxQuery = useQuery(salesTaxQueryAll);

  useEffect(() => {
    if (!companyQuery.isLoading) {
      setCompanies(companyQuery.data || []);
    }
    if (!taxQuery.isLoading) {
      setSalesTaxes(taxQuery.data || []);
    }
  }, [
    companyQuery.data,
    companyQuery.isLoading,
    taxQuery.data,
    taxQuery.isLoading,
  ]);

  const handleSubmit = async (event: React.FormEvent) => {
    toast.info("Generating report");
    event.preventDefault();

    const data = {
      company: company,
      location: location,
      month_ending: monthEnding,
    };

    try {
      const response = await apiClient.post(
        "/tickets/reports/SalesTax/filter/",
        data
      );
      setReportData(response.data);
      toast.success("Report loaded.");
    } catch (error) {
      console.error("Error fetching report data:", error);
      toast.error("An error occurred generating the report");
    }
  };

  const handleExportExcel = async (event: React.FormEvent) => {
    event.preventDefault();
    toast.info("Generating report");

    const data = {
      company: company,
      location: location,
      month_ending: monthEnding,
    };

    try {
      const response = await apiClient.post(
        "/tickets/reports/SalesTax/export_excel/",
        data,
        {
          responseType: "blob",
        }
      );

      const blob = await response.data;
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = `MonthlySalesTaxReport - ${monthEnding.toLocaleString()}.xlsx`;
      link.click();
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error submitting report:", error);
    }
  };

  const columns = [
    {
      name: "ID",
      sortable: true,
      selector: (row: ReportData) => row.id,
    },
    {
      name: "Issue Date",
      sortable: true,
      selector: (row: ReportData) =>
        row.issue_date?.toLocaleString().slice(0, 10) ?? "",
    },
    {
      name: "Job Number",
      sortable: true,
      selector: (row: ReportData) => row.job_number,
    },
    {
      name: "Tax Entity",
      sortable: true,
      selector: (row: ReportData) => row.tax_entity,
    },
    {
      name: "Item Total",
      sortable: true,
      selector: (row: ReportData) =>
        "$" +
        Number(row.item_total).toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
    },
    {
      name: "Tax Total",
      sortable: true,
      selector: (row: ReportData) =>
        "$" +
        Number(row.tax_total).toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
    },
    {
      name: "Issue Total",
      sortable: true,
      selector: (row: ReportData) =>
        "$" +
        Number(row.issue_total).toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
    },
  ];
  const calculateTotals = (data: ReportData[]) => {
    let itemTotal = 0;
    let taxTotal = 0;
    let issueTotal = 0;

    data.forEach((issue) => {
      itemTotal += Number(issue.item_total);
      taxTotal += Number(issue.tax_total);
      issueTotal += Number(issue.issue_total);
    });

    return { itemTotal, taxTotal, issueTotal };
  };
  const RenderTotalsRow = ({ data }: { data: ReportData[] }) => {
    const { itemTotal, taxTotal, issueTotal } = calculateTotals(data);

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
          justifyContent: "space-around",
          marginRight: "10px", // Adjust this value as needed
        }}
      >
        <div>Item Total: ${itemTotal.toFixed(2)}</div>
        <div>Tax Total: ${taxTotal.toFixed(2)}</div>
        <div>Issue Total: ${issueTotal.toFixed(2)}</div>
      </div>
    );
  };

  return (
    <div>
      <Layout>
      <div id="report-title-container" className="hide-printable">

        <ToastContainer />
        <h2>Monthly Sales Tax Report</h2>
        <br />
        <h4>REPORT PARAMETERS</h4>
        <form onSubmit={handleSubmit}>
          <label>Company:</label> <br />
          <select
            name="company"
            className="form-control"
            onChange={(e) => setCompany(e.target.value)}
          >
            <option value="">All Companies</option>
            {companies.filter(Boolean).map((item: CompanyQueryData) => (
              <option key={item.id} value={item.id}>
                {item.company_code}: {item.company}
              </option>
            ))}
          </select>
          <label>Taxing Entity:</label> <br />
          <select
            name="location"
            className="form-control"
            onChange={(e) => setLocation(e.target.value)}
          >
            <option value="">All Tax Entities</option>
            {salesTaxes.filter(Boolean).map((item: SalesTaxQueryData) => (
              <option key={item.id} value={item.id}>
                {item.sales_tax_code}: {item.sales_tax}
              </option>
            ))}
          </select>
          <label>Month Ending:</label> <br />
          <input
            name="month_ending"
            type="date"
            className="form-control"
            defaultValue={DateTime.local().endOf("month").toISODate()}
            onChange={(e) => setMonthEnding(DateTime.fromISO(e.target.value))}
          />
          <button type="submit" className="btn btn-sm btn-success mt-2 button-margin">
            Generate Filtered Report
          </button>
          <button
            type="submit"
            className="btn btn-sm btn-success mt-2 button-margin"
            onClick={handleExportExcel}
          >
            Export to Excel
          </button>
          <button
            className="btn btn-sm btn-success mt-2  button-margin"
            onClick={() => window.print()}
          >
            Print/Export PDF
          </button>
        </form>

        <br />
        <br />

        </div>

        <div className="report-data-printable">

        {Object.entries(reportData).map(([companyId, companyData]) => {
          // Convert stock issues from an object to an array.
          const stockIssuesArray = Object.values(companyData.stock_issues);
          const stockChargesArray = Object.values(companyData.stock_charges);
          const combinedArray = stockIssuesArray.concat(stockChargesArray);
          // Group by tax_id
          const groupedByTaxId: { [taxId: string]: any[] } = {};

          combinedArray.forEach((issue) => {
            if (!groupedByTaxId[issue.tax_id]) {
              groupedByTaxId[issue.tax_id] = [];
            }
            groupedByTaxId[issue.tax_id].push(issue);
          });

          return (
            <div key={companyId}>
              <h4>
                Company: {companyData.company__company_code} -{" "}
                {companyData.company__company}
              </h4>
              {Object.entries(groupedByTaxId).map(([taxId, issuesForTaxId]) => {
                const firstIssue = issuesForTaxId[0];
                const firstTaxEntity = firstIssue
                  ? firstIssue.tax_entity
                  : null;

                return (
                  <div key={taxId}>
                    <h5>
                      Tax Entity: {firstTaxEntity}
                    </h5>
                    <DataTable
                      data={issuesForTaxId}
                      columns={columns}
                    />
                    <RenderTotalsRow data={issuesForTaxId} />
                  </div>
                );
              })}
            </div>
          );
        })}
        </div>
      </Layout>
    </div>
  );
}

export default MonthlySalesTaxReport;
