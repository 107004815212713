import React, { ReactElement, useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Modal } from "react-bootstrap";
import type {
  CompanyQueryData,
  JobMutationData,
  JobQueryData,
  SalesTaxQueryData,
} from "../apis/interfaces/common";
import {
  companyQueryAll,
  jobQuery,
  jobQueryAll,
  salesTaxQueryAll,
} from "../apis/queries/common";
import { createJobMutation, updateJobMutation } from "../apis/mutations/common";

import JobForm from "../forms/JobForm";

import Layout from "../components/Layout";
import Loader from "../components/Loader";
import BasicFilterTable from "../components/BasicFilterTable";
import FilterJobModal from "../components/filters/JobFilter";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ErrorFallback from "../components/Error";
import * as Sentry from "@sentry/react";

interface JobTableProps {
  onAddNew: any;
  onEdit: any;
  jobs: Array<JobQueryData>;
  setJobInitialValues: any;
  setShowJobModal: any;
}

interface JobModalProps {
  show: boolean;
  onCancel: Function;
  onOk: Function;
  initialValues: JobMutationData;
  setShowJobModal: any;
  setJobInitialValues: any;
}
interface FilterDataProps {
  company: number;
  sales_tax: number;
  active: boolean;
}

const JobModal = (props: JobModalProps) => {
  const { show, onCancel, onOk, initialValues } = props;
  const queryClient = useQueryClient();
  const createJob = useMutation({
    ...createJobMutation,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["jobQueryAll"] });
      onOk();
    },
  });
  const updateJobMutationFn = async (params: [number, JobMutationData]) => {
    const [id, data] = params;
    try {
      data.company = data.companies[0];
      await updateJobMutation.mutationFn(id, data);
      onOk();
      toast.success("Job updated successfully");
    } catch (error) {
      toast.error("Error updating job");
    }
  };

  const updateJob = useMutation(updateJobMutationFn);

  const hndSubmit = async (values: JobMutationData) => {
    if (!values.id) {
      try {
        values.company = values.companies[0];
        createJob.mutate(values);
        toast.success("Job created successfully");
      } catch (err) {
        toast.error("Error creating job");
      }
    } else {
      updateJob.mutate([values.id as number, values]);
    }
  };
  const handleModalClose = () => {
    onCancel();
  };

  return (
    <Modal show={show} onCancel={handleModalClose}>
      <Modal.Header closeButton onClick={handleModalClose}>
        Add Job
      </Modal.Header>
      <JobForm
        initialValues={initialValues}
        onCancel={handleModalClose}
        onSubmit={hndSubmit}
      />
    </Modal>
  );
};

const JobTable = (props: JobTableProps) => {
  const { onAddNew, jobs, setShowJobModal, setJobInitialValues } = props;

  const { data: company_data } = useQuery(companyQueryAll());
  const { data: tax_data } = useQuery(salesTaxQueryAll);

  const [companies, setCompanies] = React.useState<CompanyQueryData[]>([]);
  const [taxes, setTaxes] = React.useState<SalesTaxQueryData[]>([]);
  const [showFilter, setShowFilter] = useState(false);
  const [filterExplainer, setFilterExplainer] = useState<
    ReactElement | undefined
  >(<>Active Records Only</>);
  const [filteredDataSource, setFilteredDataSource] =
    useState<JobQueryData[]>(jobs);
  const [filteredData, setFilteredData] = useState<JobQueryData[]>([]);
  const [tableData, setTableData] = useState<JobQueryData[]>([]);
  const [noResults, setNoResults] = useState(false);
  const hndFilterOpen = () => {
    setShowFilter(true);
  };
  const hndFilterClose = () => {
    setShowFilter(false);
  };

  const hndFilterApply = (filterData: FilterDataProps) => {
    // Apply the filter to the data and update the state
    setNoResults(false);
    let newFilteredData: Array<JobQueryData>;
    newFilteredData = jobs.filter(
      (row: JobQueryData) =>
        row.companies.includes(filterData.company) ||
        row.sales_tax.id === filterData.sales_tax ||
        row.active === filterData.active
    );
    if (newFilteredData.length === 0) {
      setFilteredData([]);
      setNoResults(true);
    }
    setFilteredData(newFilteredData);

    const explainerRow: Array<string> = [];

    if (filterData.company > 0) {
      explainerRow.push(`Company ${filterData.company}`);
    }

    if (filterData.sales_tax > 0) {
      explainerRow.push(`Sales Tax ${filterData.sales_tax}`);
    }

    if (filterData.active) {
      explainerRow.push("Active Records Only");
    } else {
      explainerRow.push("Inactive Records Only");
    }
    setFilterExplainer(<>{explainerRow.join(", ")}</>);

    hndFilterClose();
  };
  React.useEffect(() => {
    if (company_data) {
      setCompanies(company_data);
    }
    if (tax_data) {
      setTaxes(tax_data);
    }
  }, [company_data, tax_data]);

  const fireOnEdit = (row: JobQueryData) => {
    setJobInitialValues({
      id: row.id,
      job: row.job,
      job_number: row.job_number,
      companies: row.companies,
      company: row.company,
      sales_tax: row.sales_tax,
      active: row.active,
      users: row.users,
    });

    setShowJobModal(true);
  };

  const columns = [
    {
      name: "Name",
      sortable: true,
      selector: (row: JobQueryData) => row.job,
    },
    {
      name: "Job Number",
      sortable: true,
      selector: (row: JobQueryData) => row.job_number,
    },
    {
      name: "Company",
      sortable: true,
      selector: (row: JobQueryData) => {
        if (row.companies) {
          const companyData = row.companies.map((companyId) => {
            const companyObject = companies.find(
              (company) => company.id === companyId
            );
            return companyObject?.company || "";
          });

          // Join the company names together if there are multiple
          return companyData.join(", ");
        } else {
          // return a default value if companies is undefined
          return "";
        }
      },
    },
    {
      name: "Sales Tax",
      sortable: true,
      selector: (row: JobQueryData) => {
        const taxData = taxes.find((tax) => {
          return tax.id === Number(row.sales_tax);
        });

        return taxData?.sales_tax || "";
      },
    },
    {
      name: "Active",
      sortable: true,
      selector: (row: JobQueryData) => row.active,
      cell: (row: JobQueryData) => (
        <>
          {row.active ? (
            <span className="text-success">&#x2714;</span> // Checkmark symbol
          ) : (
            <span className="text-danger">&#x2718;</span> // Cross symbol
          )}
        </>
      ),
    },
    {
      name: "Actions",
      cell: (row: JobQueryData) => (
        <>
          <button
            className="btn btn-sm btn-link"
            onClick={fireOnEdit.bind(this, row)}
          >
            Edit
          </button>
        </>
      ),
    },
  ];
  const filterFn = (keyword: string, item: JobQueryData) => {
    const lowercaseKeyword = keyword.toLowerCase();
    const itemValues = Object.values(item);

    for (const value of itemValues) {
      if (typeof value === "string") {
        const lowercaseValue = value.toLowerCase();

        if (lowercaseValue.includes(lowercaseKeyword)) {
          return true;
        }
      }
    }

    return false;
  };

  const filterButtons = (
    <>
      <button className="btn btn-link" onClick={hndFilterOpen}>
        Filter Results
      </button>
      <button className="btn btn-link" onClick={onAddNew}>
        Add New
      </button>
    </>
  );

  useEffect(() => {
    if (filteredData.length > 0) {
      setTableData(filteredData);
    } else if (noResults) {
      setTableData([]);
    } else if (jobs) {
      const activeJobs = jobs.filter((job) => job.active);
      setTableData(activeJobs);
    }
  }, [filteredData, filteredDataSource, jobs, noResults]);

  return (
    <div className="row">
      <ToastContainer />

      <div className="col-12">
        <div className="border border-secondary-subtle p-0 d-flex">
          <div className="align-self-center mr-2"></div>
        </div>
        <div className="p-2">
          <BasicFilterTable
            tableKey="jobs"
            title="Jobs"
            dataSource={tableData}
            columns={columns}
            filterFn={filterFn}
            filterButtons={filterButtons}
            exportFileName={"Job Export Data.csv"}
            filterExplainer={filterExplainer}
          />
          <FilterJobModal
            show={showFilter}
            onClose={hndFilterClose}
            onApply={hndFilterApply}
            setShowFilterJobModal={setShowFilter}
          />
        </div>
      </div>
    </div>
  );
};

const blankJobForm = () => {
  return {
    job: "",
    job_number: "",
    companies: [0],
    sales_tax: {
      sales_tax: "",
      sales_tax_code: "",
      sales_tax_percentage: 0,
      state: "",
    },
    active: true,
    users: [0],
  };
};

const Job = () => {
  const [showJobModal, setShowJobModal] = useState(false);
  const [loadJobId] = useState<undefined | number>(undefined);
  const [jobInitialValues, setJobInitialValues] = useState(blankJobForm());
  const jobs = useQuery(jobQueryAll());
  useQuery({
    queryFn: () => jobQuery,
    queryKey: ["jobQuery", loadJobId],
    enabled: !!loadJobId,
    onSuccess: (data: JobQueryData) => {
      setJobInitialValues(data);
      setShowJobModal(true);
    },
  });

  const hndAddNew = () => {
    setJobInitialValues(blankJobForm());
    setShowJobModal(true);
  };

  const hndEdit = () => {
    setShowJobModal(true);
    setJobInitialValues({
      ...jobInitialValues,
      job: "",
      job_number: "",
      users: [0],
    });
  };

  const hndModelClose = () => {
    setShowJobModal(false);
    jobs.refetch();
  };
  
  useEffect(() => {
    jobs.refetch();
  }, [ showJobModal ])


  return (
    <Sentry.ErrorBoundary fallback={ErrorFallback}>
     
        <Layout>
          <Loader isLoading={jobs.isLoading && jobs.data !== undefined}>
            <JobTable
              onAddNew={hndAddNew}
              onEdit={hndEdit}
              jobs={jobs.data}
              setJobInitialValues={setJobInitialValues}
              setShowJobModal={setShowJobModal}
            />
          </Loader>
          <JobModal
            show={showJobModal}
            onOk={hndModelClose}
            onCancel={hndModelClose}
            initialValues={jobInitialValues}
            setShowJobModal={setShowJobModal}
            setJobInitialValues={setJobInitialValues}
          />
        </Layout>
      
    </Sentry.ErrorBoundary>
  );
};

export default Job;
