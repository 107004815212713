import { useEffect, useState } from "react";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { Modal } from "react-bootstrap";
import { ToastContainer } from "react-toastify";

import Layout from "../../components/Layout";
import Loader from "../../components/Loader";
import BasicFilterTable from "../../components/BasicFilterTable";
import ErrorFallback from "../../components/Error";
import * as Sentry from "@sentry/react";
import {
  stockOrderDetailLookup,
  stockOrderQueryAll,
} from "../../apis/queries/invitems";
import {
  StockOrderDetailMutationData,
  StockOrderQueryData,
} from "../../apis/interfaces/invitems";
interface FilterJsonIntermediateProps {
  textsearch?: string;
}
interface OrderTableProps {
  onEdit: any;
  orders: Array<StockOrderQueryData>;
  setShowChargeModal: any;
  setSelectedOrderId: any;
  hndSetSearchTerm: any;
  hndTextFilterCleared: any;
  clearTextFilter: any;
}

interface OrderDetailModalProps {
  show: boolean;
  onCancel: Function;
  onOk: Function;
  dataSource: Array<StockOrderDetailMutationData>;
  setShowChargeModal: any;
}

const StockOrderModal = (props: OrderDetailModalProps) => {
  const { show, onCancel, dataSource } = props;

  const handleModalClose = () => {
    onCancel();
  };
  const filterButtons = <></>;
  const filterFn = (keyword: string, item: StockOrderDetailMutationData) => {
    const lowercaseKeyword = keyword.toLowerCase();
    const itemValues = Object.values(item);

    for (const value of itemValues) {
      if (typeof value === "string") {
        const lowercaseValue = value.toLowerCase();

        if (lowercaseValue.includes(lowercaseKeyword)) {
          return true;
        }
      }
    }

    return false;
  };
  const columns = [
    
    {
      name: "Date Received",
      sortable: true,
      selector: (row: StockOrderDetailMutationData) => {
        const d = new Date(row.date_received);
        return `${d.getFullYear()}-${(d.getMonth() + 1)
          .toString()
          .padStart(2, "0")}-${d.getDate().toString().padStart(2, "0")}`;
      },
    },
    {
      name: "Item",
      sortable: true,
      selector: (row: StockOrderDetailMutationData) => row.stock.stock,
    },
    {
      name: "Unit Price",
      sortable: true,
      selector: (row: StockOrderDetailMutationData) =>
        parseFloat(row.unit_price.toString()).toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        }),
    },
    {
      name: "Qty Ordered",
      sortable: true,
      selector: (row: StockOrderDetailMutationData) => row.quantity_ordered,
    },
    {
      name: "Qty Received",
      sortable: true,
      selector: (row: StockOrderDetailMutationData) => row.quantity_received,
    },
  ];

  return (
    <Modal show={show} onCancel={handleModalClose} size="xl">
      <Modal.Header closeButton onClick={handleModalClose}>
        Order Details
      </Modal.Header>
      <BasicFilterTable
        tableKey="orders"
        title="Stock Order Details"
        dataSource={Array.isArray(dataSource) ? dataSource : []}
        columns={columns}
        filterFn={filterFn}
        filterButtons={filterButtons}
        exportFileName={"Stock Order Export Data.csv"}
      />
    </Modal>
  );
};

const OrderTable = (props: OrderTableProps) => {
  const {
    orders,
    setShowChargeModal: setShowOrderDetailModal,
    setSelectedOrderId,
    onEdit,
    hndSetSearchTerm,
    hndTextFilterCleared,
    clearTextFilter,
  } = props;

  const fireOnView = (row: StockOrderQueryData) => {
    setSelectedOrderId(row.id);
    setShowOrderDetailModal(true);
    onEdit(row.id);
  };

  const commonStyle = {
    width: '16.67%', 
    textAlign: 'center', 
    verticalAlign: 'middle'
};

const columns = [
    {
      name: "Date Ordered",
      sortable: true,
      selector: (row: StockOrderQueryData) => {
        const d = new Date(row.date_ordered);
        return `${d.getFullYear()}-${(d.getMonth() + 1)
          .toString()
          .padStart(2, "0")}-${d.getDate().toString().padStart(2, "0")}`;
      },
      width:"8rem",
    },
    {
      name: "Date Received",
      sortable: true,
      selector: (row: StockOrderQueryData) => {
        if(!row.date_received){
          return "N/A";
        }
        else{
          const d = new Date(row.date_received);
          return `${d.getFullYear()}-${(d.getMonth() + 1)
            .toString()
            .padStart(2, "0")}-${d.getDate().toString().padStart(2, "0")}`;
        }
      },
      width:"8rem",
    },
    {
      name: "Order Number",
      sortable: true,
      selector: (row: StockOrderQueryData) => row.comments,
      width:"8rem",
    },
    {
      name: "Vendor",
      sortable: true,
      selector: (row: StockOrderQueryData) => row.vendor.vendor_name,
      width:"15rem",
    },
    {
      name: "Items",
      sortable: true,
      selector: (row: StockOrderQueryData) => row.stock_items_list,
      style: {
        width: '20rem',
        wordWrap: 'break-word',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }
    },
    {
      name: "Total Items",
      sortable: true,
      selector: (row: StockOrderQueryData) => row.total_items,

        width: '8rem'

    },
    {
      name: "Total Cost",
      sortable: true,
      selector: (row: StockOrderQueryData) =>
        parseFloat(String(row.total_cost ?? "")).toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        }),
      style: commonStyle
    },
    {
      name: "Actions",
      cell: (row: StockOrderQueryData) => (
        <>
          <button
            className="btn btn-sm btn-link"
            onClick={fireOnView.bind(this, row)}
          >
            View
          </button>
        </>
      ),
      style: commonStyle
    },
];


  const filterFn = (keyword: string, item: StockOrderQueryData) => {
    const lowercaseKeyword = keyword.toLowerCase();
    const itemValues = Object.values(item);

    for (const value of itemValues) {
      if (typeof value === "string") {
        const lowercaseValue = value.toLowerCase();

        if (lowercaseValue.includes(lowercaseKeyword)) {
          return true;
        }
      }
    }

    return false;
  };

  const filterButtons = <></>;
  return (
    <div className="row">
      <div className="col-12">
        <div className="p-2">
          <BasicFilterTable
            tableKey="orders"
            title="Stock Orders"
            dataSource={orders}
            columns={columns}
            filterHnd={hndSetSearchTerm}
            onFilterCleared={hndTextFilterCleared.bind(this)}
            clearFilterFlag={clearTextFilter}
            filterFn={filterFn}
            filterButtons={filterButtons}
            exportFileName={"Stock Order Export Data.csv"}
          />
        </div>
      </div>
    </div>
  );
};

const StockOrder = () => {
  const [showChargeModal, setShowDetailModal] = useState(false);
  const [details, setDetails] = useState<
    Array<StockOrderDetailMutationData> | []
  >([]);

  const [clearTextFilter, setClearTextFilter] = useState(false);
  const [filterJson, setFilterJson] = useState<string>("");

  const stockOrders = useInfiniteQuery(
    ["stockOrderQueryAll"],
    ({ pageParam = { limit: 50, offset: 0 } }) =>
      stockOrderQueryAll(pageParam.limit, pageParam.offset, filterJson).queryFn(),
    {
      getNextPageParam: (lastPage, pages) => {
        // Assuming the backend returns the results in an array, check if we've fetched all items.
        if (lastPage.results.length < 50) return undefined; // no next page

        // Calculate the next offset based on the length of the pages array.
        const nextOffset = pages.length * 50; // 25 is the limit
        return { limit: 50, offset: nextOffset };
      },
    
    }
  );

  const [selectedOrderId, setSelectedOrderId] = useState<number | null>(null);
   // Initialize with page 1
  // This useQuery will automatically re-run whenever selectedOrderId changes
  useQuery(
    ["stockOrderDetailQuery", selectedOrderId],
    stockOrderDetailLookup(selectedOrderId as number).queryFn,
    {
      enabled: !!selectedOrderId,
      onSuccess: (data: StockOrderDetailMutationData[]) => {
        setDetails(data);
      },
    }
  );

  const hndSetSearchTerm = (term: string) => {
    let oldFilterJson: FilterJsonIntermediateProps = {};
  
    try {
      oldFilterJson = JSON.parse(filterJson);
    } catch (e) {}
  
    term = term.trim();
  
    if (term.length === 0) {
      delete oldFilterJson.textsearch; // Remove the textsearch key if term is empty
    } else if (oldFilterJson.textsearch === term) {
      return;
    } else {
      oldFilterJson.textsearch = term;
    }
  
    setFilterJson(JSON.stringify(oldFilterJson));
  };
  

  const hndTextFilterCleared = () => {
    console.log("setting the clear flag to false");
    setClearTextFilter(false);
  };
  useEffect(() => {
    stockOrders.refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterJson]);

  // Sort charges so that active ones come first

  const hndModelClose = () => {
    setShowDetailModal(false);
    stockOrders.refetch();
  };
  const handleView = async (id: number) => {
    setSelectedOrderId(id);
    setShowDetailModal(true);
  };

  return (
    <Sentry.ErrorBoundary fallback={ErrorFallback}>
      <Layout>
        <Loader
          isLoading={stockOrders.isLoading && stockOrders.data !== undefined}
        >
          {stockOrders.isSuccess ? (
            <OrderTable
              onEdit={handleView}
              orders={
                stockOrders.data?.pages.flatMap((page) => page.results) || []
              }
              setShowChargeModal={setShowDetailModal}
              setSelectedOrderId={setSelectedOrderId}
              hndSetSearchTerm={hndSetSearchTerm}
              hndTextFilterCleared={hndTextFilterCleared}
              clearTextFilter={clearTextFilter}
            />
          ) : (
            <div>Loading orders...</div>
          )}

          {stockOrders.hasNextPage && (
            <button
              className="btn btn-primary"
              onClick={() => stockOrders.fetchNextPage()} // Use built-in function to fetch the next page
            >
              Load more orders
            </button>
          )}
        </Loader>
        <ToastContainer />
        <StockOrderModal
          show={showChargeModal}
          onOk={hndModelClose}
          onCancel={hndModelClose}
          dataSource={details as StockOrderDetailMutationData[]}
          setShowChargeModal={setShowDetailModal}
        />
      </Layout>
    </Sentry.ErrorBoundary>
  );
};

export default StockOrder;
