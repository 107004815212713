import { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useQuery } from "@tanstack/react-query";
import {
  CompanyMutationData,
  SalesTaxMutationData,
} from "../../apis/interfaces/common";
import {
  companyQueryAll,
  jobQueryAll,
  salesTaxQueryAll,
} from "../../apis/queries/common";

interface FilterJobModalProps {
  show: boolean;
  onClose: () => void;
  onApply: (filterData: any) => void;
  setShowFilterJobModal: any;
}

const FilterJobModal = (props: FilterJobModalProps) => {
  const [taxFilter, setTaxFilter] = useState(0);
  const [companyFilter, setCompanyFilter] = useState(0);
  const [activeFilter, setActiveFilter] = useState(true);
  const [taxes, setTaxes] = useState<SalesTaxMutationData[]>([]);
  const [companies, setCompanies] = useState<CompanyMutationData[]>([]);

  const { show, onApply, setShowFilterJobModal } = props;
  const { data: jobs } = useQuery(jobQueryAll());

  const handleCloseFilterJobModal = () => {
    setShowFilterJobModal(false);
  };

  const handleApplyFilter = () => {
    const filterData = {
      sales_tax: taxFilter,
      company: companyFilter,
      active: activeFilter,
    };
    onApply(filterData);
    handleCloseFilterJobModal();
  };

  const handleClearFilter = () => {
    setTaxFilter(0);
    setCompanyFilter(0);
    setActiveFilter(false);
  };

  const { data: tax_data } = useQuery(salesTaxQueryAll);
  const { data: company_data } = useQuery(companyQueryAll());

  useEffect(() => {
    if (tax_data) {
      setTaxes(tax_data);
    }
    if (company_data) {
      setCompanies(company_data);
    }
  }, [jobs, companies, taxes, tax_data, company_data]);

  return (
    <>
      <Modal show={show} onHide={handleCloseFilterJobModal}>
        <Modal.Header closeButton onClick={handleCloseFilterJobModal}>
          <Modal.Title>Filter Results</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-2">
              <Form.Label>Company:</Form.Label>
              <Form.Select
                value={companyFilter}
                onChange={(e) => setCompanyFilter(Number(e.target.value))}
              >
                <option value="">Select a Company</option>
                {companies.filter(Boolean).map((company) => (
                  <option key={company.id} value={company.id}>
                    {company.company}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Label>Sales Tax:</Form.Label>
              <Form.Select
                value={taxFilter}
                onChange={(e) => setTaxFilter(Number(e.target.value))}
              >
                <option value="">Select Sales Tax</option>
                {taxes.filter(Boolean).map((tax) => (
                  <option key={tax.id} value={tax.id}>
                    {tax.sales_tax}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Check
                type="checkbox"
                label="Toggle active/inactive"
                checked={activeFilter}
                onChange={(e) => setActiveFilter(e.target.checked)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClearFilter}>
            Clear
          </Button>
          <Button variant="primary" onClick={handleApplyFilter}>
            Apply
          </Button>
          <Button variant="secondary" onClick={handleCloseFilterJobModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default FilterJobModal;
