import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

import {
  loginMutation,
  loginMutationData,
} from "../apis/mutations/authentication";
import { useMutation } from "@tanstack/react-query";

const LoginPage = () => {
  const [ showLoginForm, setShowLoginForm ] = useState(false);

  const mutation = useMutation(loginMutation.mutationFn, {
    onSuccess: (data) => {
       if (data.data.success) {
        if (data.data.is_superuser) {
          navigate("/");
        } else {
          navigate("/staff-dashboard");
        }
      }
    },
    onError: (error: any) => {
      if (error.response.status === 405) {
        window.location.href = "/api/sign_in";
      }
      console.error("Login error:", error.response);
    },
  });

  const navigate = useNavigate();

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email address is required"),
    password: Yup.string()
      .min(8, "Password must be at least 8 characters long")
      .required("Password is required"),
  });

  const hndSubmit = async (values: loginMutationData) => {
    mutation.mutate(values);
  };

  const swapLoginForm = () => {
    setShowLoginForm(true);
  };

  return (
    <>
      {showLoginForm ?
      <div className="row mt-5">
        <div className="col-md-6 offset-md-3 col-sm-10 offset-sm-1">
          <Formik
            initialValues={{ email: "", password: "" }}
            validationSchema={validationSchema}
            onSubmit={hndSubmit}
          >
            {({
              values,
              handleChange,
              handleSubmit,
              isSubmitting,
              isValid,
            }) => (
              <Form
                onSubmit={handleSubmit}
                className="login-form border border-warning rounded p-4"
              >
                <p>Please log in to continue.</p>
                {(mutation.status === "error" && mutation.error?.response?.status !== 405) ||
                (mutation.data?.data && !mutation.data?.data.success) ? (
                  <div className="alert alert-danger">
                    Something went wrong. Please double-check your email and
                    password and try again.
                  </div>
                ) : null}
                  {(mutation.status === "error" && mutation.error?.response?.status === 405) && (
                
                  <div className="alert alert-danger">
                    Redirecting to SSO...
                  </div>
                )}

                <div className="form-outline mb-4">
                  <label htmlFor="email">Email:</label>
                  <Field
                    type="email"
                    id="email"
                    name="email"
                    value={values.email}
                    className="form-control"
                    onChange={handleChange}
                    autoComplete="username"
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="error-message text-danger"
                  />
                </div>
                <div className="form-outline mb-4">
                  <label htmlFor="password">Password:</label>
                  <Field
                    type="password"
                    id="password"
                    name="password"
                    value={values.password}
                    className="form-control"
                    onChange={handleChange}
                    autoComplete="current-password"
                  />
                  <ErrorMessage
                    name="password"
                    component="div"
                    className="error-message text-danger"
                  />
                </div>
                <div className="d-flex">
                  <button
                    type="submit"
                    className="btn btn-primary btn-block mb-4 me-auto"
                    disabled={isSubmitting || !isValid}
                  >
                    {isSubmitting || mutation.status === "loading"
                      ? "Signing In..."
                      : "Sign In"}
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary btn-block mb-4 me-auto"
                    onClick={() =>
                      (window.location.href = window.location.href.replace(
                        "/login",
                        "/api/sign_in"
                      ))
                    }
                  >
                    {"Sign In with Microsoft"}
                  </button>

                  <button
                    className="btn btn-link btn-block mb-4"
                    onClick={(e) => {
                      e.preventDefault();
                      navigate("/reset");
                    }}
                  >
                    Forgot password?
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
          :
      <div className="row mt-5">
        <div className="col-md-6 offset-md-3 col-sm-10 offset-sm-1">
          <div className="login-form border border-warning rounded p-4 text-center">
            <p>Welcome to the Yonkers Store. Click the Sign In with Microsoft button to sign in with your Yonkers account.</p>
            <button
              className="btn btn-primary btn-block mb-4 me-auto"
              onClick={() =>
                (window.location.href = window.location.href.replace(
                  "/login",
                  "/api/sign_in"
                ))
              }
            >
              {"Sign In with Microsoft"}
            </button>

            <p><button
              className="btn btn-link btn-block mb-4"
              onClick={swapLoginForm}
            >
              Admin Sign In
            </button></p>
          </div>
        </div>
      </div>
      }
    </>
  );
};

export default LoginPage;
