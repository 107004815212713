import React, { useEffect, useState } from "react";
import { apiClient } from "../../apis/client";
import Layout from "../../components/Layout";
import "../../styles/reports.css";
import { useJobQueryAll } from "../Tickets/Logic/MutationsAndQueries";
import {
  CompanyQueryData,
  DepartmentQueryData,
  JobQueryData,
} from "../../apis/interfaces/common";
import { companyQueryAll, departmentQueryAll } from "../../apis/queries/common";
import { useQuery } from "@tanstack/react-query";
import DataTable from "react-data-table-component";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import "../../styles/print.css";

const { DateTime } = require("luxon");

interface StockIssueDetail {
  id: number;
  created_at: string;
  quantity: number;
  stock: string;
  stock_type: string;
  unit_price: number;
  sales_tax_percentage: number;
  item_amt: number;
  total_amt: number;
  tax_amt: number;
  freight_charge: number;
}

interface Job {
  job_id: number;
  job_number: string;
  job: string;
  stock_issue_details: StockIssueDetail[];
}

interface Data {
  [companyId: string]: {
    company: string;
    company_code: string;
    jobs: Job[];
  };
}

function MonthlyStockIssueUsage() {
  const [data, setData] = useState<Data>({});
  const [jobs, setJobs] = useState<JobQueryData[]>([]);
  const [companies, setCompanies] = useState<CompanyQueryData[]>([]);
  const [depts, setDepts] = useState<DepartmentQueryData[]>([]);
  const [job, setJob] = useState(0);
  const [company, setCompany] = useState(0);
  const [dept, setDept] = useState(0);
  const [monthEnding, setMonthEnding] = useState<Date>(DateTime.now());
  const jobQuery = useJobQueryAll();
  const companyQuery = useQuery(companyQueryAll());
  const departmentQuery = useQuery(departmentQueryAll());

  useEffect(() => {
    if (!jobQuery.isLoading) {
      setJobs(jobQuery.data);
    }
    if (!companyQuery.isLoading) {
      setCompanies(companyQuery.data);
    }
    if (!departmentQuery.isLoading) {
      setDepts(departmentQuery.data);
    }
  }, [
    companyQuery.data,
    companyQuery.isLoading,
    departmentQuery.data,
    departmentQuery.isLoading,
    jobQuery.data,
    jobQuery.isLoading,
  ]);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    toast.info("Generating report");

    const requestData = {
      job: job,
      company: company,
      department: dept,
      month_ending: monthEnding,
    };

    try {
      const response = await apiClient.post(
        "/tickets/reports/StockIssueUsage/filter/",
        requestData
      );
      const responseData = response.data;

      const newData: Data = {};
      Object.keys(responseData).forEach((companyId) => {
        const companyData = responseData[companyId];
        Object.keys(companyData).forEach((jobId) => {
          const jobData = companyData[jobId];
          if (!newData[companyId]) {
            newData[companyId] = {
              company: jobData.company__company,
              company_code: jobData.company__company_code,
              jobs: [],
            };
          }

          const job: Job = {
            job_id: jobData.job_id,
            job_number: jobData.job_number,
            job: jobData.job,
            stock_issue_details: jobData.stock_issue_details,
          };

          newData[companyId].jobs.push(job);
        });
      });

      setData(newData);
      toast.success("Report loaded.");
    } catch (error) {
      console.error("Error fetching report data:", error);
      toast.error("An error occurred generating the report");
    }
  };

  const handleExport = async (event: React.FormEvent) => {
    event.preventDefault();
    toast.info("Generating report");

    const requestData = {
      job: job,
      company: company,
      department: dept,
      month_ending: monthEnding,
    };

    try {
      const response = await apiClient.post(
        "/tickets/reports/StockIssueUsage/export_excel/",
        requestData,
        { responseType: "blob" }
      );
      const blob = await response.data;
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = `Monthly Stock Issue Usage - ${monthEnding.toLocaleString()}.xlsx`;
      link.click();
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error submitting report:", error);
    }
  };
  const stockIssueColumns = [
    { name: "ID", selector: (row: StockIssueDetail) => row.id, width: "80px" },
    {
      name: "Quantity",
      selector: (row: StockIssueDetail) => Number(row.quantity).toFixed(2),
      width: "80px",
    },
    { name: "Stock", selector: (row: StockIssueDetail) => row.stock },
    {
      name: "Item Amount",
      selector: (row: StockIssueDetail) =>
        "$" + Number(row.item_amt).toFixed(2),
    },
    {
      name: "Freight Charge (non-taxable)",
      selector: (row: StockIssueDetail) =>
        "$" + Number(row.freight_charge).toFixed(2),
    },
    {
      name: "Tax Amount",
      selector: (row: StockIssueDetail) => "$" + Number(row.tax_amt).toFixed(2),
    },
    {
      name: "Total Amount",
      selector: (row: StockIssueDetail) =>
        "$" + Number(row.total_amt).toFixed(2),
    },
  ];
  const options = jobs
  ?.filter((jobElem: JobQueryData) => jobElem.active)
  .map((jobElem: JobQueryData) => ({
    value: jobElem.id,
    label: `${jobElem.job_number} - ${jobElem.job}`,
  })) ?? 0;

const hndSelectJob = (
  selectedOption: { value: number | undefined; label: string } | null
) => {
  const job = selectedOption
    ? jobs.find(
        (jobItem: JobQueryData) => jobItem.id === selectedOption.value
      )
    : null;
  setJob(job?.id ?? 0);
};
function customFilter(option:any , rawInput: any) {
  const searchTerm = rawInput.toLowerCase();
  return option.label.toLowerCase().includes(searchTerm);
}
  return (
    <div>
      <Layout>
      <div id="report-title-container" className="hide-printable">

        <ToastContainer />
        <h2>Monthly Stock Issue Usage Report</h2>
        <br></br>
        <h4>REPORT PARAMETERS</h4>
        <form onSubmit={handleSubmit}>
          <label>Job:</label> <br />
          <Select
                  name="job"
                  className="form-control"
                  onChange={hndSelectJob}
                  options={options}
                  isClearable
                  filterOption={customFilter}
                />
          <label>Company:</label> <br />
          <select
            name="company"
            className="form-control"
            onChange={(e) => setCompany(Number(e.target.value))}
          >
            <option value="">All Companies</option>
            {companies.filter(Boolean).map((item: CompanyQueryData) => (
              <option key={item.id} value={item.id}>
                {item.company_code} - {item.company}
              </option>
            ))}
          </select>
          <label>Department:</label> <br />
          <select
            name="dept"
            className="form-control"
            onChange={(e) => setDept(Number(e.target.value))}
          >
            <option value="">All Departments</option>
            {depts.filter(Boolean).map((item: DepartmentQueryData) => (
              <option key={item.id} value={item.id}>
                {item.department_number} - {item.department}
              </option>
            ))}
          </select>
          <label>Month Ending:</label> <br />
          <input
            name="month_ending"
            type="date"
            className="form-control"
            defaultValue={DateTime.local().endOf("month").toISODate()}
            onChange={(e) => setMonthEnding(new Date(e.target.value))}
          ></input>
          <button type="submit" className="btn btn-sm btn-success mt-2 button-margin">
            Generate Filtered Report
          </button>
          <button
            type="submit"
            className="btn btn-sm btn-success mt-2 button-margin"
            onClick={handleExport}
          >
            Export to Excel
          </button>
          <button
            className="btn btn-sm btn-success mt-2  button-margin"
            onClick={() => window.print()}
          >
            Print/Export PDF
          </button>
        </form>

        <br />
        <br />
        </div>

        <div className="report-data-printable">

        {data && (
          <div>
            {Object.values(data).map((companyData: any) => (
              <div key={companyData.company_code}>
                <h2>
                  Company: {companyData.company_code} - {companyData.company}
                </h2>
                {companyData.jobs.map((job: Job) => (
                  <div>
                    <h3>
                      Job: {job.job_number} - {job.job}
                    </h3>
                    <h4>Stock Issue Details</h4>
                    <DataTable
                      data={job.stock_issue_details}
                      columns={stockIssueColumns}
                    />
                  </div>
                ))}
              </div>
            ))}
          </div>
        )}
        </div>
      </Layout>
    </div>
  );
}

export default MonthlyStockIssueUsage;
