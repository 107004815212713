import React, { useEffect, useState } from "react";
import { apiClient } from "../../apis/client";
import Layout from "../../components/Layout";
import BasicFilterTable from "../../components/BasicFilterTable";
import { useQuery } from "@tanstack/react-query";
import { toast, ToastContainer } from "react-toastify";
import Select from "react-select";

import "../../styles/reports.css";
import "react-toastify/dist/ReactToastify.css";
import "../../styles/print.css";

import { DailyTicketReportJobDetail, DailyTicketReportChargeDetail, DailyTicketReportTicketDetail, FlatDailyTicketReportTicketDetail, FlatDailyTicketReportChargeDetail } from "../../apis/interfaces/tickets";
import { report } from "process";


const { DateTime } = require("luxon");

function DailyTicketReport() {
  const [reportData, setReportData] = useState<any | null>(null);
  const [chargeReportData, setChargeReportData] = useState<any|null>(null);
  const [monthEnding, setMonthEnding] = useState<any | null>(null);

  const hndSetDate = (e: any) => {
    setMonthEnding(DateTime.fromISO(e.target.value));
  }

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    toast.info(`Generating report...`);

    const data = {
      startdate: (new DateTime(monthEnding)).toISODate(),
    };

    try {
      const response = await apiClient.get(
        "/tickets/reports/daily_ticket_report/",
        { params: data }
      );
      const report_data = Object.values(response.data);
      const report_keys = Object.keys(response.data);
      const flatdata: Array<FlatDailyTicketReportTicketDetail> = [];
      const flatcharge: Array<FlatDailyTicketReportChargeDetail> = [];

      for (const job_name_idx in report_keys) {
        const job_name: string = report_keys[job_name_idx] as string;
        console.log(job_name);
        (report_data[job_name_idx] as DailyTicketReportJobDetail)?.tickets?.forEach((ticket: DailyTicketReportTicketDetail) => {
          console.log(ticket);
          flatdata.push({
            ...ticket,
            "job": <>{job_name}<br />{(report_data[job_name_idx] as DailyTicketReportJobDetail)?.detail}</>
          });
        });

        (report_data[job_name_idx] as DailyTicketReportJobDetail)?.charges?.forEach((charge: DailyTicketReportChargeDetail) => {
          console.log(charge);
          flatcharge.push({
            ...charge,
            "job": <>{job_name}<br />{(report_data[job_name_idx] as DailyTicketReportJobDetail)?.detail}</>
          });
        });

      }

      setReportData(flatdata);
      setChargeReportData(flatcharge);
      toast.success("Report loaded.");
    } catch (error) {
      console.error("Error submitting report:", error);
    }
  };

  const handleExportExcel = async (event: React.FormEvent) => {
    event.preventDefault();
    toast.info("Generating report");

    const data = {
        startdate: (new DateTime(monthEnding)).toISODate(),
        output: "xlsx",
    };

    try {
      const response = await apiClient.get(
        "/tickets/reports/daily_ticket_report/",
        { 
            params: data,
            responseType: "blob" 
        }
      );

      const blob = await response.data;
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = `Daily Ticket Report - ${monthEnding.toISODate()}.xlsx`;
      link.click();
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error submitting report:", error);
    }
  };

  const filterButtons = <></>;

  const getTotalConsumable = () => {
    let totalConsumable = 0;

    reportData.forEach((row: FlatDailyTicketReportTicketDetail) => totalConsumable += row.delivered_consumable);

    return totalConsumable;
  }

  const getTotalPerDay = () => {
    let totalPerDay = 0;

    reportData.forEach((row: FlatDailyTicketReportTicketDetail) => totalPerDay += row.delivered_per_day);

    return totalPerDay;
  }

  const getTotalMiscCharge = () => {
    let totalMiscCharge = 0;

    chargeReportData.forEach((row: FlatDailyTicketReportChargeDetail) => row.charge_type !== "RENT" ? totalMiscCharge += row.charge_amount : null);

    return totalMiscCharge;
  }

  const getTotalRentCharge = () => {
    let totalRentCharge = 0;

    chargeReportData.forEach((row: FlatDailyTicketReportChargeDetail) => row.charge_type === "RENT" ? totalRentCharge += row.charge_amount : null);

    return totalRentCharge;
  }

  const columns = [
    {
      name: "Ticket",
      sortable: true,
      selector: (row: FlatDailyTicketReportTicketDetail) => row.id,
      width: "7%",
    },
    {
      name: "Job Number",
      sortable: true,
      selector: (row: FlatDailyTicketReportTicketDetail) => row.job,
      width: "20%",
    },
    {
      name: "Status",
      sortable: true,
      selector: (row: FlatDailyTicketReportTicketDetail) => row.status,
      width: "auto",
      cellStyle: {
        whiteSpace: "normal",
        overflow: "visible",
      },
    },
    {
      name: <>Delivered<br />Per-Day</>,
      sortable: true,
      selector: (row: FlatDailyTicketReportTicketDetail) => Number(row.delivered_per_day).toLocaleString("en-US", { style: "currency", currency: "USD" }),
      width: "auto",
    },
    {
      name: <>Delivered<br />Consumable</>,
      sortable: true,
      selector: (row: FlatDailyTicketReportTicketDetail) => Number(row.delivered_consumable).toLocaleString("en-US", { style: "currency", currency: "USD" }),
      width: "auto",
    },
];

const charge_columns = [
  {
    name: "ID",
    sortable: true,
    selector: (row: FlatDailyTicketReportChargeDetail) => row.charge,
    width: "7%",
  },
  {
    name: "Ticket",
    sortable: true,
    selector: (row: FlatDailyTicketReportChargeDetail) => row.ticket,
    width: "7%",
  },
  {
    name: "Type",
    sortable: true,
    selector: (row: FlatDailyTicketReportChargeDetail) => row.charge_type,
    width: "7%",
  },
  {
    name: "Nontaxable",
    sortable: true,
    selector: (row: FlatDailyTicketReportChargeDetail) => Number(row.nontaxable).toLocaleString("en-US", { style: "currency", currency: "USD" }),
    width: "auto",
  },
  {
    name: "Taxable",
    sortable: true,
    selector: (row: FlatDailyTicketReportChargeDetail) => Number(row.taxable).toLocaleString("en-US", { style: "currency", currency: "USD" }),
    width: "auto",
  },
  {
    name: "Total",
    sortable: true,
    selector: (row: FlatDailyTicketReportChargeDetail) => Number(row.charge_amount).toLocaleString("en-US", { style: "currency", currency: "USD" }),
    width: "auto",
  },
  {
    name: "Comments",
    sortable: true,
    selector: (row: FlatDailyTicketReportChargeDetail) => row.comments,
    width: "45%",
    wrap: true,
  },
];


  const renderForm = () => {
    return (
      <div>
        <h4>REPORT PARAMETERS</h4>
        <form onSubmit={handleSubmit}>
          <label>Day:</label> <br />
          <input
            name="month_ending"
            type="date"
            className="form-control"
            defaultValue={DateTime.local().toISODate()}
            onChange={hndSetDate}
          ></input>
          <button
            type="submit"
            className="btn btn-sm btn-success mt-2 button-margin"
          >
            Generate Filtered Report
          </button>
          <button
            type="submit"
            className="btn btn-sm btn-success mt-2 button-margin"
            onClick={handleExportExcel}
          >
            Export to Excel
          </button>
        </form>
      </div>
    );
  };

  return (
    <div>
      <Layout>
        <div id="report-title-container" className="hide-printable">
          <ToastContainer />
          <h2>Daily Ticket Report</h2>
          <br />
          {renderForm()}
          <br />
          <br />
        </div>

        <div className="report-data-printable">
          {reportData && chargeReportData ? <>
            <div>
                <BasicFilterTable
                    tableKey="DailyTicketReport"
                    title={`Daily Ticket Report - ${monthEnding.toISODate()}`}
                    dataSource={reportData}
                    columns={columns}
                    filterButtons={filterButtons}
                    hideSearch={true}
                    hideExport={true}
                    exportFileName={`DailyTicketReport - ${monthEnding.toISODate()}.csv`}
                />
            </div>
            <div>
                <BasicFilterTable
                    tableKey="DailyChargeReport"
                    title={`Daily Misc Charge Report - ${monthEnding.toISODate()}`}
                    dataSource={chargeReportData.filter((item: FlatDailyTicketReportChargeDetail) => item.charge_type !== "RENT")}
                    columns={charge_columns}
                    filterButtons={filterButtons}
                    hideSearch={true}
                    hideExport={true}
                    exportFileName={`DailyChargeReport - ${monthEnding.toISODate()}.csv`}
                />
            </div>
            <div>
                <BasicFilterTable
                    tableKey="DailyRentChargeReport"
                    title={`Daily Rent Charge Report - ${monthEnding.toISODate()}`}
                    dataSource={chargeReportData.filter((item: FlatDailyTicketReportChargeDetail) => item.charge_type === "RENT")}
                    columns={charge_columns}
                    filterButtons={filterButtons}
                    hideSearch={true}
                    hideExport={true}
                    exportFileName={`DailyChargeReport - ${monthEnding.toISODate()}.csv`}
                />
            </div>

            <div className="border-top">
              <div className="d-flex">
                <div className="text-end flex-grow-1 pe-2">Total Consumable:</div>
                <div className="text-end">{getTotalConsumable().toLocaleString("en-US", { style: "currency", currency: "USD" })}</div>
              </div>
              <div className="d-flex">
                <div className="text-end flex-grow-1 pe-2">Total Per Day:</div>
                <div className="text-end">{getTotalPerDay().toLocaleString("en-US", { style: "currency", currency: "USD" })}</div>
              </div>
              <div className="d-flex">
                <div className="text-end flex-grow-1 pe-2">Total Misc Charge:</div>
                <div className="text-end">{getTotalMiscCharge().toLocaleString("en-US", { style: "currency", currency: "USD" })}</div>
              </div>
              <div className="d-flex">
                <div className="text-end flex-grow-1 pe-2">Total Rent Charge:</div>
                <div className="text-end">{getTotalRentCharge().toLocaleString("en-US", { style: "currency", currency: "USD" })}</div>
              </div>
            </div>
          </> : null}
        </div>
      </Layout>
    </div>
  );
}

export default DailyTicketReport;
