import {
  ChargeMutationData,
  CompanyMutationData,
  DepartmentMutationData,
  JobMutationData,
  LocationMutationData,
  SalesTaxMutationData,
  VendorMutationData,
  CostCodeMutationData
} from "../interfaces/common";
import { apiClient } from "../client";

// #region Company Mutations
export const createCompanyMutation = {
  queryKey: ["companyQuery"],
  mutationFn: (data: CompanyMutationData) => {
    return apiClient.post("/common/company/", data);
  },
};

export const updateCompanyMutation = {
  queryKey: ["companyQuery"],
  mutationFn: (id: number, data: CompanyMutationData) => {
    return apiClient.patch(`/common/company/${id}/`, data);
  },
};

export const deleteCompanyMutation = {
  queryKey: ["companyQuery"],
  mutationFn: (id: number) => {
    return apiClient.delete(`/common/company/${id}/`);
  },
};
// #endregion

// #region SalesTax Mutations
export const createSalesTaxMutation = {
  queryKey: ["salesTaxQuery"],
  mutationFn: (data: SalesTaxMutationData) => {
    return apiClient.post("/common/salestax/", data);
  },
};

export const updateSalesTaxMutation = {
  queryKey: ["salesTaxMutation"],
  mutationFn: (id: number, data: SalesTaxMutationData) => {
    return apiClient.patch(`/common/salestax/${id}/`, data);
  },
};

export const deleteSalesTaxMutation = {
  queryKey: ["salesTaxQuery"],
  mutationFn: (id: number) => {
    return apiClient.delete(`/common/salestax/${id}/`);
  },
};
// #endregion

// #region Department Mutations
export const createDepartmentMutation = {
  queryKey: ["departmentQuery"],
  mutationFn: (data: DepartmentMutationData) => {
    return apiClient.post("/common/department/", data);
  },
};

export const updateDepartmentMutation = {
  queryKey: ["departmentQuery"],
  mutationFn: (id: number, data: DepartmentMutationData) => {
    return apiClient.patch(`/common/department/${id}/`, data);
  },
};

export const deleteDepartmentMutation = {
  queryKey: ["departmentQuery"],
  mutationFn: (id: number) => {
    return apiClient.delete(`/common/department/${id}/`);
  },
};
// #endregion

// #region Charge Mutations
export const createChargeMutation = {
  queryKey: ["chargeQuery"],
  mutationFn: (data: ChargeMutationData) => {
    return apiClient.post("/common/charge/", data);
  },
};

export const updateChargeMutation = {
  queryKey: ["chargeQuery"],
  mutationFn: (id: number, data: ChargeMutationData) => {
    return apiClient.patch(`/common/charge/${id}/`, data);
  },
};

export const deleteChargeMutation = {
  queryKey: ["chargeQuery"],
  mutationFn: (id: number) => {
    return apiClient.delete(`/common/charge/${id}/`);
  },
};
// #endregion

// #region Job Mutations
export const createJobMutation = {
  queryKey: ["jobQuery"],
  mutationFn: (data: JobMutationData) => {
    return apiClient.post("/common/job/", data);
  },
};

export const updateJobMutation = {
  queryKey: ["jobQuery"],
  mutationFn: (id: number, data: JobMutationData) => {
    return apiClient.patch(`/common/job/${id}/`, data);
  },
};

export const deleteJobMutation = {
  queryKey: ["jobQuery"],
  mutationFn: (id: number) => {
    return apiClient.delete(`/common/job/${id}/`);
  },
};
// #endregion

// #region Vendor Mutations
export const createVendorMutation = {
  queryKey: ["vendorQuery"],
  mutationFn: (data: VendorMutationData) => {
    return apiClient.post("/common/vendor/", data);
  },
};

export const updateVendorMutation = {
  queryKey: ["vendorQuery"],
  mutationFn: (id: number, data: VendorMutationData) => {
    return apiClient.patch(`/common/vendor/${id}/`, data);
  },
};

export const deleteVendorMutation = {
  queryKey: ["vendorQuery"],
  mutationFn: (id: number) => {
    return apiClient.delete(`/common/vendor/${id}/`);
  },
};
// #endregion

// #region Location Mutations
export const createLocationMutation = {
  queryKey: ["locationQuery"],
  mutationFn: (data: LocationMutationData) => {
    return apiClient.post("/common/location/", data);
  },
};

export const updateLocationMutation = {
  queryKey: ["locationQuery"],
  mutationFn: (id: number, data: LocationMutationData) => {
    return apiClient.patch(`/common/location/${id}/`, data);
  },
};

export const deleteLocationMutation = {
  queryKey: ["locationQuery"],
  mutationFn: (id: number) => {
    return apiClient.delete(`/common/location/${id}/`);
  },
};
// #endregion

export const createCostCodeMutation = {
  queryKey: ["costCodeQuery"],
  mutationFn: (data: CostCodeMutationData) => {
    return apiClient.post("/common/costcode/", data);
  },
};

export const updateCostCodeMutation = {
  queryKey: ["costCodeQuery"],
  mutationFn: (id: number, data: CostCodeMutationData) => {
    return apiClient.patch(`/common/costcode/${id}/`, data);
  },
};
