import { apiClient } from "../client";

import type { User } from "../interfaces/common";

export const profileQuery = {
  queryKey: ["userProfile"],
  queryFn: async () => {
    return await apiClient.get<User>("/auth/v1/get_profile/");
  },
  retry: false,
};

export const verifyPasswordResetQuery = (userHash: any, resetToken: any) => {
  return {
    queryKey: ["verifyPasswordReset"],
    queryFn: async () => {
      return await apiClient.get(
        `/auth/v1/verify_token/${userHash}/${resetToken}/`
      );
    },
    retry: false,
  };
};

export const logoutQuery = {
  queryKey: ["logout"],
  queryFn: async () => {
    return await apiClient.get("/auth/v1/logout/");
  },
  retry: false,
};
