import React, { useEffect, useState } from "react";
import { apiClient } from "../../apis/client";
import Layout from "../../components/Layout";
import BasicFilterTable from "../../components/BasicFilterTable";
import { toast, ToastContainer } from "react-toastify";
import Select from "react-select";

import "../../styles/reports.css";
import "react-toastify/dist/ReactToastify.css";
import "../../styles/print.css";

import { JobQueryData } from "../../apis/interfaces/common";
import { JobSummaryReportData, JobSummaryReportTicket, JobSummaryReportCharge, JobSummaryReportTicketItem } from "../../apis/interfaces/tickets";

import { useJobQueryAll } from "../Tickets/Logic/MutationsAndQueries";

const { DateTime } = require("luxon");

type JobSummaryReportQuery = {
    job: number;
    startdate?: Date;
    enddate?: Date;
    output?: string;
}

function JobSummaryReport() {
  const [reportData, setReportData] = useState<JobSummaryReportData | null>(null);
  const [jobs, setJobs] = useState<JobQueryData[]>([]);

  const [job, setJob] = useState<number | null>(null);

  const [monthStart, setMonthStart] = useState<Date|null>(null);
  const [monthEnd, setMonthEnd] = useState<Date|null>(null);

  const jobQuery = useJobQueryAll();

  useEffect(() => {
    if (!jobQuery.isLoading) {
      setJobs(jobQuery.data);
    }
  }, [
    jobQuery.data,
    jobQuery.isLoading,
  ]);
  
  const formatOptions = () => {
    if (job) {
        const data: JobSummaryReportQuery = {
            job: job,
        };

        if (monthStart) data.startdate = monthStart;
        if (monthEnd) data.enddate = monthEnd;

        return data;
    }

    throw new Error("Job not selected.");
  }

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    toast.info("Generating report");

    try {
        const data = formatOptions();
        setReportData(null);
        const response = await apiClient.get(
            "/tickets/reports/job_summary_report/",
            { params: data }
        );
        const cleanedData = response.data;
        setReportData(cleanedData as JobSummaryReportData);
        toast.success("Report loaded.");
    } catch (error) {
        console.error("Error submitting report:", error);
    }
  };

  const handleExportExcel = async (event: React.FormEvent) => {
    event.preventDefault();
    toast.info("Generating report");

    try {
        const data = {
            ...formatOptions(),
            output: "xlsx",
        };
        const response = await apiClient.get(
            "/tickets/reports/job_summary_report/",
            { responseType: "blob", params: data }
        );

        const blob = await response.data;
        console.log(response.headers)
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = response.headers["content-disposition"].split("; ")[1].replace(/filename="(.*)"/, "$1");
        link.click();
        URL.revokeObjectURL(url);
    } catch (error) {
        console.error("Error submitting report:", error);
    }
  };

  const filterFn = null;

  const filterButtons = <></>;

  const ticketColumns = [
    {
      name: "Type",
      sortable: true,
      selector: (row: JobSummaryReportTicketItem) => row.type === "consumable" ? "C" : "S",
      width: "5%",
      wrap: true,
    },
    {
      name: "Stock Item",
      sortable: true,
      selector: (row: JobSummaryReportTicketItem) => <>{row.stock}<br />{row.category}</>,
      width: "23%",
      wrap: true,
    },
    {
      name: "YCC Serial",
      sortable: true,
      selector: (row: JobSummaryReportTicketItem) => row.ycc_serial,
      width: "auto",
      wrap: true,
    },
    {
      name: "Unit Price",
      sortable: true,
      selector: (row: JobSummaryReportTicketItem) =>
        Number(row.unit_price ?? 0).toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
        }),
      width: "auto",
      wrap: true,
    },
    {
        name: <>Qty<br />Req</>,
        sortable: true,
        selector: (row: JobSummaryReportTicketItem) => row.qty_requested,
        width: "auto",
        wrap: true,
      },
    {
      name: "Deliver",
      sortable: true,
      selector: (row: JobSummaryReportTicketItem) => row.delivered ? DateTime.fromISO(row.delivered_at).toLocaleString() : "No",
      width: "auto",
      wrap: true,
    },
    {
        name: <>Qty<br />Del</>,
        sortable: true,
        selector: (row: JobSummaryReportTicketItem) => row.qty_delivered,
        width: "auto",
        wrap: true,
      },
    {
        name: "Return",
        sortable: true,
        selector: (row: JobSummaryReportTicketItem) => row.returned ? DateTime.fromISO(row.returned_at).toLocaleString() : "No",
        width: "auto",
        wrap: true,
      },
    {
        name: <>Qty<br />Ret</>,
        sortable: true,
        selector: (row: JobSummaryReportTicketItem) => row.qty_returned,
        width: "auto",
        wrap: true,
      },
    {
        name: "Transf",
        sortable: true,
        selector: (row: JobSummaryReportTicketItem) => row.transferred ? DateTime.fromISO(row.transferred_at).toLocaleString() : "No",
        width: "auto",
        wrap: true,
      },
  ];

  const chargeColumns = [
    {
        name: "Type",
        sortable: true,
        selector: (row: JobSummaryReportCharge) => row.type,
        width: "7%",
        wrap: true,
      },
    {
        name: "Charge Date",
        sortable: true,
        selector: (row: JobSummaryReportCharge) => DateTime.fromISO(row.charge_date).toLocaleString(),
        width: "10%",
        wrap: true,
      },
    {
        name: "Amount",
        sortable: true,
        selector: (row: JobSummaryReportCharge) => Number(row.amount ?? 0).toLocaleString("en-US", { style: "currency", currency: "USD" }),
        width: "10%",
        wrap: true,
      },
    {
        name: "Tax",
        sortable: true,
        selector: (row: JobSummaryReportCharge) => Number(row.tax ?? 0).toLocaleString("en-US", { style: "currency", currency: "USD" }),
        width: "10%",
        wrap: true,
      },
    {
        name: "Non-tax",
        sortable: true,
        selector: (row: JobSummaryReportCharge) => Number(row.nontaxable ?? 0).toLocaleString("en-US", { style: "currency", currency: "USD" }),
        width: "10%",
        wrap: true,
      },
    {
        name: "Comments",
        sortable: true,
        selector: (row: JobSummaryReportCharge) => row.comments,
        width: "auto",
        wrap: true,
    },
  ];
  
  const renderJobSummary = () => {
    const job_info = jobs.find((test_job: JobQueryData) => test_job.id === job);

    return job_info && reportData && (
      <div>
        <br />
        <div className="d-flex flex-column justify-content-between">
          <h5 className="w-100">Summary for job {job_info.job_number} - {job_info.job}</h5>
          <div className="w-100">
            <table className="table table-sm table-bordered">
            {monthStart || monthEnd ? 
                <tr>
                    <td className="w-25 text-end pe-2">Start Date:</td>
                    <td className="w-25">
                        {monthStart && monthStart.toLocaleString()}
                    </td>
                    <td className="w-25 text-end pe-2">End Date:</td>
                    <td className="w-25">
                        {monthEnd && monthEnd.toLocaleString()}
                    </td>
                </tr> : null}

                <tr>
                    <td className="text-end pe-2 w-25">Ticket Count:</td>
                    <td className="w-25">{reportData.summary.ticket_count}</td>
                    <td className="text-end pe-2 w-25">Charge Count:</td>
                    <td className="w-25">{reportData.summary.charge_count}</td>
                </tr>
                <tr>
                    <td className="text-end pe-2">Total Consumable Cost:</td>
                    <td>
                        {Number(reportData.summary.total_consumable_cost ?? 0).toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                        })}
                    </td>
                    <td className="text-end pe-2">Total Consumable Tax:</td>
                    <td>
                        {Number(reportData.summary.total_consumable_tax ?? 0).toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                        })}
                    </td>
                </tr>
                <tr>
                  <td colSpan={2} />
                </tr>
                <tr>
                    <td className="text-end pe-2">Total Misc Charge Cost:</td>
                    <td>
                        {Number(reportData.summary.total_charge ?? 0).toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                        })}
                    </td>
                    <td className="text-end pe-2">Total Misc Charge Tax:</td>
                    <td>
                        {Number(reportData.summary.total_charge_tax ?? 0).toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                        })}
                    </td>
                </tr>
                <tr>
                    <td className="text-end pe-2">
                        Total Misc Charge Non-taxable:
                    </td>
                    <td>
                        {Number(reportData.summary.total_charge_nontaxable ?? 0).toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                        })}
                    </td>
                    <td colSpan={2}></td>
                </tr>
                <tr>
                  <td colSpan={2} />
                </tr>
                <tr>
                    <td className="text-end pe-2">Total Rent Charge Cost:</td>
                    <td>
                        {Number(reportData.summary.total_rent ?? 0).toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                        })}
                    </td>
                    <td className="text-end pe-2">Total Rent Charge Tax:</td>
                    <td>
                        {Number(reportData.summary.total_rent_tax ?? 0).toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                        })}
                    </td>
                </tr>
                <tr>
                    <td className="text-end pe-2">
                        Total Rent Charge Non-taxable:
                    </td>
                    <td>
                        {Number(reportData.summary.total_rent_nontaxable ?? 0).toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                        })}
                    </td>
                    <td colSpan={2}></td>
                </tr>
            </table>                
          </div>
        </div>
        <br />
        <br />
      </div>
    );
  };

  const renderTicketSummary = (ticket: JobSummaryReportTicket) => {
    return (
    <div>
        <br />
        <div className="d-flex flex-column justify-content-between">
          <h5 className="w-100">Summary for {ticket.status} ticket {ticket.id}</h5>
          <div className="w-100">
            <table className="table table-sm table-bordered">
                <tr>
                    <td className="w-25 text-end pe-2">Created On:</td>
                    <td className="w-25">
                        {ticket.created_at && DateTime.fromISO(ticket.created_at).toLocaleString()}
                    </td>
                    <td className="w-25 text-end pe-2">Received By:</td>
                    <td className="w-25">
                        {ticket.received_by}
                    </td>
                </tr>

                <tr>
                    <td className="text-end pe-2">Total Consumable Cost:</td>
                    <td>
                        {Number(ticket.total_consumable_cost ?? 0).toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                        })}
                    </td>
                    <td className="text-end pe-2">Total Consumable Tax:</td>
                    <td>
                        {Number(ticket.total_consumable_tax ?? 0).toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                        })}
                    </td>
                </tr>
                <tr>
                  <td colSpan={2} />
                </tr>
                <tr>
                    <td className="text-end pe-2">Total Misc Charge Cost:</td>
                    <td>
                        {Number(ticket.total_charge ?? 0).toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                        })}
                    </td>
                    <td className="text-end pe-2">Total Misc Charge Tax:</td>
                    <td>
                        {Number(ticket.total_charge_tax ?? 0).toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                        })}
                    </td>
                </tr>
                <tr>
                    <td className="text-end pe-2">
                        Total Misc Charge Non-taxable:
                    </td>
                    <td>
                        {Number(ticket.total_charge_nontaxable ?? 0).toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                        })}
                    </td>
                    <td colSpan={2}></td>
                </tr>
                <tr>
                  <td colSpan={2} />
                </tr>
                <tr>
                    <td className="text-end pe-2">Total Rent Charge Cost:</td>
                    <td>
                        {Number(ticket.total_rent ?? 0).toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                        })}
                    </td>
                    <td className="text-end pe-2">Total Rent Charge Tax:</td>
                    <td>
                        {Number(ticket.total_rent_tax ?? 0).toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                        })}
                    </td>
                </tr>
                <tr>
                    <td className="text-end pe-2">
                        Total Rent Charge Non-taxable:
                    </td>
                    <td>
                        {Number(ticket.total_rent_nontaxable ?? 0).toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                        })}
                    </td>
                    <td colSpan={2}></td>
                </tr>

            </table>                
          </div>
        </div>

          <br />
          <br />
    </div>
      );
    };  

  const options =
    jobs
      ?.filter((jobElem: JobQueryData) => jobElem.active)
      .map((jobElem: JobQueryData) => ({
        value: jobElem.id,
        label: `${jobElem.job_number} - ${jobElem.job}`,
      })) ?? 0;

  const hndSelectJob = (
    selectedOption: { value: number | undefined; label: string } | null
  ) => {
    const job = selectedOption
      ? jobs.find(
          (jobItem: JobQueryData) => jobItem.id === selectedOption.value
        )
      : null;
    setJob(job?.id ?? null);
  };

  function customFilter(option: any, rawInput: any) {
    const searchTerm = rawInput.toLowerCase();
    return option.label.toLowerCase().includes(searchTerm);
  }

  const renderForm = () => {
    return (
      <div>
        <h4>REPORT PARAMETERS</h4>
        <form onSubmit={handleSubmit}>
          <label>Job:</label> <br />
          <Select
            name="job"
            className="form-control"
            onChange={hndSelectJob}
            isClearable
            options={options}
            filterOption={customFilter}
          />
          <label>Optional Start Date:</label> <br />
          <input
            name="month_start"
            type="date"
            className="form-control"
            defaultValue=""
            onChange={(e) => setMonthStart(new Date(e.target.value))}
          ></input>
          <label>Optional End Date:</label> <br />
          <input
            name="month_end"
            type="date"
            className="form-control"
            defaultValue=""
            onChange={(e) => setMonthEnd(new Date(e.target.value))}
          ></input>
          <button
            type="submit"
            className="btn btn-sm btn-success mt-2 button-margin"
          >
            Generate Filtered Report
          </button>
          <button
            type="submit"
            className="btn btn-sm btn-success mt-2 button-margin"
            onClick={handleExportExcel}
          >
            Export to Excel
          </button>
          <button
            className="btn btn-sm btn-success mt-2  button-margin"
            onClick={() => window.print()}
          >
            Print/Export PDF
          </button>
        </form>
      </div>
    );
  };

  const renderChargeTable = (reportData: JobSummaryReportData) => {
    const job_info = jobs.find((test_job: JobQueryData) => test_job.id === job);

    return job_info && reportData.charges.length > 0 && (<>
        <br />
        <br />
        <div key={`ticket-${job_info.id}`} className="border-bottom border-top-dark">
          <BasicFilterTable
            tableKey={`TicketChargeTable-${job_info.id}`}
            title={`Non-Ticket Charges - ${job_info.job_number}`}
            dataSource={reportData.charges}
            columns={chargeColumns}
            filterFn={undefined}
            filterButtons={filterButtons}
            hideExport={true}
            hideSearch={true}
            exportFileName={`General Ledger Data - ${job_info.id}.csv`}
          />
          
        </div>
    </>);
  }

  const renderTable = (reportData: JobSummaryReportData) => {
    const ticketTables = reportData.tickets.map((elem: JobSummaryReportTicket) => {
      return (<>
        <br />
        <br />
        <div key={`ticket-${elem.id}`} className="border-bottom border-top-dark">
          {renderTicketSummary(elem)}
          <BasicFilterTable
            tableKey={`TicketTable-${elem.id}`}
            title={`Tickets - ${elem.id}`}
            dataSource={elem.items}
            columns={ticketColumns}
            filterFn={undefined}
            filterButtons={filterButtons}
            hideExport={true}
            hideSearch={true}
            exportFileName={`${elem.id} Ticket Summary.csv`}
          />
          {elem.charges.length > 0 ? <>
            {elem.charges.filter((item) => item.type !== "RENT").length > 0 ? <BasicFilterTable
              tableKey={`MiscChargeTable-${elem.id}`}
              title={`Misc Charges - ${elem.id}`}
              dataSource={elem.charges.filter((item) => item.type !== "RENT")}
              columns={chargeColumns}
              filterFn={undefined}
              filterButtons={filterButtons}
              hideExport={true}
              hideSearch={true}
              exportFileName={`${elem} Ticket Misc Charges.csv`}
            /> : null}
            {elem.charges.filter((item) => item.type === "RENT").length > 0 ? <BasicFilterTable
              tableKey={`RentChargeTable-${elem.id}`}
              title={`Rent Charges - ${elem.id}`}
              dataSource={elem.charges.filter((item) => item.type === "RENT")}
              columns={chargeColumns}
              filterFn={undefined}
              filterButtons={filterButtons}
              hideExport={true}
              hideSearch={true}
              exportFileName={`${elem} Ticket Rent Charges.csv`}
            /> : null}
          </> : null}
          
        </div>
    </>);
    });

    return ticketTables;
  };

  return (
    <div>
      <Layout>
        <div id="report-title-container" className="hide-printable">
          <ToastContainer />
          <h2>Job Summary Report</h2>
          <br />
          {renderForm()}
          <br />
          <br />
        </div>

        <div className="report-data-printable">
            {reportData && renderJobSummary()}
            {reportData && renderChargeTable(reportData)}
            {reportData && renderTable(reportData)}
        </div>
      </Layout>
    </div>
  );
}

export default JobSummaryReport;
