import * as React from "react";
import { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";

import "../../styles/print.css";

import Layout from "../../components/Layout";
import Loader from "../../components/Loader";
import ErrorFallback from "../../components/Error";

import { useQuery } from "@tanstack/react-query";
import * as Sentry from "@sentry/react";
import { stockIssueDetailByJobQuery } from "../../apis/queries/tickets";
import { jobQuery } from "../../apis/queries/common";

import CostCodeReassignmentModal from "../../components/CostCodeReassignmentModal";
import { debounce } from "lodash";
import { memo, useCallback } from "react";
import { StockIssueDetailQueryWithVendorData } from "../../apis/interfaces/tickets";
const { DateTime } = require("luxon");

interface StaffInventoryCardsProps {
  jobId: number;
  searchString: string;
  showSmallTools: boolean;
  filterBilled: boolean;
  onReassignCostCode: Function;
  inventory: Array<StockIssueDetailQueryWithVendorData>
}
interface StaffInventoryJobProps {
  jobId: string;
}

const StaffInventoryCards = memo((props: StaffInventoryCardsProps) => {
  const {
    searchString,
    filterBilled,
    showSmallTools,
    onReassignCostCode,
    inventory,
  } = props;

  const formatType = (stockType: string) => {
    return stockType === "small-tool" ? "Small Tools" : "Consumable";
  };

  const formatDate = (elem: StockIssueDetailQueryWithVendorData) => {
    return elem.date_delivered
      ? DateTime.fromISO(elem.date_delivered).toLocaleString({
          month: "long",
          day: "numeric",
          year: "numeric",
        })
      : null;
  };

  const formatReturnedDate = (elem: StockIssueDetailQueryWithVendorData) => {
    return elem.date_returned
      ? DateTime.fromISO(elem.date_returned).toLocaleString({
          month: "long",
          day: "numeric",
          year: "numeric",
        })
      : null;
  };


  const formatBilledDate = (elem: StockIssueDetailQueryWithVendorData) => {
    return elem.date_charged
      ? DateTime.fromISO(elem.date_charged).toLocaleString({
          month: "long",
          day: "numeric",
          year: "numeric",
        })
      : null;
  };

  const shouldDisplay = (elem: StockIssueDetailQueryWithVendorData) => {
    if (
      elem.delivered &&
      ((filterBilled && !elem.date_charged) || !filterBilled) &&
      (searchString.length <= 3 ||
        `${elem.stock.make} ${elem.stock.model} ${elem.stock.stock} ${elem.stock.ycc_serial} ${elem.stock_issue}`
          .toLowerCase()
          .includes(searchString.toLowerCase()))
    ) {
      return (
        (elem.stock.stock_type === "small-tool" && !elem.date_returned && !elem.date_transferred && showSmallTools) ||
        (elem.stock.stock_type !== "small-tool" && !showSmallTools)
      );
    }

    return false;
  };

  const hndReassignCostCode = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      const id = event.currentTarget.getAttribute("data-sidid");
      const item = inventory.find(
        (sid: StockIssueDetailQueryWithVendorData) => sid.id?.toString() === id
      );

      onReassignCostCode(item);
    },
    [inventory, onReassignCostCode]
  );

  return <>
      {inventory
        .sort((a: any, b: any) => a.stock.stock.localeCompare(b.stock.stock))
        .map(
          (elem: StockIssueDetailQueryWithVendorData) =>
            shouldDisplay(elem) && (
              <div className="card job-inv-card mb-4" key={elem.id}>
                <div className="card-body">
                  <h2>{elem.stock.stock}</h2>
                  {elem.stock.stock_type === "small-tool" ? (
                    <h4>
                      {elem.stock.make} {elem.stock.model}
                    </h4>
                  ) : null}

                  <div className="d-flex w-100 sd-inventory-container">
                    <div className="job-inv-title text-end">
                      Type:
                    </div>
                    <div className="w-50">
                      {formatType(elem.stock.stock_type)}
                      <br />
                      {elem.stock.stock_category.stock_category}
                    </div>

                    {elem.stock.stock_type === "small-tool" ? (<><div className="job-inv-title text-end">
                      YCC Serial:
                    </div>
                    <div className="w-50">
                      {elem.stock.ycc_serial}
                    </div></>) : null}

                    {elem.stock.stock_type === "consumable" ? (<>
                    <div className="job-inv-title text-end">
                      Qty Out:
                    </div>
                    <div className="w-50">
                      {elem.qty_delivered}
                    </div>
                    </>) : null}

                    <div className="job-inv-title text-end">
                      Delivered On:
                    </div>
                    <div className="w-50">
                      {formatDate(elem)}
                    </div>

                    {elem.stock.stock_type === "small-tool" ? (<>
                    <div className="job-inv-title text-end">
                      Rental Rate:
                    </div>
                    <div className="w-50">
                      {" "}
                            
                      {(
                        Number(elem.stock.rental_rate) * 8
                      ).toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                      })}
                      /{"day"}
                    </div>
                    </>) : null}

                    <div className="job-inv-title text-end">
                      Notes:
                    </div>
                    <div className="w-50">
                      {elem.note}
                    </div>

                    <div className="job-inv-title text-end">
                      From Ticket:
                    </div>
                    <div className="w-50">
                      <Link to={`/staff/ticket/${elem.stock_issue}`}>{elem.stock_issue}</Link>
                    </div>

                    <div className="job-inv-title text-end">
                      Cost Code:
                    </div>
                    <div className="w-50">
                      {elem.cost_code ? (
                          <>
                            {elem.cost_code.cost_code}{" "}
                            {elem.cost_code.cost_type}
                            <br />
                          </>
                        ) : null}
                        {!elem.date_charged ? (
                          <button
                            data-sidid={elem.id}
                            onClick={hndReassignCostCode}
                            className="btn btn-link btn-sm p-0"
                          >
                            Change Cost Code
                          </button>
                        ) : null}
                        {elem.date_charged ? (
                          <>
                            <br />
                            Billed {formatBilledDate(elem)}
                          </>
                        ) : null}
                    </div>

                  </div>
                </div>
              </div>
            )
        )}
    </>
}
);

const StaffInventoryJobTitle = (props: StaffInventoryJobProps) => {
  const { jobId } = props;
  const job = useQuery(jobQuery(jobId));

  console.log(job.isLoading);

  return job.isLoading ? <></> : <>{job.data.job}</>;
};

const StaffInventoryDisplay: React.FC = () => {
  const { id } = useParams();
  const [searchString, setSearchString] = useState("");
  const [showCostCodeModal, setShowCostCodeModal] = useState(false);
  const [costCodeStockIssue, setCostCodeStockIssue] =
    useState<StockIssueDetailQueryWithVendorData | null>(null);
  const [jobId, setJobId] = useState<string | null>(null);
  const [filterBilled, setFilterBilled] = useState(false);
  const inventory = useQuery(stockIssueDetailByJobQuery(parseInt(`${id}`)));

  useEffect(() => {
    if (id && !isNaN(parseInt(id))) {
      setJobId(id);
    }
  }, [id]);

  const debouncedSearchUpdate = useCallback(
    debounce((newSearchString) => {
      setSearchString(newSearchString);
    }, 300),
    []
  );

  const hndSearchUpdate = (ev: any) => {
    debouncedSearchUpdate(ev.target.value);
  };

  const hndShowCostCode = (item: StockIssueDetailQueryWithVendorData) => {
    setCostCodeStockIssue(item);
    setShowCostCodeModal(true);
  };

  const hndCancelCostCode = () => {
    setShowCostCodeModal(false);
  };

  const hndUpdateCostCode = () => {
    setShowCostCodeModal(false);
    setJobId(null);
    setTimeout(() => {
      id && setJobId(id);
    }, 5);
  };

  const hndSwapItemFilter = () => {
    setFilterBilled(!filterBilled);
  };

  return (
    <Sentry.ErrorBoundary fallback={ErrorFallback}>
      <Layout>
        <Loader isLoading={!jobId}>
          <div className="row mb-4">
            <div className="col-md-10 offset-md-1 col-sm-12">
              <div className="border border-secondary-subtle p-0">
                <h1 className="dashboard m-0 p-2">
                  Inventory For:
                  <br />
                  {jobId ? <StaffInventoryJobTitle jobId={jobId} /> : null}
                </h1>
              </div>
              <input
                type="text"
                placeholder="Search Items"
                className="search-input hide-printable"
                onChange={hndSearchUpdate}
              />

              <div className="p-2">
                {searchString.length > 3 ? (
                  <p className="text-center show-printable">
                    Search for: {searchString}
                  </p>
                ) : null}
              </div>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-md-10 offset-md-1 col-sm-12">
              <p className="text-center hide-printable">
                <button
                  className="btn btn-link btn-print-button"
                  type="button"
                  onClick={() => window.print()}
                >
                  Print Report
                </button>
              </p>

              <p className="text-center hide-printable">
                Showing {filterBilled ? <>Unbilled</> : <>All</>} Items &bull;{" "}
                <button
                  className="btn btn-link btn-print-button p-0"
                  type="button"
                  onClick={hndSwapItemFilter}
                >
                  Change
                </button>
              </p>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-md-10 offset-md-1 col-sm-12">
              <div className="border border-secondary-subtle p-0">
                <h1 className="dashboard m-0 p-2">Small Tools Inventory</h1>
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-12">
              {jobId && !inventory.isLoading ? (
                <StaffInventoryCards
                  inventory={inventory.data}
                  jobId={parseInt(jobId)}
                  searchString={searchString}
                  showSmallTools={true}
                  filterBilled={filterBilled}
                  onReassignCostCode={hndShowCostCode.bind(this)}
                />
              ) : <h1>Loading inventory...</h1>}
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-md-10 offset-md-1 col-sm-12">
              <div className="border border-secondary-subtle p-0">
                <h1 className="dashboard m-0 p-2">Consumable Inventory</h1>
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-12">
              {jobId && !inventory.isLoading ? (
                <StaffInventoryCards
                  inventory={inventory.data}
                  jobId={parseInt(jobId)}
                  searchString={searchString}
                  showSmallTools={false}
                  filterBilled={filterBilled}
                  onReassignCostCode={hndShowCostCode.bind(this)}
                />
              ) : <h1>Loading inventory...</h1>}
            </div>
          </div>

          {jobId && costCodeStockIssue ? (
            <CostCodeReassignmentModal
              jobId={Number(jobId)}
              stockIssueDetail={costCodeStockIssue}
              onCancel={hndCancelCostCode}
              onChange={hndUpdateCostCode}
              show={showCostCodeModal}
            />
          ) : null}
        </Loader>
      </Layout>
    </Sentry.ErrorBoundary>
  );
};

export default StaffInventoryDisplay;
