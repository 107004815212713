import * as Yup from "yup";
import { ErrorMessage, Field, Form, Formik, FormikHelpers } from "formik";
import type {
  StockCategoryQueryData,
  StockMutationData,
  UnitOfMeasureQueryData,
  MakeModelListItem,
} from "../apis/interfaces/invitems";
import { Modal } from "react-bootstrap";
import {
  stockCategoryQueryAll,
  stockQueryAll,
  unitOfMeasureQueryAll,
} from "../apis/queries/invitems";
import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { vendorQueryAll } from "../apis/queries/common";
import { VendorQueryData } from "../apis/interfaces/common";
import { createStockAdjustmentMutation } from "../apis/mutations/invitems";

interface StockFormProps {
  initialValues: StockMutationData;
  onSubmit: any;
  onCancel: any;
  show: any;
  showEdit: any;
  makeModels: any;
}

const validationSchema = Yup.object({
  stock_disposition: Yup.string().optional(),
  serial: Yup.string().optional(),
  stock_type: Yup.string().optional(),
  case_quantity: Yup.string().optional(),
  stock: Yup.string().optional(),
  lead_time: Yup.number(),
  taxable_ny: Yup.boolean(),
  taxable_nj: Yup.boolean(),
  quantity_on_hand: Yup.number()
    .test("quantityValidation", "Quantity is required", function (value) {
      if (this.parent.isQuantityEnabled) {
        return Yup.number().optional().isValidSync(value);
      }
      return true;
    })
    .test(
      "smallToolValidation",
      "Quantity cannot exceed 1 when type is small tool.",
      function () {
        const type = this.parent.stock_type;
        return !(type === "small-tool" && this.parent.quantity_on_hand > 1);
        // Validation passes
      }
    ),
  reason: Yup.string().test(
    "reasonValidation",
    "Reason is required",
    function (value) {
      if (this.parent.isQuantityEnabled) {
        return Yup.string().optional().isValidSync(value);
      }
      return true;
    }
  ),
  reorder_point: Yup.number(),
  reorder_quantity: Yup.number(),
  unit_of_measure: Yup.number().optional(),
  unit_price: Yup.number(),
  vendor: Yup.number().optional(),
});

const StockForm = (props: StockFormProps) => {
  const { initialValues, onSubmit, onCancel, makeModels } = props;
  const [cats, setCats] = useState<StockCategoryQueryData[]>([]);
  const [catOpts, setCatOpts] = useState<Array<JSX.Element>>([]);
  const [units, setUnits] = useState<UnitOfMeasureQueryData[]>([]);
  const [vendors, setVendors] = useState<VendorQueryData[]>([]);
  const [isQuantityEnabled, setIsQuantityEnabled] = useState(false);
  const [reason, setReason] = useState("");
  const [newQuantity, setnewQuantity] = useState(0);
  const [, setModels] = useState<string[]>([]);
  const [, setMakes] = useState<string[]>([]);
  const [make, setMake] = useState<string>("");
  const [model, setModel] = useState<string>("");

  const { data } = useQuery(stockCategoryQueryAll());
  const [isUpdatingStock, setIsUpdatingStock] = useState(false);
  const { data: unit_data } = useQuery(unitOfMeasureQueryAll());
  const { data: vendor_data } = useQuery(vendorQueryAll());
  const { data: stockItems } = useQuery(stockQueryAll(0, 0));

  const createStockAdjustment = useMutation(createStockAdjustmentMutation);

  useEffect(() => {
    if (data) {
      setCats(data);

      const tmpCatOpts: Array<JSX.Element> = [];

      data.forEach((cat: StockCategoryQueryData) => {
        if (cat.active && !cat.parent_category) {
          tmpCatOpts.push((<option key={cat.id} value={cat.id}>{cat.stock_category}</option>));

          data.forEach((subcat: StockCategoryQueryData) => {
            if (subcat.active && subcat.parent_category && subcat.parent_category.id === cat.id) {
              tmpCatOpts.push((<option key={subcat.id} value={subcat.id}>&nbsp; &nbsp;-- {subcat.stock_category}</option>));
            }
          });
        }
      });

      setCatOpts(tmpCatOpts);
    }
    if (unit_data) {
      setUnits(unit_data);
    }
    if (vendor_data) {
      setVendors(vendor_data);
    }
    if (stockItems) {
      const stockItemsArray = Object.values(stockItems);
      const uniqueModels = Array.from(
        new Set(
          stockItemsArray
            .filter(
              (item: any) =>
                item && item.stock_type === "small-tool" && item.active
            )
            .map((item: any) => item.model)
        )
      );
      const uniqueMakes = Array.from(
        new Set(
          stockItemsArray
            .filter(
              (item: any) =>
                item && item.stock_type === "small-tool" && item.active
            )
            .map((item: any) => item.make)
        )
      );
      setModels(uniqueModels as string[]);
      setMakes(uniqueMakes as string[]);
    }
  }, [data, stockItems, unit_data, vendor_data]);
  const handleSubmit = (
    values: StockMutationData,
    { setStatus }: FormikHelpers<StockMutationData>
  ) => {
    try {
      validationSchema.validateSync(values, { abortEarly: false });
      if (initialValues?.stock) {
        setIsUpdatingStock(true);
        makeModels.refetch();
      }
    } catch (errors) {
      setStatus("An error occurred during form submission.");
    }
  };
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      //validationSchema={validationSchema}
      onClose={onCancel}
    >
      {({
        values,
        handleChange,
        handleSubmit,
        isSubmitting,
        isValid,
        setStatus,
      }) => (
        <>
          <Form onSubmit={handleSubmit}>
            <Modal.Body>
              <div className="form-group mb-2">
                <label htmlFor="stock">Stock Name:</label>
                <Field
                  type="text"
                  id="stock"
                  name="stock"
                  className="form-control"
                  onChange={handleChange}
                />
                <ErrorMessage
                  name="stock"
                  component="div"
                  className="error-message text-danger"
                />
              </div>
              <div className="form-group mb-2">
                <label htmlFor="stock">Vendor:</label>
                <Field
                  as="select"
                  id="vendor"
                  name="vendor"
                  className="form-control"
                >
                  <option>Select a vendor</option>
                  {vendors.map((vendor) => (
                    <option key={vendor.id} value={vendor.id}>
                      {vendor.vendor_name}
                    </option>
                  ))}
                </Field>
                <ErrorMessage
                  name="vendor"
                  component="div"
                  className="error-message text-danger"
                />
              </div>
              <div className="form-group mb-2">
                <label htmlFor="stock">Type:</label>
                <Field
                  as="select"
                  id="stock_type"
                  name="stock_type"
                  className="form-control"
                  onChange={handleChange}
                >
                  <option value="">Select a type</option>
                  <option value="small-tool">Small Tool</option>
                  <option value="consumable">Consumable</option>
                </Field>
                <ErrorMessage
                  name="stock_type"
                  component="div"
                  className="error-message text-danger"
                />
              </div>
              <div className="form-group mb-2">
                <label htmlFor="stock">Category:</label>
                <Field
                  as="select"
                  id="stock_category.id"
                  name="stock_category.id"
                  className="form-control"
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                    const selectedCategoryId = Number(e.target.value);
                    const selectedCategory = cats.find(
                      (cat) => cat.id === selectedCategoryId
                    );
                    handleChange({
                      target: {
                        name: "stock_category",
                        value: {
                          id: selectedCategory?.id || "",
                          stock_category:
                            selectedCategory?.stock_category || "",
                        },
                      },
                    });
                  }}
                >
                  <option value="">Select a category</option>
                  {catOpts.map((catopt) => catopt)}
                </Field>

                <ErrorMessage
                  name="stock_category"
                  component="div"
                  className="error-message text-danger"
                />
              </div>
              {values.stock_type === "small-tool" && (
                <div className="form-group mb-2">
                  <label htmlFor="stock">Serial:</label>
                  <Field
                    type="text"
                    id="serial"
                    name="serial"
                    className="form-control"
                    onChange={handleChange}
                  />
                  <ErrorMessage
                    name="serial"
                    component="div"
                    className="error-message text-danger"
                  />
                </div>
              )}
              {values.stock_type === "small-tool" && (
                <div className="form-group mb-2">
                  <label htmlFor="stock">YCC Serial:</label>
                  <Field
                    type="text"
                    id="ycc_serial"
                    name="ycc_serial"
                    className="form-control"
                    onChange={handleChange}
                  />
                  <ErrorMessage
                    name="ycc_serial"
                    component="div"
                    className="error-message text-danger"
                  />
                </div>
              )}
              {values.stock_type === "small-tool" && (
                <div className="form-group mb-2">
                  <label htmlFor="acquisition_date">Acquisition Date:</label>
                  <Field
                    type="date"
                    id="acquisition_date"
                    name="acquisition_date"
                    className="form-control"
                    onChange={handleChange}
                  />
                  <ErrorMessage
                    name="acquisition_date"
                    component="div"
                    className="error-message text-danger"
                  />
                </div>
              )}
              {makeModels.data && values.stock_type === "small-tool" && (
                <div className="form-group mb-2">
                  <label htmlFor="stock">Make:</label>
                  <Field
                    as="select"
                    id="make"
                    name="make"
                    className="form-control"
                    onChange={handleChange}
                  >
                    <option value="">Select a make</option>
                    {makeModels.data.map((make: MakeModelListItem, index: number) => (
                      <option
                        key={`make-selector-${index}`}
                        value={make.name}
                      >
                        {make.name}
                      </option>
                    ))}
                    <option value="-1">Other</option>
                  </Field>
                  <ErrorMessage
                    name="make"
                    component="div"
                    className="error-message text-danger"
                  />
                </div>
              )}
              {values.make === "-1" && (
                <div className="form-group mb-2">
                  <label htmlFor="modelOther">Other Make:</label>
                  <Field
                    type="text"
                    id="makeOther"
                    name="makeOther"
                    className="form-control"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      handleChange(e);
                      setMake(e.target.value);
                    }}
                  />
                  <ErrorMessage
                    name="makeOther"
                    component="div"
                    className="error-message text-danger"
                  />
                </div>
              )}
              {makeModels.data && values.stock_type === "small-tool" && (
                <div className="form-group mb-2">
                  <label htmlFor="stock">Model:</label>
                  <Field
                    as="select"
                    id="model"
                    name="model"
                    className="form-control"
                    onChange={handleChange}
                    disabled={values.make === ""}
                  >
                    <option value="">
                      Select a model{values.make === "" ? <> first</> : null}
                    </option>
                    {makeModels.data.map((make: MakeModelListItem) => {
                      if (make.name === values.make) {
                        return make.children.map((model: string, index) => (
                          <option key={`make-model-${index}`} value={model}>
                            {model}
                          </option>
                        ));
                      }
                      return null;
                    })}
                    <option value="-1">Other</option>
                  </Field>
                  <ErrorMessage
                    name="model"
                    component="div"
                    className="error-message text-danger"
                  />
                </div>
              )}
              {values.model === "-1" && (
                <div className="form-group mb-2">
                  <label htmlFor="modelOther">Other Model:</label>
                  <Field
                    type="text"
                    id="modelOther"
                    name="modelOther"
                    className="form-control"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      handleChange(e);
                      setModel(e.target.value);
                    }}
                  />
                  <ErrorMessage
                    name="modelOther"
                    component="div"
                    className="error-message text-danger"
                  />
                </div>
              )}
              {values.stock_type === "small-tool" && (
                <div className="form-group mb-2">
                  <label htmlFor="stock">Rental Rate (hourly):</label>
                  <Field
                    type="number"
                    id="rental_rate"
                    name="rental_rate"
                    className="form-control"
                    onChange={handleChange}
                    inputMode="decimal"
                  />
                  <ErrorMessage
                    name="rental_rate"
                    component="div"
                    className="error-message text-danger"
                  />
                </div>
              )}

              <div className="form-group mb-2">
                <label htmlFor="stock">Disposition:</label>
                <Field
                  as="select"
                  id="stock_disposition"
                  name="stock_disposition"
                  className="form-control"
                  onChange={handleChange}
                >
                  <option value="">Select a disposition</option>
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                  <option value="sold">Sold</option>
                  <option value="missing">MIA</option>
                  <option value="repair">Repair</option>
                </Field>
                <ErrorMessage
                  name="stock_disposition"
                  component="div"
                  className="error-message text-danger"
                />
              </div>
              <div className="form-group mb-2">
                <label htmlFor="stock">Lead Time (days):</label>
                <Field
                  type="number"
                  id="lead_time"
                  name="lead_time"
                  className="form-control"
                  onChange={handleChange}
                  inputMode="numeric"
                  pattern="[0-9]*"
                />
                <ErrorMessage
                  name="lead_time"
                  component="div"
                  className="error-message text-danger"
                />
              </div>
              {values.stock_type !== "small-tool" && (
                <div className="form-group mb-2">
                  <label htmlFor="stock">Taxable (NY):</label>
                  <Field
                    type="checkbox"
                    id="taxable_ny"
                    name="taxable_ny"
                    className="form-check-input"
                    onChange={handleChange}
                  />
                  <ErrorMessage
                    name="taxable_ny"
                    component="div"
                    className="error-message text-danger"
                  />
                </div>
              )}
              {values.stock_type !== "small-tool" && (
                <div className="form-group mb-2">
                  <label htmlFor="stock">Taxable (NJ):</label>
                  <Field
                    type="checkbox"
                    id="taxable_nj"
                    name="taxable_nj"
                    className="form-check-input"
                    onChange={handleChange}
                  />
                  <ErrorMessage
                    name="taxable_nj"
                    component="div"
                    className="error-message text-danger"
                  />
                </div>
              )}
              {!isQuantityEnabled && values.stock_type !== "small-tool" && (
                <div className="form-group mb-2">
                  {!values.id &&
                    !isQuantityEnabled &&
                    values.stock_type !== "small-tool" && (
                      <label htmlFor="stock">Initial Quantity:</label>
                    )}
                  {values.id &&
                    !isQuantityEnabled &&
                    values.stock_type !== "small-tool" && (
                      <label htmlFor="stock">Quantity on Hand:</label>
                    )}

                  <Field
                    type="text"
                    id="quantity_on_hand"
                    name="quantity_on_hand"
                    className="form-control"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      handleChange(e);
                      setnewQuantity(parseInt(e.target.value, 10));
                    }}
                    disabled={values.id && !isQuantityEnabled}
                    inputMode="numeric"
                    pattern="[0-9]*"
                  />
                  <ErrorMessage
                    name="quantity_on_hand"
                    component="div"
                    className="error-message text-danger"
                  />
                </div>
              )}
              {isQuantityEnabled && (
                <div className="form-group mb-2">
                  <label htmlFor="stock">New Quantity:</label>
                  <Field
                    type="number"
                    id="newQuantity"
                    name="newQuantity"
                    className="form-control"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      handleChange(e);
                      setIsUpdatingStock(true);
                      setnewQuantity(parseInt(e.target.value, 10));
                    }}
                    disabled={values.id && !isQuantityEnabled}
                    inputMode="numeric"
                    pattern="[0-9]*"
                  />
                  <ErrorMessage
                    name="newQuantity"
                    component="div"
                    className="error-message text-danger"
                  />
                </div>
              )}
              {isQuantityEnabled && (
                <div className="form-group mb-2">
                  <label htmlFor="stock">Reason:</label>
                  <Field
                    type="text"
                    id="reason"
                    name="reason"
                    className="form-control is-invalid"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      handleChange(e);
                      setIsUpdatingStock(true);
                      setReason(e.target.value);
                    }}
                  />
                  <ErrorMessage
                    name="reason"
                    component="div"
                    className="error-message text-danger"
                  />
                </div>
              )}
              {values.id && (
                <button
                  type="button"
                  className="btn btn-sm btn-secondary"
                  onClick={() => setIsQuantityEnabled(!isQuantityEnabled)}
                >
                  Update Quantity
                </button>
              )}

              {values.stock_type !== "small-tool" && (
                <div className="form-group mb-2">
                  <label htmlFor="stock">Reorder Point:</label>
                  <Field
                    type="number"
                    id="reorder_point"
                    name="reorder_point"
                    className="form-control"
                    onChange={handleChange}
                    inputMode="numeric"
                    pattern="[0-9]*"
                  />
                  <ErrorMessage
                    name="reorder_point"
                    component="div"
                    className="error-message text-danger"
                  />
                </div>
              )}
              {values.stock_type !== "small-tool" && (
                <div className="form-group mb-2">
                  <label htmlFor="stock">Reorder Quantity:</label>
                  <Field
                    type="number"
                    id="reorder_quantity"
                    name="reorder_quantity"
                    className="form-control"
                    onChange={handleChange}
                    inputMode="numeric"
                    pattern="[0-9]*"
                  />
                  <ErrorMessage
                    name="reorder_quantity"
                    component="div"
                    className="error-message text-danger"
                  />
                </div>
              )}
              {values.stock_type !== "small-tool" && (
                <div className="form-group mb-2">
                  <label htmlFor="stock">Unit Of Measure:</label>
                  <Field
                    as="select"
                    id="unit_of_measure"
                    name="unit_of_measure"
                    className="form-control"
                  >
                    <option value="">Select a unit</option>
                    {units.map((unit) => (
                      <option key={unit.id} value={unit.id}>
                        {unit.unit_of_measure}
                      </option>
                    ))}
                  </Field>
                </div>
              )}
              {values.stock_type !== "small-tool" && (
                <div className="form-group mb-2">
                  <label htmlFor="stock">Case Quantity:</label>
                  <Field
                    type="number"
                    id="case_quantity"
                    name="case_quantity"
                    className="form-control"
                    onChange={handleChange}
                    inputMode="numeric"
                    pattern="[0-9]*"
                  />
                </div>
              )}
              <div className="form-group mb-2">
                {values.stock_type !== "small-tool" && (
                  <label htmlFor="stock">Unit Price:</label>
                )}
                {values.stock_type === "small-tool" && (
                  <label htmlFor="stock">Acquisition Cost</label>
                )}
                <Field
                  type="number"
                  id="unit_price"
                  name="unit_price"
                  className="form-control"
                  onChange={handleChange}
                />
                <ErrorMessage
                  name="unit_price"
                  component="div"
                  className="error-message text-danger"
                />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="text-right">
                <button
                  onClick={onCancel}
                  className="btn btn-sm btn-danger mx-3"
                  type="button"
                >
                  Cancel
                </button>

                <button
                  type="submit"
                  className="btn btn-sm btn-success"
                  disabled={!(!isSubmitting && isValid)}
                  onClick={async () => {
                    try {
                      if (isUpdatingStock) {
                        if (values.stock_type === "small-tool") {
                          values.reorder_quantity = 1;
                          values.reorder_point = 0;
                          values.taxable_nj = false;
                          values.taxable_ny = false;
                          if (model.length > 0) {
                            values.model = model;
                          }
                          if (make.length > 0) {
                            values.make = make;
                          }
                        }
                        const oldQuantity =
                          Number(newQuantity) - Number(values.quantity_on_hand);
                        await createStockAdjustment.mutate({
                          comments: reason,
                          date_adjusted: new Date(),
                          deleted: false,
                          quantity: Number(oldQuantity),
                          stock: values,
                        });
                        values.quantity_on_hand = Number(newQuantity);
                        onSubmit(values);
                      } else {
                        if (values.stock_type === "small-tool") {
                          values.taxable_nj = false;
                          values.taxable_ny = false;
                        }
                        onSubmit(values);
                      }
                    } catch (error) {
                      setStatus("An error occurred during form submission.");
                    }
                  }}
                >
                  Save
                </button>
              </div>
            </Modal.Footer>
          </Form>
        </>
      )}
    </Formik>
  );
};

export default StockForm;
