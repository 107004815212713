// Meta routes for the app

import SalesTax from "../pages/Meta/SalesTax";
import Company from "../pages/Meta/Company";
import Department from "../pages/Meta/Department";
import Vendor from "../pages/Meta/Vendor";
import Charge from "../pages/Meta/Charge";
import Location from "../pages/Meta/Location";
import StockCategory from "../pages/Meta/StockCategory";
import StockOrder from "../pages/Meta/StockOrders";
import CostCode from "../pages/Meta/CostCode";

const metaRoutes = [
  {
    path: "/meta/charge/:id?",
    element: <Charge />,
  },
  {
    path: "/meta/company/:id?",
    element: <Company />,
  },
  {
    path: "/meta/department/:id?",
    element: <Department />,
  },
  {
    path: "/meta/location/:id?",
    element: <Location />,
  },
  {
    path: "/meta/salestax/:id?",
    element: <SalesTax />,
  },
  {
    path: "/meta/vendor/:id?",
    element: <Vendor />,
  },
  {
    path: "/meta/stock_category/:id?",
    element: <StockCategory />,
  },
  {
    path: "/meta/stock_order/:id?",
    element: <StockOrder />,
  },
  {
    path: "/meta/cost_code/:id?",
    element: <CostCode />,
  },
];

export default metaRoutes;
