import React from "react";
import { toast } from "react-toastify";
import { useTransferTicket } from "./MutationsAndQueries";
import { StockIssueDetailQueryData } from "../../../apis/interfaces/tickets";
import * as Sentry from '@sentry/react';

const { DateTime } = require("luxon");

const useTransferItem = () => {
  const performTransfer = useTransferTicket();

  const transferItem = async (
    event: React.FormEvent<HTMLFormElement>,
    selectedTicketItem: StockIssueDetailQueryData
  ) => {
    event.preventDefault();
    const formData = new FormData(event.target as HTMLFormElement);
    const newJob = formData.get("new_job");
    const transferDate = formData.get("transfer_date");
    const receivedBy = formData.get('transferred_to_person');
    // Close out old item on old job and do relevant changes.
    if (selectedTicketItem?.stock.stock_type === "small-tool") {
      const closeSI = {
        job: Number(newJob),
        stock_issue_detail: Number(selectedTicketItem?.id),
        date_transferred: DateTime.fromISO(transferDate?.toString() ?? ""),
        received_by: receivedBy
      };
      try {
        await performTransfer.mutateAsync(closeSI);
        toast.success("Tool transferred.");
      } catch (error) {
        toast.error(
          "An error has occurred while transferring. Please try again."
        );
        Sentry.captureException(error);
      }
    }
  };
  return { transferItem };
};
export default useTransferItem;
