import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Modal } from "react-bootstrap";
import type {
  SalesTaxMutationData,
  SalesTaxQueryData,
} from "../../apis/interfaces/common";
import { salesTaxQueryAll } from "../../apis/queries/common";
import {
  createSalesTaxMutation,
  updateSalesTaxMutation,
} from "../../apis/mutations/common";

import SalesTaxForm from "../../forms/SalesTaxForm";

import BasicFilterTable from "../../components/BasicFilterTable";
import Layout from "../../components/Layout";
import Loader from "../../components/Loader";
import { toast, ToastContainer } from "react-toastify";
import ErrorFallback from "../../components/Error";
import * as Sentry from "@sentry/react";

interface SalesTaxTableProps {
  onAddNew: any;
  onEdit: any;
  taxes: Array<SalesTaxQueryData>;
  setSalesTaxInitialValues: any;
  setShowSalesTaxModal: any;
  setEdit: any;
  edit: boolean;
}

interface SalesTaxModalProps {
  show: boolean;
  onCancel: Function;
  onOk: Function;
  initialValues: SalesTaxMutationData;
  setShowSalesTaxModal: any;
  setSalesTaxInitialValues: any;
  setEdit: any;
  edit: boolean;
}

const SalesTaxModal = (props: SalesTaxModalProps) => {
  const { show, onCancel, onOk, initialValues, setEdit, edit } = props;
  const queryClient = useQueryClient();
  const createSalesTax = useMutation({
    ...createSalesTaxMutation,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["sales_taxQueryAll"] });
      onOk();
    },
  });
  const updateSalesTaxMutationFn = async (
    params: [number, SalesTaxMutationData]
  ) => {
    const [id, data] = params;
    try {
      await updateSalesTaxMutation.mutationFn(id, data);
      onOk();
    } catch (error) {
      console.log(error);
    }
  };
  const updateSalesTax = useMutation(updateSalesTaxMutationFn);

  const hndSubmit = async (values: SalesTaxMutationData) => {
    try {
      if (!values.id) {
        await createSalesTax.mutateAsync(values);
        toast.success("Sales Tax Created");
      } else {
        await updateSalesTax.mutateAsync([values.id as number, values]);
        setEdit(false);
        toast.success("Sales Tax Updated");
      }
    } catch (err) {
      console.log(err);
      toast.error("An error occurred while updating the sales tax");
    }
  };
  const handleModalClose = () => {
    onCancel();
    setEdit(false);
  };

  return (
    <Modal show={show} onCancel={handleModalClose}>
      {edit && (
        <Modal.Header closeButton onClick={handleModalClose}>
          Edit Sales Tax
        </Modal.Header>
      )}
      {!edit && (
        <Modal.Header closeButton onClick={handleModalClose}>
          Add Sales Tax
        </Modal.Header>
      )}

      <SalesTaxForm
        initialValues={initialValues}
        onCancel={handleModalClose}
        onSubmit={hndSubmit}
      />
    </Modal>
  );
};

const SalesTaxTable = (props: SalesTaxTableProps) => {
  const {
    onAddNew,
    taxes,
    setShowSalesTaxModal,
    setSalesTaxInitialValues,
    setEdit,
  } = props;
  const fireOnEdit = (row: SalesTaxQueryData) => {
    setEdit(true);
    setSalesTaxInitialValues({
      id: row.id,
      sales_tax: row.sales_tax,
      sales_tax_code: row.sales_tax_code,
      sales_tax_percentage: row.sales_tax_percentage,
      state: row.state,
      active: row.active,
    });
    setShowSalesTaxModal(true);
  };

  const buttons = (
    <button className="btn btn-link" onClick={onAddNew}>
      Add New
    </button>
  );

  const filterFn = (filter: string, row: SalesTaxQueryData) => {
    return filter.trim().length > 0
      ? row.sales_tax.toLowerCase().includes(filter) ||
          row.sales_tax_code.toLowerCase().includes(filter) ||
          row.state.toLowerCase().includes(filter)
      : row;
  };

  const columns = [
    {
      name: "Name",
      sortable: true,
      selector: (row: SalesTaxQueryData) => row.sales_tax,
    },
    {
      name: "Tax Code",
      sortable: true,
      selector: (row: SalesTaxQueryData) => row.sales_tax_code,
    },
    {
      name: "Tax Percentage",
      sortable: true,
      selector: (row: SalesTaxQueryData) => row.sales_tax_percentage,
    },
    {
      name: "Tax State",
      sortable: true,
      selector: (row: SalesTaxQueryData) => row.state,
    },
    {
      name: "Active",
      sortable: true,
      selector: (row: SalesTaxQueryData) => row.active,
      cell: (row: SalesTaxQueryData) => (
        <>
          {row.active ? (
            <span className="text-success">&#x2714;</span> // Checkmark symbol
          ) : (
            <span className="text-danger">&#x2718;</span> // Cross symbol
          )}
        </>
      ),
    },
    {
      name: "Actions",
      cell: (row: SalesTaxQueryData) => (
        <>
          <button
            className="btn btn-sm btn-link"
            onClick={fireOnEdit.bind(this, row)}
          >
            Edit
          </button>
        </>
      ),
    },
  ];

  return (
    <BasicFilterTable
      tableKey="salestax"
      title="Sales Tax"
      dataSource={taxes}
      columns={columns}
      buttons={buttons}
      filterFn={filterFn}
      exportFileName="Sales Tax.csv"
    />
  );
};

const blankSalesTaxForm = () => {
  return {
    sales_tax: "",
    sales_tax_code: "",
    sales_tax_percentage: 0,
    state: "",
  };
};

const SalesTax = () => {
  const [showSalesTaxModal, setShowSalesTaxModal] = useState(false);

  const [salesTaxInitialValues, setSalesTaxInitialValues] = useState(
    blankSalesTaxForm()
  );
  const [edit, setEdit] = useState(false);
  const taxes = useQuery(salesTaxQueryAll);

  const hndAddNew = () => {
    setSalesTaxInitialValues(blankSalesTaxForm());
    setShowSalesTaxModal(true);
  };

  const hndEdit = () => {
    setEdit(true);
    setShowSalesTaxModal(true);
    setSalesTaxInitialValues({
      ...salesTaxInitialValues,
      sales_tax: "",
      sales_tax_code: "",
      sales_tax_percentage: 0,
      state: "",
    });
  };

  const hndModelClose = () => {
    setEdit(false);
    setShowSalesTaxModal(false);
    taxes.refetch();
  };
  let sortedTaxes = taxes.data;
  if (sortedTaxes) {
    sortedTaxes = [...sortedTaxes].sort((a, b) => b.active - a.active);
  }

  return (
    <Sentry.ErrorBoundary fallback={ErrorFallback}>
     
        <Layout>
          <Loader isLoading={!taxes.isSuccess}>
            <SalesTaxTable
              onAddNew={hndAddNew}
              onEdit={hndEdit}
              taxes={sortedTaxes}
              setSalesTaxInitialValues={setSalesTaxInitialValues}
              setShowSalesTaxModal={setShowSalesTaxModal}
              edit={edit}
              setEdit={setEdit}
            />
          </Loader>
          <ToastContainer />
          <SalesTaxModal
            show={showSalesTaxModal}
            onOk={hndModelClose}
            onCancel={hndModelClose}
            initialValues={salesTaxInitialValues}
            setShowSalesTaxModal={setShowSalesTaxModal}
            setSalesTaxInitialValues={setSalesTaxInitialValues}
            edit={edit}
            setEdit={setEdit}
          />
        </Layout>
      
    </Sentry.ErrorBoundary>
  );
};

export default SalesTax;
