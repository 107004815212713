import * as Yup from "yup";
import { ErrorMessage, Field, Form, Formik, FormikHelpers } from "formik";
import type { LocationMutationData } from "../apis/interfaces/common";
import { JobQueryData } from "../apis/interfaces/common";
import { Modal } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { jobQueryAll } from "../apis/queries/common";

interface LocationFormProps {
  initialValues: LocationMutationData;
  onSubmit: any;
  onCancel: any;
}

const validationSchema = Yup.object({
  location_title: Yup.string().required("Location name is required"),
  location_description: Yup.string().required(
    "Location description is required"
  ),
  address: Yup.string().notRequired(),
  address2: Yup.string().notRequired(),
  city: Yup.string().notRequired(),
  state: Yup.string().notRequired(),
  zip_code: Yup.string().notRequired(),
  country: Yup.string().notRequired(),
  job: Yup.string().notRequired(),
});

const LocationForm = (props: LocationFormProps) => {
  const { initialValues, onSubmit, onCancel } = props;
  const [jobs, setJobs] = useState<JobQueryData[]>([]);

  const { data } = useQuery(jobQueryAll());
  const handleSubmit = (
    values: LocationMutationData,
    { setStatus }: FormikHelpers<LocationMutationData>
  ) => {
    try {
      validationSchema.validateSync(values, { abortEarly: false });
      onSubmit(values);
    } catch (errors) {
      setStatus("An error occurred during form submission.");
    }
  };
  useEffect(() => {
    if (data) {
      setJobs(data);
    }
  }, [data]);
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({
        handleChange,
        handleSubmit,
        isSubmitting,
        isValid,


      }) => (
        <>
          <Form onSubmit={handleSubmit}>
            <Modal.Body>
              <div className="form-group mb-2">
                <label htmlFor="location">Location Name:</label>
                <Field
                  type="text"
                  id="location_title"
                  name="location_title"
                  className="form-control"
                  onChange={handleChange}
                />
                <ErrorMessage
                  name="location_title"
                  component="div"
                  className="error-message text-danger"
                />
              </div>

              <div className="form-group mb-2">
                <label htmlFor="location">Location Description:</label>
                <Field
                  type="text"
                  id="location_description"
                  name="location_description"
                  className="form-control"
                  onChange={handleChange}
                />
                <ErrorMessage
                  name="location_description"
                  component="div"
                  className="error-message text-danger"
                />
              </div>
              <div className="form-group mb-2">
                <label htmlFor="location">Address:</label>
                <Field
                  type="text"
                  id="address"
                  name="address"
                  className="form-control"
                  onChange={handleChange}
                />
                <ErrorMessage
                  name="address"
                  component="div"
                  className="error-message text-danger"
                />
              </div>
              <div className="form-group mb-2">
                <label htmlFor="location">Address2:</label>
                <Field
                  type="text"
                  id="address2"
                  name="address2"
                  className="form-control"
                  onChange={handleChange}
                />
                <ErrorMessage
                  name="address2"
                  component="div"
                  className="error-message text-danger"
                />
              </div>
              <div className="form-group mb-2">
                <label htmlFor="location">City:</label>
                <Field
                  type="text"
                  id="city"
                  name="city"
                  className="form-control"
                  onChange={handleChange}
                />
                <ErrorMessage
                  name="city"
                  component="div"
                  className="error-message text-danger"
                />
              </div>
              <div className="form-group mb-2">
                <label htmlFor="location">State:</label>
                <Field
                  type="text"
                  id="state"
                  name="state"
                  className="form-control"
                  onChange={handleChange}
                />
                <ErrorMessage
                  name="state"
                  component="div"
                  className="error-message text-danger"
                />
              </div>
              <div className="form-group mb-2">
                <label htmlFor="location">Zip Code:</label>
                <Field
                  type="text"
                  id="zip_code"
                  name="zip_code"
                  className="form-control"
                  onChange={handleChange}
                />
                <ErrorMessage
                  name="zip_code"
                  component="div"
                  className="error-message text-danger"
                />
              </div>
              <div className="form-group mb-2">
                <label htmlFor="location">Country:</label>
                <Field
                  type="text"
                  id="country"
                  name="country"
                  className="form-control"
                  onChange={handleChange}
                />
                <ErrorMessage
                  name="country"
                  component="div"
                  className="error-message text-danger"
                />
              </div>
              <div className="form-group mb-2">
                <label htmlFor="job">Job:</label>
                <Field as="select" id="job" name="job" className="form-control">
                  <option value="">Select a job</option>
                  {jobs.map((job) => (
                    <option key={job.id} value={job.id}>
                      {job.job}
                    </option>
                  ))}
                </Field>
                <ErrorMessage
                  name="job"
                  component="div"
                  className="error-message text-danger"
                />
              </div>
              <div className="form-check mb-2">
                <Field
                  type="checkbox"
                  id="active"
                  name="active"
                  className="form-check-input"
                  onChange={handleChange}
                />
                <label className="form-check-label" htmlFor="active">
                  Active
                </label>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="text-right">
                <button
                  onClick={onCancel}
                  className="btn btn-sm btn-danger mx-3"
                  type="button"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-sm btn-success"
                  disabled={!(!isSubmitting && isValid)}
                >
                  Save
                </button>
              </div>
            </Modal.Footer>
          </Form>
        </>
      )}
    </Formik>
  );
};

export default LocationForm;
