import { useState } from "react";
import Layout from "../../components/Layout";

import TicketDashboard from "../../components/tickets/TicketDashboard";
import CreateTicketModal from "../../components/tickets/CreateTicketModal";
import ErrorFallback from "../../components/Error";
import * as Sentry from "@sentry/react";

const TicketIndex = () => {
  const [limit, setLimit] = useState(50);
  const [offset, setOffset] = useState(0);
  const [sortField, setSortField] = useState('');
  const [sortDirection, setSortDirection] = useState('');
  const [showTicketForm, setShowTicketForm] = useState(false);

  const hndChangeLimit = (newLimit: number) => {
    setLimit(newLimit > 0 ? newLimit : 50);
  };

  const hndChangeOffset = (newOffset: number) => {
    setOffset(newOffset > 0 ? newOffset : 0);
  };

  const hndShowTicketModal = () => {
    setShowTicketForm(true);
  };

  const hndCloseTicketModal = () => {
    setShowTicketForm(false);
  };

  const hndSetSort = (field: string, direction: string) => {
      console.log(field, direction);
      setSortField(field);
      setSortDirection(direction);
  };

  return (
    <Layout>
      <Sentry.ErrorBoundary fallback={ErrorFallback}>
       
          <TicketDashboard
            limit={limit}
            offset={offset}
            sortField={sortField}
            sortDirection={sortDirection}
            onSetLimit={hndChangeLimit}
            onSetOffset={hndChangeOffset}
            onSetSort={hndSetSort}
            onCreateTicket={hndShowTicketModal}
          />
          <CreateTicketModal
            show={showTicketForm}
            hndRefresh={hndCloseTicketModal.bind(this)}
          />
        
      </Sentry.ErrorBoundary>
    </Layout>
  );
};

export default TicketIndex;
