import Layout from "../../components/Layout";
import CreateTicketForm from "../../forms/CreateTicketForm";
import { useNavigate, useParams } from "react-router-dom";
import ErrorFallback from "../../components/Error";
import * as Sentry from "@sentry/react";

const CreateTicket = () => {
  const navigate = useNavigate();
  const params = useParams();

  const jobId = Number(params.id);

  const hndTicketSubmitted = () => {
    navigate("/staff-dashboard");
  };

  return (
    <Layout>
      <div className="row">
        <div className="col-12">
          <h1 className="dashboard m-0 p-2">Create Ticket</h1>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <Sentry.ErrorBoundary fallback={ErrorFallback}>
           
              <CreateTicketForm
                hndTicketSubmit={hndTicketSubmitted}
                jobId={jobId}
              />
            
          </Sentry.ErrorBoundary>
        </div>
      </div>
    </Layout>
  );
};

export default CreateTicket;
