import { profileQuery } from "../apis/queries/authentication";
import { useQuery } from "@tanstack/react-query";

const AppHeader = () => {
  const profile = useQuery<any>(profileQuery);

  const formatDisplay = (data: any) => {
    return (
      <>
        ,{" "}
        {data.first_name && data.last_name
          ? `${data.first_name} ${data.last_name}`
          : data.email}
      </>
    );
  };

  return (
    <>
      <div className="d-sm-block d-xs-block d-none">
        <div className="container-fluid d-flex bg-ycc-dark siteheader">
          <div className="flex-fill">
            <a
              href={
                profile.data?.data && profile.data.data.is_superuser
                  ? "/"
                  : "/staff-dashboard"
              }
            >
              <img
                src="/yonkers-logo022.png"
                alt="Yonkers Contracing Company"
                className="ycc-logo"
              />
            </a>
          </div>
          <div className="p-2 align-self-center">
            Welcome
            {profile.status === "loading" || profile.status === "error" ? (
              <>. Please log in.</>
            ) : (
              formatDisplay(profile.data.data)
            )}
          </div>
        </div>
      </div>
      <div className="d-block d-sm-none d-xs-none">
        <div className="container-fluid bg-ycc-dark siteheader">
          <div className="flex-fill">
            <a
              href={
                profile.data?.data && profile.data.data.is_superuser
                  ? "/"
                  : "/staff-dashboard"
              }
            >
              <img
                src="/yonkers-logo022.png"
                alt="Yonkers Contracing Company"
                className="ycc-logo"
              />
            </a>
          </div>
          <div className="p-2 align-self-center flex-fill text-center">
            Welcome
            {profile.status === "loading" || profile.status === "error" ? (
              <>. Please log in.</>
            ) : (
              formatDisplay(profile.data.data)
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AppHeader;
