import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Link, useParams } from "react-router-dom";

import type { performResetPasswordMutationData } from "../apis/mutations/authentication";
import { performResetPasswordMutation } from "../apis/mutations/authentication";
import { verifyPasswordResetQuery } from "../apis/queries/authentication";

function ConfirmResetPassword() {
  let { codeone: userHash, codetwo: resetToken } = useParams();

  console.log(userHash);

  const verifyReset = useQuery(verifyPasswordResetQuery(userHash, resetToken));
  const performResetMutation = useMutation(performResetPasswordMutation);

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required."),
    new_password: Yup.string()
      .min(8, "Password must be at least 8 characters long")
      .required("Password is required"),
    token: Yup.string().required("Reset token is required."),
  });

  const hndSubmit = async (values: performResetPasswordMutationData) => {
    await performResetMutation.mutateAsync(values);

    return performResetMutation.status === "error";


  };

  if (verifyReset.status === "loading") {
    return (
      <div className="row mt-5">
        <div className="col-md-6 offset-md-3 col-sm-10 offset-sm-1">
          <div className="login-form border border-warning rounded p-4">
            Loading, please wait...
          </div>
        </div>
      </div>
    );
  }

  if (
    performResetMutation.status === "success" &&
    performResetMutation.data.data.success
  ) {
    return (
      <div className="row mt-5">
        <div className="col-md-6 offset-md-3 col-sm-10 offset-sm-1">
          <div className="login-form border border-warning rounded p-4">
            <div className="alert alert-success">
              <strong>Success!</strong> Your password has been changed
              successfully.<Link to="/login">Click here to log in.</Link>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="row mt-5">
      <div className="col-md-6 offset-md-3 col-sm-10 offset-sm-1">
        <Formik
          initialValues={{
            email: "",
            new_password: "",
            token: resetToken || "",
          }}
          validationSchema={validationSchema}
          onSubmit={hndSubmit}
        >
          {({
            isSubmitting,
            values,
            handleSubmit,
            handleChange,
          }) => (
            <Form
              name="signupForm"
              id="signupForm"
              className="login-form border border-warning rounded p-4"
              onSubmit={handleSubmit}
            >
              <p>Enter your new password here.</p>

              {performResetMutation.status === "error" ||
              (performResetMutation.status === "success" &&
                !performResetMutation.data.data.success) ? (
                <div className="alert alert-danger">
                  Something went wrong. Please double-check your new password
                  and try again, or{" "}
                  <Link to="/reset">click here to try again.</Link>
                </div>
              ) : null}

              <div className="form-group mb-4">
                <label htmlFor="email">Email:</label>
                <Field
                  type="email"
                  id="email"
                  name="email"
                  value={values.email}
                  className="form-control"
                  autoComplete="email"
                />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="error-message text-danger"
                />
              </div>

              <div className="form-group mb-4">
                <label htmlFor="new_password">New Password:</label>
                <Field
                  type="password"
                  id="password"
                  name="new_password"
                  value={values.new_password}
                  className="form-control"
                  onChange={handleChange}
                />
                <ErrorMessage
                  name="password"
                  component="div"
                  className="error-message text-danger"
                />
              </div>

              <div className="form-group mb-4">
                <label htmlFor="token">Reset Token:</label>
                <Field
                  type="text"
                  id="token"
                  name="token"
                  value={values.token}
                  className="form-control"
                  disabled
                />
                <ErrorMessage
                  name="token"
                  component="div"
                  className="error-message text-danger"
                />
              </div>

              <button
                type="submit"
                id="submitButton"
                className="btn btn-primary"
                disabled={isSubmitting}
              >
                <span>Reset Password</span>
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default ConfirmResetPassword;
