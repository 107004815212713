import { useMutation, useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import type { ITreeViewOnNodeSelectProps } from "react-accessible-treeview";
import TreeView, { flattenTree } from "react-accessible-treeview";

import {
  stockCategoryQueryAll,
  stockMetaQueryAll,
  stockSimilarQuery,
  unitOfMeasureQueryAll,
  stockFavoritesQuery,
} from "../apis/queries/invitems";
import { jobQueryAll, costCodeQueryAll } from "../apis/queries/common";
import { submitTicketMutation } from "../apis/mutations/tickets";

import type {
  StockCategoryQueryData,
  StockMetaData,
  UnitOfMeasureQueryData,
  StockFavoritesData,
} from "../apis/interfaces/invitems";
import type {
  JobQueryData,
  CostCodeQueryData,
} from "../apis/interfaces/common";
import type { SelectedItem, TicketSubmit } from "../apis/interfaces/tickets";
import Loader from "../components/Loader";
import { profileQuery } from "../apis/queries/authentication";
import * as Sentry from "@sentry/react";
import { toast } from "react-toastify";
import { debounce } from "lodash";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import CostCodeSelector from "../components/CostCodeSelector";
import { calculateSelectedValue as alertCalculateSelectedValue } from "./AddItemToTicketForm";

interface FlatTreeNode {
  name: string;
  children: Array<FlatTreeNode>;
  metadata: string;
}

interface AddItemFormProps {
  categories: Array<StockCategoryQueryData>;
  stockItems: Array<StockMetaData>;
  stockItemFavorites?: Array<StockFavoritesData>;
  onItemSelected: Function;
}

interface TicketItemListTableProps {
  selectedInvItems: Array<SelectedItem>;
  hndRemoveItem: any;
  categories: Array<StockCategoryQueryData>;
  uoms: Array<UnitOfMeasureQueryData>;
  selectedJob: number | string;
}

interface CreateTicketFormProps {
  hndTicketSubmit: Function;
  jobId?: number;
}

const calculateSelectedValue = (selectedInvItems: Array<SelectedItem>) => {
  const consumableValue = selectedInvItems
    .reduce((accumulator: number, curValue: SelectedItem) => {
      return accumulator + (curValue.item.stock_type === 'consumable' ? curValue.item.unit_price * curValue.qty : 0)
    }, 0)
    .toLocaleString("en-US", { currency: "USD", style: "currency" }) || null;

  const smallToolValue = selectedInvItems
  .reduce((accumulator: number, curValue: SelectedItem) => {
    return accumulator + (curValue.item.stock_type === 'small-tool' ? curValue.item.rental_rate * 8 * curValue.qty : 0)
  }, 0)
  .toLocaleString("en-US", { currency: "USD", style: "currency" }) || null;

  return <>
    {consumableValue ? consumableValue : null}
    {smallToolValue ? <><br />{smallToolValue}/day rental</> : null}
  </>;
};

const AddItemForm = (props: AddItemFormProps) => {
  const { categories, stockItems, stockItemFavorites, onItemSelected } = props;
  const [searchTerm, setSearchTerm] = useState<string>("");

  const treeViewItems: FlatTreeNode = { name: "", children: [], metadata: "" };

  const getCategory = (category_id: number) => {
    return categories.find((elem) => elem.id === category_id);
  };

  const findChildStockItems = (category_id: number) => {
    const childStock: Array<FlatTreeNode> = [];

    stockItems.forEach((stock: StockMetaData) => {
      if (stock.stock_category_id === category_id) {
        const category = getCategory(stock.stock_category_id);
        const regex = new RegExp(searchTerm, "i");
        if (
          (searchTerm.length > 0 && category && regex.test(stock.stock)) ||
          searchTerm.length === 0
        ) {
          childStock.push({
            name: `${stock.stock} CAT ${stock.stock_category}`,
            children: [],
            metadata: "",
          });
        }
      }
    });

    return childStock;
  };

  const findChildCategoryItems = (parent_category: number) => {
    const childrens: Array<FlatTreeNode> = [];

    categories.forEach((elem: StockCategoryQueryData) => {
      if (
        !elem.parent_category ||
        elem.parent_category.id !== parent_category
      ) {
        return;
      }

      const childrenCats: Array<FlatTreeNode> = elem.id
        ? findChildCategoryItems(elem.id)
        : [];
      const childStock = elem.id ? findChildStockItems(elem.id) : [];

      if (childStock.length > 0) {
        childrens.push({
          name: `C: ${elem.stock_category}`,
          children: childrenCats.concat(childStock),
          metadata: "",
        });
      }
    });

    return childrens;
  };

  if (searchTerm.length === 0 && stockItemFavorites && stockItemFavorites.length > 0) {
    const favoritesCat = {
      name: 'C: Favorites',
      children: stockItemFavorites.map((item: StockFavoritesData) => { return {
        name: `${item.stock} CAT ${item.stock_category_name}`,
        children: [],
        metadata: "",
      } }),
      metadata: ""
    };
    treeViewItems.children.push(favoritesCat);
  }


  categories.forEach((elem: StockCategoryQueryData) => {
    if (elem.parent_category) {
      return;
    }

    const childrenCats: Array<FlatTreeNode> = elem.id
      ? findChildCategoryItems(elem.id)
      : [];
    const childStock = elem.id ? findChildStockItems(elem.id) : [];

    if (childrenCats.length > 0 || childStock.length > 0) {
      treeViewItems.children.push({
        name: `C: ${elem.stock_category}`,
        children: childrenCats.concat(childStock),
        metadata: "",
      });
    }
  });

  let formattedTree = flattenTree(treeViewItems);

  const filteredTree = formattedTree.map((node) => {
    const updatedNode = { ...node };
    if (
      true &&
      updatedNode.name.includes("C:")
    ) {
      updatedNode.name = updatedNode.name.replace(/C:/g, "");
    }

    return updatedNode;
  });

  const hndTreeSelection = (props: ITreeViewOnNodeSelectProps) => {
    const { element } = props;

    if (element.children.length === 0) {
      // we've got ourselves an actual selectable item, so find it now
      const item = stockItems.find(
        (stockItem: StockMetaData) => `${stockItem.stock} CAT ${stockItem.stock_category}` === element.name
      );

      if (item) {
        onItemSelected(item);
      }
    }
  };

  return (
    <>
      <input
        type="search"
        placeholder="Find Item..."
        className="form-control mb-1"
        onChange={(ev) => setSearchTerm(ev.target.value)}
      />

      <div className="tool-tree-container">
        <TreeView
          data={filteredTree}
          aria-label="Tool List"
          togglableSelect
          clickAction="EXCLUSIVE_SELECT"
          className="tool-tree"
          onNodeSelect={hndTreeSelection}
          nodeRenderer={({
            element,
            getNodeProps,
            isBranch,
            isExpanded,
            isSelected,

          }) => (
            <div {...getNodeProps()}>
              {isBranch && isExpanded ? <span>&#x1f4c2;</span> : null}
              {isBranch && !isExpanded ? <span>&#x1f4c1;</span> : null}
              <span className={isSelected ? "fw-bold" : ""}>
                {element.name.replace(/ CAT .*/, '')}
              </span>
            </div>
          )}
        />
      </div>
    </>
  );
};

const TicketItemListTable = (props: TicketItemListTableProps) => {
  const { selectedInvItems, hndRemoveItem, categories, uoms, selectedJob } =
    props;

  const costcodes = useQuery(
    costCodeQueryAll(
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      selectedJob
    )
  );

  const getCategory = (category: number) =>
    categories.find((elem) => elem.id === category);
  const getUofM = (measure: number) => uoms.find((elem) => elem.id === measure);
  const getCostCode = (code: number) =>
    costcodes && costcodes.data
      ? costcodes.data.find((elem: CostCodeQueryData) => elem.id === code)
      : null;

  return (
    <table className="table table-sm table-striped">
      <thead>
        <tr>
          <td>Item Name/Type</td>
          <td></td>
          <td>Qty Requested</td>
          <td>Remove</td>
          <td></td>
        </tr>
      </thead>
      <tbody>
        {selectedInvItems.map((item: SelectedItem, idx) => (
          <tr key={`selected-item-${idx}`}>
            <td>
              {item.item.stock}
              <br />
              {item.item.stock_type === "small-tool" &&
                `Serial: ${item.item.ycc_serial}`}
              <span className="fs-6">
                {getCategory(item.item.stock_category_id)?.stock_category}
              </span>
              {item.costcode && (
                <>
                  <br />
                  <span className="fs-6">
                    Cost Code {getCostCode(item.costcode)?.cost_code}{" "}
                    {getCostCode(item.costcode)?.cost_type}
                  </span>
                </>
              )}
            </td>
            <td>{item.qty.toLocaleString("en-US")}</td>
            {item.item.stock_type === "consumable" && (
              <td>
                $
                {item.item.unit_price.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })}
                /{getUofM(item.item.unit_of_measure_id)?.unit_of_measure}
                <br />
                {(item.item.unit_price * item.qty).toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })}{" "}
                total
              </td>
            )}
            {item.item.stock_type === "small-tool" && (
              <td>
                
                {(item.item.rental_rate * 8).toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })}
                /{"day"}
                <br />
              </td>
            )}
            <td>
              <button
                className="btn btn-link btn-sm"
                onClick={hndRemoveItem}
                data-item-id={item.item.id}
              >
                &#x274c;
              </button>
            </td>
          </tr>
        ))}
      </tbody>
      <tfoot>
        <tr>
          <td colSpan={2} className="text-end">
            Total(s):
          </td>
          <td colSpan={2}>{calculateSelectedValue(selectedInvItems)}</td>
        </tr>
      </tfoot>
    </table>
  );
};

const CreateTicketForm = (props: CreateTicketFormProps) => {
  const { hndTicketSubmit, jobId } = props;

  const jobs = useQuery(jobQueryAll());
  const categories = useQuery(stockCategoryQueryAll());
  const stockItemsData = useQuery(stockMetaQueryAll());
  const stockFavoritesData = useQuery(stockFavoritesQuery());
  const unitsOfMeasureData = useQuery(unitOfMeasureQueryAll());
  const user = useQuery(profileQuery);
  const ticketSubmit = useMutation(submitTicketMutation);
  const [nonSI, setNonSI] = useState(false);
  const [selectedInvItem, setSelectedInvItem] = useState<StockMetaData | null>(
    null
  );
  const [selectedInvItems, setSelectedInvItems] = useState<Array<SelectedItem>>(
    []
  );
  const [requestNotes, setRequestNotes] = useState<string>("");
  const [costcode, setCostcode] = useState<number>(0);

  const [qtyRequested, setQtyRequested] = useState<number>(0);
  const [selectedJob, setSelectedJob] = useState<JobQueryData | null>(null);
  const [stockItems, setStockItems] = useState<StockMetaData[]>([]);
  const handleChange = debounce((value) => {
    setRequestNotes(value);
  }, 300);

  const hndItemSelected = (item: StockMetaData | null) => {
    setSelectedInvItem(item);

    if (item?.stock_type === "small-tool") {
      setQtyRequested(1);
    }
  };

  const hndClearItem = () => {
    setQtyRequested(0);
    setRequestNotes("");
    setSelectedInvItem(null);
    setCostcode(0);
  };

  const mutation = useMutation(
    (id: number) => stockSimilarQuery(id).queryFn(),
    {
      onSuccess: (data) => {
        if (data?.length > 0) {
          // Find the first item not already in selectedInvItems
          const firstUniqueItem = data.find(
            (item: StockMetaData) =>
              !selectedInvItems.some(
                (selected) => selected.item.ycc_serial === item.ycc_serial
              )
          );

          if (firstUniqueItem) {
            setSelectedInvItems((prevItems) => [
              ...prevItems,
              { item: firstUniqueItem, qty: qtyRequested, notes: requestNotes },
            ]);
            hndClearItem();
          } else {
            let temp = selectedInvItem;
            toast.error(`${temp?.stock} is currently in use.`, {
              autoClose: 5000, // This toast will close after 5 seconds
            });
          }
        } else {
          toast.error(
            `${selectedInvItem?.stock} is currently out of stock or at another job.`
          );
        }
      },
      onError: (error) => {
        console.error("Failed to fetch in stock items:", error);
        Sentry.captureException(error);
      },
    }
  );

  const hndAddItem = () => {
    if (selectedInvItem && qtyRequested > 0) {
      if (selectedInvItem.stock_type === "small-tool") {
        mutation.mutate(selectedInvItem.id);
      } else {
        setSelectedInvItems([
          ...selectedInvItems,
          {
            item: selectedInvItem,
            qty: qtyRequested,
            notes: requestNotes,
            costcode: costcode,
          },
        ]);
        hndClearItem();
      }
    }
  };

  const hndRemoveItem = (ev: any) => {
    const id = parseInt(
      ev.target.attributes.getNamedItem("data-item-id").value
    );
    const idx = selectedInvItems.findIndex(
      (elem: SelectedItem) => elem.item.id === id
    );
    const editableInvItems = selectedInvItems;

    editableInvItems.splice(idx, 1);

    setSelectedInvItems([...editableInvItems]);
  };

  const hndSendTicket = () => {
    if (selectedJob === null) {
      return false;
    }

    const ticketValue = alertCalculateSelectedValue(selectedInvItems);

    if (
      window.confirm(
        `Are you sure you wish to submit this ticket with items totalling ${ticketValue}?`
      ) &&
      selectedJob.id
    ) {
      const ticketPayload: TicketSubmit = {
        job: selectedJob.id,
        items: selectedInvItems,
      };

      try {
        ticketSubmit.mutate(ticketPayload);
      } catch (err) {
        Sentry.captureException(err);
      }
    }
  };

  if (ticketSubmit.isSuccess) {
    window.setTimeout(() => {
      hndTicketSubmit();
    }, 250);
  }

  useEffect(() => {
    if (jobId !== undefined && jobs.data) {
      const job = jobs.data.find(
        (jobItem: JobQueryData) => jobItem.id === Number(jobId)
      );
      setSelectedJob(job);
    }
    if (!stockItemsData.isLoading && stockItemsData.data) {
      const sortedStockItems = [...stockItemsData.data].sort((a, b) =>
        a.stock.localeCompare(b.stock)
      );
      setStockItems(sortedStockItems);
    }
  }, [jobId, jobs.isLoading, stockItemsData.data, stockItemsData.isLoading]);

  const options =
    jobs.data
      ?.filter((jobElem: JobQueryData) => jobElem.active)
      .map((jobElem: JobQueryData) => ({
        value: jobElem.id,
        label: `${jobElem.job_number} - ${jobElem.job}`,
      })) ?? 0;

  const hndSelectJob = (
    selectedOption: { value: number | undefined; label: string } | null
  ) => {
    const job = selectedOption
      ? jobs.data
          .filter((job: JobQueryData) => job.active)
          .find((jobItem: JobQueryData) => jobItem.id === selectedOption.value)
      : null;
    setSelectedJob(job);
  };
  const defaultValue = selectedJob
    ? {
        value: selectedJob.id,
        label: `${selectedJob.job_number} - ${selectedJob.job}`,
      }
    : null;
  function customFilter(option: any, rawInput: any) {
    const searchTerm = rawInput.toLowerCase();
    return option.label.toLowerCase().includes(searchTerm);
  }

  return (
    <>
      <Loader
        isLoading={
          !(
            jobs.data &&
            categories.data &&
            stockItemsData.data &&
            stockItemsData.isSuccess &&
            stockItems.length > 0
          )
        }
      >
        {ticketSubmit.isSuccess ? (
          <div className="row">
            <div className="col-md-12">
              <div className="alert alert-success text-center">
                <strong>Success!</strong> Your ticket was saved successfully.
              </div>
            </div>
          </div>
        ) : null}
        {ticketSubmit.isError ? (
          <div className="row">
            <div className="col-md-12">
              <div className="alert alert-danger text-center">
                <strong>Error!</strong> Something went wrong submitting your
                ticket.
              </div>
            </div>
          </div>
        ) : null}
        <div className="row">
          <div className="col-md-6 col-sm-12">
            {!user.data?.data.is_superuser && (
              <div className="form-group mb-2">
                <label htmlFor="job">Job: {selectedJob?.job}</label>
              </div>
            )}
            {user.data?.data.is_superuser && (
              <div className="form-group mb-2">
                <label htmlFor="job">Select Job:</label>
                <Select
                  name="job"
                  className="form-control"
                  onChange={hndSelectJob}
                  options={options}
                  isClearable
                  defaultValue={defaultValue}
                  filterOption={customFilter}
                />
              </div>
            )}

            <p>Select Item:</p>
            {!ticketSubmit.isLoading &&
              stockItemsData.data &&
              !stockItemsData.isLoading &&
              stockItemsData.isSuccess &&
              stockItems.length > 0 &&
              stockFavoritesData.data &&
              !stockFavoritesData.isLoading && (
                <AddItemForm
                  categories={categories.data}
                  stockItems={stockItems}
                  stockItemFavorites={stockFavoritesData.data}
                  onItemSelected={hndItemSelected.bind(this)}
                />
              )}

            <div className="form-group mb-2">
              <label>Selected Item:</label>
              <input
                type="text"
                disabled
                className="form-control"
                value={selectedInvItem ? selectedInvItem.stock : ""}
              />
            </div>

            {selectedInvItem?.stock_type === "small-tool" && (
              <div className="form-group mb-2">
                <label>Quantity Required:</label>
                <input
                  inputMode="numeric"
                  pattern="[0-9]*"
                  type="text"
                  className="form-control"
                  onChange={() => setQtyRequested(1)}
                  value={1}
                  defaultValue={1}
                  disabled
                />
              </div>
            )}
            {selectedInvItem?.stock_type === "consumable" && (
              <div className="form-group mb-2">
                <label>Quantity Required:</label>
                <input
                  inputMode="numeric"
                  pattern="[0-9]*"
                  type="text"
                  className="form-control"
                  onChange={(e) => setQtyRequested(Number(e.target.value))}
                  value={qtyRequested ?? ""}
                />
              </div>
            )}
            <label>
              Unit:{" "}
              {
                unitsOfMeasureData?.data?.find(
                  (item: UnitOfMeasureQueryData) =>
                    selectedInvItem?.unit_of_measure_id === item.id
                )?.unit_of_measure
              }
            </label>
            <br />

            <label>Case Quantity: {selectedInvItem?.case_quantity}</label>
            {selectedInvItem && (
              <div className="form-group mb-2">
                <label>Notes:</label>
                <input
                  name="notes"
                  type="text"
                  className="form-control"
                  onChange={(e) => handleChange(e.target.value.toString())}
                />
              </div>
            )}
            {selectedInvItem && (
              <div className="form-group mb-2">
                <label>Cost Code:</label>
                <CostCodeSelector
                  selectedJob={selectedJob?.id}
                  onChange={(newval: number) => setCostcode(newval)}
                  defaultValue={undefined}
                />
              </div>
            )}

            {Number(selectedInvItem?.quantity_on_hand) <
              Number(qtyRequested) && (
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="25"
                  height="25"
                  viewBox="0 0 48 48"
                >
                  <path
                    fill="#F44336"
                    d="M21.2,44.8l-18-18c-1.6-1.6-1.6-4.1,0-5.7l18-18c1.6-1.6,4.1-1.6,5.7,0l18,18c1.6,1.6,1.6,4.1,0,5.7l-18,18C25.3,46.4,22.7,46.4,21.2,44.8z"
                  ></path>
                  <path
                    fill="#FFF"
                    d="M21.6,32.7c0-0.3,0.1-0.6,0.2-0.9c0.1-0.3,0.3-0.5,0.5-0.7c0.2-0.2,0.5-0.4,0.8-0.5s0.6-0.2,1-0.2s0.7,0.1,1,0.2c0.3,0.1,0.6,0.3,0.8,0.5c0.2,0.2,0.4,0.4,0.5,0.7c0.1,0.3,0.2,0.6,0.2,0.9s-0.1,0.6-0.2,0.9s-0.3,0.5-0.5,0.7c-0.2,0.2-0.5,0.4-0.8,0.5c-0.3,0.1-0.6,0.2-1,0.2s-0.7-0.1-1-0.2s-0.5-0.3-0.8-0.5c-0.2-0.2-0.4-0.4-0.5-0.7S21.6,33.1,21.6,32.7z M25.8,28.1h-3.6L21.7,13h4.6L25.8,28.1z"
                  ></path>
                </svg>
                Lead time may be increased on this item.
              </div>
            )}
            <div className="form-group mb-2 d-flex justify-content-end">
              <button
                className="btn btn-sm btn-outline-secondary me-2"
                onClick={hndClearItem}
              >
                Clear Selection
              </button>

              <button
                className="btn btn-sm btn-primary"
                onClick={hndAddItem}
                disabled={!(selectedInvItem && qtyRequested > 0)}
              >
                Add to Order
              </button>
            </div>
          </div>
          <div className="col-md-6 col-sm-12">
            <p>Items On Order:</p>

            {selectedJob && selectedJob.id ? (
              <TicketItemListTable
                hndRemoveItem={hndRemoveItem.bind(this)}
                selectedInvItems={selectedInvItems}
                categories={categories.data ? categories.data : []}
                uoms={unitsOfMeasureData.data ? unitsOfMeasureData.data : []}
                selectedJob={selectedJob.id}
              />
            ) : (
              <p>Select a job first.</p>
            )}

            <div className="d-flex justify-content-center">
              <button
                className="btn btn-success"
                onClick={hndSendTicket}
                disabled={
                  ticketSubmit.isLoading ||
                  selectedJob === null ||
                  (!nonSI && selectedInvItems.length === 0)
                }
              >
                {ticketSubmit.isLoading ? <>Saving...</> : <>Save Ticket</>}
              </button>
            </div>
            <div className="d-flex justify-content-center mb-2 mt-4">
              <div>
                <input
                  className="mr-4"
                  style={{ marginRight: "10px" }}
                  type="checkbox"
                  onChange={(e) => setNonSI(e.target.checked)}
                />
              </div>
              <label className="ml-4">
                This ticket is a non-stock item ticket. No items are needed.
              </label>
            </div>
          </div>
        </div>
      </Loader>
    </>
  );
};

export default CreateTicketForm;
export { AddItemForm, TicketItemListTable };
