import React, { useEffect, useState } from "react";
import { apiClient } from "../../apis/client";
import Layout from "../../components/Layout";
import "../../styles/reports.css";
import "../../styles/print.css";

import { useJobQueryAll } from "../Tickets/Logic/MutationsAndQueries";
import {
  CompanyQueryData,
  DepartmentQueryData,
  JobQueryData,
} from "../../apis/interfaces/common";
import { companyQueryAll, departmentQueryAll } from "../../apis/queries/common";
import { useQuery } from "@tanstack/react-query";
import DataTable from "react-data-table-component";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";

const { DateTime } = require("luxon");

interface StockIssueDetail {
  id: number;
  quantity: number;
  stock: string;
  stock_type: string;
  unit_price: number;
  sales_tax_percentage: number;
  item_amt: number;
  total_amt: number;
  tax_amt: number;
  freight_charge: number;
  date_delivered: Date;
  cost_code: string;
}

interface StockCharge {
  id: number;
  charge_amount: number;
  charge_tax: number;
  charge_date: Date;
  nontaxable: boolean;
  comments: string;
  stock_issue_id: number;
}

interface StockIssue {
  id: number;
  stock_issue_details: StockIssueDetail[];
  stock_charges: StockCharge[];
}

interface Data {
  [key: string]: {
    company_id: number;
    company_code: string;
    company_name: string;
    jobs: {
      [key: string]: {
        job_id: number;
        job_number: string;
        job_name: string;
        stock_issues: StockIssue[];
        stock_charges: StockCharge[];
      };
    };
  };
}

function MonthlyIssuesAndCreditsDetail() {
  const [data, setData] = useState<Data>({});
  const [jobs, setJobs] = useState<JobQueryData[]>([]);
  const [companies, setCompanies] = useState<CompanyQueryData[]>([]);
  const [depts, setDepts] = useState<DepartmentQueryData[]>([]);
  const [job, setJob] = useState(0);
  const [company, setCompany] = useState(0);
  const [dept, setDept] = useState(0);


  const [monthEnding, setMonthEnding] = useState<Date>(DateTime.now());
  const jobQuery = useJobQueryAll();
  const companyQuery = useQuery(companyQueryAll());
  const departmentQuery = useQuery(departmentQueryAll());
  const [companyChargeTotals, setCompanyChargeTotals] = useState<Record<string, number>>({});
  const [companyIssueTotals, setCompanyIssueTotals] = useState<Record<string, number>>({});
  const [companyTaxTotals, setCompanyTaxTotals] = useState<Record<string, number>>({});
  useEffect(() => {
    if (!jobQuery.isLoading) {
      setJobs(jobQuery.data);
    }
    if (!companyQuery.isLoading) {
      setCompanies(companyQuery.data);
    }
    if (!departmentQuery.isLoading) {
      setDepts(departmentQuery.data);
    }
  }, [
    companyQuery.data,
    companyQuery.isLoading,
    departmentQuery.data,
    departmentQuery.isLoading,
    jobQuery.data,
    jobQuery.isLoading,
  ]);
  useEffect(() => {
    if (data) {
        const typedData = data as Data;

        const tempIssueTotals: Record<string, number> = {};
        const tempChargeTotals: Record<string, number> = {};
        const tempTaxTotals: Record<string, number> = {};

        for (let company of Object.values(typedData)) {
            let companyIssueTotal = 0;
            let companyChargeTotal = 0;
            let companyTaxTotal = 0;

            for (let job of Object.values(company.jobs)) {
                companyIssueTotal += job.stock_issues.reduce((total, issue) => {
                    return total + issue.stock_issue_details.reduce((total, detail) => {
                        return total + Number(detail.total_amt ?? 0);
                    }, 0);
                }, 0);

                companyChargeTotal += job.stock_charges.reduce((total, charge) => {
                    return total + (Number(charge.charge_amount) || 0);
                }, 0);

                companyTaxTotal += job.stock_issues.reduce((total, issue) => {
                  return total + issue.stock_issue_details.reduce((total, detail) => {
                      return total + Number(detail.tax_amt ?? 0);
                  }, 0);
                }, 0);

                companyTaxTotal += job.stock_charges.reduce((total, charge) => {
                    return total + (Number(charge.charge_tax) || 0);
                }, 0);
            }

            console.log(`totals for ${company.company_name}: ${companyIssueTotal}, ${companyChargeTotal}`);

            tempIssueTotals[company.company_name] = companyIssueTotal;
            tempChargeTotals[company.company_name] = companyChargeTotal;
            tempTaxTotals[company.company_name] = companyTaxTotal;
        }

        // 3. Set the state after calculating the values
        setCompanyIssueTotals(tempIssueTotals);
        setCompanyChargeTotals(tempChargeTotals);
        setCompanyTaxTotals(tempTaxTotals);
    }
}, [data]);


  const handleSubmit = async (event: React.FormEvent) => {
    toast.info("Generating report");
    event.preventDefault();

    const data = {
      job: job,
      company: company,
      department: dept,
      month_ending: monthEnding,
    };
    try {
      const response = await apiClient.post(
        "/tickets/reports/MonthlyChargesDetail/filter/",
        data
      );
      setData(response.data);
      toast.success("Report loaded.");
    } catch (error) {
      console.error("Error submitting report:", error);
    }
  };

  const handleExport = async (event: React.FormEvent) => {
    event.preventDefault();
    toast.info("Generating report");
    const data = {
      job: job,
      company: company,
      department: dept,
      month_ending: monthEnding,
    };
    try {
      const response = await apiClient.post(
        "/tickets/reports/MonthlyChargesDetail/export_excel/",
        data,
        { responseType: "blob" }
      );
      const blob = await response.data;
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = `MonthlyChargesDetail - ${monthEnding.toLocaleString()}.xlsx`;
      link.click();
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error submitting report:", error);
    }
  };

  const stockIssueColumns = [
    { name: "ID", selector: (row: StockIssueDetail) => row.id },
    {name:"Delivered", selector:(row: StockIssueDetail) => new Date(row.date_delivered).toLocaleDateString()},
    { name: "Stock", selector: (row: StockIssueDetail) => row.stock },

    {
      name: "Cost Code",
      selector: (row: StockIssueDetail) => row.cost_code,
      sortable: true,
    },

    {
      name: "Quantity",
      selector: (row: StockIssueDetail) => Number(row.quantity).toFixed(2),
    },

    {
      name: "Item Amount",
      selector: (row: StockIssueDetail) =>
        "$" +
        Number(row.item_amt).toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
    },
    {
      name: "Nontaxable Amount",
      selector: (row: StockIssueDetail) =>
        "$" +
        Number(row.freight_charge ?? 0).toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
    },
    {
      name: "Tax Amount",
      selector: (row: StockIssueDetail) =>
        "$" +
        Number(row.tax_amt ?? 0).toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
    },

    {
      name: "Total Amount",
      selector: (row: StockIssueDetail) =>
        "$" +
        Number(row.total_amt ?? 0).toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
    },
  ];
  const stockChargeColumns = [
    { name: "ID", selector: (row: StockCharge) => row.id },
    { name: "StockIssue ID", selector: (row: StockCharge) => row.stock_issue_id },

    {name:"Charge Date", selector:(row: StockCharge) => new Date(row.charge_date).toLocaleDateString()},

    { name: "Charge Details", selector: (row: StockCharge) => row.comments },

    {
      name: "Charge Amount",
      selector: (row: StockCharge) =>
        Number(
          Number(row.charge_amount ?? 0) -
            Number(row.charge_tax ?? 0) -
            Number(row.nontaxable ?? 0)
        ).toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
    },
    {
      name: "Non-Taxable",
      selector: (row: StockCharge) => row.nontaxable ?? 0,
    },

    {
      name: "Charge Tax",
      selector: (row: StockCharge) =>
        Number(row.charge_tax ?? 0).toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
    },
    {
      name: "Charge Total",
      selector: (row: StockCharge) =>
        Number(Number(row.charge_amount ?? 0)).toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
    },
  ];
  const options =
    jobs
      ?.filter((jobElem: JobQueryData) => jobElem.active)
      .map((jobElem: JobQueryData) => ({
        value: jobElem.id,
        label: `${jobElem.job_number} - ${jobElem.job}`,
      })) ?? 0;

  const hndSelectJob = (
    selectedOption: { value: number | undefined; label: string } | null
  ) => {
    const job = selectedOption
      ? jobs.find(
          (jobItem: JobQueryData) => jobItem.id === selectedOption.value
        )
      : null;
    setJob(job?.id ?? 0);
  };

  return (
    <div>
      <Layout>
        <div id="report-title-container" className="hide-printable">
          <ToastContainer />

          <h2>Monthly Issues And Credit Detail Report</h2>
          <br></br>
          <h4>REPORT PARAMETERS</h4>
          <form onSubmit={handleSubmit}>
            <label>Job:</label> <br />
            <Select
              name="job"
              className="form-control"
              onChange={hndSelectJob}
              options={options}
              isClearable
            />
            <label>Company:</label> <br />
            <select
              name="company"
              className="form-control"
              onChange={(e) => setCompany(Number(e.target.value))}
            >
              <option value="">All Companies</option>
              {companies.filter(Boolean).map((item: CompanyQueryData) => (
                <option key={item.id} value={item.id}>
                  {item.company_code} - {item.company}
                </option>
              ))}
            </select>
            <label>Department:</label> <br />
            <select
              name="dept"
              className="form-control"
              onChange={(e) => setDept(Number(e.target.value))}
            >
              <option value="">All Departments</option>
              {depts.filter(Boolean).map((item: DepartmentQueryData) => (
                <option key={item.id} value={item.id}>
                  {item.department_number} - {item.department}
                </option>
              ))}
            </select>
            <label>Month Ending:</label> <br />
            <input
              name="month_ending"
              type="date"
              className="form-control"
              defaultValue={DateTime.local().endOf("month").toISODate()}
              onChange={(e) => setMonthEnding(new Date(e.target.value))}
            ></input>
            <button
              type="submit"
              className="btn btn-sm btn-success mt-2 button-margin"
            >
              Generate Filtered Report
            </button>
            <button
              type="submit"
              className="btn btn-sm btn-success mt-2 button-margin"
              onClick={handleExport}
            >
              Export to Excel
            </button>
            <button
              className="btn btn-sm btn-success mt-2  button-margin"
              onClick={() => window.print()}
            >
              Print/Export PDF
            </button>
          </form>

          <br />
          <br />

        </div>

        <div className="report-data-printable">
        {data && (
          <div>
            {Object.values(data).map((company: any) => (
              <div key={company.company_id}>
                <hr />
                <h1>
                  Company: {company.company_code} - {company.company_name}
                </h1>
                <h4>Overall Total for {company.company_name}</h4>
                <p>
                  Stock Issues Total: {" "}
                  {Number(
                    companyIssueTotals[company.company_name] ?? 0
                  ).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                </p>
                <p>
                  Stock Charges Total: {" "}
                  {Number(
                    companyChargeTotals[company.company_name] ?? 0
                  ).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                </p>
                <p>
                  Tax Assessed: {" "}
                  {Number(
                    companyTaxTotals[company.company_name] ?? 0
                  ).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                </p>
                <hr />

                <br />
                <br />
                {Object.values(company.jobs).map((job: any) => (
                  <div key={job.job_id}>
                    <h3>
                      Job: {job.job_number} - {job.job_name}
                    </h3>

                    {job.stock_issues.map((issue: any) => {
                      const issueTotal = issue.stock_issue_details.reduce(
                        (total: number, detail: StockIssueDetail) => {
                          return total + Number(detail.total_amt ?? 0);
                        },
                        0
                      );
                      const taxTotal = issue.stock_issue_details.reduce(
                        (total: number, detail: StockIssueDetail) => {
                          return total + Number(detail.tax_amt ?? 0);
                        },
                        0
                      );
                      const itemTotal = issue.stock_issue_details.reduce(
                        (total: number, detail: StockIssueDetail) => {
                          return total + Number(detail.item_amt ?? 0);
                        },
                        0
                      );
                      const nonTaxTotal = issue.stock_issue_details.reduce(
                        (total: number, detail: StockIssueDetail) => {
                          return total + Number(detail.freight_charge ?? 0);
                        },
                        0
                      );

                      return (
                        <div key={issue.id}>
                          <h4>Stock Issue: {issue.id}</h4>
                          <DataTable
                            data={issue.stock_issue_details}
                            columns={stockIssueColumns}
                          />
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "flex-end",
                            }}
                          >
                            <p className="summary-text">
                              Item Total: ${Number(itemTotal ?? 0).toFixed(2)}
                            </p>
                            <p className="summary-text">
                              Nontaxable Total: $
                              {Number(nonTaxTotal ?? 0).toFixed(2)}
                            </p>
                            <p className="summary-text">
                              Tax Total: ${Number(taxTotal ?? 0).toFixed(2)}
                            </p>
                            <p className="summary-text">Total: ${Number(issueTotal ?? 0).toFixed(2)}</p>
                          </div>
                          <hr />
                        </div>
                      );
                    })}

                    {job.stock_charges.map((charge: StockCharge) => {
                      const chargeTotal =
                        Number(charge.charge_amount ?? 0) -
                          Number(charge.charge_tax ?? 0) ?? 0;
                      const taxTotal = charge.charge_tax ?? 0;
                      const overallTotal = charge.charge_amount ?? 0;

                      return (
                        <div key={charge.id}>
                          <h4>Stock Charge: {charge.id}</h4>
                          <DataTable
                            data={[charge]}
                            columns={stockChargeColumns}
                          />
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "flex-end",
                            }}
                          >
                            <p className="summary-text">
                              Item Total: ${Number(chargeTotal ?? 0).toFixed(2)}
                            </p>
                            <p className="summary-text">
                              Tax Total: ${Number(taxTotal ?? 0).toFixed(2)}
                            </p>
                            <p className="summary-text">
                              Overall Total: $
                              {Number(overallTotal ?? 0).toFixed(2)}
                            </p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ))}
              </div>
            ))}
          </div>
        )}
        </div>
      </Layout>
    </div>
  );
}

export default MonthlyIssuesAndCreditsDetail;
