import React, { useState } from "react";
import { apiClient } from "../../apis/client";
import Layout from "../../components/Layout";
import BasicFilterTable from "../../components/BasicFilterTable";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../styles/print.css";

const { DateTime } = require("luxon");

interface ReportData {
  id: number;
  stock: string;
  quantity_on_hand: number;
  unit_price: number;
  extended_cost: number;
}

function InventoryValuationReport() {
  const [reportData, setReportData] = useState<ReportData[] | null>(null);
  const [monthEnding, setMonthEnding] = useState(new Date());
  const handleSubmit = async (event: React.FormEvent) => {
    toast.info("Generating report");
    event.preventDefault();
    const data = {
      date: monthEnding,
    };
    try {
      const response = await apiClient.post(
        "/invitems/reports/InventoryValuation/filter/",
        data
      );
      setReportData(response.data);
      toast.success("Report loaded.");
    } catch (error) {
      console.error("Error submitting report:", error);
    }
  };
  const handleExportExcel = async (event: React.FormEvent) => {
    toast.info("Generating report");
    event.preventDefault();
    const data = {
      date: monthEnding,
    };
    try {
      const response = await apiClient.post(
        "/invitems/reports/InventoryValuation/export_excel/",
        data,
        { responseType: "blob" } // Set the responseType to 'blob' to receive a Blob object in the response
      );
      const blob = await response.data;
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = `InventoryValuationReport - ${monthEnding.toLocaleString()}.xlsx`;
      link.click();
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error submitting report:", error);
    }
  };
  const filterFn = (keyword: string, item: ReportData) => {
    const lowercaseKeyword = keyword.toLowerCase();
    const itemValues = Object.values(item);

    for (const value of itemValues) {
      if (typeof value === "string") {
        const lowercaseValue = value.toLowerCase();

        if (lowercaseValue.includes(lowercaseKeyword)) {
          return true;
        }
      }
    }

    return false;
  };

  const filterButtons = <></>;
  const columns = [
    {
      name: "Item ID",
      sortable: true,
      selector: (row: ReportData) => row.id,
    },
    {
      name: "Item Description",
      sortable: true,
      selector: (row: ReportData) => row.stock,
      wrap: true,
    },
    {
      name: "Quantity on Hand",
      sortable: true,
      selector: (row: ReportData) => row.quantity_on_hand,
    },
    {
      name: "Last Unit Price",
      sortable: true,
      selector: (row: ReportData) => {
        return (
          "$" +
          Number(row.unit_price).toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
        );
      },
    },
    {
      name: "Extended Cost",
      sortable: true,
      selector: (row: ReportData) =>
        "$" +
        Number(row.extended_cost).toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
    },
  ];
  const grandTotal = reportData?.reduce(
    (total, row) => Number(total) + Number(row.extended_cost),
    0
  );

  return (
    <div id="divToPrint">
      <Layout>
      <div id="report-title-container" className="hide-printable">

        <ToastContainer />
        <h2>Inventory Valuation Report</h2>
        <br></br>
        <h4 className="d-none">REPORT PARAMETERS</h4>
        <form onSubmit={handleSubmit}>
          <label className="d-none">Last Date:</label> <br />
          <input
            name="month_ending"
            type="date"
            className="form-control d-none"
            required={true}
            defaultValue={DateTime.local().endOf("month").toISODate()}
            onChange={(e) => setMonthEnding(new Date(e.target.value))}
          ></input>
          <button
            type="submit"
            className="btn btn-sm btn-success mt-2 button-margin"
          >
            Generate Filtered Report
          </button>
          <button
            type="submit"
            className="btn btn-sm btn-success mt-2  button-margin"
            onClick={handleExportExcel}
          >
            Export to Excel
          </button>
          <button
            className="btn btn-sm btn-success mt-2  button-margin"
            onClick={() => window.print()}
          >
            Print/Export PDF
          </button>
        </form>

        <br />
        <br />
      </div>

      <div className="report-data-printable">

        {reportData && (
          <div>
            <BasicFilterTable
              tableKey="InventoryValuation"
              title="Inventory Valuation Report Results"
              dataSource={reportData as ReportData[]}
              columns={columns}
              filterFn={filterFn}
              filterButtons={filterButtons}
              exportFileName={"Inventory Valuation Report.csv"}
            />
            <div style={{ textAlign: "right" }}>
              <label>Grand Total: {grandTotal} </label>
            </div>
          </div>
        )}
      </div>
      </Layout>
    </div>
  );
}

export default InventoryValuationReport;
