import React, { useEffect, useState } from "react";
import { apiClient } from "../../apis/client";
import Layout from "../../components/Layout";
import BasicFilterTable from "../../components/BasicFilterTable";
import { useJobQueryAll } from "../Tickets/Logic/MutationsAndQueries";
import { JobQueryData } from "../../apis/interfaces/common";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../styles/print.css";
import Select from "react-select";

const { DateTime } = require("luxon");

interface ReportData {
  id: number;
  date_issued: Date;
  job__job_number: string;
  job__id: number;
  charge_amount_sum: number;
  freight_charge_sum: number;
  charge_tax_sum: number;
}

function StockedItemsReport() {
  const [reportData, setReportData] = useState<ReportData[] | null>(null);
  const [jobs, setJobs] = useState<JobQueryData[]>([]);
  const [job, setJob] = useState(0);
  const [monthEnding, setMonthEnding] = useState<Date>(DateTime.now());
  const jobQuery = useJobQueryAll();

  useEffect(() => {
    if (!jobQuery.isLoading) {
      setJobs(jobQuery.data);
    }
  }, [jobQuery.data, jobQuery.isLoading]);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    toast.info("Generating report");

    const data = {
      job: job,
      month_ending: monthEnding,
    };
    try {
      const response = await apiClient.post(
        "/tickets/reports/StockIssueRegister/filter/",
        data
      );
      setReportData(response.data);
      toast.success("Report loaded.");
    } catch (error) {
      console.error("Error submitting report:", error);
    }
  };
  const filterFn = (keyword: string, item: ReportData) => {
    const lowercaseKeyword = keyword.toLowerCase();
    const itemValues = Object.values(item);

    for (const value of itemValues) {
      if (typeof value === "string") {
        const lowercaseValue = value.toLowerCase();

        if (lowercaseValue.includes(lowercaseKeyword)) {
          return true;
        }
      }
    }

    return false;
  };

  const filterButtons = <></>;
  const columns = [
    {
      name: "ID",
      sortable: true,
      selector: (row: ReportData) => row.id,
      width: "80px",
      wrap: true,
    },
    {
      name: "Date Issued",
      sortable: true,
      selector: (row: ReportData) =>
        row.date_issued?.toLocaleString().slice(0, 10),
      wrap: true,
      width: "125px",
    },
    {
      name: "Job #",
      sortable: true,
      selector: (row: ReportData) => row.job__job_number,
      wrap: true,
      width: "90px",
    },
    {
      name: "Item Total",
      sortable: true,
      selector: (row: ReportData) =>
        "$" +
        Number(Number(row.charge_amount_sum ?? 0)).toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
      wrap: true,
      width: "110px",
    },
    {
      name: "Freight (non-taxable)",
      sortable: true,
      selector: (row: ReportData) =>
        "$" +
        Number(row.freight_charge_sum).toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
      wrap: true,
      width: "100px",
    },
    {
      name: "Tax Total",
      sortable: true,
      selector: (row: ReportData) =>
        "$" +
        Number(row.charge_tax_sum).toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
      wrap: true,
      width: "100px",
    },
    {
      name: "Total Issue Amount",
      sortable: true,
      selector: (row: ReportData) =>
        "$" +
        Number(
          Number(row.charge_amount_sum ?? 0) +
            Number(row.charge_tax_sum ?? 0) +
            Number(row.freight_charge_sum ?? 0)
        ).toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
      wrap: true,
      width: "20%",
    },
  ];
  const options =
    jobs
      ?.filter((jobElem: JobQueryData) => jobElem.active)
      .map((jobElem: JobQueryData) => ({
        value: jobElem.id,
        label: `${jobElem.job_number} - ${jobElem.job}`,
      })) ?? 0;

  const hndSelectJob = (
    selectedOption: { value: number | undefined; label: string } | null
  ) => {
    const job = selectedOption
      ? jobs.find(
          (jobItem: JobQueryData) => jobItem.id === selectedOption.value
        )
      : null;
    setJob(job?.id ?? 0);
  };
  function customFilter(option: any, rawInput: any) {
    const searchTerm = rawInput.toLowerCase();
    return option.label.toLowerCase().includes(searchTerm);
  }
  return (
    <div>
      <Layout>
        <div id="report-title-container" className="hide-printable">
          <ToastContainer />
          <h2>Stock Issue Register Report</h2>
          <br></br>
          <h4>REPORT PARAMETERS</h4>
          <form onSubmit={handleSubmit}>
            <label>Job:</label> <br />
            <Select
              name="job"
              className="form-control"
              onChange={hndSelectJob}
              options={options}
              isClearable
              filterOption={customFilter}
            />
            <label>Month Ending:</label> <br />
            <input
              name="month_ending"
              type="date"
              className="form-control"
              defaultValue={DateTime.local().endOf("month").toISODate()}
              onChange={(e) => setMonthEnding(new Date(e.target.value))}
            ></input>
            <button type="submit" className="btn btn-sm btn-success mt-2">
              Generate Filtered Report
            </button>
            <button
              type="submit"
              className="btn btn-sm btn-success mt-2 button-margin"
              onClick={() =>
                (document.querySelector(".btn.btn-link") as HTMLElement).click()
              }
            >
              Export to Excel
            </button>
            <button
              className="btn btn-sm btn-success mt-2  button-margin"
              onClick={() => window.print()}
            >
              Print/Export PDF
            </button>
          </form>

          <br />
          <br />
        </div>

        <div className="report-data-printable">
          {reportData && (
            <BasicFilterTable
              tableKey="StockIssueRegister"
              title="Stock Issue Register Report Results"
              dataSource={reportData as ReportData[]}
              columns={columns}
              filterFn={filterFn}
              filterButtons={filterButtons}
              exportFileName={"Stock Issue Register Data.csv"}
            />
          )}
        </div>
      </Layout>
    </div>
  );
}

export default StockedItemsReport;
