import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Layout from "../../components/Layout";
import Loader from "../../components/Loader";
import BasicFilterTable from "../../components/BasicFilterTable";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import { apiClient } from "../../apis/client";

import ErrorFallback from "../../components/Error";
import { useQuery } from "@tanstack/react-query";
import * as Sentry from "@sentry/react";
import { smallToolsByJobQuery } from "../../apis/queries/tickets";
import { useJobQueryAll } from "../Tickets/Logic/MutationsAndQueries";
import { jobQuery } from "../../apis/queries/common";

import type { StockIssueDetailQueryWithVendorData } from "../../apis/interfaces/tickets";
import { JobQueryData } from "../../apis/interfaces/common";

const { DateTime } = require("luxon");

interface StockOutReportProps {
  jobId: string|number;
}

const StockOutReportBody = (props: StockOutReportProps) => {
  const { jobId } = props;
  const inventory = useQuery(smallToolsByJobQuery(jobId));
  const job = useQuery(jobQuery(`${jobId}`));

  const [ filteredInv, setFilteredInv ] = useState<StockIssueDetailQueryWithVendorData[]>([]);
  const [ jobName, setJobName ] = useState<string>('');

  const filterFn = (keyword: string, item: StockIssueDetailQueryWithVendorData) => {
    const itemValues = [
      item.stock.ycc_serial,
      item.stock.serial,
      item.stock.make,
      item.stock.model,
      item.stock.stock, 
      item.stock.vendor.vendor_name,
    ];

    return itemValues.join(' ').toLowerCase().includes(keyword.toLowerCase());
  };

  const filterButtons = (
    <>
    </>
  );

  const columns = [
    {
      name: "Tool #",
      sortable: true,
      selector: (row: StockIssueDetailQueryWithVendorData) => row.stock.ycc_serial,
      width: "9%",
    },
    {
      name: "Ticket #",
      sortable: true,
      selector: (row: StockIssueDetailQueryWithVendorData) => row.stock_issue,
      width: "9%",
      cell: (row: StockIssueDetailQueryWithVendorData) => <><Link to={`/ticket/${row.stock_issue}`} target="_blank">{row.stock_issue}</Link></>,
    },
    {
      name: "Status",
      sortable: true,
      selector: (row: StockIssueDetailQueryWithVendorData) => row.date_transferred ? 2 : (row.returned || row.date_returned) ? 3 : 0,
      width: "9%",
      cell: (row: StockIssueDetailQueryWithVendorData) => {
        if (row.date_transferred) {
          return <span className="badge bg-secondary">Transferred</span>
        }

        if (row.date_returned || row.returned) {
          return <span className="badge bg-success">Returned</span>
        }

        return <span className="badge bg-primary">On Site</span>
      }
    },
    {
      name: <>Date<br />Delivered</>,
      sortable: true,
      selector: (row: StockIssueDetailQueryWithVendorData) => row.date_delivered,
      cell: (row: StockIssueDetailQueryWithVendorData) => row.date_delivered ? DateTime.fromISO(row.date_delivered).toISODate() : null,
      wrap: true,
      width: "9%",
    },
    {
      name: <>Date<br />Returned</>,
      sortable: true,
      selector: (row: StockIssueDetailQueryWithVendorData) => row.date_returned || row.date_transferred,
      cell: (row: StockIssueDetailQueryWithVendorData) => row.date_transferred ? DateTime.fromISO(row.date_transferred).toISODate() : row.date_returned ? DateTime.fromISO(row.date_returned).toISODate() : null,
      wrap: true,
      width: "9%",
    },
    {
      name: "Description",
      sortable: true,
      selector: (row: StockIssueDetailQueryWithVendorData) => row.stock.stock,
      wrap: true,
    },
    {
      name: "Detail",
      sortable: true,
      selector: (row: StockIssueDetailQueryWithVendorData) => `${row.stock.make} ${row.stock.model}`,
      cell: (row: StockIssueDetailQueryWithVendorData) => <>
        {row.stock.make} {row.stock.model}<br />
        SER {row.stock.serial}
      </>
    },
    {
      name: "Value",
      sortable: true,
      selector: (row: StockIssueDetailQueryWithVendorData) => row.date_returned ? row.rent_charges : row.stock.unit_price,
      cell: (row: StockIssueDetailQueryWithVendorData) => <>
        {row.date_returned ? <>
          {row.rent_charges?.toLocaleString("en-us", { style: "currency", currency: "USD"})} total rent charges
        </> : <>
          {row.rent_charges?.toLocaleString("en-us", { style: "currency", currency: "USD"})} rent to date<br />
          {Number(row.stock?.unit_price).toLocaleString("en-us", { style: "currency", currency: "USD"})} item value
        </>}
      </>
    }
  ];

  useEffect(() => {
    if (inventory.data) {
      setFilteredInv(inventory.data.filter((elem: any) => elem.stock.stock_type === 'small-tool' && elem.active).sort((row: StockIssueDetailQueryWithVendorData) => row.date_transferred ? 0 : (row.returned || row.date_returned) ? 1 : -1));
      toast.success("Report loaded.");
    }
  }, [ inventory.isLoading ]);

  useEffect(() => {
    if (job.data) {
      setJobName(`${job.data.job_number} - ${job.data.job}`)
    } else {
      setJobName('');
    }
  }, [ job.isLoading ]);

  return (
    <div>
      <Loader isLoading={inventory.isLoading}>
        {inventory.data && (
          <BasicFilterTable
            tableKey="StockOut"
            title={`Job ${jobName} Small Tools Active Inventory`}
            dataSource={filteredInv as StockIssueDetailQueryWithVendorData[]}
            columns={columns}
            filterFn={filterFn}
            filterButtons={filterButtons}
            exportFileName={`Small Tools at Job - ${jobName}.csv`}
          />
        )}
      </Loader>
    </div>
  );

};

function StockOutReport() {
  const [ jobId, setJobId ] = useState<number|undefined>(undefined);
  const [ runReport, setRunReport ] = useState<boolean>(false);
  const jobQuery = useJobQueryAll();

  const hndSelectJob = (
    selectedOption: { value: number | undefined; label: string } | null
  ) => {
    setJobId(undefined);
    setRunReport(false);
    if (selectedOption && selectedOption.value) { 
      setJobId(selectedOption.value);
    } else {
      setJobId(undefined);
    }
  };

  const hndRunReport = () => {
    setRunReport(false);
    setRunReport(false);
    setRunReport(false);
    setRunReport(!!jobId);

    jobId && toast.info("Generating report");
  };

  const hndExportExcel = async (event: React.FormEvent) => {
    event.preventDefault();
    toast.info("Generating report");

    try {
        const response = await apiClient.get(
            `/tickets/reports/StockAtJob/export_excel/${jobId}/`,
            { responseType: "blob"}
        );

        const blob = await response.data;
        console.log(response.headers)
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = response.headers["content-disposition"].split("; ")[1].replace(/filename="(.*)"/, "$1");
        link.click();
        URL.revokeObjectURL(url);
    } catch (error) {
        console.error("Error submitting report:", error);
    }
  };

  return (
    <Sentry.ErrorBoundary fallback={ErrorFallback}>
    <Layout>
    <div id="report-title-container" className="hide-printable">

        <ToastContainer />
        <h2>Small Tools By Job Report</h2>

        <div className="row mb-2">
          <div className="col-12">
            <label>Job:</label> <br />
            {jobQuery.data && <Select
                    name="job"
                    className="form-control"
                    onChange={hndSelectJob}
                    isClearable
                    options={jobQuery.data.map((elem: JobQueryData) => { return { "value": elem.id, "label": `${elem.job_number} - ${elem.job}`} } )}
                  />}
            <button className="btn btn-success" type="button" onClick={hndRunReport}>Run Report</button>
            <button className="btn btn-success" type="button" onClick={hndExportExcel}>Export to Excel</button>
            <br />
          </div>
        </div>
        </div>

        <div className="report-data-printable">

        {jobId && runReport && <StockOutReportBody jobId={jobId} />}
        </div>
      </Layout>
    </Sentry.ErrorBoundary>
  );
}

export default StockOutReport;
