import { useMutation, useQuery } from "@tanstack/react-query";
import {
  stockChargeQueryAll,
  stockIssueQuery,
  stockIssueQueryAll,
  ticketDashboardQueryAll
} from "../../../apis/queries/tickets";
import {
  stockCategoryQueryAll,
  stockQueryAll,
} from "../../../apis/queries/invitems";
import { toast } from "react-toastify";
import { StockMarkReadyMutationData } from "../../../apis/interfaces/invitems";
import {
  StockChargeMutationData,
  StockIssueDetailMutationData,
  StockIssueStatusMutationData,
} from "../../../apis/interfaces/tickets";
import { markStockReadyMutation } from "../../../apis/mutations/invitems";
import {
  createStockChargeMutation,
  createStockIssueDetailMutation,
  deleteStockChargeMutation,
  markTicketStatusMutation,
  transferTicketMutation,
  updateStockChargeMutation,
  updateStockIssueDetailMutation,
  generateStockIssueChargesMutation,
} from "../../../apis/mutations/tickets";
import {
  chargeQueryAll,
  departmentQueryAll,
  jobQueryAll,
} from "../../../apis/queries/common";
import * as Sentry from '@sentry/react';
export const useStockIssueQuery = (stockIssueId: number) => {
  return useQuery(stockIssueQuery(stockIssueId));
};
export const useStockIssueQueryAll = (
  limit: number = 25,
  offset: number = 0,
  sortField: string = "created_by",
  sortDirection: string = "desc",
  filter: string = ""
) => {
  return useQuery(stockIssueQueryAll(limit, offset, filter, sortField, sortDirection));
};

export const useTicketDashboardQueryAll = (
  limit: number = 25,
  offset: number = 0,
  sortField: string = "created_by",
  sortDirection: string = "desc",
  filter: string = ""
) => {
  return useQuery(ticketDashboardQueryAll(limit, offset, filter, sortField, sortDirection));
};

export const useJobQueryAll = () => {
  return useQuery(jobQueryAll());
};
export const useStockCategoryQueryAll = () => {
  return useQuery(stockCategoryQueryAll());
};

export const useStockQueryAll = (limit: number = 25, offset: number = 0) => {
  return useQuery(stockQueryAll(limit, offset));
};

const markItemAsReadyFn = async (
  params: [number, StockIssueDetailMutationData]
) => {
  const [id, data] = params;
  try {
    await updateStockIssueDetailMutation.mutationFn(id, data);
    if (data.delivered) {
      toast.success("Item Marked as Delivered.");
    } else if (data.ready) {
      toast.success("Item Marked as Ready.");
    } else if (data.deleted_at) {
      toast.success("Item removed from ticket.");
    }
  } catch (error) {
    toast.error("An error occurred while marking as ready. Try again.");
    Sentry.captureException(error);

  }
};
const returnItemMutationFn = async (
  params: [number, StockIssueDetailMutationData]
) => {
  const [id, data] = params;
  try {
    await updateStockIssueDetailMutation.mutationFn(id, data);
    toast.success("Item Processed.");
  } catch (error) {
    toast.error("An error occurred while marking as ready. Try again.");
    Sentry.captureException(error);
  }
};
const updateMiscChargeFn = async (
  params: [number, StockChargeMutationData]
) => {
  const [id, data] = params;
  try {
    await updateStockChargeMutation.mutationFn(id, data);
    toast.success("Charge updated.");
  } catch (error) {
    toast.error("An error occurred while updating the charge. Try again.");
    Sentry.captureException(error);

  }
};
const updateStockFn = async (params: [number, StockMarkReadyMutationData]) => {
  const [id, data] = params;
  try {
    await markStockReadyMutation.mutationFn(id, data);
  } catch (error) {
    toast.error("An error occurred while marking as ready. Try again.");
    Sentry.captureException(error);

  }
};
const updateStockIssueStatusFn = async (
  params: [number, StockIssueStatusMutationData]
) => {
  const [id, data] = params;
  try {
    await markTicketStatusMutation.mutationFn(id, data);
  } catch (error) {
    console.log(error);
    Sentry.captureException(error);

  }
};
const generateStockIssueChargesFn = async (params: [number]) => {
  const [id] = params;
  try {
    await generateStockIssueChargesMutation.mutationFn(id);
    toast.success("Rental charges generated.");
  } catch (error) {
    toast.error("An error occurred while generating rental charges for this ticket. Try again.");
    Sentry.captureException(error);
  }

}

export const useMarkItemAsReadyOrDelivered = () => {
  return useMutation(markItemAsReadyFn);
};

export const useUpdateMiscCharge = () => {
  return useMutation(updateMiscChargeFn);
};

export const useCreateCharge = () => {
  return useMutation(createStockChargeMutation);
};

export const useStockChargeQueryAll = (): { data: any; isLoading: any } => {
  return useQuery(stockChargeQueryAll());
};

export const useUpdateStockIssueStatusMutation = (): { mutate: any } => {
  return useMutation(updateStockIssueStatusFn);
};

export const useUpdateStockMutation = () => {
  return useMutation(updateStockFn);
};

export const useDeleteMiscChargeMutation = () => {
  return useMutation(deleteStockChargeMutation);
};

export const useReturnItemMutation = () => {
  return useMutation(returnItemMutationFn);
};
export const useCreateStockIssueDetailMutation = () => {
  return useMutation(createStockIssueDetailMutation);
};

export const useDepartmentQueryAll = (): { data: any } => {
  return useQuery(departmentQueryAll());
};

export const useChargeQueryAll = (): { data: any } => {
  return useQuery(chargeQueryAll());
};
export const useTransferTicket = () => {
  return useMutation(transferTicketMutation);
};

export const useGenerateStockIssueCharges = () => {
  return useMutation(generateStockIssueChargesFn)
};
