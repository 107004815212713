import * as React from "react";
import { Link } from "react-router-dom";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import Layout from "../../components/Layout";
import Loader from "../../components/Loader";
import { jobQueryAll } from "../../apis/queries/common";
import { stockIssueQueryAll } from "../../apis/queries/tickets";
import { profileQuery } from "../../apis/queries/authentication";
import type { StockIssueQueryData } from "../../apis/interfaces/tickets";
import { JobQueryData } from "../../apis/interfaces/common";
import "../../styles/staff-dashboard.css";
import { stockQueryAll } from "../../apis/queries/invitems";
import * as Sentry from "@sentry/react";
import ErrorFallback from "../../components/Error";

const { DateTime } = require("luxon");

interface JobCardProps {
  job: JobQueryData;
}
interface TicketCardProps {
  ticket: StockIssueQueryData;
}

const JobCard: React.FC<JobCardProps> = ({ job }) => {
  return (
    <div className="job-card pb-0">
      <h5>{job.job}</h5>
      <p>{job.job_number}</p>
      <div className="row border-top mb-0">
        <div className="col-6 border-end">
          <Link className="job-card-button" to={`/staff/ticket/new/${job.id}`}>New Ticket</Link>
        </div>
        <div className="col-6">
          <Link className="job-card-button" to={`/staff/inventory/${job.id}`}>Current Inventory</Link>
        </div>
      </div>
    </div>
  );
};

const TicketCard: React.FC<TicketCardProps> = ({ ticket }) => {
  return (
    <div className="ticket-card">
      <h5>
        #{ticket.id} - {ticket.status}
      </h5>
      <div>
        <span>{ticket.job.job}</span>
        <br />
        <span>{ticket.job.job_number}</span>
      </div>
      <p>{DateTime.fromISO(ticket.created_at).toLocaleString()}</p>
      <Link to={`/staff/ticket/${ticket.id}`}>Open</Link>
    </div>
  );
};

const StaffIndex: React.FC = () => {
  const jobs = useQuery(jobQueryAll());
  const tickets = useQuery(stockIssueQueryAll());
  const user = useQuery(profileQuery);
  const [assignedJobs, setAssignedJobs] = React.useState([]);
  const [assignedTickets, setAssignedTickets] = React.useState([]);
  const [searchTerm, setSearchTerm] = React.useState("");
  const queryClient = useQueryClient();
  const isSuperuser = user.data?.data.is_superuser;
  const userId = user.data?.data.id;
  React.useEffect(() => {
    queryClient.prefetchQuery(["stockQueryAll"], stockQueryAll(0, 0));
  }, [queryClient]);

  React.useEffect(() => {
    if (jobs.isSuccess) {
      if (user.data?.data.is_superuser !== true) {
        const newJobs = jobs.data
          .filter((job: JobQueryData) =>
            job.users.includes(user.data?.data.id as number)
          )
          .filter((job: JobQueryData) => job.active);
        setAssignedJobs(newJobs);
      } else {
        setAssignedJobs(jobs.data.filter((job: JobQueryData) => job.active));
      }
    }
  }, [
    jobs.data,
    jobs.isSuccess,
    user.data?.data.id,
    user.data?.data.is_superuser,
  ]);

  React.useEffect(() => {
    if (tickets.isSuccess) {
      if (user.data?.data.is_superuser !== true) {
        const newTickets = tickets.data.results
          .filter((ticket: StockIssueQueryData) =>
            ticket.job.users.includes(user.data?.data.id as number)
          )
          .filter((ticket: StockIssueQueryData) => ticket.active);
        setAssignedTickets(newTickets);
      } else {
        setAssignedTickets(
          tickets.data.results.filter(
            (ticket: StockIssueQueryData) => ticket.active
          )
        );
      }
    }
  }, [
    tickets.data,
    tickets.isSuccess,
    user.data?.data.id,
    user.data?.data.is_superuser,
  ]);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const filteredJobs = React.useMemo(() => {
    if (!jobs.data) return [];
    const jobFilter = isSuperuser
      ? (job: JobQueryData) => job.active
      : (job: JobQueryData) => job.users.includes(userId as number) && job.active;

    return jobs.data.filter(jobFilter)
      .filter((job: JobQueryData) =>
        job.job.toLowerCase().includes(searchTerm.toLowerCase()) ||
        job.job_number.toLowerCase().includes(searchTerm.toLowerCase())
      );
  }, [jobs.data, isSuperuser, userId, searchTerm]);

  const filteredTickets = React.useMemo(() => {
    if (!tickets.data) return [];
    const ticketFilter = isSuperuser
      ? (ticket: StockIssueQueryData) => ticket.active
      : (ticket: StockIssueQueryData) =>
        ticket.job.users.includes(userId as number) && ticket.active;

    return tickets.data.results.filter(ticketFilter)
      .filter((ticket: StockIssueQueryData) =>
        ticket.status.toLowerCase().includes(searchTerm.toLowerCase()) ||
        ticket.job.job.toLowerCase().includes(searchTerm.toLowerCase()) ||
        ticket.job.job_number.toLowerCase().includes(searchTerm.toLowerCase())
      );
  }, [tickets.data, isSuperuser, userId, searchTerm]);

  return (
    <Layout>
      <Loader isLoading={!(jobs.data && tickets.data)}>
        <div className="row mb-4">
          <div className="col-md-10 offset-md-1 col-sm-12">
            <div className="border border-secondary-subtle p-0">
              <h1 className="dashboard m-0 p-2 d-flex">
                <div className="flex-grow-1">
                  My Jobs
                </div>
                <div className="ml-auto text-right">
                  <Link to="/staff/costcodes" style={{ fontSize: "14pt", textTransform: "none" }}>Reassign Cost Codes</Link>
                </div>  
              </h1>
            </div>
            <input
              type="text"
              value={searchTerm}
              onChange={handleSearch}
              placeholder="Search"
              className="search-input"
            />

            <div className="p-2">
              <Sentry.ErrorBoundary fallback={ErrorFallback}>
              {filteredJobs.map((job: JobQueryData) => (
                    <JobCard key={job.id} job={job} />
              ))}
              </Sentry.ErrorBoundary>
            </div>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-md-10 offset-md-1 col-sm-12">
            <div className="border border-secondary-subtle p-0">
              <h1 className="dashboard m-0 p-2">My Tickets</h1>
            </div>
            <div className="p-2">
              <Sentry.ErrorBoundary fallback={ErrorFallback}>
              {filteredTickets.map((ticket: StockIssueQueryData) => (
                    <TicketCard key={ticket.id} ticket={ticket} />
              ))}
              </Sentry.ErrorBoundary>
            </div>
          </div>
        </div>
      </Loader>
    </Layout>
  );
};

export default StaffIndex;
