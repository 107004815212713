import { createBrowserRouter } from "react-router-dom";

import Dashboard from "../pages/Dashboard";
import Login from "../pages/Login";
import Logout from "../pages/Logout";
import ResetPassword from "../pages/resetPassword";
import ConfirmResetPassword from "../pages/ConfirmResetPassword";
import Profile from "../pages/Profile";

import metaRoutes from "./meta";
import invRoutes from "./inventory";
import ticketRoutes from "./tickets";
import jobRoutes from "./jobs";
import staffRoutes from "./staff";
import reportRoutes from "./reporting";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Dashboard />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/logout",
    element: <Logout />,
  },
  {
    path: "/reset",
    element: <ResetPassword />,
  },
  {
    path: "/reset/:codeone/:codetwo/",
    element: <ConfirmResetPassword />,
  },
  {
    path: "/profile",
    element: <Profile />,
  },
  ...metaRoutes,
  ...invRoutes,
  ...ticketRoutes,
  ...jobRoutes,
  ...staffRoutes,
  ...reportRoutes,
]);

export { router };
