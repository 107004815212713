// Reporting routes for the app

import ActiveJobsReport from "../pages/Reporting/ActiveJobsReport";
import InventoryValuationReport from "../pages/Reporting/InventoryValuationReport";
import MonthlyIssuesAndCreditsDetail from "../pages/Reporting/MonthlyIssuesAndCreditsDetail";
import MonthlyIssuesAndCreditsSummary from "../pages/Reporting/MonthlyIssuesAndCreditsSummary";
import MonthlySalesTaxReport from "../pages/Reporting/MonthlySalesTaxReport";
import StockIssueJTD from "../pages/Reporting/StockIssueJTD";
import MonthlyStockIssueUsage from "../pages/Reporting/MonthlyStockIssueUsage";
import OrderExistingReport from "../pages/Reporting/OrderExistingReport";
import ReorderRequiredReport from "../pages/Reporting/ReorderRequiredReport";
import StockIssueRegisterReport from "../pages/Reporting/StockIssueRegisterReport";
import StockItemIndex from "../pages/Reporting/StockItemIndexReport";
import StockedItemsReport from "../pages/Reporting/StockedItemsReport";
import StockOutReport from "../pages/Reporting/StockOutReport";
import CostCodeReassignment from "../pages/Reporting/CostCodeReassignment";
import GLReport from "../pages/Reporting/GLReport";
import DailyTicketReport from "../pages/Reporting/DailyTicketReport";
import JobSummaryReport from "../pages/Reporting/JobSummaryReport";

const reportRoutes = [
  {
    path: "/reports/stocked_items_report/",
    element: <StockedItemsReport />,
  },
  {
    path: "/reports/reorder_required_report/",
    element: <ReorderRequiredReport />,
  },
  {
    path: "/reports/active_jobs_report/",
    element: <ActiveJobsReport />,
  },
  {
    path: "/reports/inventory_valuation_report/",
    element: <InventoryValuationReport />,
  },
  {
    path: "/reports/monthly_sales_tax_report/",
    element: <MonthlySalesTaxReport />,
  },
  {
    path: "/reports/order_existing_report/",
    element: <OrderExistingReport />,
  },
  {
    path: "/reports/stock_issue_register_report/",
    element: <StockIssueRegisterReport />,
  },
  {
    path: "/reports/monthly_issues_and_credit_summary_report/",
    element: <MonthlyIssuesAndCreditsSummary />,
  },
  {
    path: "/reports/monthly_issues_and_credit_detail_report/",
    element: <MonthlyIssuesAndCreditsDetail />,
  },
  {
    path: "/reports/monthly_stock_issue_usage/",
    element: <MonthlyStockIssueUsage />,
  },
  {
    path: "/reports/stock_item_index_report/",
    element: <StockItemIndex />,
  },
  {
    path: "/reports/stock_issue_jtd_report/",
    element: <StockIssueJTD />,
  },
  {
    path: "/reports/stock_out_report/",
    element: <StockOutReport />,
  },
  {
    path: "/reports/items_missing_cc/",
    element: <CostCodeReassignment />,
  },
  {
    path: "/reports/gl_report/",
    element: <GLReport />,
  },
  {
    path: "/reports/daily_ticket_report/",
    element: <DailyTicketReport />,
  },
  { 
    path: "/reports/job_summary_report/",
    element: <JobSummaryReport />,
  }
];

export default reportRoutes;
