// Meta routes for the app

import Job from "../pages/Job";

const jobRoutes = [
  {
    path: "/jobs/:id?",
    element: <Job />,
  },
];

export default jobRoutes;
