import React, { useEffect, useState } from "react";
import { apiClient } from "../../apis/client";
import Layout from "../../components/Layout";
import BasicFilterTable from "../../components/BasicFilterTable";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

interface ReportData {
  job_number: string;
  job: string;
  company__company: string;
  sales_tax__state: string;
  sales_tax__sales_tax: string;
  sales_tax__sales_tax_percentage: number;
  active: boolean;
}

function ActiveJobsReport() {
  const [reportData, setReportData] = useState<ReportData[] | null>(null);
  useEffect(() => {
    const getReport = async () => {
      try {
        const response = await apiClient.get(
          "/common/reports/ActiveJobReport/generate/",
          {
            headers: {
              Accept: "application/json",
            },
          }
        );
        setReportData(response.data);
      } catch (error) {
        console.error("Error fetching report data:", error);
        toast.error("Error fetching report data");
      }
    };

    getReport();
  }, []);
  const filterFn = (keyword: string, item: ReportData) => {
    const lowercaseKeyword = keyword.toLowerCase();
    const itemValues = Object.values(item);

    for (const value of itemValues) {
      if (typeof value === "string") {
        const lowercaseValue = value.toLowerCase();

        if (lowercaseValue.includes(lowercaseKeyword)) {
          return true;
        }
      }
    }

    return false;
  };
  const handleExportExcel = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      const response = await apiClient.get(
        "/common/reports/ActiveJobReport/export_excel/",
        { responseType: "blob" }
      );
      const blob = await response.data;
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "ActiveJobsReport.xlsx";
      link.click();
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error exporting report:", error);
      toast.error("An error occurred while exporting the report.");
    }
  };

  const filterButtons = (
    <>
      <button
        type="submit"
        className="btn btn-sm btn-success mt-2"
        onClick={handleExportExcel}
      >
        Export to Excel
      </button>
    </>
  );
  const columns = [
    {
      name: "Job Number",
      sortable: true,
      selector: (row: ReportData) => row.job_number,
      width: "12%",
    },
    {
      name: "Job Name",
      sortable: true,
      selector: (row: ReportData) => row.job,
      wrap: true,
    },
    {
      name: "Job Company",
      sortable: true,
      selector: (row: ReportData) => row.company__company,
      wrap: true,
    },
    {
      name: "Sales Tax Locality",
      sortable: true,
      selector: (row: ReportData) => row.sales_tax__sales_tax,
    },
    {
      name: "Sales Tax Percentage",
      sortable: true,
      selector: (row: ReportData) => row.sales_tax__sales_tax_percentage,
    },
  ];
  return (
    <div>
      <Layout>
      <div id="report-title-container" className="hide-printable">
        <ToastContainer />
        <h2>Active Jobs Report</h2>
        <br></br>
      </div>

      <div className="report-data-printable">
        {reportData && (
          <BasicFilterTable
            tableKey="ActiveJobs"
            title="Active Jobs Report Results"
            dataSource={reportData as ReportData[]}
            columns={columns}
            filterFn={filterFn}
            filterButtons={filterButtons}
            exportFileName={"Active Jobs Export Data.csv"}
          />
        )}
      </div>
      </Layout>
    </div>
  );
}

export default ActiveJobsReport;
