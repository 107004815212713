import { apiClient } from "../client";

const STALE_TIME = 1000 * 60 * 120; // Set stale time to 120 minutes
// #region Company Queries
export const companyQueryAll = () => {
  return {
    queryKey: ["companyQueryAll"],
    queryFn: async () => {
      const response = await apiClient.get("/common/company/");
      return response.data;
    },
    staleTime: STALE_TIME,
  };
};

export const companyQuery = (id: string) => {
  return {
    queryKey: ["companyQuery", id],
    queryFn: async () => {
      const response = await apiClient.get(`/common/company/${id}/`);
      return response.data;
    },
    staleTime: STALE_TIME,
  };
};
// #endregion

// #region SalesTax Queries
export const salesTaxQueryAll = {
  queryKey: ["salesTaxQueryAll"],
  queryFn: async () => {
    const response = await apiClient.get("/common/salestax/");
    return response.data;
  },
  staleTime: STALE_TIME,
};

export const salesTaxQuery = (id: string) => {
  return {
    queryKey: ["salesTaxQuery", id],
    queryFn: async () => {
      const response = await apiClient.get(`/common/salestax/${id}/`);
      return response.data;
    },
  };
};
// #endregion

// #region Department Queries
export const departmentQueryAll = () => {
  return {
    queryKey: ["departmentQueryAll"],
    queryFn: async () => {
      const response = await apiClient.get("/common/department/");
      return response.data;
    },
    staleTime: STALE_TIME,
  };
};

export const departmentQuery = (id: string) => {
  return {
    queryKey: ["departmentQuery", id],
    queryFn: async () => {
      const response = await apiClient.get(`/common/department/${id}/`);
      return response.data;
    },
  };
};
// #endregion

// #region Charge Queries
export const chargeQueryAll = () => {
  return {
    queryKey: ["chargeQueryAll"],
    queryFn: async () => {
      const response = await apiClient.get("/common/charge/");
      return response.data;
    },
    staleTime: STALE_TIME,
  };
};

export const chargeQuery = (id: string) => {
  return {
    queryKey: ["chargeQuery", id],
    queryFn: async () => {
      const response = await apiClient.get(`/common/charge/${id}/`);
      return response.data;
    },
  };
};
// #endregion

// #region Job Queries
export const jobQueryAll = () => {
  return {
    queryKey: ["jobQueryAll"],
    queryFn: async () => {
      const response = await apiClient.get("/common/job/");
      return response.data;
    },
    staleTime: STALE_TIME,
  };
};

export const jobQuery = (id: string) => {
  return {
    queryKey: ["jobQuery", id],
    queryFn: async () => {
      const response = await apiClient.get(`/common/job/${id}/`);
      return response.data;
    },
  };
};
// #endregion

// #region Vendor Queries
export const vendorQueryAll = () => {
  return {
    queryKey: ["vendorQueryAll"],
    queryFn: async () => {
      const response = await apiClient.get("/common/vendor/");
      return response.data;
    },
    staleTime: STALE_TIME,
  };
};

export const vendorQuery = (id: string) => {
  return {
    queryKey: ["vendorQuery", id],
    queryFn: async () => {
      const response = await apiClient.get(`/common/vendor/${id}/`);
      return response.data;
    },
  };
};
// #endregion

// #region Location Queries
export const locationQueryAll = () => {
  return {
    queryKey: ["locationQueryAll"],
    queryFn: async () => {
      const response = await apiClient.get("/common/location/");
      return response.data;
    },
    staleTime: STALE_TIME,
  };
};

export const locationQuery = (id: string) => {
  return {
    queryKey: ["locationQuery", id],
    queryFn: async () => {
      const response = await apiClient.get(`/common/location/${id}/`);
      return response.data;
    },
  };
};
// #endregion

export const userQueryAll = () => {
  return {
    queryKey: ["userQueryAll"],
    queryFn: async () => {
      const response = await apiClient.get("/common/user/");
      return response.data;
    },
    staleTime: STALE_TIME,
  };
};

export const costCodeQueryAll = (
  limit: number = 25,
  offset: number = 0,
  filter: string = "",
  sortField: string = "",
  sortDirection: string = "asc",
  job: string|number = ""
) => {
  return {
    queryKey: ["costCodeQueryAll"],
    queryFn: async () => {
      let ordering = "";

      if (sortDirection === "desc" && sortField.length > 0) {
        ordering = `-${sortField}`;
      } else {
        if (sortField.length > 0) {
          ordering = sortField;
        }
      }

      const response = await apiClient.get(`/common/costcode/?limit=${limit}&offset=${offset}&ordering=${ordering}&search=${filter}&job=${job}`);
      return response.data;
    },
    staleTime: STALE_TIME,
  };
};

export const costCodeQuery = (id: string) => {
  return {
    queryKey: ["costCodeQuery", id],
    queryFn: async () => {
      const response = await apiClient.get(`/common/costcode/${id}/`);
      return response.data;
    },
  };
};
