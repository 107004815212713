import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Modal } from "react-bootstrap";
import type {
  ChargeMutationData,
  ChargeQueryData,
} from "../../apis/interfaces/common";
import { chargeQueryAll } from "../../apis/queries/common";
import {
  createChargeMutation,
  updateChargeMutation,
} from "../../apis/mutations/common";
import { toast, ToastContainer } from "react-toastify";

import ChargeForm from "../../forms/ChargeForm";

import Layout from "../../components/Layout";
import Loader from "../../components/Loader";
import BasicFilterTable from "../../components/BasicFilterTable";
import ErrorFallback from "../../components/Error";
import * as Sentry from "@sentry/react";

interface ChargeTableProps {
  onAddNew: any;
  onEdit: any;
  charges: Array<ChargeQueryData>;
  setChargeInitialValues: any;
  setShowChargeModal: any;
  setEdit: any;
}

interface ChargeModalProps {
  show: boolean;
  onCancel: Function;
  onOk: Function;
  initialValues: ChargeMutationData;
  setShowChargeModal: any;
  setChargeInitialValues: any;
  edit: boolean;
  setEdit: any;
}

const ChargeModal = (props: ChargeModalProps) => {
  const { show, onCancel, onOk, initialValues, edit, setEdit } = props;
  const queryClient = useQueryClient();
  const createCharge = useMutation({
    ...createChargeMutation,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["chargeQueryAll"] });
      onOk();
    },
  });
  const updateChargeMutationFn = async (
    params: [number, ChargeMutationData]
  ) => {
    const [id, data] = params;
    try {
      await updateChargeMutation.mutationFn(id, data);
      onOk();
    } catch (error) {
      // Handle error
    }
  };

  const updateCharge = useMutation(updateChargeMutationFn);

  const hndSubmit = async (values: ChargeMutationData) => {
    try {
      if (!values.id) {
        createCharge.mutate(values);
        toast.success("Charge Created");
      } else {
        updateCharge.mutate([values.id as number, values]);
        setEdit(false);
        toast.success("Charge Updated");
      }
    } catch (err) {
      console.log(err);
      toast.error("An error occurred while updating the charge");
    }
  };
  const handleModalClose = () => {
    onCancel();
    setEdit(false);
  };

  return (
    <Modal show={show} onCancel={handleModalClose}>
      {edit && (
        <Modal.Header closeButton onClick={handleModalClose}>
          Edit Charge
        </Modal.Header>
      )}
      {!edit && (
        <Modal.Header closeButton onClick={handleModalClose}>
          Add Charge
        </Modal.Header>
      )}
      <ChargeForm
        initialValues={initialValues}
        onCancel={handleModalClose}
        onSubmit={hndSubmit}
      />
    </Modal>
  );
};

const ChargeTable = (props: ChargeTableProps) => {
  const {
    onAddNew,
    charges,
    setShowChargeModal,
    setChargeInitialValues,
    setEdit,
  } = props;

  const fireOnEdit = (row: ChargeQueryData) => {
    setChargeInitialValues({
      id: row.id,
      charge: row.charge,
      charge_number: row.charge_number,
      active: row.active,
    });
    setEdit(true);
    setShowChargeModal(true);
  };

  const columns = [
    {
      name: "Name",
      sortable: true,
      selector: (row: ChargeQueryData) => row.charge,
    },
    {
      name: "Charge Number",
      sortable: true,
      selector: (row: ChargeQueryData) => row.charge_number,
    },
    {
      name: "Active",
      sortable: true,
      selector: (row: ChargeQueryData) => row.active,
      cell: (row: ChargeQueryData) => (
        <>
          {row.active ? (
            <span className="text-success">&#x2714;</span> // Checkmark symbol
          ) : (
            <span className="text-danger">&#x2718;</span> // Cross symbol
          )}
        </>
      ),
    },
    {
      name: "Actions",
      cell: (row: ChargeQueryData) => (
        <>
          <button
            className="btn btn-sm btn-link"
            onClick={fireOnEdit.bind(this, row)}
          >
            Edit
          </button>
        </>
      ),
    },
  ];
  const filterFn = (keyword: string, item: ChargeQueryData) => {
    const lowercaseKeyword = keyword.toLowerCase();
    const itemValues = Object.values(item);

    for (const value of itemValues) {
      if (typeof value === "string") {
        const lowercaseValue = value.toLowerCase();

        if (lowercaseValue.includes(lowercaseKeyword)) {
          return true;
        }
      }
    }

    return false;
  };

  const filterButtons = (
    <>
      <button className="btn btn-link" onClick={onAddNew}>
        Add New
      </button>
    </>
  );
  return (
    <div className="row">
      <div className="col-12">
        <div className="p-2">
          <BasicFilterTable
            tableKey="charges"
            title="Charges"
            dataSource={charges}
            columns={columns}
            filterFn={filterFn}
            filterButtons={filterButtons}
            exportFileName={"Charge Export Data.csv"}
          />
        </div>
      </div>
    </div>
  );
};

const blankChargeForm = () => {
  return {
    charge: "",
    charge_number: "",
    active: true,
  };
};

const Charge = () => {
  const [showChargeModal, setShowChargeModal] = useState(false);
  const [chargeInitialValues, setChargeInitialValues] = useState(
    blankChargeForm()
  );
  const [edit, setEdit] = useState(false);

  const charges = useQuery(chargeQueryAll());

  // Sort charges so that active ones come first
  let sortedCharges = charges.data;
  if (sortedCharges) {
    sortedCharges = [...sortedCharges].sort((a, b) => b.active - a.active);
  }

  const hndAddNew = () => {
    setChargeInitialValues(blankChargeForm());
    setShowChargeModal(true);
  };

  const hndEdit = () => {
    setShowChargeModal(true);
    setChargeInitialValues({
      ...chargeInitialValues,
      charge: "",
      charge_number: "",
    });
    setEdit(true);
  };

  const hndModelClose = () => {
    setShowChargeModal(false);
    setEdit(false);
    charges.refetch();
  };

  console.log("Charge is being rerendered");

  return (
    <Sentry.ErrorBoundary fallback={ErrorFallback}>
     
        <Layout>
          <Loader isLoading={charges.isLoading && charges.data !== undefined}>
            <ChargeTable
              onAddNew={hndAddNew}
              onEdit={hndEdit}
              charges={sortedCharges}
              setChargeInitialValues={setChargeInitialValues}
              setShowChargeModal={setShowChargeModal}
              setEdit={setEdit}
            />
          </Loader>
          <ToastContainer />
          <ChargeModal
            show={showChargeModal}
            onOk={hndModelClose}
            edit={edit}
            onCancel={hndModelClose}
            initialValues={chargeInitialValues}
            setShowChargeModal={setShowChargeModal}
            setChargeInitialValues={setChargeInitialValues}
            setEdit={setEdit}
          />
        </Layout>
      
    </Sentry.ErrorBoundary>
  );
};

export default Charge;
