import { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { StockCategoryQueryData } from "../../apis/interfaces/invitems";
import { useQuery } from "@tanstack/react-query";
import { stockCategoryQueryAll } from "../../apis/queries/invitems";
import { VendorQueryData } from "../../apis/interfaces/common";
import { vendorQueryAll } from "../../apis/queries/common";

interface FilterModalProps {
  show: boolean;
  onClose: () => void;
  onApply: (filterData: any) => void;
  setShowFilterModal: any;
  onClear: () => void;
}

const FilterModal = (props: FilterModalProps) => {
  const [typeFilter, setTypeFilter] = useState("");
  const [categoryFilter, setCategoryFilter] = useState("");
  const [vendorFilter, setVendorFilter] = useState("");
  const [makeFilter, setMakeFilter] = useState("");
  const [modelFilter, setModelFilter] = useState("");
  const [inStockFilter, setInStockFilter] = useState(0);
  const [reorderFilter, setReorderFilter] = useState(0);
  const [taxNJFilter, setTaxNJFilter] = useState(false);
  const [taxNYFilter, setTaxNYFilter] = useState(false);
  const [categories, setCategories] = useState<StockCategoryQueryData[]>([]); // State variable to store categories
  const [vendors, setVendors] = useState<VendorQueryData[]>([]); // State variable to store categories
  const [dispositionFilter, setDispositionFilter] = useState(""); // State variable to store categories
  const [catOpts, setCatOpts] = useState<Array<JSX.Element>>([]);

  const { show, onApply, setShowFilterModal, onClear } = props;

  const handleCloseFilterModal = () => {
    setShowFilterModal(false);
  };

  const handleApplyFilter = () => {
    const filterData = {
      type: typeFilter,
      category: categoryFilter,
      inStock: inStockFilter,
      reorder: reorderFilter,
      make: makeFilter,
      model: modelFilter,
      taxable_nj: taxNJFilter,
      taxable_ny: taxNYFilter,
      disposition: dispositionFilter,
      vendor: vendorFilter,
    };
    onApply(filterData);
    handleCloseFilterModal();
  };

  const handleClearFilter = () => {
    setTypeFilter("");
    setCategoryFilter("");
    setMakeFilter("");
    setModelFilter("");
    setInStockFilter(0);
    setReorderFilter(0);
    setTaxNJFilter(false);
    setTaxNYFilter(false);
    setDispositionFilter("");
    onClear();
  };

  const { data } = useQuery(stockCategoryQueryAll());
  const { data: vendor_data } = useQuery(vendorQueryAll());

  useEffect(() => {
    if (data) {
      setCategories(data);

      const tmpCatOpts: Array<JSX.Element> = [];

      data.forEach((cat: StockCategoryQueryData) => {
        if (cat.active && !cat.parent_category) {
          tmpCatOpts.push((<option key={cat.id} value={cat.id}>{cat.stock_category}</option>));

          data.forEach((subcat: StockCategoryQueryData) => {
            if (subcat.active && subcat.parent_category && subcat.parent_category.id === cat.id) {
              tmpCatOpts.push((<option key={subcat.id} value={subcat.id}>&nbsp; &nbsp;-- {subcat.stock_category}</option>));
            }
          });
        }
      });

      setCatOpts(tmpCatOpts);

    }
    if (vendor_data) {
      setVendors(vendor_data);
    }
  }, [data, vendor_data]);

  return (
    <>
      <Modal show={show} onHide={handleCloseFilterModal}>
        <Modal.Header closeButton>
          <Modal.Title>Filter Results</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-2">
              <Form.Label>Type:</Form.Label>
              <Form.Select
                value={typeFilter}
                onChange={(e) => setTypeFilter(e.target.value)}
              >
                <option value="">Select type...</option>
                <option value="consumable">Consumable</option>
                <option value="small-tool">Small Tool</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Label>Disposition:</Form.Label>
              <Form.Select
                value={dispositionFilter}
                onChange={(e) => setDispositionFilter(e.target.value)}
              >
                <option value="">Select disposition...</option>
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
                <option value="sold">Sold</option>
                <option value="missing">Missing</option>
                <option value="repair">Repair</option>
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-2">
              <Form.Label>Category:</Form.Label>
              <Form.Select
                value={categoryFilter}
                onChange={(e) => setCategoryFilter(e.target.value)}
              >
                <option value="">Select category...</option>
                {catOpts.map((category) => category)}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Label>Vendor:</Form.Label>
              <Form.Select
                value={vendorFilter}
                onChange={(e) => setVendorFilter(e.target.value)}
              >
                <option value="">Select vendor...</option>
                {vendors
                  .sort((a, b) => a.vendor_name.localeCompare(b.vendor_name))
                  .map((vendor) => (
                    <option key={vendor.id} value={vendor.vendor_name}>
                      {vendor.vendor_name}
                    </option>
                  ))}
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-2">
              <Form.Label>In Stock:</Form.Label>
              <Form.Select
                value={String(inStockFilter)}
                onChange={(e) => setInStockFilter(Number(e.target.value))}
              >
                <option value="">In Stock?</option>
                <option value="1">Yes</option>
                <option value="2">No</option>
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-2">
              <Form.Label>Needs Reorder:</Form.Label>
              <Form.Select
                value={String(reorderFilter)}
                onChange={(e) => setReorderFilter(Number(e.target.value))}
              >
                <option value="">Needs Reorder?</option>
                <option value="1">Yes</option>
                <option value="2">No</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Check
                type="checkbox"
                checked={taxNJFilter}
                onChange={(e) => setTaxNJFilter(e.target.checked)}
                label="Taxable (NJ)"
              />
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Check
                type="checkbox"
                checked={taxNYFilter}
                onChange={(e) => setTaxNYFilter(e.target.checked)}
                label="Taxable (NY)"
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClearFilter}>
            Clear
          </Button>
          <Button variant="primary" onClick={handleApplyFilter}>
            Apply
          </Button>
          <Button variant="secondary" onClick={handleCloseFilterModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default FilterModal;
