import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Modal } from "react-bootstrap";
import type {
  DepartmentMutationData,
  DepartmentQueryData,
} from "../../apis/interfaces/common";
import { departmentQueryAll } from "../../apis/queries/common";
import {
  createDepartmentMutation,
  updateDepartmentMutation,
} from "../../apis/mutations/common";

import DepartmentForm from "../../forms/DepartmentForm";

import Layout from "../../components/Layout";
import Loader from "../../components/Loader";
import BasicFilterTable from "../../components/BasicFilterTable";
import { toast, ToastContainer } from "react-toastify";
import ErrorFallback from "../../components/Error";
import * as Sentry from "@sentry/react";

interface DepartmentTableProps {
  onAddNew: any;
  onEdit: any;
  departments: Array<DepartmentQueryData>;
  setDepartmentInitialValues: any;
  setShowDepartmentModal: any;
  edit: boolean;
  setEdit: any;
}

interface DepartmentModalProps {
  show: boolean;
  onCancel: Function;
  onOk: Function;
  initialValues: DepartmentMutationData;
  setShowDepartmentModal: any;
  setDepartmentInitialValues: any;
  edit: boolean;
  setEdit: any;
}

const DepartmentModal = (props: DepartmentModalProps) => {
  const { show, onCancel, onOk, initialValues, edit, setEdit } = props;
  const queryClient = useQueryClient();
  const createDepartment = useMutation({
    ...createDepartmentMutation,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["departmentQueryAll"] });
      onOk();
    },
  });
  const updateDepartmentMutationFn = async (
    params: [number, DepartmentMutationData]
  ) => {
    const [id, data] = params;
    try {
      await updateDepartmentMutation.mutationFn(id, data);
      onOk();
    } catch (error) {
      console.log(error);
    }
  };

  const updateDepartment = useMutation(updateDepartmentMutationFn);

  const hndSubmit = async (values: DepartmentMutationData) => {
    try {
      if (!values.id) {
        await createDepartment.mutateAsync(values);
        toast.success("Department created");
      } else {
        await updateDepartment.mutateAsync([values.id as number, values]);
        setEdit(false);
        toast.success("Department updated");
      }
    } catch (err) {
      console.log(err);
      toast.error("An error occurred while updating the department");
    }
  };
  const handleModalClose = () => {
    setEdit(false);
    onCancel();
  };

  return (
    <Modal show={show} onCancel={handleModalClose}>
      {edit && (
        <Modal.Header closeButton onClick={handleModalClose}>
          Edit Department
        </Modal.Header>
      )}
      {!edit && (
        <Modal.Header closeButton onClick={handleModalClose}>
          Add Department
        </Modal.Header>
      )}
      <DepartmentForm
        initialValues={initialValues}
        onCancel={handleModalClose}
        onSubmit={hndSubmit}
      />
    </Modal>
  );
};

const DepartmentTable = (props: DepartmentTableProps) => {
  const {
    onAddNew,
    departments,
    setShowDepartmentModal,
    setDepartmentInitialValues,
    setEdit,
  } = props;

  const fireOnEdit = (row: DepartmentQueryData) => {
    setDepartmentInitialValues({
      id: row.id,
      department: row.department,
      department_number: row.department_number,
      active: row.active,
    });
    setEdit(true);
    setShowDepartmentModal(true);
  };

  const columns = [
    {
      name: "Name",
      sortable: true,
      selector: (row: DepartmentQueryData) => row.department,
    },
    {
      name: "Department Number",
      sortable: true,
      selector: (row: DepartmentQueryData) => row.department_number,
    },
    {
      name: "Active",
      sortable: true,
      selector: (row: DepartmentQueryData) => row.active,
      cell: (row: DepartmentQueryData) => (
        <>
          {row.active ? (
            <span className="text-success">&#x2714;</span> // Checkmark symbol
          ) : (
            <span className="text-danger">&#x2718;</span> // Cross symbol
          )}
        </>
      ),
    },
    {
      name: "Actions",
      cell: (row: DepartmentQueryData) => (
        <>
          <button
            className="btn btn-sm btn-link"
            onClick={fireOnEdit.bind(this, row)}
          >
            Edit
          </button>
        </>
      ),
    },
  ];
  const filterFn = (keyword: string, item: DepartmentQueryData) => {
    const lowercaseKeyword = keyword.toLowerCase();
    const itemValues = Object.values(item);

    for (const value of itemValues) {
      if (typeof value === "string") {
        const lowercaseValue = value.toLowerCase();

        if (lowercaseValue.includes(lowercaseKeyword)) {
          return true;
        }
      }
    }

    return false;
  };

  const filterButtons = (
    <>
      <button className="btn btn-link" onClick={onAddNew}>
        Add New
      </button>
    </>
  );
  return (
    <div className="row">
      <div className="col-12">
        <div className="border border-secondary-subtle p-0 d-flex">
          <div className="align-self-center mr-2"></div>
        </div>
        <div className="p-2">
          <Sentry.ErrorBoundary fallback={ErrorFallback}>
           
              <BasicFilterTable
                tableKey="departments"
                title="Departments"
                dataSource={departments}
                columns={columns}
                filterFn={filterFn}
                filterButtons={filterButtons}
                exportFileName={"Department Export Data.csv"}
              />
            
          </Sentry.ErrorBoundary>
        </div>
      </div>
    </div>
  );
};

const blankDepartmentForm = () => {
  return {
    department: "",
    department_number: "",
    active: true,
  };
};

const Department = () => {
  const [showDepartmentModal, setShowDepartmentModal] = useState(false);
  const [departmentInitialValues, setDepartmentInitialValues] = useState(
    blankDepartmentForm()
  );
  const [edit, setEdit] = useState(false);

  const departments = useQuery(departmentQueryAll());

  // Sort departments so that active ones come first
  let sortedDepartments = departments.data;
  if (sortedDepartments) {
    sortedDepartments = [...sortedDepartments].sort(
      (a, b) => b.active - a.active
    );
  }

  const hndAddNew = () => {
    setDepartmentInitialValues(blankDepartmentForm());
    setShowDepartmentModal(true);
  };

  const hndEdit = () => {
    setShowDepartmentModal(true);
    setEdit(true);
    setDepartmentInitialValues({
      ...departmentInitialValues,
      department: "",
      department_number: "",
    });
  };

  const hndModelClose = () => {
    setShowDepartmentModal(false);
    setEdit(false);
    departments.refetch();
  };

  return (
    <Layout>
      <Sentry.ErrorBoundary fallback={ErrorFallback}>
       
          <Loader
            isLoading={departments.isLoading && departments.data !== undefined}
          >
            <DepartmentTable
              onAddNew={hndAddNew}
              onEdit={hndEdit}
              departments={sortedDepartments}
              setDepartmentInitialValues={setDepartmentInitialValues}
              setShowDepartmentModal={setShowDepartmentModal}
              edit={edit}
              setEdit={setEdit}
            />
          </Loader>
          <ToastContainer />
          <DepartmentModal
            show={showDepartmentModal}
            onOk={hndModelClose}
            onCancel={hndModelClose}
            initialValues={departmentInitialValues}
            setShowDepartmentModal={setShowDepartmentModal}
            setDepartmentInitialValues={setDepartmentInitialValues}
            edit={edit}
            setEdit={setEdit}
          />
        
      </Sentry.ErrorBoundary>
    </Layout>
  );
};

export default Department;
