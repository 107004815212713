import * as Yup from "yup";
import { ErrorMessage, Field, Form, Formik, FormikHelpers } from "formik";
import type {
  StockCategoryQueryData,
  StockMutationData,
  UnitOfMeasureQueryData,
} from "../apis/interfaces/invitems";
import { Modal } from "react-bootstrap";
import {
  stockCategoryQueryAll,
  unitOfMeasureQueryAll,
} from "../apis/queries/invitems";
import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { vendorQueryAll } from "../apis/queries/common";
import { VendorQueryData } from "../apis/interfaces/common";
import {
  createStockAdjustmentMutation,
  createStockOrderDetailMutation,
  createStockOrderMutation,
  updateStockMutation,
} from "../apis/mutations/invitems";
import DataTable from "react-data-table-component";

interface ReplenishMultipleFormProps {
  initialValues: StockMutationData;
  onSubmit: any;
  onCancel: any;
  selectedRows: StockMutationData[];
}

interface RowData {
  orderDate: Date;
  id: number;
  quantityReceived: number;
  unitPrice: number;
  addQuantity: number;
  values: StockMutationData;
  receivedDate: Date;
  orderQuantity: number;
  notes: string;
  orderNumber: string;
  serial: string;
  ycc_serial: string;
  make: string;
  model: string;
  rental_rate: number;
}

const validationSchema = Yup.object({
  orderNumber: Yup.string().required(),
  vendor: Yup.number().required(),
});

const ReplenishMultipleForm = (props: ReplenishMultipleFormProps) => {
  const { initialValues, onSubmit, onCancel, selectedRows } = props;
  const [, setCats] = useState<StockCategoryQueryData[]>([]);
  const [units, setUnits] = useState<UnitOfMeasureQueryData[]>([]);
  const [vendors, setVendors] = useState<VendorQueryData[]>([]);
  const [vendorId, setVendorId] = useState(0);
  const [notes] = useState("");
  const [, setOrderQuantity] = useState(0);
  const [, setAddQuantity] = useState(0);
  const [orderNumber, setOrderNumber] = useState(""); //This will go into StockOrder.Comments
  const [orderDate, setOrderDate] = useState(new Date()); //This will go into StockOrder.Comments
  const [, setReceivedDate] = useState(new Date()); //This will go into StockOrder.Comments
  const { data } = useQuery(stockCategoryQueryAll());
  const { data: unit_data } = useQuery(unitOfMeasureQueryAll());
  const { data: vendor_data } = useQuery(vendorQueryAll());
  const createStockAdjustment = useMutation(createStockAdjustmentMutation);
  const createStockOrder = useMutation(createStockOrderMutation);
  const createStockOrderDetail = useMutation(createStockOrderDetailMutation);
  const updateStockMutationFn = async (params: [number, StockMutationData]) => {
    const [id, data] = params;
    try {
      await updateStockMutation.mutationFn(id, data);
    } catch (error) {
      console.log("Stock PATCH error: ", error);
    }
  };

  const updateStock = useMutation(updateStockMutationFn);

  useEffect(() => {
    if (data) {
      setCats(data);
    }
    if (unit_data) {
      setUnits(unit_data);
    }
    if (vendor_data) {
      setVendors(vendor_data);
    }
  }, [data, unit_data, vendor_data]);

  const handleSubmit = (
    values: StockMutationData,
    { setStatus }: FormikHelpers<StockMutationData>
  ) => {
    try {
      validationSchema.validateSync(values, { abortEarly: false });
    } catch (errors) {
      setStatus("Issue validating schema.");
    }
  };
  const [rowData, setRowData] = useState<RowData[]>([]);
  const [totals, setTotals] = useState({ ordered: 0, received: 0, price: 0 });

  useEffect(() => {
    // Initialize rowData state with the selectedRows data
    const initialRowData = selectedRows.map((row) => ({
      id: row.id || 0,
      quantityReceived: 0,
      unitPrice: 0,
      orderNumber: "",
      receivedDate: new Date(),
      orderDate: new Date(),
      orderQuantity: 0,
      notes: "",
      addQuantity: 0,
      values: initialValues,
      serial: "",
      ycc_serial: "",
      make: "",
      model: "",
      rental_rate: 0,
      active: true,
    }));
    setRowData(initialRowData);
  }, [initialValues, selectedRows]);
  const handleRowDataChange = (
    id: number,
    field: keyof RowData,
    value: string
  ) => {
    setRowData((prevRowData) =>
      prevRowData.map((row) => {
        if (row.id === id) {
          return { ...row, [field]: value };
        }
        return row;
      })
    );
  };
  const [localSelectedRows] = useState(selectedRows);
  let consumableRows = [] as StockMutationData[];
  let smallToolRows = [] as StockMutationData[];
  if (localSelectedRows && localSelectedRows.length > 0) {
    consumableRows = localSelectedRows.filter(
      (item) => item.stock_type === "consumable"
    );
    smallToolRows = localSelectedRows.filter(
      (item) => item.stock_type === "small-tool"
    );
  }
  useEffect(() => {
    // Calculate totals whenever consumableRows data changes
    const orderedTotal = rowData.reduce(
      (sum, row) => Number(sum) + Number(Number(row.unitPrice) || 0),
      0
    );
    const receivedTotal = rowData.reduce(
      (sum, row) => Number(sum) + Number(row.quantityReceived || 0),
      0
    );
    const priceTotal = rowData.reduce(
      (sum, row) => Number(sum) + Number(Number(row.unitPrice) * Number(row.quantityReceived) || 0),
      0
    );

    setTotals({
      ordered: orderedTotal,
      received: receivedTotal,
      price: priceTotal,
    });
  }, [rowData]);

  const smallToolColumns = [
    {
      name: "Name",
      sortable: true,
      selector: (row: StockMutationData) => row.stock,
    },
    {
      name: "Serial",
      sortable: true,
      cell: (row: StockMutationData) => (
        <input
          type="text"
          value={
            rowData.find((data) => data.id === row.id)?.serial ||
            selectedRows.find((data) => data.id === row.id)?.serial
          }
          onChange={(e) =>
            handleRowDataChange(row.id || 0, "serial", e.target.value)
          }
          disabled={row.stock_type !== "small-tool"}
        />
      ),
    },
    {
      name: "YCC Serial",
      sortable: true,
      cell: (row: StockMutationData) => (
        <input
          type="text"
          value={
            rowData.find((data) => data.id === row.id)?.ycc_serial ||
            selectedRows.find((data) => data.id === row.id)?.ycc_serial
          }
          onChange={(e) =>
            handleRowDataChange(Number(row.id), "ycc_serial", e.target.value)
          }
          disabled={row.stock_type !== "small-tool"}
        />
      ),
    },
    {
      name: "Make",
      sortable: true,
      cell: (row: StockMutationData) => (
        <input
          type="text"
          value={
            rowData.find((data) => data.id === row.id)?.make ||
            selectedRows.find((data) => data.id === row.id)?.make
          }
          onChange={(e) =>
            handleRowDataChange(Number(row.id), "make", e.target.value)
          }
          disabled={row.stock_type !== "small-tool"}
        />
      ),
    },
    {
      name: "Model",
      sortable: true,
      cell: (row: StockMutationData) => (
        <input
          type="text"
          value={
            rowData.find((data) => data.id === row.id)?.model ||
            selectedRows.find((data) => data.id === row.id)?.model
          }
          onChange={(e) =>
            handleRowDataChange(Number(row.id), "model", e.target.value)
          }
          disabled={row.stock_type !== "small-tool"}
        />
      ),
    },
    {
      name: "Rental Rate (hourly)",
      sortable: true,
      cell: (row: StockMutationData) => (
        <input
          type="number"
          value={
            rowData.find((data) => data.id === row.id)?.rental_rate ||
            selectedRows.find((data) => data.id === row.id)?.rental_rate
          }
          onChange={(e) =>
            handleRowDataChange(Number(row.id), "rental_rate", e.target.value)
          }
          disabled={row.stock_type !== "small-tool"}
        />
      ),
    },
    {
      name: "Unit Price",
      sortable: true,
      cell: (row: StockMutationData) => (
        <input
          type="number"
          value={rowData.find((data) => data.id === row.id)?.unitPrice}
          onChange={(e) =>
            handleRowDataChange(row.id || 0, "unitPrice", e.target.value)
          }
        />
      ),
    },
  ];
  const columns = [
    {
      name: "Name",
      sortable: true,
      cell: (row: StockMutationData) => (
        <div
          style={{
            whiteSpace: "normal",
            wordWrap: "break-word",
            maxWidth: "150px",
          }}
        >
          {row.stock}
        </div>
      ),
    },

    {
      name: "Unit of Measure",
      sortable: true,
      selector: (row: StockMutationData) => {
        const unitData = units.find(
          (unit) => unit.id === Number(row.unit_of_measure.id)
        );
        return unitData?.unit_of_measure || "";
      },
    },
    {
      name: "Quantity Ordered",
      sortable: true,
      cell: (row: StockMutationData) => (
        <input
          style={{ width: "80px" }} // Adjust the width as needed
          type="number"
          value={
            rowData.find((data) => data.id === row.id)?.orderQuantity ||
            (row.stock_type === "small-tool" ? 1 : undefined)
          }
          onChange={(e) =>
            handleRowDataChange(row.id || 0, "orderQuantity", e.target.value)
          }
          disabled={row.stock_type === "small-tool"}
        />
      ),
    },
    {
      name: "Quantity Received",
      sortable: true,
      cell: (row: StockMutationData) => (
        <input
          style={{ width: "80px" }} // Adjust the width as needed
          type="number"
          value={
            rowData.find((data) => data.id === row.id)?.quantityReceived ||
            (row.stock_type === "small-tool" ? 1 : undefined)
          }
          onChange={(e) =>
            handleRowDataChange(row.id || 0, "quantityReceived", e.target.value)
          }
          disabled={row.stock_type === "small-tool"}
        />
      ),
    },
    {
      name: "Unit Price",
      sortable: true,
      cell: (row: StockMutationData) => (
        <input
          style={{ width: "80px" }} // Adjust the width as needed
          type="number"
          value={rowData.find((data) => data.id === row.id)?.unitPrice}
          onChange={(e) =>
            handleRowDataChange(row.id || 0, "unitPrice", e.target.value)
          }
        />
      ),
    },
    {
      name: "Current Price",
      sortable: true,
      cell: (row: StockMutationData) => (
        <div
          style={{
            whiteSpace: "normal",
            wordWrap: "break-word",
            maxWidth: "150px",
          }}
        >
          ${row.unit_price}
        </div>
      ),
    },
  ];
  return (
    <div className="container my-2 w-100">
      <div className="w-100">
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({
            values,
            handleChange,
            handleSubmit,
            isSubmitting,
            isValid,
            setStatus,
          }) => (
            <>
              <Form onSubmit={handleSubmit}>
                <Modal.Body className="modal-xl">
                  {values.stock_type === "small-tool" && (
                    <div className="form-group mb-2">
                      <label htmlFor="stock">
                        Small Tools are limited to 1 item in stock per record.
                      </label>
                    </div>
                  )}
                  <div className="form-group mb-2">
                    <label htmlFor="stock">Vendor:</label>
                    <Field
                      as="select"
                      id="vendor"
                      name="vendor"
                      className="form-control"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        handleChange(e);
                        setVendorId(parseFloat(e.target.value));
                      }}
                      defaultValue={0}
                    >
                      <option key="0" selected>
                        Select a vendor
                      </option>
                      {vendors.map((vendor) => (
                        <option key={vendor.id} value={vendor.id}>
                          {vendor.vendor_name}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage
                      name="vendor"
                      component="div"
                      className="error-message text-danger"
                    />
                  </div>
                  <div className="form-group mb-2">
                    <label htmlFor="stock">Order Number:</label>
                    <Field
                      type="text"
                      id="orderNumber"
                      name="orderNumber"
                      className="form-control"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        handleChange(e);
                        setOrderNumber(e.target.value);
                      }}
                    />
                    <ErrorMessage
                      name="orderNumber"
                      component="div"
                      className="error-message text-danger"
                    />
                  </div>
                  <div className="form-group mb-2">
                    <label htmlFor="stock">Order Date:</label>
                    <Field
                      type="date"
                      id="orderDate"
                      name="orderDate"
                      className="form-control"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        handleChange(e);
                        setOrderDate(new Date(e.target.value));
                      }}
                    />
                    <ErrorMessage
                      name="orderDate"
                      component="div"
                      className="error-message text-danger"
                    />
                  </div>
                  <div className="form-group mb-2">
                    <label htmlFor="stock">Received Date:</label>
                    <Field
                      type="date"
                      id="receivedDate"
                      name="receivedDate"
                      className="form-control"
                      defaultValue={new Date().toISOString().slice(0, 10)}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        handleChange(e);
                        setReceivedDate(new Date(e.target.value));
                      }}
                    />
                    <ErrorMessage
                      name="receivedDate"
                      component="div"
                      className="error-message text-danger"
                    />
                  </div>
                  <div className="table-responsive">
                    <h4>Consumables: </h4>
                    <DataTable columns={columns} data={consumableRows} />
                    <h4>Small Tools:</h4>
                    <DataTable
                      columns={smallToolColumns}
                      data={smallToolRows}
                    />
                  </div>
                </Modal.Body>
                <div>
                  <div
                    style={{
                      marginTop: "20px",
                      padding: "0 10px",
                      textAlign: "right"
                    }}
                  >
                    <div>Totals:</div>
                    <div>Total Ordered: {totals.ordered.toFixed(2)}</div>
                    <div>Total Received: {totals.received}</div>
                    <div>Total Price: {totals.price.toFixed(2)}</div>
                  </div>
                </div>
                <Modal.Footer>
                  <div className="text-right">
                    <button
                      onClick={onCancel}
                      className="btn btn-sm btn-danger mx-3"
                    >
                      Cancel
                    </button>

                    <button
                      type="submit"
                      className="btn btn-sm btn-success"
                      disabled={!(!isSubmitting && isValid)}
                      onClick={async () => {
                        try {
                          const stockOrder = await createStockOrder.mutateAsync(
                            {
                              comments: orderNumber,
                              date_ordered: orderDate,
                              deleted: false,
                              vendor: vendors.find(
                                (vendor) => vendor.id === vendorId
                              ) as VendorQueryData,
                              status: "Bulk Replenished",
                            }
                          );
                          for (const row of rowData) {
                            const selectedRow = localSelectedRows.find(
                              (r) => r.id === row.id
                            );
                            if (selectedRow) {
                              console.log(
                                "selectedRow (submit): ",
                                selectedRow
                              );
                              if(row.unitPrice && row.unitPrice > 0){
                                selectedRow.unit_price = row.unitPrice;
                              }
                              selectedRow.vendor = vendorId;
                              if (row.values.stock_type === "small-tool") {
                                setAddQuantity(1);
                                setOrderQuantity(1);
                              }
                              try {
                                createStockAdjustment.mutate({
                                  comments: orderNumber,
                                  date_adjusted: new Date(),
                                  deleted: false,
                                  quantity: row.quantityReceived,
                                  stock: selectedRow,
                                });
                              } catch (error) {
                                console.log(
                                  "StockAdjustment POST Error: ",
                                  error
                                );
                                setStatus(
                                  "An error occurred while creating StockAdjustment."
                                );
                              }
                              console.log("vendor: ", values.vendor);
                              console.log(
                                "vendorSearch: ",
                                vendors.find(
                                  (vendor) => vendor.id === values.vendor
                                ) as VendorQueryData
                              );
                              try {
                                createStockOrderDetail.mutate({
                                  stock_order: stockOrder.data,
                                  stock: selectedRow,
                                  date_received: row.receivedDate ?? new Date(),
                                  quantity_ordered: row.orderQuantity,
                                  quantity_received: row.quantityReceived,
                                  unit_price: row.unitPrice,
                                  note: notes,
                                });
                              } catch (error) {
                                console.log(
                                  "StockOrderDetail POST Error: ",
                                  error
                                );
                                setStatus(
                                  "An error occurred while creating StockOrderDetail."
                                );
                              }
                              if (selectedRow.stock_type !== "small-tool") {
                                selectedRow.quantity_on_hand += Number(
                                  row.quantityReceived
                                );
                                selectedRow.active = true;
                                selectedRow.stock_disposition = "active";
                              } else {
                                selectedRow.serial = row.serial;
                                selectedRow.quantity_on_hand = 1;
                                selectedRow.vendor = values.vendor;
                                if (row.make.length > 0) {
                                  selectedRow.make = row.make;
                                }
                                if (row.model.length > 0) {
                                  selectedRow.model = row.model;
                                }
                                if (row.rental_rate > 0) {
                                  selectedRow.rental_rate = row.rental_rate;
                                }
                                if (row.ycc_serial.length > 0) {
                                  selectedRow.ycc_serial = row.ycc_serial;
                                }
                              }
                              updateStock.mutate([
                                selectedRow.id as number,
                                selectedRow,
                              ]);
                              onSubmit(selectedRow);
                            }
                          }
                        } catch (error) {
                          setStatus(
                            "An error occurred during form submission."
                          );
                        }
                      }}
                    >
                      Save
                    </button>
                  </div>
                </Modal.Footer>
              </Form>
            </>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ReplenishMultipleForm;
