import React, { useEffect, useState } from "react";
import { apiClient } from "../../apis/client";
import Layout from "../../components/Layout";
import BasicFilterTable from "../../components/BasicFilterTable";
import { VendorQueryData } from "../../apis/interfaces/common";
import { vendorQueryAll } from "../../apis/queries/common";
import { useQuery } from "@tanstack/react-query";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../styles/print.css";

interface FormData {
  vendor: string;
}

interface ReportData {
  id: number;
  vendor__vendor_name: string;
  vendor__active: boolean;
  stock: string;
  quantity_on_hand: number;
  under_by: number;
  reorder_quantity: number;
  reorder_point: number;
}

function ReorderRequiredReport() {
  const [formData, setFormData] = useState<FormData | null>(null);
  const [reportData, setReportData] = useState<ReportData[] | null>(null);
  const [vendors, setVendors] = useState<VendorQueryData[]>([]);
  const vendorQuery = useQuery(vendorQueryAll());
  useEffect(() => {
    if (!vendorQuery.isLoading) {
      setVendors(vendorQuery.data);
    }
  }, [vendorQuery.data, vendorQuery.isLoading]);
  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    toast.info("Generating report");

    const data = {
      vendor: formData?.vendor,
    };
    try {
      const response = await apiClient.post(
        "/invitems/reports/ReorderRequired/filter/",
        data
      );
      setReportData(response.data);
      toast.success("Report loaded.");
    } catch (error) {
      console.error("Error submitting report:", error);
    }
  };
  const handleExportExcel = async (event: React.FormEvent) => {
    event.preventDefault();
    toast.info("Generating report");

    const data = {
      vendor: formData?.vendor,
    };
    try {
      const response = await apiClient.post(
        "/invitems/reports/ReorderRequired/export_excel/",
        data,
        { responseType: "blob" } // Set the responseType to 'blob' to receive a Blob object in the response
      );
      const blob = await response.data;
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = `Reorder Required Report - ${new Date().toLocaleString()}.xlsx`;
      link.click();
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error fetching report data:", error);
      toast.error("An error occurred generating the report");
    }
  };
  const filterFn = (keyword: string, item: ReportData) => {
    const lowercaseKeyword = keyword.toLowerCase();
    const itemValues = Object.values(item);

    for (const value of itemValues) {
      if (typeof value === "string") {
        const lowercaseValue = value.toLowerCase();

        if (lowercaseValue.includes(lowercaseKeyword)) {
          return true;
        }
      }
    }

    return false;
  };

  const filterButtons = <></>;
  const columns = [
    {
      name: "Vendor",
      sortable: true,
      selector: (row: ReportData) => `${row.vendor__vendor_name}${row.vendor__active ? ' - Inactive' : ''}`,
      wrap:true,
      width: "28%"

    },
    {
      name: "Item ID",
      sortable: true,
      selector: (row: ReportData) => row.id,
      width:"6%",
    },
    {
      name: "Item Description",
      sortable: true,
      selector: (row: ReportData) => row.stock,
      wrap:true,
      width: "32%"
    },
    {
      name: "Reorder Point",
      sortable: true,
      selector: (row: ReportData) => row.reorder_point,
      width:"8%",

    },
    {
      name: "Qty on Hand",
      sortable: true,
      selector: (row: ReportData) => row.quantity_on_hand,
      width:"8%",

    },
    {
      name: "Under by",
      sortable: true,
      selector: (row: ReportData) => row.under_by,
      width:"8%",

    },
    {
      name: "Reorder Qty",
      sortable: true,
      selector: (row: ReportData) => row.reorder_quantity,
      width:"8%",

    },
  ];
  return (
    <div>
      <Layout>
      <div id="report-title-container" className="hide-printable">

        <ToastContainer />
        <h2>Reorder Required Report</h2>
        <br></br>
        <h4>REPORT PARAMETERS</h4>
        <form onSubmit={handleSubmit}>
          <label>Vendor:</label> <br />
          <select
            name="vendor"
            className="form-control"
            onChange={(e) => setFormData({ vendor: e.target.value })}
          >
            <option value="">All Vendors</option>
            {vendors.filter(Boolean).map((item: VendorQueryData) => (
              <option key={item.id} value={item.id}>
                {item.vendor_name}
              </option>
            ))}
          </select>
          <button type="submit" className="btn btn-sm btn-success mt-2 button-margin">
            Generate Filtered Report
          </button>
          <button
            type="submit"
            className="btn btn-sm btn-success mt-2 button-margin"
            onClick={handleExportExcel}
          >
            Export to Excel
          </button>
          <button
            className="btn btn-sm btn-success mt-2  button-margin"
            onClick={() => window.print()}
          >
            Print/Export PDF
          </button>
        </form>

        <br />
        <br />
      </div>

      <div className="report-data-printable">

        {reportData && (
          <BasicFilterTable
            tableKey="ReorderRequired"
            title="Reorder Required Report Results"
            dataSource={reportData as ReportData[]}
            columns={columns}
            filterFn={filterFn}
            filterButtons={filterButtons}
            exportFileName={"Reorder Required Export Data.csv"}
          />
        )}

        </div>
      </Layout>
    </div>
  );
}

export default ReorderRequiredReport;
