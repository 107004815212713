import * as React from "react";
import { ReactElement, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import type {
  StockIssueDetailQueryData,
  StockIssueQueryData,
  StockChargeQueryData,
  TicketDashboardSearchItem
} from "../../apis/interfaces/tickets";
import BasicFilterTable from "../BasicFilterTable";

import FilterTicketModal from "../filters/TicketFilter";
import { useTicketDashboardQueryAll } from "../../pages/Tickets/Logic/MutationsAndQueries";
import { useQuery } from "@tanstack/react-query";
import { CompanyQueryData, JobQueryData } from "../../apis/interfaces/common";
import { companyQueryAll, jobQueryAll } from "../../apis/queries/common";
import { debounce } from "lodash";

const { DateTime } = require("luxon");
interface FilterDataProps {
  status: string;
  job: number;
  company: number;
  requested_by: number;
  date_submitted: Date | null;
  textsearch?: string;
}

interface FilterJsonIntermediateProps {
  status?: string;
  job?: number;
  company?: number;
  companies?: Array<number>;
  requested_by?: number;
  date_submitted?: Date;
  textsearch?: string;
}

interface TicketDashboardProps {
  limit: number;
  offset: number;
  sortField: string;
  sortDirection: string;
  onSetLimit: Function;
  onSetOffset: Function;
  onSetSort: Function;
  onCreateTicket: Function;
}

const TicketDashboard = (props: TicketDashboardProps) => {
  const {
    limit,
    offset,
    sortField,
    sortDirection,
    onSetLimit,
    onSetOffset,
    onCreateTicket,
    onSetSort,
  } = props;
  const [filterJson, setFilterJson] = useState<string>("");
  const ticketQuery = useTicketDashboardQueryAll(
    limit,
    offset,
    sortField,
    sortDirection,
    filterJson
  );
  const companyQuery = useQuery(companyQueryAll());
  const jobs = useQuery(jobQueryAll());

  const [showFilter, setShowFilter] = useState(false);
  const [filterExplainer, setFilterExplainer] = useState<
    ReactElement | undefined
  >(undefined);
  const [noResults, setNoResults] = useState(false);
  const [companies, setCompanies] = useState<CompanyQueryData[]>([]);
  const [clearTextFilter, setClearTextFilter] = useState(false);

  React.useEffect(() => {
    if (companyQuery && companyQuery.data) {
      setCompanies(companyQuery.data);
    }
  }, [companyQuery.data]);
  const calcRowCost = (items: Array<StockIssueDetailQueryData>) => {
    return items
      .filter((item: StockIssueDetailQueryData) => item.active)
      .reduce((accumulator: number, row: StockIssueDetailQueryData) => {
        return row.stock && row.stock.stock_type && row.stock.stock_type === 'consumable' ? accumulator + row.unit_price * row.quantity : accumulator;
      }, 0);
  };
  const calcPerDayRowCost = (items: Array<StockIssueDetailQueryData>) => {
    return items
      .filter((item: StockIssueDetailQueryData) => item.active)
      .reduce((accumulator: number, row: StockIssueDetailQueryData) => {
        return row.stock && row.stock.stock_type && row.stock.stock_type === 'small-tool' ? accumulator + row.stock.rental_rate * 8 * row.quantity : accumulator;
      }, 0);
  };
  const calcMiscChargeCost = (row: TicketDashboardSearchItem): ReactElement|null => {
    // Calculates misc charge costs.

    if(Number(row.rent_charge_total) === 0 && Number(row.nonrent_charge_total) === 0) {
      return null;
    }

    return <>
      Charges:<br />
      {row.rent_charge_total > 0 ? <>{Number(row.rent_charge_total).toLocaleString('en-US', { style: 'currency', currency: 'USD' })} rental<br /></> : null}
      {row.nonrent_charge_total > 0 ? <>{Number(row.nonrent_charge_total).toLocaleString('en-US', { style: 'currency', currency: 'USD' })} misc<br /></> : null}      
    </>
  };
  const calcMiscChargeCount = (items: Array<StockChargeQueryData>): ReactElement|null => {
    // Calculates misc charge amounts.

    if(items.length > 0) {
      let chargeCount = Number(0);

      items.forEach((item: StockChargeQueryData) => {
        // Just consider purchases for non-stock items (NSTK) for now?
        if(item.charge?.charge_number === 'NSTK') {
          chargeCount++
        }
      })

      return chargeCount > 0 ? <><br />Non-stock: {chargeCount}</> : null;
    }

    return null;
  };

  const hndFilterOpen = () => {
    setShowFilter(true);
  };
  const hndFilterClose = () => {
    setShowFilter(false);
  };
  const hndFilterClear = () => {
    setFilterJson(JSON.stringify({}));
    setClearTextFilter(true);
    onSetOffset(0);
  };
  const formattedFilterExplainer = React.useMemo(() => {
    try {
      const filterData = filterJson.length > 0 ? JSON.parse(filterJson) : {};

      const explainerRow: Array<string> = [];

      if (filterData.status?.length) {
        explainerRow.push(`Status ${filterData.status}`);
      }

      if (filterData.job && jobs.data) {
        const job = jobs.data.find(
          (elem: JobQueryData) => elem.id === filterData.job
        );

        explainerRow.push(`Job ${job.job}`);
      }

      if (filterData.company && companyQuery.data) {
        const company = companyQuery.data.find(
          (elem: CompanyQueryData) => elem.id === filterData.company
        );

        explainerRow.push(`Company ${company.company}`);
      }

      if (filterData.date_submitted?.length) {
        explainerRow.push(`Date Submitted ${filterData.date_submitted}`);
      }

      if (filterData.textsearch?.length) {
        explainerRow.push(`Text "${filterData.textsearch}"`);
      }

      let clearButton = <></>;

      if (explainerRow.length > 0) {
        clearButton = (
          <>
            {" "}
            -{" "}
            <a className="btn-link" onClick={hndFilterClear}>
              Clear
            </a>
          </>
        );
      }

      setFilterExplainer(<>{explainerRow.join(", ")}</>);
    } catch (e) {
      setFilterExplainer(<></>);
    }
  }, [filterJson, jobs.data, companyQuery.data]);
  const hndFilterApply = (filterData: FilterDataProps) => {
    let newFilterJson: FilterJsonIntermediateProps = {};

    // Preserve existing textsearch if it exists
        try {
      let existingFilters = JSON.parse(filterJson);
            if (existingFilters.textsearch) {
        newFilterJson.textsearch = existingFilters.textsearch;
      }
    } catch (e) {
      console.log("Error applying filter: ", e);
    }

    // Map other filterData fields to newFilterJson
    if (filterData.company) newFilterJson.company = filterData.company;
    if (filterData.job) newFilterJson.job = filterData.job;
    if (filterData.status) newFilterJson.status = filterData.status;
    if (filterData.requested_by)
      newFilterJson.requested_by = filterData.requested_by;
    if (filterData.date_submitted)
      newFilterJson.date_submitted = filterData.date_submitted;

    setFilterJson(JSON.stringify(newFilterJson));
onSetOffset(0);
  };

  const columns = [
    {
      name: "ID",
      sortable: true,
      sortField: "id",
      selector: (row: TicketDashboardSearchItem) => row.id,
      maxWidth: "2%",
    },
    {
      name: "Job and Companies",
      sortable: true,
      sortField: "job",
      cell: (row: TicketDashboardSearchItem) => {
        let job = "";
        let jobNum = "";
        let rowCompanies = "";

        // add job name
        if (row.job) {
          job = row.job;
          jobNum = row.job_number;
        }

        // add companies
        if (row.companies) {          
          rowCompanies = row.companies;
        }

        return (
          <div>
            <strong>
              {job} {jobNum}
            </strong>
            <div>{rowCompanies}</div>
          </div>
        );
      },
    },
    {
      name: "Status",
      sortable: true,
      sortField: "status",
      selector: (row: TicketDashboardSearchItem) =>
        row.status !== "NULL" ? row.status : "",
    },
    {
      name: "Date Created",
      sortable: true,
      sortField: "date_issued",
      selector: (row: TicketDashboardSearchItem) =>
        row.date_issued ? DateTime.fromISO(row.date_issued).toLocaleString() : "",
    },
    {
      name: "Last Delivery",
      sortable: true,
      sortField: "last_delivery_date",
      selector: (row: TicketDashboardSearchItem) => {
        return row.last_delivery_date ? DateTime.fromISO(row.last_delivery_date).toLocaleString() : "";
      },
    },

    {
      name: "Item Count",
      sortable: false,
      sortField: "item_count",
      selector: (row: TicketDashboardSearchItem) => row.item_count
    },
    {
      name: "Item Cost",
      sortable: false,
      cell: (row: TicketDashboardSearchItem) => (
        <>
          {
            Number(row.total_consumable_cost || 0 + row.total_consumable_tax || 0 + row.total_freight || 0).toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })}
          {row.total_per_day_cost > 0
            ? <><br />${row.total_per_day_cost.toLocaleString("en-US", { style: "currency", currency: "USD" })}/day</>
            : ""}
          <br />{calcMiscChargeCost(row)}
        </>
      ),
    },
    {
      name: "Actions",
      cell: (row: TicketDashboardSearchItem) => (
        <>
          <Link
            className="btn btn-sm mr-auto ml-auto btn-secondary ticket-button text-nowrap"
            to={`/ticket/${row.id}`}
          >
            Open
          </Link>
        </>
      ),
    },
  ];

  const filterButtons = (
    <>
      <button className="btn btn-link" onClick={hndFilterOpen}>
        Filter Results
      </button>
    </>
  );

  const headerButtons = (
    <>
      <button
        className="btn btn-primary btn-sm"
        onClick={() => {
          onCreateTicket();
        }}
      >
        Create Ticket
      </button>
    </>
  );

  const hndOnChangeOffset = (value: number) => {
    onSetOffset(value);
  };

  const hndOnChangeLimit = (value: number) => {
    onSetLimit(value);
  };

  const hndSetSearchTerm = (term: string) => {
    debouncedSetSearchTerm(term);
  };

  const debouncedSetSearchTerm = React.useCallback(
    debounce((term) => {
      let originalFilter = {};
      let updatedFilter = {};
  
      const trimmedTerm = term.trim();
  
      if (filterJson) {
        try {
          originalFilter = JSON.parse(filterJson);
          if (typeof originalFilter === "object" && originalFilter !== null) {
            updatedFilter = { ...originalFilter, textsearch: trimmedTerm };
          } else {
            updatedFilter = { textsearch: trimmedTerm };
          }
        } catch (e) {
          console.log("Parsing of filterJson failed: ", e);
          updatedFilter = { textsearch: trimmedTerm };
        }
      } else {
        updatedFilter = { textsearch: trimmedTerm };
      }
  
      const originalFilterStr = JSON.stringify(originalFilter);
      const updatedFilterStr = JSON.stringify(updatedFilter);
  
      if (originalFilterStr !== updatedFilterStr) {
        setFilterJson(updatedFilterStr);
        onSetOffset(0);
      }
    }, 300),
    [filterJson, onSetOffset]
  );
  

  const hndTextFilterCleared = () => {
    console.log("setting the clear flag to false");
    setClearTextFilter(false);
  };

  const hndChangeSort = (column: any, direction: any) => {
    onSetSort(
      column.sortField ? column.sortField : "",
      direction === "asc" ? "asc" : "desc"
    );
  };

  const filteredDataSource = React.useMemo(() => {
    if (noResults) {
      return [];
    } else if (ticketQuery.data?.results) {
      return ticketQuery.data.results;
    } else {
      return [];
    }
  }, [ticketQuery.data, noResults, filterJson]); // Include filterJson in dependencies if it's used for filtering

  useEffect(() => {
    ticketQuery.refetch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, offset, sortField, sortDirection, filterJson]);

  return ticketQuery.data?.results ? (
    <>
      <BasicFilterTable
        tableKey="ticket-dashboard"
        title="Tickets"
        dataSource={filteredDataSource}
        columns={columns}
        filterButtons={filterButtons}
        filterHnd={hndSetSearchTerm}
        buttons={headerButtons}
        exportFileName="Ticket Export.csv"
        filterExplainer={filterExplainer}
        tableCount={ticketQuery.data?.count}
        limit={limit}
        offset={offset}
        onChangeLimit={hndOnChangeLimit.bind(this)}
        onChangeOffset={hndOnChangeOffset.bind(this)}
        isLoading={ticketQuery.isLoading}
        onFilterCleared={hndTextFilterCleared}
        clearFilterFlag={clearTextFilter}
        onChangeSort={hndChangeSort.bind(this)}
      />
      <FilterTicketModal
        show={showFilter}
        onClose={hndFilterClose}
        onApply={hndFilterApply}
        onClear={hndFilterClear}
        setShowFilterTicketModal={setShowFilter}
      />
    </>
  ) : null;
};

export default TicketDashboard;
