import type {
  StockIssueQueryData,
  StockIssueStatusMutationData,
  TicketTransfer,
} from "../interfaces/tickets";
import {
  StockChargeMutationData,
  StockIssueDetailMutationData,
  StockIssueMutationData,
  TicketSubmit,
} from "../interfaces/tickets";

import { apiClient } from "../client";
import { AxiosResponse } from "axios";

// #region StockCharge Mutations
export const createStockChargeMutation = {
  queryKey: ["stockChargeQuery"],
  mutationFn: (data: StockChargeMutationData) => {
    return apiClient.post("/tickets/stock_charge/", data);
  },
};

export const updateStockChargeMutation = {
  queryKey: ["stockChargeQuery"],
  mutationFn: (id: number, data: StockChargeMutationData) => {
    return apiClient.patch(`/tickets/stock_charge/${id}/`, data);
  },
};

export const deleteStockChargeMutation = {
  queryKey: ["stockChargeQuery"],
  mutationFn: (id: number) => {
    return apiClient.delete(`/tickets/stock_charge/${id}/`);
  },
};
// #endregion

// #region StockIssueDetail Mutations
export const createStockIssueDetailMutation = {
  queryKey: ["stockIssueDetailQuery"],
  mutationFn: (data: StockIssueDetailMutationData) => {
    return apiClient.post("/tickets/stock_issue_detail/", data);
  },
};

export const updateStockIssueDetailMutation = {
  queryKey: ["stockIssueDetailQuery"],
  mutationFn: (id: number, data: StockIssueDetailMutationData) => {
    return apiClient.patch(`/tickets/stock_issue_detail/${id}/`, data);
  },
};
export const updateSIDCostCodeMutation = {
  queryKey: ["stockIssueDetailQuery"],
  mutationFn: (id: number, data: number) => {
    return apiClient.patch(`/tickets/v1/stock_issue_detail/update_costcode/${id}/`, data);
  },
};

export const deleteStockIssueDetailMutation = {
  queryKey: ["stockIssueDetailQuery"],
  mutationFn: (id: number) => {
    return apiClient.delete(`/tickets/stock_issue_detail/${id}/`);
  },
};
// #endregion

// #region StockIssue Mutations
export const createStockIssueMutation = {
  queryKey: ["stockIssueQuery"],
  mutationFn: (data: StockIssueMutationData) => {
    return apiClient.post("/tickets/stock_issue/", data);
  },
};

export const updateStockIssueMutation = {
  queryKey: ["stockIssueQuery"],
  mutationFn: (id: number, data: StockIssueMutationData) => {
    return apiClient.patch(`/tickets/stock_issue/${id}/`, data);
  },
};

export const deleteStockIssueMutation = {
  queryKey: ["stockIssueQuery"],
  mutationFn: (id: number) => {
    return apiClient.delete(`/tickets/stock_issue/${id}/`);
  },
};

export const generateStockIssueChargesMutation = {
  queryKey: ["stockIssueQuery"],
  mutationFn: (id: number) => {
    return apiClient.post(`/tickets/v1/stock_issue/generate_charges/${id}/`)
  },
}
// #endregion

export const submitTicketMutation = {
  queryKey: ["stockIssueQuery"],
  mutationFn: (
    data: TicketSubmit
  ): Promise<AxiosResponse<StockIssueQueryData>> => {
    return apiClient.post<StockIssueQueryData>(
      "/tickets/v1/stock_issue/submit/",
      data
    );
  },
};
export const markTicketStatusMutation = {
  queryKey: ["stockIssueQuery"],
  mutationFn: (id: number, data: StockIssueStatusMutationData) => {
    return apiClient.patch(`/tickets/stock_issue/${id}/`, data);
  },
};
export const transferTicketMutation = {
  queryKey: ["stockIssueQuery"],
  mutationFn: (
    data: TicketTransfer
  ): Promise<AxiosResponse<StockIssueQueryData>> => {
    return apiClient.post<StockIssueQueryData>(
      "/tickets/v1/stock_issue/transfer/",
      data
    );
  },
};
