// noinspection DuplicatedCode

import * as Yup from "yup";
import { ErrorMessage, Field, Form, Formik, FormikHelpers } from "formik";
import type {
  StockCategoryQueryData,
  StockMutationData,
  UnitOfMeasureQueryData,
} from "../apis/interfaces/invitems";
import { Modal } from "react-bootstrap";
import {
  stockCategoryQueryAll,
  stockQueryAll,
  unitOfMeasureQueryAll,
} from "../apis/queries/invitems";
import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { vendorQueryAll } from "../apis/queries/common";
import { VendorQueryData } from "../apis/interfaces/common";
import {
  createStockAdjustmentMutation,
  createStockOrderDetailMutation,
  createStockOrderMutation,
  updateStockMutation,
} from "../apis/mutations/invitems";

interface ReplenishFormProps {
  initialValues: StockMutationData;
  onSubmit: any;
  onCancel: any;
}

const validationSchema = Yup.object({
  stock: Yup.string().required(),
  quantity_added: Yup.number()
    .test("quantityValidation", "Quantity is required", function (value) {
      if (this.parent.isQuantityEnabled) {
        return Yup.number().required().isValidSync(value);
      }
      return true;
    })
    .test(
      "smallToolValidation",
      "Quantity cannot exceed 1 when type is small tool.",
      function () {
        const type = this.parent.stock_type;
        return !(type === "small-tool" && this.parent.quantity_on_hand > 1);
        // Validation passes
      }
    ),
  serial: Yup.string(),
  orderDate: Yup.date(),
  receivedDate: Yup.date(),

  order_number: Yup.string().required(),
  total_price: Yup.number(),
  unit_of_measure: Yup.number().required(),
  vendor: Yup.number().required(),
  note: Yup.string(),
});

const ReplenishForm = (props: ReplenishFormProps) => {
  const { initialValues, onSubmit, onCancel } = props;
  const [, setCats] = useState<StockCategoryQueryData[]>([]);
  const [units, setUnits] = useState<UnitOfMeasureQueryData[]>([]);
  const [vendors, setVendors] = useState<VendorQueryData[]>([]);
  const [vendorNum, setVendor] = useState<number>(Number(initialValues.vendor));
  const [notes, setNotes] = useState("");
  const [orderQuantity, setOrderQuantity] = useState(0);
  const [addQuantity, setAddQuantity] = useState(0);
  const [orderNumber, setOrderNumber] = useState(""); //This will go into StockOrder.Comments
  const [unitPrice, setunitPrice] = useState(0);
  const [orderDate, setOrderDate] = useState(new Date()); //This will go into StockOrder.Comments
  const [receivedDate, setReceivedDate] = useState(new Date()); //This will go into StockOrder.Comments
  const [models, setModels] = useState<string[]>([]);
  const [makes, setMakes] = useState<string[]>([]);
  const [, setMake] = useState<string>("");
  const [, setModel] = useState<string>("");
  const { data } = useQuery(stockCategoryQueryAll());
  const { data: unit_data } = useQuery(unitOfMeasureQueryAll());
  const { data: vendor_data } = useQuery(vendorQueryAll());

  const createStockAdjustment = useMutation(createStockAdjustmentMutation);
  const createStockOrder = useMutation(createStockOrderMutation);
  const createStockOrderDetail = useMutation(createStockOrderDetailMutation);
  const { data: stockItems } = useQuery(stockQueryAll());
  const updateStockMutationFn = async (params: [number, StockMutationData]) => {
    const [id, data] = params;

    try {
      await updateStockMutation.mutationFn(id, data);
    } catch (error) {
      console.log("Stock PATCH error: ", error);
    }
  };

  const updateStock = useMutation(updateStockMutationFn);
  useEffect(() => {
    if (data) {
      setCats(data);
    }
    if (unit_data) {
      setUnits(unit_data);
    }
    if (vendor_data) {
      setVendors(vendor_data);
    }
    if (stockItems) {
      const stockItemsArray = Object.values(stockItems);
      const uniqueModels = Array.from(
        new Set(
          stockItemsArray
            .filter((item: any) => item && item.stock_type === "small-tool")
            .map((item: any) => item.model)
        )
      );
      const uniqueMakes = Array.from(
        new Set(
          stockItemsArray
            .filter((item: any) => item && item.stock_type === "small-tool")
            .map((item: any) => item.make)
        )
      );
      setModels(uniqueModels as string[]);
      setMakes(uniqueMakes as string[]);
    }
  }, [data, stockItems, unit_data, vendor_data]);

  const handleSubmit = (
    values: StockMutationData,
    { setStatus }: FormikHelpers<StockMutationData>
  ) => {
    try {
      validationSchema.validateSync(values, { abortEarly: false });
    } catch (errors) {
      setStatus("Issue validating schema.");
    }
  };
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      //validationSchema={validationSchema}
    >
      {({
        values,
        handleChange,
        handleSubmit,
        isSubmitting,
        isValid,
        setStatus,
      }) => (
        <>
          <Form onSubmit={handleSubmit}>
            <Modal.Body>
              {values.stock_type === "small-tool" && (
                <div className="form-group mb-2">
                  <label htmlFor="stock">
                    Small Tools are limited to 1 item in stock per record.
                  </label>
                </div>
              )}
              <div className="form-group mb-2">
                <label htmlFor="stock">Stock Name:</label>
                <Field
                  type="text"
                  id="stock"
                  name="stock"
                  className="form-control"
                  onChange={handleChange}
                  disabled={true}
                />
                <ErrorMessage
                  name="stock"
                  component="div"
                  className="error-message text-danger"
                />
              </div>
              <div className="form-group mb-2">
                <label htmlFor="stock">Vendor:</label>
                <Field
                  as="select"
                  id="vendor"
                  name="vendor"
                  className="form-control"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    handleChange(e);
                    setVendor(Number(e.target.value));
                  }}
                >
                  <option value="0">Select a vendor</option>
                  {vendors.map((vendor) => (
                    <option key={vendor.id} value={vendor.id}>
                      {vendor.vendor_name}
                    </option>
                  ))}
                </Field>
                <ErrorMessage
                  name="vendor"
                  component="div"
                  className="error-message text-danger"
                />
              </div>
              {values.stock_type === "small-tool" && (
                <div className="form-group mb-2">
                  <label htmlFor="stock">Serial:</label>
                  <Field
                    type="text"
                    id="serial"
                    name="serial"
                    className="form-control"
                    onChange={handleChange}
                  />
                  <ErrorMessage
                    name="serial"
                    component="div"
                    className="error-message text-danger"
                  />
                </div>
              )}
              {values.stock_type === "small-tool" && (
                <div className="form-group mb-2">
                  <label htmlFor="stock">YCC Serial:</label>
                  <Field
                    type="text"
                    id="ycc_serial"
                    name="ycc_serial"
                    className="form-control"
                    onChange={handleChange}
                  />
                  <ErrorMessage
                    name="ycc_serial"
                    component="div"
                    className="error-message text-danger"
                  />
                </div>
              )}
              {values.stock_type === "small-tool" && (
                <div className="form-group mb-2">
                  <label htmlFor="stock">Make:</label>
                  <Field
                    as="select"
                    id="make"
                    name="make"
                    className="form-control"
                    onChange={handleChange}
                  >
                    <option value="">Select a make</option>
                    {makes.filter(Boolean).map((make) => (
                      <option
                        key={make}
                        value={make}
                        selected={make === values.make}
                      >
                        {make}
                      </option>
                    ))}
                    <option value="other">Other</option>
                  </Field>
                  <ErrorMessage
                    name="make"
                    component="div"
                    className="error-message text-danger"
                  />
                </div>
              )}
              {values.make === "other" && (
                <div className="form-group mb-2">
                  <label htmlFor="modelOther">Other Make:</label>
                  <Field
                    type="text"
                    id="makeOther"
                    name="makeOther"
                    className="form-control"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      handleChange(e);
                      setMake(e.target.value);
                    }}
                  />
                  <ErrorMessage
                    name="makeOther"
                    component="div"
                    className="error-message text-danger"
                  />
                </div>
              )}
              {values.stock_type === "small-tool" && (
                <div className="form-group mb-2">
                  <label htmlFor="stock">Model:</label>
                  <Field
                    as="select"
                    id="model"
                    name="model"
                    className="form-control"
                    onChange={handleChange}
                  >
                    <option value="">Select a model</option>
                    {models.filter(Boolean).map((model) => (
                      <option
                        key={model}
                        value={model}
                        selected={model === values.model}
                      >
                        {model}
                      </option>
                    ))}
                    <option value="other">Other</option>
                  </Field>
                  <ErrorMessage
                    name="model"
                    component="div"
                    className="error-message text-danger"
                  />
                </div>
              )}
              {values.model === "other" && (
                <div className="form-group mb-2">
                  <label htmlFor="modelOther">Other Model:</label>
                  <Field
                    type="text"
                    id="modelOther"
                    name="modelOther"
                    className="form-control"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      handleChange(e);
                      setModel(e.target.value);
                    }}
                  />
                  <ErrorMessage
                    name="modelOther"
                    component="div"
                    className="error-message text-danger"
                  />
                </div>
              )}
              {values.stock_type === "small-tool" && (
                <div className="form-group mb-2">
                  <label htmlFor="stock">Rental Rate (hourly):</label>
                  <Field
                    type="number"
                    id="rental_rate"
                    name="rental_rate"
                    className="form-control"
                    onChange={handleChange}
                  />
                  <ErrorMessage
                    name="rental_rate"
                    component="div"
                    className="error-message text-danger"
                  />
                </div>
              )}
              <div className="form-group mb-2">
                <label htmlFor="stock">Order Date:</label>
                <Field
                  type="date"
                  id="orderDate"
                  name="orderDate"
                  className="form-control"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    handleChange(e);
                    setOrderDate(new Date(e.target.value));
                  }}
                />
                <ErrorMessage
                  name="orderDate"
                  component="div"
                  className="error-message text-danger"
                />
              </div>
              <div className="form-group mb-2">
                <label htmlFor="stock">Received Date:</label>
                <Field
                  type="date"
                  id="receivedDate"
                  name="receivedDate"
                  className="form-control"
                  defaultValue={new Date().toISOString().slice(0, 10)}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    handleChange(e);
                    setReceivedDate(new Date(e.target.value));
                  }}
                />
                <ErrorMessage
                  name="receivedDate"
                  component="div"
                  className="error-message text-danger"
                />
              </div>
              <div className="form-group mb-2">
                <label htmlFor="stock">Order Number:</label>
                <Field
                  type="text"
                  id="orderNumber"
                  name="orderNumber"
                  className="form-control"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    handleChange(e);
                    setOrderNumber(e.target.value);
                  }}
                />
                <ErrorMessage
                  name="orderNumber"
                  component="div"
                  className="error-message text-danger"
                />
              </div>

              {values.stock_type !== "small-tool" && (
                <div className="form-group mb-2">
                  <label htmlFor="stock">Quantity Ordered:</label>
                  <Field
                    type="text"
                    id="orderQuantity"
                    name="orderQuantity"
                    className="form-control is-invalid"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      handleChange(e);
                      setOrderQuantity(parseInt(e.target.value, 10));
                    }}
                  />
                  <ErrorMessage
                    name="orderQuantity"
                    component="div"
                    className="error-message text-danger"
                  />
                </div>
              )}
              {values.stock_type !== "small-tool" && (
                <div className="form-group mb-2">
                  <label htmlFor="stock">Quantity Received/Added:</label>
                  <Field
                    type="text"
                    id="addQuantity"
                    name="addQuantity"
                    className="form-control is-invalid"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      handleChange(e);
                      setAddQuantity(parseInt(e.target.value, 10));
                    }}
                  />
                  <ErrorMessage
                    name="addQuantity"
                    component="div"
                    className="error-message text-danger"
                  />
                </div>
              )}
              <div className="form-group mb-2">
                <label htmlFor="stock">Unit Price:</label>
                <Field
                  type="text"
                  id="unitPrice"
                  name="unitPrice"
                  className="form-control"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    handleChange(e);
                    setunitPrice(Number(Number(e.target.value).toFixed(2)));
                  }}
                  defaultValue={values.unit_price}
                />
                <ErrorMessage
                  name="unitPrice"
                  component="div"
                  className="error-message text-danger"
                />
              </div>

              <div className="form-group mb-2">
                <label htmlFor="stock">Unit Of Measure:</label>
                <Field
                  as="select"
                  id="unit_of_measure"
                  name="unit_of_measure"
                  className="form-control"
                >
                  <option value="">Select a unit</option>
                  {units.map((unit) => (
                    <option key={unit.id} value={unit.id}>
                      {unit.unit_of_measure}
                    </option>
                  ))}
                </Field>
              </div>
              <div className="form-group mb-2">
                <label htmlFor="stock">Note:</label>
                <Field
                  type="text"
                  id="notes"
                  name="notes"
                  className="form-control"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    handleChange(e);
                    setNotes(e.target.value);
                  }}
                />
                <ErrorMessage
                  name="notes"
                  component="div"
                  className="error-message text-danger"
                />
              </div>
              <div className="form-check mb-2">
                <Field
                  type="checkbox"
                  id="active"
                  name="active"
                  className="form-check-input"
                  onChange={handleChange}
                />
                <label className="form-check-label" htmlFor="active">
                  Active
                </label>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="text-right">
                <button
                  onClick={onCancel}
                  className="btn btn-sm btn-danger mx-3"
                >
                  Cancel
                </button>

                <button
                  type="submit"
                  className="btn btn-sm btn-success"
                  disabled={!(!isSubmitting && isValid)}
                  onClick={async () => {
                    try {
                      if (values.stock_type === "small-tool") {
                        setAddQuantity(1);
                        setOrderQuantity(1);
                        values.reorder_point = 0;
                        values.reorder_quantity = 1;
                      }
                      // Create StockAdjustment
                      createStockAdjustment.mutate({
                        comments: orderNumber,
                        date_adjusted: new Date(),
                        deleted: false,
                        quantity: addQuantity,
                        stock: values,
                      });

                      // Generate StockOrder
                      const stockOrder = await createStockOrder.mutateAsync({
                        comments: orderNumber,
                        date_ordered: orderDate,
                        deleted: false,
                        vendor: vendors.find(
                          (vendor) => vendor.id === Number(vendorNum)
                        ) as VendorQueryData,
                        status: "Replenished",
                      });
                      let smallToolQuant = 1;
                      if (values.stock_type !== "small-tool") {
                        values.quantity_on_hand =
                          Number(addQuantity) + Number(values.quantity_on_hand);
                          if(unitPrice && unitPrice > 0){
                            values.unit_price = unitPrice;
                          }
                          values.vendor = Number(vendorNum);
                      } else {
                        values.quantity_on_hand = smallToolQuant;
                      }

                      // Generate StockOrderDetail
                      createStockOrderDetail.mutate({
                        stock_order: stockOrder.data,
                        stock: values,
                        date_received: receivedDate,
                        quantity_ordered: orderQuantity,
                        quantity_received: addQuantity,
                        unit_price: unitPrice,
                        note: notes,
                      });
                      onSubmit(values);
                    } catch (error) {
                      setStatus(
                        "An error occurred during form submission: " + error
                      );
                    }
                    updateStock.mutate([values.id as number, values]);
                  }}
                >
                  Save
                </button>
              </div>
            </Modal.Footer>
          </Form>
        </>
      )}
    </Formik>
  );
};

export default ReplenishForm;
