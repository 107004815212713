import { useQuery } from "@tanstack/react-query";
import { logoutQuery } from "../apis/queries/authentication";
import { Link } from "react-router-dom";

const Logout = () => {
  const doLogout = useQuery(logoutQuery);

  if (doLogout.status === "success") {
    return (
      <>
        <div className="row mt-5">
          <div className="col-md-6 offset-md-3 col-sm-10 offset-sm-1">
            <div className="login-form border border-success rounded p-4 text-center">
              <div className="alert alert-success">
                You've been successfully logged out.
              </div>

              <p>
                <Link to="/login">Log back in</Link>
              </p>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="row mt-5">
        <div className="col-md-6 offset-md-3 col-sm-10 offset-sm-1">
          <div className="login-form border border-success rounded p-4 text-center">
            Please wait...
          </div>
        </div>
      </div>
    </>
  );
};

export default Logout;
