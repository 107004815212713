import { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { costCodeQueryAll } from "../apis/queries/common";
import Select from "react-select";

import type { CostCodeQueryData } from "../apis/interfaces/common";

interface CostCodeSelectorProps {
    onChange: Function;
    selectedJob: number|undefined;
    defaultValue: number|undefined;
}

const CostCodeSelector = (props: CostCodeSelectorProps) => {
    const { selectedJob, onChange, defaultValue } = props;
    const [ costcode, setCostcode ] = useState<number|undefined>(defaultValue);

    const codes = useQuery(costCodeQueryAll(-1, -1, undefined, undefined, undefined, Number(selectedJob).toString()));

    useEffect(() => {
        onChange(costcode);
    }, [ costcode ]);

    useEffect(() => {
        codes.refetch();
    }, [ selectedJob ]);

    return <>
        {codes.data && <Select
            name="cost_code_id"
            id="cost_code_id"
            className="form-control"
            options={
                Array.isArray(codes.data) 
                ? codes.data.map((elem: CostCodeQueryData) => ({
                    value: elem.id,
                    label: `${elem.short_name} - ${elem.cost_code} ${elem.cost_type} - ${elem.description}`,
                  }))
                : []
            }            
            onChange={(selectedOption: any) => setCostcode(Number(selectedOption.value))
            }
        />}
    </>;
};

export default CostCodeSelector;
