import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";

import {
  stockCategoryQueryAll,
  stockMetaQueryAll,
  stockSimilarQuery,
  stockFavoritesQuery,
  unitOfMeasureQueryAll,
} from "../apis/queries/invitems";
import { jobQueryAll } from "../apis/queries/common";
import { submitTicketMutation } from "../apis/mutations/tickets";
import CostCodeSelector from "../components/CostCodeSelector";

import type {
  StockMetaData,
  UnitOfMeasureQueryData,
  StockFavoritesData,
} from "../apis/interfaces/invitems";
import type { JobQueryData } from "../apis/interfaces/common";
import type { SelectedItem } from "../apis/interfaces/tickets";
import Loader from "../components/Loader";
import useAddItem from "../pages/Tickets/Logic/AddItemLogic";
import { AddItemForm, TicketItemListTable } from "./CreateTicketForm";
import ErrorFallback from "../components/Error";
import * as Sentry from "@sentry/react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { debounce } from "lodash";
interface AddItemToTicketFormProps {
  jobId?: number;
  stockIssue: any;
  setShowAddItemDialog: any;
}

export const calculateSelectedValue = (selectedInvItems: Array<SelectedItem>) => {
  const consumableValue = selectedInvItems.reduce(
    (accumulator: number, curValue: SelectedItem) => {
      return curValue.item.stock_type === 'consumable' ? accumulator + curValue.item.unit_price * curValue.qty : 0;
    },
    0
  );

  const rentalValue = selectedInvItems.reduce(
    (accumulator: number, curValue: SelectedItem) => {
      return curValue.item.stock_type === 'small-tool' ? accumulator + curValue.item.rental_rate * curValue.qty : 0;
    },
    0
  ) * 8;

  return `${consumableValue.toLocaleString('en-US', { style: 'currency', currency: 'USD' })} up-front and ${rentalValue.toLocaleString('en-US', { style: 'currency', currency: 'USD'})} per day`
};

const AddItemToTicketForm = (props: AddItemToTicketFormProps) => {
  const { jobId, stockIssue, setShowAddItemDialog } = props;

  const jobs = useQuery(jobQueryAll());

  const categories = useQuery(stockCategoryQueryAll());
  const stockItemsData = useQuery(stockMetaQueryAll());
  const unitsOfMeasureData = useQuery(unitOfMeasureQueryAll());
  const stockFavoritesData = useQuery(stockFavoritesQuery());

  const ticketSubmit = useMutation(submitTicketMutation);

  const [selectedInvItem, setSelectedInvItem] = useState<StockMetaData | null>(
    null
  );
  const [selectedInvItems, setSelectedInvItems] = useState<Array<SelectedItem>>(
    []
  );
  const [qtyRequested, setQtyRequested] = useState<number>(0);
  const [requestNotes, setRequestNotes] = useState<string>("");
  const [selectedJob, setSelectedJob] = useState<JobQueryData | null>(null);
  const [costcode, setCostcode] = useState<number>(0);
  const [stockItems, setStockItems] = useState<StockMetaData[]>([]);

  const { addItem } = useAddItem();
  const handleChange = (value: string) => {
    setRequestNotes(value);
  };
  const hndItemSelected = (item: StockMetaData | null) => {
    setSelectedInvItem(item);
    if (item?.stock_type === "small-tool") {
      setQtyRequested(1);
    }
  };
  
  const hndClearItem = () => {
    setQtyRequested(0);
    setRequestNotes("");
    setSelectedInvItem(null);
    setCostcode(0);
  };

  const mutation = useMutation(
    (id: number) => stockSimilarQuery(id).queryFn(),
    {
      onSuccess: (data) => {
        if (data?.length > 0) {
          // Find the first item not already in selectedInvItems
          const firstUniqueItem = data.find(
            (item: StockMetaData) =>
              !selectedInvItems.some(
                (selected) => selected.item.ycc_serial === item.ycc_serial
              )
          );

          if (firstUniqueItem) {
            setSelectedInvItems((prevItems) => [
              ...prevItems,
              { item: firstUniqueItem, qty: qtyRequested },
            ]);
            hndClearItem();
          } else {
            toast.error(`${selectedInvItem?.stock} is currently in use.`, {
              autoClose: 5000, // This toast will close after 5 seconds
            });
          }
        } else {
          toast.error(
            `${selectedInvItem?.stock} is currently out of stock or at another job.`
          );
          
        }
      },
      onError: (error) => {
        console.error("Failed to fetch in stock items:", error);
        Sentry.captureException(error);
      },
    }
  );

  const hndAddItem = () => {
        if (selectedInvItem && qtyRequested > 0) {
            if (selectedInvItem.stock_type === "small-tool") {
        mutation.mutate(selectedInvItem.id);
      } else {
        setSelectedInvItems([
          ...selectedInvItems,
          {
            item: selectedInvItem,
            qty: qtyRequested,
            notes: requestNotes,
            costcode: costcode,
          },
        ]);
                setRequestNotes("");
        hndClearItem();
      }
    }
  };

  const hndRemoveItem = (ev: any) => {
    const id = parseInt(
      ev.target.attributes.getNamedItem("data-item-id").value
    );
    const idx = selectedInvItems.findIndex(
      (elem: SelectedItem) => elem.item.id === id
    );
    const editableInvItems = selectedInvItems;

    editableInvItems.splice(idx, 1);

    setSelectedInvItems([...editableInvItems]);
  };

  const hndSendTicket = (event: React.FormEvent) => {
    if (selectedInvItems.length === 0 || selectedJob === null) {
      
      return false;
    }

    const ticketValue = calculateSelectedValue(selectedInvItems);

    if (
      window.confirm(
        `Are you sure you wish to submit these items totalling ${ticketValue}?`
      ) &&
      selectedJob.id
    ) {
      setShowAddItemDialog(false);
      for (const item of selectedInvItems) {
                addItem(
          event,
          stockIssue,
          item.item as StockMetaData,
          item.qty,
          item.notes?.toString() ?? "",
          item.costcode || 0
        );
      }
    }
  };

  useEffect(() => {
    if (jobId !== undefined && jobs.data) {
      const job = jobs.data.find(
        (jobItem: JobQueryData) => jobItem.id === Number(jobId)
      );
      setSelectedJob(job);
    }
    if (stockItemsData.data && !stockItemsData.isLoading) {
      const sortedStockItems = [...stockItemsData.data].sort((a, b) =>
        a.stock.localeCompare(b.stock)
      );

      setStockItems(sortedStockItems);
    }
  }, [jobId, jobs, stockItemsData.data, stockItemsData.isLoading]);

  return (
    <>
      <Loader
        isLoading={
          !(
            jobs.data &&
            categories.data &&
            stockItemsData.data &&
            !stockItemsData.isLoading
          )
        }
      >
        <Sentry.ErrorBoundary fallback={ErrorFallback}>
          {ticketSubmit.isSuccess ? (
            <div className="row">
              <div className="col-md-12">
                <div className="alert alert-success text-center">
                  <strong>Success!</strong> Item added to ticket successfully.
                </div>
              </div>
            </div>
          ) : null}
          {ticketSubmit.isError ? (
            <div className="row">
              <div className="col-md-12">
                <div className="alert alert-danger text-center">
                  <strong>Error!</strong> Something went wrong submitting your
                  items.
                </div>
              </div>
            </div>
          ) : null}
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <div className="form-group mb-2">
                <label htmlFor="job">Job: {selectedJob?.job}</label>
              </div>

              <p>Select Item:</p>
              {!stockItemsData.isLoading && stockItems.length > 0 && (
                <AddItemForm
                  categories={categories.data}
                  stockItems={stockItems}
                  stockItemFavorites={stockFavoritesData.data}
                  onItemSelected={hndItemSelected.bind(this)}
                />
              )}

              <div className="form-group mb-2">
                <label>Selected Item:</label>
                <input
                  type="text"
                  disabled
                  className="form-control"
                  value={selectedInvItem ? selectedInvItem.stock : ""}
                />
              </div>

              {selectedInvItem?.stock_type !== "consumable" && (
                <div className="form-group mb-2">
                  <label>Quantity Required:</label>
                  <input
                    inputMode="numeric"
                    pattern="[0-9]*"
                    type="text"
                    className="form-control"
                    onChange={() => setQtyRequested(1)}
                    value={1}
                    disabled
                  />
                </div>
              )}
              {selectedInvItem?.stock_type === "consumable" && (
                <div className="form-group mb-2">
                  <label>Quantity Required:</label>
                  <input
                    inputMode="numeric"
                    pattern="[0-9]*"
                    type="text"
                    className="form-control"
                    onChange={(e) => setQtyRequested(Number(e.target.value))}
                    value={qtyRequested ?? ""}
                  />

                  <label>
                    Unit:{" "}
                    {
                      unitsOfMeasureData?.data?.find(
                        (item: UnitOfMeasureQueryData) =>
                          selectedInvItem.unit_of_measure_id === item.id
                      )?.unit_of_measure
                    }
                  </label>
                  <br/>
                  <label>Case Quantity: {selectedInvItem?.case_quantity}</label>

                </div>
              )}
              {selectedInvItem && (
                <div className="form-group mb-2">
                  <label>Notes:</label>
                  <input
                    name="notes"
                    type="text"
                    className="form-control"
                    value={requestNotes ?? ""}
                    onChange={(e) => handleChange(e.target.value.toString())}
                  />
                </div>
              )}
              {selectedInvItem && (
                <div className="form-group mb-2">
                  <label>Cost Code:</label>
                  <CostCodeSelector 
                    selectedJob={selectedJob?.id}
                    onChange={(newval: number) => setCostcode(newval)}                    
                    defaultValue={undefined}
                  />
                </div>
              )}

              {Number(selectedInvItem?.quantity_on_hand) <
                Number(qtyRequested) && (
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width="25"
                    height="25"
                    viewBox="0 0 48 48"
                  >
                    <path
                      fill="#F44336"
                      d="M21.2,44.8l-18-18c-1.6-1.6-1.6-4.1,0-5.7l18-18c1.6-1.6,4.1-1.6,5.7,0l18,18c1.6,1.6,1.6,4.1,0,5.7l-18,18C25.3,46.4,22.7,46.4,21.2,44.8z"
                    ></path>
                    <path
                      fill="#FFF"
                      d="M21.6,32.7c0-0.3,0.1-0.6,0.2-0.9c0.1-0.3,0.3-0.5,0.5-0.7c0.2-0.2,0.5-0.4,0.8-0.5s0.6-0.2,1-0.2s0.7,0.1,1,0.2c0.3,0.1,0.6,0.3,0.8,0.5c0.2,0.2,0.4,0.4,0.5,0.7c0.1,0.3,0.2,0.6,0.2,0.9s-0.1,0.6-0.2,0.9s-0.3,0.5-0.5,0.7c-0.2,0.2-0.5,0.4-0.8,0.5c-0.3,0.1-0.6,0.2-1,0.2s-0.7-0.1-1-0.2s-0.5-0.3-0.8-0.5c-0.2-0.2-0.4-0.4-0.5-0.7S21.6,33.1,21.6,32.7z M25.8,28.1h-3.6L21.7,13h4.6L25.8,28.1z"
                    ></path>
                  </svg>
                  Lead time may be increased on this item.
                </div>
              )}
              <div className="form-group mb-2 d-flex justify-content-end">
                <button
                  className="btn btn-sm btn-outline-secondary me-2"
                  onClick={hndClearItem}
                >
                  Clear Selection
                </button>

                <button
                  className="btn btn-sm btn-primary"
                  onClick={hndAddItem}
                  disabled={!(selectedInvItem && qtyRequested > 0)}
                >
                  Add to Order
                </button>
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <p>Items On Order:</p>

              {jobId && <TicketItemListTable
                hndRemoveItem={hndRemoveItem.bind(this)}
                selectedInvItems={selectedInvItems}
                categories={categories.data ? categories.data : []}
                uoms={unitsOfMeasureData.data ? unitsOfMeasureData.data : []}
                selectedJob={jobId}
              />}

              <div className="d-flex justify-content-center">
                <button
                  className="btn btn-success"
                  onClick={hndSendTicket}
                  disabled={
                    ticketSubmit.isLoading ||
                    selectedJob === null ||
                    selectedInvItems.length === 0
                  }
                >
                  {ticketSubmit.isLoading ? (
                    <>Saving...</>
                  ) : (
                    <>Add Items to Ticket</>
                  )}
                </button>
              </div>
            </div>
          </div>
        </Sentry.ErrorBoundary>
      </Loader>
    </>
  );
};

export default AddItemToTicketForm;
