import { useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { Modal } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  stockCategoryQueryAll,
  stockMetaQueryAll,
  unitOfMeasureQueryAll,
  stockFavoritesQuery,
} from "../../apis/queries/invitems";

import Loader from "../../components/Loader";
import Layout from "../../components/Layout";
import BasicFilterTable from "../../components/BasicFilterTable";
import { AddItemForm } from "../../forms/CreateTicketForm";

import { stockIssueQuery } from "../../apis/queries/tickets";
import { createStockIssueDetailMutation } from "../../apis/mutations/tickets";
import CostCodeSelector from "../../components/CostCodeSelector";

import type {
  StockIssueDetailQueryData,
  StockIssueQueryData,
} from "../../apis/interfaces/tickets";
import {
  StockMetaData,
  UnitOfMeasureQueryData,
  StockFavoritesData,

} from "../../apis/interfaces/invitems";
import { useUpdateStockIssueStatusMutation } from "../Tickets/Logic/MutationsAndQueries";
import ErrorFallback from "../../components/Error";
import * as Sentry from "@sentry/react";
import { debounce } from "lodash";
import CostCodeReassignmentModal from "../../components/CostCodeReassignmentModal";

interface StaffTicketDisplayBodyProps {
  stockIssueId: number;
}

interface StaffTicketAddItemModalProps {
  ticketJob: number;
  categories: any;
  stockItems: any;
  stockFavorites: Array<StockFavoritesData>,
  show: boolean;
  hndClose: any;
  hndAdd: any;
  setRequestNotes: any;
  requestNotes: any;
}

const { DateTime } = require("luxon");

const StaffTicketAddItemModal = (props: StaffTicketAddItemModalProps) => {
  const [selectedInvItem, setSelectedInvItem] = useState<StockMetaData | null>(
    null
  );
  const [qtyRequested, setQtyRequested] = useState<number>(0);
  const [costcode, setCostcode] = useState<number>(0);

  const {
    ticketJob,
    categories,
    stockItems,
    show,
    hndClose,
    hndAdd,
    setRequestNotes,
    stockFavorites,
  } = props;
  const unitsOfMeasureData = useQuery(unitOfMeasureQueryAll());

  const hndItemSelected = (item: StockMetaData | null) => {
    setSelectedInvItem(item);
    if (item && item.stock_type === 'small-tool') {
      setQtyRequested(1);
    }
  };

  const hndAddItem = () => {
    hndAdd(selectedInvItem, qtyRequested, costcode);
  };

  const hndClearItem = () => {
    setQtyRequested(0);
    setRequestNotes("");
    setSelectedInvItem(null);
    setCostcode(0);
  };
  const handleChange = debounce((value) => {
    setRequestNotes(value);
  }, 300);

  return (
    <Modal key="staff-add-item-to-ticket" show={show} onHide={hndClose}>
      <Modal.Header closeButton>Add Item to Ticket</Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-12">
            <p>Select Item:</p>

            {
              stockItems ? (
                <>
                  <AddItemForm
                    categories={categories}
                    stockItems={stockItems}
                    stockItemFavorites={stockFavorites}
                    onItemSelected={hndItemSelected.bind(this)}
                  />

                  <div className="form-group mb-2">
                    <label>Selected Item:</label>
                    <input
                      type="text"
                      disabled
                      className="form-control"
                      value={selectedInvItem ? selectedInvItem.stock : ""}
                    />
                  </div>

                  {selectedInvItem?.stock_type !== "consumable" && (
                    <div className="form-group mb-2">
                      <label>Quantity Required:</label>
                      <input
                        inputMode="numeric"
                        pattern="[0-9]*"
                        type="text"
                        className="form-control"
                        onChange={() => setQtyRequested(1)}
                        value={1}
                        disabled
                      />
                    </div>
                  )}
                  {selectedInvItem?.stock_type === "consumable" && (
                    <div className="form-group mb-2">
                      <label>Quantity Required:</label>
                      <input
                        inputMode="numeric"
                        pattern="[0-9]*"
                        type="text"
                        className="form-control"
                        onChange={(e) =>
                          setQtyRequested(Number(e.target.value))
                        }
                        value={qtyRequested ?? ""}
                      />

                      <label>
                        Unit:{" "}
                        {
                          unitsOfMeasureData?.data?.find(
                            (item: UnitOfMeasureQueryData) =>
                              selectedInvItem.unit_of_measure_id === item.id
                          )?.unit_of_measure
                        }
                      </label>
                    </div>
                  )}
                  {selectedInvItem && (
                    <div className="form-group mb-2">
                      <label>Notes:</label>
                      <input
                        name="notes"
                        type="text"
                        className="form-control"
                        onChange={(e) =>
                          handleChange(e.target.value.toString())
                        }
                      />
                    </div>
                  )}
                  {Number(selectedInvItem?.quantity_on_hand) <
                    Number(qtyRequested) && (
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        x="0px"
                        y="0px"
                        width="25"
                        height="25"
                        viewBox="0 0 48 48"
                      >
                        <path
                          fill="#F44336"
                          d="M21.2,44.8l-18-18c-1.6-1.6-1.6-4.1,0-5.7l18-18c1.6-1.6,4.1-1.6,5.7,0l18,18c1.6,1.6,1.6,4.1,0,5.7l-18,18C25.3,46.4,22.7,46.4,21.2,44.8z"
                        ></path>
                        <path
                          fill="#FFF"
                          d="M21.6,32.7c0-0.3,0.1-0.6,0.2-0.9c0.1-0.3,0.3-0.5,0.5-0.7c0.2-0.2,0.5-0.4,0.8-0.5s0.6-0.2,1-0.2s0.7,0.1,1,0.2c0.3,0.1,0.6,0.3,0.8,0.5c0.2,0.2,0.4,0.4,0.5,0.7c0.1,0.3,0.2,0.6,0.2,0.9s-0.1,0.6-0.2,0.9s-0.3,0.5-0.5,0.7c-0.2,0.2-0.5,0.4-0.8,0.5c-0.3,0.1-0.6,0.2-1,0.2s-0.7-0.1-1-0.2s-0.5-0.3-0.8-0.5c-0.2-0.2-0.4-0.4-0.5-0.7S21.6,33.1,21.6,32.7z M25.8,28.1h-3.6L21.7,13h4.6L25.8,28.1z"
                        ></path>
                      </svg>
                      Lead time may be increased on this item.
                    </div>
                  )}
                  {selectedInvItem && (
                    <div className="form-group mb-2">
                      <label>Cost Code:</label>
                      <CostCodeSelector
                        selectedJob={ticketJob}
                        onChange={(newval: number) => setCostcode(newval)}
                        defaultValue={undefined}
                      />
                    </div>
                  )}

                  <div className="form-group mb-2 d-flex justify-content-end">
                    <button
                      className="btn btn-sm btn-outline-secondary me-2"
                      onClick={hndClearItem}
                    >
                      Clear Selection
                    </button>
                    <button
                      className="btn btn-sm btn-primary"
                      onClick={hndAddItem}
                      disabled={
                        !(selectedInvItem && qtyRequested > 0)
                      }
                    >
                      Add to Order
                    </button>
                  </div>
                </>
              ) : (
                <p>Loading...</p>
              ) // Show loading message or a spinner
            }
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

const StaffTicketDisplayBody = (props: StaffTicketDisplayBodyProps) => {
  const { stockIssueId } = props;
  const [requestNotes, setRequestNotes] = useState<string>("");
  const stockIssue = useQuery(stockIssueQuery(stockIssueId));
  const categories = useQuery(stockCategoryQueryAll());
  const stockItemsData = useQuery(stockMetaQueryAll());
  const stockFavoritesData = useQuery(stockFavoritesQuery());

  const addItemMutation = useMutation(createStockIssueDetailMutation);
  const { mutate: updateStockIssueStatusMutation } =
    useUpdateStockIssueStatusMutation();
  const [showAddItem, setShowAddItem] = useState<boolean>(false);
  const [stockItems, setStockItems] = useState<StockMetaData[]>([]);
  const [resetTicketView, setResetTicketView] = useState(false);
  const queryClient = useQueryClient();

  useEffect(() => {
    queryClient.prefetchQuery(
      ["stockMetaQueryAll"],
      () => stockMetaQueryAll(),
      { staleTime: 300000 } // StaleTime of 5 mins
    );
  }, [queryClient]);
  useEffect(() => {
    if (stockItemsData.data) {
      setStockItems(stockItemsData.data);
    }
    if (stockIssue.data) {
      checkStatus(stockIssue.data);
    }
  }, [stockItemsData.data]);
  const calcTicketCost = (items: Array<StockIssueDetailQueryData>) => {
    return items?.reduce(
      (accumulator: number, row: StockIssueDetailQueryData) => {
        return row.active && row.stock.stock_type === 'consumable' ? accumulator + row.unit_price * row.quantity : accumulator;
      },
      0
    );
  };
  const calcTicketPerDayCost = (items: Array<StockIssueDetailQueryData>) => {
    return items?.reduce(
      (accumulator: number, row: StockIssueDetailQueryData) => {
        return accumulator + (row.active && row.stock.stock_type === 'small-tool' ? (8 * row.stock.rental_rate) * row.quantity : 0);
      },
      0
    );
  };

  function checkStatus(stockIssue: StockIssueQueryData) {
    // Filter out the stock issue detail records that aren't active
    // If there's a deleted item that's ready, it'll kick this into an in-progress
    // state that it shouldn't be in.
    const issue_details = stockIssue.stock_issue_details?.filter(
      (detail_row) => detail_row.active
    )
    const isReadyForPickup = issue_details?.every(
      (detail) => detail.ready && !detail.delivered
    );
    if (
      stockIssue?.stock_issue_details &&
      stockIssue.stock_issue_details.length === 0
    ) {
      try {
        return updateStockIssueStatusMutation([
          Number(stockIssue.id),
          { status: "Non Stock Item" },
        ]);
      } catch (error) {}
    } else {
      console.log("Stock issue details exist or data is not yet loaded");
    }

    if (isReadyForPickup) {
      stockIssue.status = "Ready for Pickup";
      updateStockIssueStatusMutation([
        Number(stockIssue.id),
        {
          status: "Ready for Pickup",
        },
      ]);
      return; // Exit early
    }

    const delivered = issue_details?.every(
      (detail) => detail.delivered || detail.transferred || detail.returned
    );

    if (delivered) {
      stockIssue.status = "Delivered";
      updateStockIssueStatusMutation([
        Number(stockIssue.id),
        {
          status: "Delivered",
        },
      ]);
      return; // Exit early
    }

    const someReady = issue_details?.some(
      (detail) => detail.ready
    );

    if (someReady && !isReadyForPickup) {
      stockIssue.status = "Processing";
      updateStockIssueStatusMutation([
        Number(stockIssue.id),
        {
          status: "Processing",
        },
      ]);
    }
  }

  const determineStockDisposition = (stockIssue: StockIssueDetailQueryData) => {
    if (stockIssue.date_delivered) {
      return <>Delivered 
        {DateTime.fromISO(stockIssue.date_delivered).toLocaleString()}
        <br />to {stockIssue.received_by}  
      </>;
    }

    if (stockIssue.ready) {
      return "Ready For Pickup";
    }

    if (stockIssue.returned) {
      return "Returned";
    }

    return "Waiting for Fulfillment";
  };

  const determineItemPrice = (stockIssue: StockIssueDetailQueryData) => {
    if (stockIssue.stock.stock_type === "small-tool") {
      return `${(8 * stockIssue.stock.rental_rate).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      })}/day`;
    }

    return `$${stockIssue.stock.unit_price.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    })}/${stockIssue.stock.unit_of_measure.unit_of_measure}`;
  };
  const [showCodeReassignModal, setShowCodeReassignModal] = useState(false);

  const [codeReassignStockIssueDetail, setCodeReassignStockIssueDetail] =
    useState<StockIssueDetailQueryData | null>(null);
  function fireCostCodeReassign(row: StockIssueDetailQueryData) {
    setCodeReassignStockIssueDetail(row);
    setShowCodeReassignModal(true);
  }
  const columns = [
    {
      name: "Qty",
      sortable: true,
      selector: (row: StockIssueDetailQueryData) => row.quantity,
      width: "80px",
    },
    {
      name: "Item",
      sortable: true,
      selector: (row: StockIssueDetailQueryData) => row.stock.stock,
      cell: (row: StockIssueDetailQueryData) => (
        <>
          {row.stock.stock}
          <br />
          {row.stock.stock_type}
          {row.cost_code ? (
            <>
              <br />
              Cost Code {row.cost_code.cost_code} {row.cost_code.cost_type}
            </>
          ) : null}
        </>
      ),
    },
    {
      name: "Notes",
      sortable: true,
      selector: (row: StockIssueDetailQueryData) => row.note,
      center: true,
      minWidth: "75px",
      grow: 0.8,
      wrap: true,
    },
    {
      name: "Price",
      sortable: true,
      selector: (row: StockIssueDetailQueryData) => determineItemPrice(row),
    },
    {
      name: "Disposition",
      sortable: false,
      selector: (row: StockIssueDetailQueryData) =>
        determineStockDisposition(row),
    },
    {
      name: "Actions",
      sortable: false,
      cell: (row: StockIssueDetailQueryData) => (
        <>
          {!row.date_charged && (
            <button
              className="btn btn-sm btn-light ml-auto ticket-button whitespace-nowrap"
              onClick={() => fireCostCodeReassign(row)}
            >
              Change Cost Code
            </button>
          )}
        </>
      ),
    },
  ];

  const hndAddNew = () => {
    setShowAddItem(true);
  };

  const hndCloseNewItem = () => {
    setShowAddItem(false);
  };

  const hndAddItem = async (
    selectedInvItem: StockMetaData,
    qtyRequested: Number,
    costcode: Number
  ) => {
    setShowAddItem(false);
    const data = {
      charged: false,
      date_charged: null,
      stock_issue: stockIssueId,
      quantity: parseInt(qtyRequested.toString()),
      stock: selectedInvItem.id,
      unit_price: parseFloat(Number(selectedInvItem?.unit_price).toString()),
      freight_charge: stockIssue.data.stock_issue_details.freight_charge,
      note: requestNotes,
      sales_tax_code: null,
      sales_tax_percentage: null,
      state: null,
      sales_tax_stored: null,
      cost_code: Number(costcode),
    };
    try {
      await addItemMutation.mutateAsync(data);
      toast.success("Item added to ticket.");
    } catch (err) {
      toast.error("An error has occurred. Please try again.");
      Sentry.captureException(err);
    }
    await stockIssue.refetch();
    checkStatus(stockIssue.data.id);
    setResetTicketView(true);
  };

  const headerButtons = (
    <>
      <button
        type="button"
        className="btn btn-sm btn-success"
        onClick={hndAddNew}
        disabled={stockIssue.data && stockIssue.data.status === "Delivered"}
      >
        Add Item
      </button>
    </>
  );

  const hndTicketResetViewDone = () => {
    setResetTicketView(false);
  };

  const smallToolsPerDay = calcTicketPerDayCost(stockIssue.data?.stock_issue_details);

  return (
    <>
      <Layout>
        <Loader
          isLoading={
            !(stockIssue.data && categories.data && stockItems.length > 0)
          }
        >
          <ToastContainer />
          <Sentry.ErrorBoundary fallback={ErrorFallback}>
            {stockIssue.data ? (
              <>
                <div className="row">
                  <div className="col-12">
                    <div className="border border-secondary-subtle p-0 d-flex">
                      <h1 className="dashboard m-0 p-2 flex-grow-1">
                        {stockIssue.data.status} Ticket #{stockIssue.data.id} -
                        {stockIssue.data.job.job}
                      </h1>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4 p-4">
                    <table className="table table-sm">
                      <tbody>
                        <tr>
                          <td>Opened:</td>
                          <td>
                            {DateTime.fromISO(
                              stockIssue.data.created_at
                            ).toLocaleString()}
                          </td>
                        </tr>
                        <tr>
                          <td>Requested By: </td>
                          <td>
                            {!stockIssue.data.created_by && "Unknown User"}
                            {stockIssue.data.created_by?.first_name}{" "}
                            {stockIssue.data.created_by?.last_name}
                          </td>
                        </tr>
                        <tr>
                          <td>Requested By Email:</td>
                          <td>
                            {!stockIssue.data.created_by && "Unknown User"}
                            {stockIssue.data.created_by?.email}
                          </td>
                        </tr>
                        <tr>
                          <td>Job:</td>
                          <td>{stockIssue.data.job.job}</td>
                        </tr>
                        <tr>
                          <td>Job Number:</td>
                          <td>{stockIssue.data.job.job_number}</td>
                        </tr>
                        <tr>
                          <td>Item Cost:</td>
                          <td>
                            $
                            {calcTicketCost(
                              stockIssue.data?.stock_issue_details
                            ).toLocaleString("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                            { smallToolsPerDay > 0 ? <>
                              <br />
                              {smallToolsPerDay.toLocaleString("en-US", { style: "currency", currency: "USD" })}
                              /day
                            </> : null }
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <BasicFilterTable
                      title="Ticket Items"
                      tableKey="ticket-items"
                      dataSource={stockIssue.data.stock_issue_details.filter(
                        (detail: StockIssueDetailQueryData) => detail.active).sort(
                        (item: StockIssueDetailQueryData) => item.created_at
                      )}
                      columns={columns}
                      filterFn={() => {}}
                      exportFileName={`Ticket ${stockIssue.data.id} Items.csv`}
                      buttons={headerButtons}
                      resetView={resetTicketView}
                      onResetDone={hndTicketResetViewDone.bind(this)}
                    />
                  </div>
                </div>
                {showCodeReassignModal &&
                  codeReassignStockIssueDetail &&
                  stockIssue.data.job && (
                    <CostCodeReassignmentModal
                      jobId={stockIssue.data.job.id}
                      stockIssueDetail={codeReassignStockIssueDetail}
                      show={showCodeReassignModal}
                      onChange={() => console.log("done change")}
                      onCancel={() => {
                        setShowCodeReassignModal(false);
                        stockIssue.refetch();
                      }}
                    />
                  )}
                {categories?.data && stockItems.length > 0 ? (
                  <Sentry.ErrorBoundary fallback={ErrorFallback}>
                    <StaffTicketAddItemModal
                      ticketJob={stockIssue.data.job.id}
                      setRequestNotes={setRequestNotes}
                      requestNotes={requestNotes}
                      show={showAddItem}
                      stockItems={stockItems}
                      stockFavorites={stockFavoritesData.data}
                      categories={categories.data}
                      hndClose={hndCloseNewItem}
                      hndAdd={hndAddItem}
                    />
                  </Sentry.ErrorBoundary>
                ) : null}
              </>
            ) : null}
          </Sentry.ErrorBoundary>
        </Loader>
      </Layout>
    </>
  );
};

const StaffTicketDisplay = () => {
  const { id } = useParams();

  return id ? (
    <Sentry.ErrorBoundary fallback={ErrorFallback}>
      <StaffTicketDisplayBody stockIssueId={parseInt(id)} />
    </Sentry.ErrorBoundary>
  ) : null;
};

export default StaffTicketDisplay;
