// Stuff for the non-admin users

import CreateTicket from "../pages/Staff/CreateTicket";
import StaffIndex from "../pages/Staff/Index";
import StaffInventoryDisplay from "../pages/Staff/InventoryDisplay";
import StaffTicketDisplay from "../pages/Staff/TicketDisplay";
import CostCodeReassignment from "../pages/Reporting/CostCodeReassignment";

const staffRoutes: any = [
  {
    path: "/staff-dashboard",
    element: <StaffIndex />,
  },
  {
    path: "/staff/ticket/new/:id?",
    element: <CreateTicket />,
  },
  {
    path: "staff/ticket/:id",
    element: <StaffTicketDisplay />,
  },
  {
    path: "staff/inventory/:id",
    element: <StaffInventoryDisplay />,
  },
  {
    path: "staff/costcodes",
    element: <CostCodeReassignment />,
  }
];

export default staffRoutes;
