import * as Yup from "yup";
import { ErrorMessage, Field, Form, Formik, FormikHelpers } from "formik";
import type { ChargeMutationData } from "../apis/interfaces/common";
import { Modal } from "react-bootstrap";

interface ChargeFormProps {
  initialValues: ChargeMutationData;
  onSubmit: any;
  onCancel: any;
}

const validationSchema = Yup.object({
  charge: Yup.string().required("Charge name is required"),
  charge_number: Yup.string().required("Charge code is required"),
});

const ChargeForm = (props: ChargeFormProps) => {
  const { initialValues, onSubmit, onCancel } = props;
  const handleSubmit = (
    values: ChargeMutationData,
    { setStatus }: FormikHelpers<ChargeMutationData>
  ) => {
    try {
      validationSchema.validateSync(values, { abortEarly: false });
      onSubmit(values);
    } catch (errors) {
      setStatus("An error occurred during form submission.");
    }
  };
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({ handleChange, handleSubmit, isSubmitting, isValid }) => (
        <>
          <Form onSubmit={handleSubmit}>
            <Modal.Body>
              <div className="form-group mb-2">
                <label htmlFor="charge">Charge Name:</label>
                <Field
                  type="text"
                  id="charge"
                  name="charge"
                  className="form-control"
                  onChange={handleChange}
                />
                <ErrorMessage
                  name="charge"
                  component="div"
                  className="error-message text-danger"
                />
              </div>

              <div className="form-group mb-2">
                <label htmlFor="charge">Charge Number:</label>
                <Field
                  type="text"
                  id="charge_number"
                  name="charge_number"
                  className="form-control"
                  onChange={handleChange}
                />
                <ErrorMessage
                  name="charge_number"
                  component="div"
                  className="error-message text-danger"
                />
              </div>

              <div className="form-check mb-2">
                <Field
                  type="checkbox"
                  id="active"
                  name="active"
                  className="form-check-input"
                  onChange={handleChange}
                />
                <label className="form-check-label" htmlFor="active">
                  Active
                </label>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="text-right">
                <button
                  onClick={onCancel}
                  className="btn btn-sm btn-danger mx-3"
                  type="button"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-sm btn-success"
                  disabled={!(!isSubmitting && isValid)}
                >
                  Save
                </button>
              </div>
            </Modal.Footer>
          </Form>
        </>
      )}
    </Formik>
  );
};

export default ChargeForm;
