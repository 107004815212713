import { apiClient } from "../client";
import { MakeModelListItem } from "../interfaces/invitems";

// #region StockIssue Queries

export const ticketDashboardQueryAll = (
  limit: number = 25,
  offset: number = 0,
  filter: string = '',
  sortField: string = '',
  sortDirection: string = '',
) => {
  return {
    queryKey: ["ticketDashboardQueryAll"],
    queryFn: async () => {
      let ordering = '';

      if (sortDirection === 'desc' && sortField.length > 0) {
        ordering = `-${sortField}`
      } else {
        if (sortField.length > 0) {
          ordering = sortField;
        }
      }

      const response = await apiClient.get(
        `/tickets/dashboard/?limit=${limit}&offset=${offset}&filter=${filter}&ordering=${ordering}`
      );
      return response.data;
    },
  };
};

export const stockIssueQueryAll = (
  limit: number = 25,
  offset: number = 0,
  filter: string = '',
  sortField: string = '',
  sortDirection: string = '',
) => {
  return {
    queryKey: ["stockIssueQueryAll"],
    queryFn: async () => {
      let ordering = '';

      if (sortDirection === 'desc' && sortField.length > 0) {
        ordering = `-${sortField}`
      } else {
        if (sortField.length > 0) {
          ordering = sortField;
        }
      }

      const response = await apiClient.get(
        `/tickets/stock_issue/?limit=${limit}&offset=${offset}&filter=${filter}&ordering=${ordering}`
      );
      return response.data;
    },
  };
};

export const stockIssueQuery = (id: number) => {
  return {
    queryKey: ["stockIssueQuery", id],
    queryFn: async () => {
      const response = await apiClient.get(`/tickets/stock_issue/${id}/`);
      return response.data;
    },
  };
};

// This query will allow users to search on any related field for StockIssue.
export const stockIssueQuerySolr = (keyword: string) => {
  return {
    queryKey: ["stockIssueQuerySolr", keyword],
    queryFn: async () => {
      const response = await apiClient.get(
        `/tickets/v1/search-stockissue/?q=${keyword}/`
      );
      return response.data;
    },
  };
};
// #endregion

// #region MiscCharge Queries
export const miscChargeQueryAll = () => {
  return {
    queryKey: ["miscChargeQueryAll"],
    queryFn: async () => {
      const response = await apiClient.get("/tickets/misc_charge/");
      return response.data;
    },
  };
};
// #endregion

// #region StockCharge Queries
export const stockChargeQueryAll = () => {
  return {
    queryKey: ["stockChargeQueryAll"],
    queryFn: async () => {
      const response = await apiClient.get("/tickets/stock_charge/");
      return response.data;
    },
  };
};

export const stockChargeQuery = (id: number) => {
  return {
    queryKey: ["stockChargeQuery", id],
    queryFn: async () => {
      const response = await apiClient.get(`/tickets/stock_charge/${id}/`);
      return response.data;
    },
  };
};
export const miscChargeLookup = (id: number) => {
  return {
    queryKey: ["stockChargeQuery", id],
    queryFn: async () => {
      const response = await apiClient.get(`/tickets/stock_charge/?stock_issue_id=${id}`);
      return response.data;
    },
  };
};
// #endregion

// #region StockIssueDetail Queries
export const stockIssueDetailQueryAll = () => {
  return {
    queryKey: ["stockIssueDetailQueryAll"],
    queryFn: async () => {
      const response = await apiClient.get("/tickets/stock_issue_detail/");
      return response.data;
    },
  };
};

export const stockIssueDetailQuery = (id: string) => {
  return {
    queryKey: ["stockIssueDetailQuery", id],
    queryFn: async () => {
      const response = await apiClient.get(
        `/tickets/stock_issue_detail/${id}/`
      );
      return response.data;
    },
  };
};

export const stockIssueDetailByJobQuery = (jobId: string|number) => {
  return {
    queryKey: ["stockIssueDetailByJobQuery", jobId],
    queryFn: async () => {
      const response = await apiClient.get(
        `/tickets/v1/details_by_job/${jobId}/`
      );
      return response.data;
    },
  };
};

export const smallToolsByJobQuery = (jobId: string|number) => {
  return {
    queryKey: ["smallToolsByJobQuery", jobId],
    queryFn: async () => {
      const response = await apiClient.get(
        `/tickets/v1/small_tools_by_job/${jobId}/`
      );
      return response.data;
    },
  };
};

export const stockIssueDetailByAllJobsQuery = () => {
  return {
    queryKey: ["stockIssueDetailByJobQuery"],
    queryFn: async () => {
      const response = await apiClient.get(
        '/tickets/v1/details_by_job/'
      );
      return response.data;
    },
  };
};

export const smallToolsByAllJobsQuery = () => {
  return {
    queryKey: ["smallToolsByJobQuery"],
    queryFn: async () => {
      const response = await apiClient.get(
        '/tickets/v1/small_tools_by_job/'
      );
      return response.data;
    },
  };
};


// #endregion
export const stockIssueFilterQueryAll = () => {
  return {
    queryKey: ["stockMakeModelQueryAll"],
    queryFn: async () => {
      const response = await apiClient.get("/tickets/stockissuefilter/");

      if (response.status === 200) {
        const fmtStockIssueFilter: Array<MakeModelListItem> = [];

        response.data.forEach((elem: Array<string>) => {
          if (!elem[0] || !elem[1]) {
            return;
          }

          let makeIdx = fmtStockIssueFilter.findIndex(
            (cmpElem: MakeModelListItem) => cmpElem.name === elem[0]
          );

          if (makeIdx === -1) {
            fmtStockIssueFilter.push({ name: elem[0], children: [elem[1]] });
          } else {
            fmtStockIssueFilter[makeIdx].children.push(elem[1]);
          }
        });

        return fmtStockIssueFilter;
      }
    },
  };
};
