import {
  StockAdjustmentMutationData,
  StockCategoryMutationData,
  StockLocationMutationData,
  StockMarkReadyMutationData,
  StockMutationData,
  StockOrderDetailMutationData,
  StockOrderMutationData,
  UnitOfMeasureMutationData,
} from "../interfaces/invitems";
import { apiClient } from "../client";

// #region StockCategory Mutations
export const createStockCategoryMutation = {
  queryKey: ["stockCategoryQuery"],
  mutationFn: (data: StockCategoryMutationData) => {
    return apiClient.post("/invitems/stock_category/", data);
  },
};

export const updateStockCategoryMutation = {
  queryKey: ["stockCategoryQuery"],
  mutationFn: (id: number, data: StockCategoryMutationData) => {
    return apiClient.patch(`/invitems/stock_category/${id}/`, data);
  },
};

export const deleteStockCategoryMutation = {
  queryKey: ["stockCategoryQuery"],
  mutationFn: (id: number) => {
    return apiClient.delete(`/invitems/stock_category/${id}`);
  },
};
// #endregion

// #region UnitOfMeasure Mutations
export const createUnitOfMeasureMutation = {
  queryKey: ["unitOfMeasureQuery"],
  mutationFn: (data: UnitOfMeasureMutationData) => {
    return apiClient.post("/invitems/unit_of_measure/", data);
  },
};

export const updateUnitOfMeasureMutation = {
  queryKey: ["unitOfMeasureQuery"],
  mutationFn: (id: number, data: UnitOfMeasureMutationData) => {
    return apiClient.patch(`/invitems/unit_of_measure/${id}/`, data);
  },
};

export const deleteUnitOfMeasureMutation = {
  queryKey: ["unitOfMeasureQuery"],
  mutationFn: (id: number) => {
    return apiClient.delete(`/invitems/unit_of_measure/${id}/`);
  },
};
// #endregion

// #region Stock Mutations
export const createStockMutation = {
  queryKey: ["stockQuery"],
  mutationFn: (data: StockMutationData) => {
    return apiClient.post("/invitems/stock/", data);
  },
};

export const updateStockMutation = {
  queryKey: ["stockQuery"],
  mutationFn: (id: number, data: StockMutationData) => {
    return apiClient.patch(`/invitems/stock/${id}/`, data);
  },
};

export const markStockReadyMutation = {
  queryKey: ["stockQuery"],
  mutationFn: (id: number, data: StockMarkReadyMutationData) => {
    return apiClient.patch(`/invitems/stock/${id}/`, data);
  },
};

export const deleteStockMutation = {
  queryKey: ["stockQuery"],
  mutationFn: (id: number) => {
    return apiClient.delete(`/invitems/stock/${id}/`);
  },
};
// #endregion

// #region StockLocation Mutations
export const createStockLocationMutation = {
  queryKey: ["stockLocationQuery"],
  mutationFn: (data: StockLocationMutationData) => {
    return apiClient.post("/invitems/stock_location/", data);
  },
};

export const updateStockLocationMutation = {
  queryKey: ["stockLocationQuery"],
  mutationFn: (id: number, data: StockLocationMutationData) => {
    return apiClient.patch(`/invitems/stock_location/${id}/`, data);
  },
};

export const deleteStockLocationMutation = {
  queryKey: ["stockLocationQuery"],
  mutationFn: (id: number) => {
    return apiClient.delete(`/invitems/stock_location/${id}/`);
  },
};
// #endregion

// #region StockAdjustment Mutations
export const createStockAdjustmentMutation = {
  queryKey: ["stockAdjustmentQuery"],
  mutationFn: (data: StockAdjustmentMutationData) => {
    return apiClient.post("/invitems/stock_adjustment/", data);
  },
};

export const updateStockAdjustmentMutation = {
  queryKey: ["stockAdjustmentQuery"],
  mutationFn: (id: number, data: StockAdjustmentMutationData) => {
    return apiClient.patch(`/invitems/stock_adjustment/${id}/`, data);
  },
};

export const deleteStockAdjustmentMutation = {
  queryKey: ["stockAdjustmentQuery"],
  mutationFn: (id: number) => {
    return apiClient.delete(`/invitems/stock_adjustment/${id}/`);
  },
};
// #endregion

// #region StockOrder Mutations
export const createStockOrderMutation = {
  queryKey: ["stockOrderQuery"],
  mutationFn: (data: StockOrderMutationData) => {
    return apiClient.post("/invitems/stock_order/", data);
  },
};

export const updateStockOrderMutation = {
  queryKey: ["stockOrderQuery"],
  mutationFn: (id: number, data: StockOrderMutationData) => {
    return apiClient.patch(`/invitems/stock_order/${id}/`, data);
  },
};

export const deleteStockOrderMutation = {
  queryKey: ["stockOrderQuery"],
  mutationFn: (id: number) => {
    return apiClient.delete(`/invitems/stock_order/${id}/`);
  },
};
// #endregion

// #region StockOrderDetail Mutations
export const createStockOrderDetailMutation = {
  queryKey: ["stockOrderDetailQuery"],
  mutationFn: (data: StockOrderDetailMutationData) => {
    return apiClient.post("/invitems/stock_order_detail/", data);
  },
};

export const updateStockOrderDetailMutation = {
  queryKey: ["stockOrderDetailQuery"],
  mutationFn: (id: number, data: StockOrderDetailMutationData) => {
    return apiClient.patch(`/invitems/stock_order_detail/${id}/`, data);
  },
};

export const deleteStockOrderDetailMutation = {
  queryKey: ["stockOrderDetailQuery"],
  mutationFn: (id: number) => {
    return apiClient.delete(`/invitems/stock_order_detail/${id}/`);
  },
};
// #endregion
