import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Modal } from "react-bootstrap";
import type {
  VendorMutationData,
  VendorQueryData,
} from "../../apis/interfaces/common";
import { vendorQueryAll } from "../../apis/queries/common";
import {
  createVendorMutation,
  updateVendorMutation,
} from "../../apis/mutations/common";

import VendorForm from "../../forms/VendorForm";

import Layout from "../../components/Layout";
import Loader from "../../components/Loader";
import BasicFilterTable from "../../components/BasicFilterTable";
import { toast, ToastContainer } from "react-toastify";
import ErrorFallback from "../../components/Error";
import * as Sentry from "@sentry/react";

interface VendorTableProps {
  onAddNew: any;
  onEdit: any;
  vendors: Array<VendorQueryData>;
  setVendorInitialValues: any;
  setShowVendorModal: any;
  setEdit: any;
  edit: boolean;
}

interface VendorModalProps {
  show: boolean;
  onCancel: Function;
  onOk: Function;
  initialValues: VendorMutationData;
  setShowVendorModal: any;
  setVendorInitialValues: any;
  setEdit: any;
  edit: boolean;
}

const VendorModal = (props: VendorModalProps) => {
  const { show, onCancel, onOk, initialValues, edit, setEdit } = props;
  const queryClient = useQueryClient();
  const createVendor = useMutation({
    ...createVendorMutation,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["vendorQueryAll"] });
      onOk();
    },
  });
  const updateVendorMutationFn = async (
    params: [number, VendorMutationData]
  ) => {
    const [id, data] = params;
    try {
      await updateVendorMutation.mutationFn(id, data);
      onOk();
    } catch (error) {
      console.log(error);
    }
  };

  const updateVendor = useMutation(updateVendorMutationFn);

  const hndSubmit = async (values: VendorMutationData) => {
    try {
      if (values.id === undefined) {
        await createVendor.mutateAsync(values);
        toast.success("Vendor Added");
      } else {
        await updateVendor.mutateAsync([values.id as number, values]);
        setEdit(false);
        toast.success("Vendor Updated");
      }
    } catch (err) {
      console.log(err);
      toast.error("An error occurred while updating the vendor");
    }
  };
  const handleModalClose = () => {
    setEdit(false);
    onCancel();
  };

  return (
    <Modal show={show} onCancel={handleModalClose}>
      {edit && (
        <Modal.Header closeButton onClick={handleModalClose}>
          Edit Vendor
        </Modal.Header>
      )}
      {!edit && (
        <Modal.Header closeButton onClick={handleModalClose}>
          Add Vendor
        </Modal.Header>
      )}
      <VendorForm
        initialValues={initialValues}
        onCancel={handleModalClose}
        onSubmit={hndSubmit}
      />
    </Modal>
  );
};

const VendorTable = (props: VendorTableProps) => {
  const {
    onAddNew,
    vendors,
    setShowVendorModal,
    setVendorInitialValues,
    setEdit,
  } = props;

  const fireOnEdit = (row: VendorQueryData) => {
    setEdit(true);
    setVendorInitialValues({
      id: row.id,
      vendor_name: row.vendor_name,
      address: row.address,
      city: row.city,
      state: row.state,
      zip_code: row.zip_code,
      country: row.country,
      fax: row.fax,
      telephone: row.telephone,
      contact: row.contact,
      notes: row.notes,
      active: row.active,
    });
    setShowVendorModal(true);
  };

  const columns = [
    {
      name: "Name",
      sortable: true,
      selector: (row: VendorQueryData) => row.vendor_name,
    },
    {
      name: "Phone",
      sortable: true,
      selector: (row: VendorQueryData) => row.telephone,
    },
    {
      name: "City",
      sortable: true,
      selector: (row: VendorQueryData) => row.city,
    },
    {
      name: "State",
      sortable: true,
      selector: (row: VendorQueryData) => row.state,
    },
    {
      name: "Active",
      sortable: true,
      selector: (row: VendorQueryData) => row.active,
      cell: (row: VendorQueryData) => (
        <>
          {row.active ? (
            <span className="text-success">&#x2714;</span> // Checkmark symbol
          ) : (
            <span className="text-danger">&#x2718;</span> // Cross symbol
          )}
        </>
      ),
    },
    {
      name: "Actions",
      cell: (row: VendorQueryData) => (
        <>
          <button
            className="btn btn-sm btn-link"
            onClick={fireOnEdit.bind(this, row)}
          >
            Edit
          </button>
        </>
      ),
    },
  ];
  const filterFn = (keyword: string, item: VendorQueryData) => {
    const lowercaseKeyword = keyword.toLowerCase();
    const itemValues = Object.values(item);

    for (const value of itemValues) {
      if (typeof value === "string") {
        const lowercaseValue = value.toLowerCase();

        if (lowercaseValue.includes(lowercaseKeyword)) {
          return true;
        }
      }
    }

    return false;
  };

  const filterButtons = (
    <>
      <button className="btn btn-link" onClick={onAddNew}>
        Add New
      </button>
    </>
  );
  return (
    <div className="row">
      <div className="col-12">
        <div className="border border-secondary-subtle p-0 d-flex">
          <div className="align-self-center mr-2"></div>
        </div>
        <div className="p-2">
          <BasicFilterTable
            tableKey="vendors"
            title="Vendors"
            dataSource={vendors}
            columns={columns}
            filterFn={filterFn}
            filterButtons={filterButtons}
            exportFileName={"Vendor Export Data.csv"}
          />
        </div>
      </div>
    </div>
  );
};

const blankVendorForm = () => {
  return {
    vendor_name: "",
    address: "",
    city: "",
    state: "",
    zip_code: "",
    country: "",
    fax: "",
    telephone: "",
    contact: "",
    notes: "",
    active: true,
  };
};

const Vendor = () => {
  const [showVendorModal, setShowVendorModal] = useState(false);
  const [vendorInitialValues, setVendorInitialValues] = useState(
    blankVendorForm()
  );
  const [edit, setEdit] = useState(false);
  const vendors = useQuery(vendorQueryAll());

  const hndAddNew = () => {
    setVendorInitialValues(blankVendorForm());
    setShowVendorModal(true);
  };

  const hndEdit = () => {
    setEdit(true);
    setShowVendorModal(true);
    setVendorInitialValues({
      ...vendorInitialValues,
      vendor_name: "",
      address: "",
      city: "",
      state: "",
      zip_code: "",
      country: "",
      fax: "",
      telephone: "",
      contact: "",
      notes: "",
      active: true,
    });
  };

  const hndModelClose = () => {
    setEdit(false);
    setShowVendorModal(false);
    vendors.refetch();
  };
  let sortedVendors = vendors.data;
  if (sortedVendors) {
    sortedVendors = [...sortedVendors].sort((a, b) => b.active - a.active);
  }
  return (
    <Sentry.ErrorBoundary fallback={ErrorFallback}>
     
        <Layout>
          <Loader isLoading={vendors.isLoading && vendors.data !== undefined}>
            <VendorTable
              onAddNew={hndAddNew}
              onEdit={hndEdit}
              vendors={sortedVendors}
              setVendorInitialValues={setVendorInitialValues}
              setShowVendorModal={setShowVendorModal}
              setEdit={setEdit}
              edit={edit}
            />
          </Loader>
          <ToastContainer />
          <VendorModal
            show={showVendorModal}
            onOk={hndModelClose}
            onCancel={hndModelClose}
            initialValues={vendorInitialValues}
            setShowVendorModal={setShowVendorModal}
            setVendorInitialValues={setVendorInitialValues}
            setEdit={setEdit}
            edit={edit}
          />
        </Layout>
      
    </Sentry.ErrorBoundary>
  );
};

export default Vendor;
