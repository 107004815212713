// Meta routes for the app

import Stock from "../pages/Inventory/Stock";

const invRoutes = [
  {
    path: "/inventory/:id?",
    element: <Stock />,
  },
];

export default invRoutes;
