import { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useQuery } from "@tanstack/react-query";
import { stockIssueQueryAll } from "../../apis/queries/tickets";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { jobQueryAll, companyQueryAll } from "../../apis/queries/common";
import {
  CompanyQueryData,
  JobQueryData,
  User,
} from "../../apis/interfaces/common";
import Select from "react-select";

const { DateTime } = require("luxon");

interface FilterTicketModalProps {
  show: boolean;
  onClose: () => void;
  onApply: (filterData: any) => void;
  onClear: () => void;
  setShowFilterTicketModal: any;
}
const FilterTicketModal = (props: FilterTicketModalProps) => {
  const [statusFilter, setStatusFilter] = useState("");
  const [jobFilter, setJobFilter] = useState(0);
  const [companyFilter, setCompanyFilter] = useState(0);
  const [dateFilter, setDateFilter] = useState<Date | null>(null);
  const [requestorFilter, setRequestorFilter] = useState(0);
  const [requestors, setRequestors] = useState<User[]>([]);

  const companies = useQuery(companyQueryAll());
  const jobs = useQuery(jobQueryAll());

  const { show, onApply, onClear, setShowFilterTicketModal } = props;
  const { data: stockIssues } = useQuery(stockIssueQueryAll());
  const handleCloseFilterTicketModal = () => {
    setShowFilterTicketModal(false);
  };

  const handleApplyFilter = () => {
    const filterData = {
      status: statusFilter,
      job: jobFilter,
      company: companyFilter,
      date_submitted: dateFilter
        ? DateTime.fromJSDate(dateFilter).toISODate()
        : null,
      requested_by: requestorFilter,
    };
    console.log("handleApplyFilter", filterData);
    onApply(filterData);
    handleCloseFilterTicketModal();
  };

  const handleClearFilter = () => {
    setStatusFilter("");
    setJobFilter(0);
    setCompanyFilter(0);
    setDateFilter(null);
    setRequestorFilter(0);
    onClear();
  };

  useEffect(() => {
    if (stockIssues?.results) {
      const uniqueRequestors: User[] = [];
      setRequestors(uniqueRequestors);
    }
  }, [stockIssues]);

  const handleDateChange = (date: Date) => {
    setDateFilter(date);
  };
  const options = jobs.data
    ?.filter((jobElem: JobQueryData) => jobElem.active)
    .map((jobElem: JobQueryData) => ({
      value: jobElem.id,
      label: `${jobElem.job_number} - ${jobElem.job}`,
    })) ?? [];
  const hndSelectJob = (selectedOption: any) => {
    setJobFilter(selectedOption ? selectedOption.value : "");
  };
  function customFilter(option:any , rawInput: any) {
    const searchTerm = rawInput.toLowerCase();
    return option.label.toLowerCase().includes(searchTerm);
  }
  return (
    <>
      <Modal show={show} onHide={handleCloseFilterTicketModal}>
        <Modal.Header closeButton>
          <Modal.Title>Filter Results</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-2">
              <Form.Label>Status:</Form.Label>
              <Form.Select
                value={statusFilter}
                onChange={(e) => setStatusFilter(e.target.value)}
              >
                <option value="">Select a Status</option>
                <option value="Submitted">Submitted</option>
                <option value="Opened">Opened</option>
                <option value="Processing">Processing</option>
                <option value="Ready for Pickup">Ready for Pickup</option>
                <option value="Delivered">Delivered</option>
                <option value="Non Stock Item">Non Stock Item</option>
                <option value="Historical">Historical</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Label>Job:</Form.Label>
              <Select
                value={options.find(
                  (option: { value: number; label: string }) =>
                    option.value === jobFilter
                ) ?? null}
                onChange={hndSelectJob}
                options={options}
                isClearable
                placeholder="Select a Job"
                filterOption={customFilter}
              />
            </Form.Group>

            <Form.Group className="mb-2">
              <Form.Label>Company:</Form.Label>
              <Form.Select
                value={companyFilter}
                onChange={(e) => setCompanyFilter(Number(e.target.value))}
              >
                <option value="">Select a Company</option>
                {companies.data
                  ?.filter(Boolean)
                  .map((company: CompanyQueryData) => (
                    <option key={company.id} value={company.id}>
                      {company.company}
                    </option>
                  ))}
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-2">
              <Form.Label>Requested by:</Form.Label>
              <Form.Select
                value={requestorFilter}
                onChange={(e) => setRequestorFilter(Number(e.target.value))}
              >
                <option value="">Select a Requestor</option>
                {requestors.filter(Boolean).map((requestor) => (
                  <option key={requestor.id} value={requestor.id}>
                    {requestor.first_name + " " + requestor.last_name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Label>Issued Date:</Form.Label>
              <DatePicker
                onChange={handleDateChange}
                onSelect={handleDateChange}
                className="form-control"
                dateFormat="MM/dd/yyyy"
                selected={dateFilter}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClearFilter}>
            Clear
          </Button>
          <Button variant="primary" onClick={handleApplyFilter}>
            Apply
          </Button>
          <Button variant="secondary" onClick={handleCloseFilterTicketModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default FilterTicketModal;
