import CreateTicketForm from "../../forms/CreateTicketForm";
import { Modal } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
interface CreateTicketModalProps {
  show: boolean;
  hndRefresh: any;
}

const CreateTicketModal = (props: CreateTicketModalProps) => {
  const { show, hndRefresh } = props;

  const hndTicketSubmit = () => {
    hndRefresh();
  };

  return (
    <>
      <Modal show={show} onHide={hndRefresh} size="xl">
      <ToastContainer />

        <Modal.Header closeButton>Create Ticket</Modal.Header>
        <Modal.Body>
          <CreateTicketForm hndTicketSubmit={hndTicketSubmit} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CreateTicketModal;
