interface LoaderProps {
  isLoading: boolean;
  children: any;
}

const Loader = (props: LoaderProps) => {
  const { isLoading } = props;

  return isLoading ? (
    <>
      <div className="d-flex w-100">
        <div className="card w-33 mx-auto">
          <div className="card-body text-center">Please wait...</div>
        </div>
      </div>
    </>
  ) : (
    <>{props.children}</>
  );
};

export default Loader;
