import { ReactElement, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import fileDownload from "js-file-download";
import "../styles/print.css";

const Papa = require("papaparse");

interface BasicFilterTableProps {
  tableKey: string;
  title: string;
  dataSource: Array<any>;
  columns: Array<any>;
  buttons?: ReactElement;
  filterButtons?: ReactElement;
  filterFn?: Function;
  filterExplainer?: ReactElement;
  exportFileName?: string;
  tableCount?: number;
  onChangeLimit?: Function;
  onChangeOffset?: Function;
  onChangeSort?: Function;
  limit?: number;
  offset?: number;
  isLoading?: boolean;
  filterHnd?: Function;
  clearFilterFlag?: boolean;
  onFilterCleared?: Function;
  resetView?: boolean;
  onResetDone?: Function;
  hideExport?: boolean;
  hideSearch?: boolean;
}

const BasicFilterTable = (props: BasicFilterTableProps) => {
  const {
    tableKey,
    dataSource,
    columns,
    title,
    buttons,
    filterFn,
    filterButtons,
    filterExplainer,
    tableCount,
    limit,
    offset,
    onChangeLimit,
    onChangeOffset,
    onChangeSort,
    isLoading,
    filterHnd,
    clearFilterFlag,
    onFilterCleared,
    resetView,
    onResetDone,
    hideExport,
    hideSearch,
  } = props;
  const [filter, setFilter] = useState("");
  const [filteredData, setFilteredData] = useState(dataSource);
  const flattenObject = (obj: any): { [key: string]: any } => {
    const result: { [key: string]: any } = {};

    for (const key in obj) {
      if (key.endsWith('.password')) {
        continue;
      }

      if ((typeof obj[key]) === 'object' && !Array.isArray(obj[key])) {
        const temp = flattenObject(obj[key]);
        for (const key2 in temp) {
          if (key2.endsWith('.password')) {
            continue;
          }
          result[key + '.' + key2] = temp[key2];
        }
      } else {
        result[key] = obj[key];
      }
    }

    return result;
  };
  
  
  const hndExportTable = () => {
    let { exportFileName } = props;
  
    if (!exportFileName) {
      exportFileName = "Indeterminate Output.csv";
    }
  
    const flatData = filteredData.map(data => flattenObject(data));
  
    fileDownload(Papa.unparse(flatData), exportFileName);
  };
  

  const hndChangeLimit = (ev: any) => {
    ev.preventDefault();

    if (onChangeLimit) {
      onChangeLimit(parseInt(ev.target.value));
    }
  };

  const hndChangeOffset = (newOffset: number) => {
    if (onChangeOffset) {
      console.log("bft changing offset");
      onChangeOffset(newOffset);
    }
  };

  const calcTotalPages = () => {
    if (tableCount && limit) {
      return Math.ceil(tableCount / limit);
    }

    return 1;
  };

  const calcCurrentPage = () => {
    if (offset !== undefined && limit) {
      return Math.ceil(offset / limit);
    }

    return 1;
  };

  const addPage = () => {
    if (offset !== undefined && limit) {
      hndChangeOffset(offset + limit);
    }
  };

  const subPage = () => {
    if (offset !== undefined && limit) {
      hndChangeOffset(offset - limit);
    }
  };

  const hndTypedPage = (ev: any) => {
    const typePage = parseInt(ev.target.value);

    if (offset !== undefined && limit && !isNaN(typePage)) {
      hndChangeOffset(limit * typePage);
    }
  };

  const hndChangeSort = async (column: any, sortDirection: any) => {
    if (onChangeSort) {
      onChangeSort(column, sortDirection);
    }
  };

  useEffect(() => {
    if (filter.trim().length > 0) {
      if (!filterHnd && !filterFn) {
        setFilteredData(dataSource);
      }

      if (filterHnd) {
        filterHnd(filter.trim());
        return;
      }

      const newFilteredData: Array<any> = [];

      dataSource.forEach((row) => {
        if (filterFn?.(filter, row)) {
          newFilteredData.push(row);
        }
      });

      setFilteredData(newFilteredData);
    } else {
      if (filterHnd) {
        filterHnd("");
        return;
      }

      setFilteredData(dataSource);
    }
  }, [dataSource, filter, filterFn, filterHnd]);

  useEffect(() => {
    if (filterHnd || filter.trim().length === 0) {
      setFilteredData(dataSource);
    }
  }, [dataSource, filter, filterHnd]);

  useEffect(() => {
    if (clearFilterFlag && onFilterCleared) {
      setFilter('');
      onFilterCleared();
    }
  }, [ clearFilterFlag ]);

  useEffect(() => {
    if (resetView && onResetDone) {
      onResetDone();
    }
  }, [ resetView ]);

  return !resetView ? (
    <>
      <div className="row">
        <div className="col-12">
          <div className="border border-secondary-subtle border-bottom-0 p-0 d-flex">
            <h1 className="dashboard m-0 p-2 flex-grow-1">{title}</h1>
            <div className="align-self-center me-2">{buttons}</div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 table-search">
          <div className="border border-secondary-subtle border-top-0 p-0 d-flex flex-column">
            <div className="p-2 flex-grow-1 d-flex">
              {!hideSearch ? <><label
                htmlFor={`${tableKey}-table-search`}
                className="d-inline-block me-2 align-self-center"
              >
                Search:
              </label>
              <input
                type="search"
                onChange={(e) => setFilter(e.target.value.toLowerCase())}
                onSelect={(e) => setFilter(e.currentTarget.value.toLowerCase())}
                name={`${tableKey}-table-search`}
                className="form-control form-control-inline d-inline-block"
                value={filter}
              /></> : <div className="flex-grow-1"></div>}
              <span>
                {filterButtons}
                {!hideExport && <button className="btn btn-link" onClick={hndExportTable}>
                  Export Table
                </button>}
              </span>
            </div>
            {filterExplainer ? (
              <div className="p-2 flex-grow-1">
                Filters Applied: {filterExplainer}
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="p-2">
            { isLoading ? <><div className="card text-center w-33 mx-auto">Loading...</div></> : null }
            <DataTable columns={columns} data={filteredData} sortServer={!!onChangeSort} onSort={onChangeSort ? hndChangeSort : undefined}  />
          </div>
        </div>
      </div>
      {tableCount ? (
        <div className="row border-top">
          <div className="col-6">
            <div className="p-2 d-flex">
              <span className="align-self-center px-2">Show:</span>
              <select
                value={limit}
                name="limitDropdown"
                onChange={hndChangeLimit}
                className="form-select w-25"
              >
                <option value="10">10 records</option>
                <option value="25">25 records</option>
                <option value="50">50 records</option>
                <option value="100">100 records</option>
              </select>
              {isLoading ? <span>Loading...</span> : null}
            </div>
          </div>
          <div className="col-6 text-end d-flex justify-content-end">
            <div className="p-2 align-self-center">
              Page {calcCurrentPage() + 1} of {calcTotalPages()}
              <span className="px-3">&bull;</span>
              Go to:{" "}
              <input
                type="number"
                style={{ width: "3rem" }}
                onChange={hndTypedPage}
                defaultValue={calcCurrentPage()}
              />
              <span className="px-3">&bull;</span>{" "}
              <a href="#" onClick={subPage}>
                Prev
              </a>{" "}
              &bull;{" "}
              <a href="#" onClick={addPage}>
                Next
              </a>
            </div>
          </div>
        </div>
      ) : null}
    </>
  ) : null;
};

export default BasicFilterTable;
