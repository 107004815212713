import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";

import {
  changePasswordMutation,
  changePasswordMutationData,
  requestEmailChangeMutation,
  updateEmailMutationData,
  updateProfileInfoMutationData,
  updateProfileMutation,
} from "../apis/mutations/authentication";
import { useMutation, useQuery } from "@tanstack/react-query";
import Layout from "../components/Layout";
import { profileQuery } from "../apis/queries/authentication";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect, useState } from "react";
import ErrorFallback from "../components/Error";
import * as Sentry from "@sentry/react";

const Profile = () => {
  const user = useQuery(profileQuery);
  const updateProfile = useMutation(updateProfileMutation);
  const changeEmail = useMutation(requestEmailChangeMutation);
  const changePassword = useMutation(changePasswordMutation);
  const navigate = useNavigate();
  const [shouldRedirect, setShouldRedirect] = useState(false);

  useEffect(() => {
    const delay = 3000;

    const timer = setTimeout(() => {
      if (shouldRedirect) {
        navigate("/login"); //This is a redirection AFTER a password is changed. This is due to expiration of credentials.
      }
    }, delay);

    return () => clearTimeout(timer);
  }, [navigate, shouldRedirect]);

  const profileValidationSchema = Yup.object({
    first_name: Yup.string().required("First name is required"),
    last_name: Yup.string().required("Last name is required"),
  });

  const emailValidationSchema = Yup.object({
    new_email: Yup.string().required("New email is required"),
    confirm_email: Yup.string()
      .required("Email confirmation is required")
      .oneOf([Yup.ref("new_email"), ""], "Emails must match"),
  });

  const passwordValidationSchema = Yup.object({
    new_password: Yup.string()
      .min(8, "Password must be at least 8 characters long")
      .required("New password is required"),
    confirm_password: Yup.string()
      .min(8, "Password must be at least 8 characters long")
      .required("Password confirmation is required")
      .oneOf([Yup.ref("new_password"), ""], "Passwords must match"),
  });

  const hndProfileChange = async (values: updateProfileInfoMutationData) => {
    try {
      const response = await updateProfile.mutateAsync(values);
      if (response.status === 200) {
        toast.success("Your name has been updated.");
      } else {
        toast.error("An error occurred while updating your profile.");
      }
    } catch (error) {
      toast.error("An error occurred while updating your profile.");
    }
  };
  const hndEmailChange = async (values: updateEmailMutationData) => {
    try {
      const response = await changeEmail.mutateAsync(values);
      if (response.status === 200) {
        toast.success(
          "Email change submitted! A confirmation link has been sent to you."
        );
      } else {
        toast.error("An error occurred while updating your email.");
      }
    } catch (error) {
      toast.error("An error occurred while updating your email.");
    }
  };
  const hndPasswordChange = async (values: changePasswordMutationData) => {
    try {
      const response = await changePassword.mutateAsync(values);
      if (response.status === 200) {
        toast.success("Your password has been updated.");
        setShouldRedirect(true);
      } else {
        toast.error("An error occurred while updating your password.");
      }
    } catch (error) {
      toast.error("An error occurred while updating your password.");
    }
  };
  return (
    <>
      <Sentry.ErrorBoundary fallback={ErrorFallback}>
          <Layout>
            <ToastContainer />
            <div className="border border-secondary-subtle border-bottom-2 p-0 d-flex">
              <h1 className="dashboard m-0 p-2 flex-grow-1">PROFILE:</h1>
            </div>
            <div className="row mt-3 mx-4">
              { user.data ?
              <Formik
                initialValues={{ first_name: user.data.data.first_name, last_name: user.data.data.last_name, send_admin_emails: user.data.data.user_profile  ? user.data.data.user_profile.send_admin_emails : true }}
                validationSchema={profileValidationSchema}
                onSubmit={hndProfileChange}
              >
                {({
                  values,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  isValid,
                }) => (
                  <Form
                    onSubmit={handleSubmit}
                    className="login-form border border-warning rounded p-1 mb-3"
                  >
                    <h5>Update Profile Info:</h5>
                    <div className="mb-3">
                      <label htmlFor="first_name">First Name:</label>
                      <Field
                        type="text"
                        id="first_name"
                        name="first_name"
                        value={values.first_name}
                        className="form-control"
                        onChange={handleChange}
                        autoComplete="off"
                      />
                      <ErrorMessage
                        name="first_name"
                        component="div"
                        className="error-message text-danger"
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="last_name">Last Name:</label>
                      <Field
                        type="text"
                        id="last_name"
                        name="last_name"
                        value={values.last_name}
                        className="form-control"
                        onChange={handleChange}
                        autoComplete="off"
                      />
                      <ErrorMessage
                        name="last_name"
                        component="div"
                        className="error-message text-danger"
                      />
                    </div>
                    <div className="mb-3">
                      <Field
                        type="checkbox"
                        id="send_admin_emails"
                        name="send_admin_emails"
                        defaultValue={values.send_admin_emails}
                        className="form-checkbox"
                        onChange={handleChange}
                        autoComplete="off"
                      />
                      <label htmlFor="send_admin_emails">Send Admin Emails</label>
                      <ErrorMessage
                        name="send_admin_emails"
                        component="div"
                        className="error-message text-danger"
                      />
                    </div>
                    <div className="d-flex">
                      <button
                        type="submit"
                        className="btn btn-primary btn-block mb-auto me-auto"
                        disabled={isSubmitting || !isValid}
                      >
                        {isSubmitting ? "Submitting" : "Submit"}
                      </button>
                    </div>
                  </Form>
                )}
              </Formik> : null }

              <Formik
                initialValues={{ new_email: "", confirm_email: "" }}
                validationSchema={emailValidationSchema}
                onSubmit={hndEmailChange}
              >
                {({
                  values,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  isValid,
                }) => (
                  <Form
                    onSubmit={handleSubmit}
                    className="login-form border border-warning rounded p-1 mb-3"
                  >
                    <h5>Update your Email:</h5>
                    <p>Current Email: {user.data?.data.email}</p>
                    <div className="mb-3">
                      <label htmlFor="email">New Email:</label>
                      <Field
                        type="email"
                        id="new_email"
                        name="new_email"
                        value={values.new_email}
                        className="form-control"
                        onChange={handleChange}
                        autoComplete="off"
                      />
                      <ErrorMessage
                        name="new_email"
                        component="div"
                        className="error-message text-danger"
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="confirm_email">Confirm Email:</label>
                      <Field
                        type="email"
                        id="confirm_email"
                        name="confirm_email"
                        value={values.confirm_email}
                        className="form-control"
                        onChange={handleChange}
                        autoComplete="off"
                      />
                      <ErrorMessage
                        name="confirm_email"
                        component="div"
                        className="error-message text-danger"
                      />
                    </div>
                    <div className="d-flex">
                      <button
                        type="submit"
                        className="btn btn-primary btn-block mb-auto me-auto"
                        disabled={isSubmitting || !isValid}
                      >
                        {isSubmitting ? "Submitting" : "Submit"}
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
              <Formik
                initialValues={{ new_password: "", confirm_password: "" }}
                validationSchema={passwordValidationSchema}
                onSubmit={hndPasswordChange}
              >
                {({
                  values,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  isValid,
                }) => (
                  <Form
                    onSubmit={handleSubmit}
                    className="login-form border border-warning rounded p-1 mb-3"
                  >
                    <h5>Change Password:</h5>
                    <div className="mb-3">
                      <label htmlFor="new_password">New Password:</label>
                      <Field
                        type="password"
                        id="new_password"
                        name="new_password"
                        value={values.new_password}
                        className="form-control"
                        onChange={handleChange}
                        autoComplete="off"
                      />
                      <ErrorMessage
                        name="new_password"
                        component="div"
                        className="error-message text-danger"
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="confirm_password">
                        Confirm New Password:
                      </label>
                      <Field
                        type="password"
                        id="confirm_password"
                        name="confirm_password"
                        value={values.confirm_password}
                        className="form-control"
                        onChange={handleChange}
                        autoComplete="off"
                      />
                      <ErrorMessage
                        name="confirm_password"
                        component="div"
                        className="error-message text-danger"
                      />
                    </div>
                    <div className="d-flex">
                      <button
                        type="submit"
                        className="btn btn-primary btn-block mb-auto me-auto"
                        disabled={isSubmitting || !isValid}
                      >
                        {isSubmitting ? "Submitting" : "Submit"}
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </Layout>
        
      </Sentry.ErrorBoundary>
    </>
  );
};

export default Profile;
