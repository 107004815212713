import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { useMutation, useQuery } from "@tanstack/react-query";

import type { requestResetPasswordMutationData } from "../apis/mutations/authentication";
import { requestResetPasswordMutation } from "../apis/mutations/authentication";
import { profileQuery } from "../apis/queries/authentication";

function ResetPassword() {
  const currentSession = useQuery(profileQuery);
  const {
    mutate: performResetRequest,
    status: resetRequestStatus,
    data: resetRequestData,
  } = useMutation(requestResetPasswordMutation);

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required."),
    confirm_email: Yup.string()
      .email("Invalid email address")
      .required("Email is required."),
  });

  const hndSubmit = async (values: requestResetPasswordMutationData) => {
    await performResetRequest(values);

    return resetRequestStatus === "error";


  };

  if (currentSession.status === "loading") {
    return (
      <div className="row mt-5">
        <div className="col-md-6 offset-md-3 col-sm-10 offset-sm-1">
          <p>Loading, please wait...</p>
        </div>
      </div>
    );
  }

  if (
    currentSession.status === "success" &&
    currentSession.data.data.email !== ""
  ) {
    return (
      <div className="row mt-5">
        <div className="col-md-6 offset-md-3 col-sm-10 offset-sm-1">
          <p>
            You're logged in. Go to your Profile page to reset your password.
          </p>
        </div>
      </div>
    );
  }

  if (resetRequestStatus === "success" && resetRequestData.data.success) {
    return (
      <div className="row mt-5">
        <div className="col-md-6 offset-md-3 col-sm-10 offset-sm-1">
          <div className="login-form border border-warning rounded p-4">
            <div className="alert alert-success">
              <strong>Success!</strong> An email has been sent to your email
              address with a link to reset your password.
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="row mt-5">
      <div className="col-md-6 offset-md-3 col-sm-10 offset-sm-1">
        <Formik
          initialValues={{
            email: "",
            confirm_email: "",
          }}
          validationSchema={validationSchema}
          onSubmit={hndSubmit}
        >
          {({
            values,
            handleSubmit,
            handleChange,
          }) => (
            <Form
              name="signupForm"
              id="signupForm"
              className="login-form border border-warning rounded p-4"
              onSubmit={handleSubmit}
            >
              <p>
                Forgot your password? Fill out this form and we'll send you an
                email with a link that will allow you to change it.
              </p>

              {resetRequestStatus === "error" ||
              (resetRequestStatus === "success" &&
                !resetRequestData.data.success) ? (
                <div className="alert alert-danger">
                  Something went wrong. Please double-check your email and
                  password and try again.
                </div>
              ) : null}

              <div className="form-group mb-4">
                <label htmlFor="email">Email:</label>
                <Field
                  type="email"
                  id="email"
                  name="email"
                  value={values.email}
                  className="form-control"
                  onChange={handleChange}
                  autoComplete="username"
                />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="error-message text-danger"
                />
              </div>

              <div className="form-group mb-4">
                <label htmlFor="confirm_email">Confirm Email:</label>
                <Field
                  type="confirm_email"
                  id="confirm_email"
                  name="confirm_email"
                  value={values.confirm_email}
                  className="form-control"
                  onChange={handleChange}
                  autoComplete="username"
                />
                <ErrorMessage
                  name="confirm_email"
                  component="div"
                  className="error-message text-danger"
                />
              </div>

              <button
                type="submit"
                id="submitButton"
                className="btn btn-primary"
              >
                <span>Continue</span>
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default ResetPassword;
