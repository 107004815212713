import * as React from "react";

import DataTable from "react-data-table-component";

import "../styles/dashboard.css";
import Layout from "../components/Layout";
import { StockIssueQueryData } from "../apis/interfaces/tickets";
import {
  StockMutationData,
  StockQueryData,
  UnitOfMeasureQueryData,
} from "../apis/interfaces/invitems";
import { useMutation, useQuery } from "@tanstack/react-query";
import { stockQueryAll, unitOfMeasureQueryAll } from "../apis/queries/invitems";
import { stockIssueQueryAll } from "../apis/queries/tickets";
import { updateStockMutation } from "../apis/mutations/invitems";
import { Modal } from "react-bootstrap";
import ReplenishForm from "../forms/ReplenishForm";
import { useNavigate } from "react-router-dom";
import { profileQuery } from "../apis/queries/authentication";
import ErrorFallback from "../components/Error";
import * as Sentry from "@sentry/react";

interface ReplenishModalProps {
  show: boolean;
  onCancel: Function;
  onOk: Function;
  setShowReplenish: any;
  showReplenish: boolean;
  stocks: any;
}

const Dashboard: React.FC = () => {
  const blankStockForm = () => {
    return {
      id: 0,
      stock_category: {
        id: 0,
        stock_category: "",
      },
      stock_disposition: "",
      stock_type: "",
      stock: "",
      lead_time: 0,
      taxable_ny: false,
      taxable_nj: false,
      quantity_on_hand: 0,
      reorder_point: 0,
      reorder_quantity: 0,
      unit_of_measure: {
        unit_of_measure: "",
      },
      unit_price: 0,
      vendor: 0,
      active: true,
      serial: "",
      rental_rate: 0,
      ycc_serial: "",
      make: "",
      model: "",
    };
  };
  const [showReplenish, setShowReplenish] = React.useState(false);
  const [stockInitialValues, setStockInitialValues] = React.useState(
    blankStockForm()
  );
  const ticketData = useQuery(stockIssueQueryAll(10, 0));
  const invData = useQuery(stockQueryAll(10, 0));
  const { data: unit_data } = useQuery(unitOfMeasureQueryAll());
  const profile = useQuery(profileQuery);
  const [units, setUnits] = React.useState<UnitOfMeasureQueryData[]>([]);
  const navigate = useNavigate();

  React.useEffect(() => {
    if (unit_data) {
      setUnits(unit_data);
    }
    if (profile.status === "error") {
      navigate("/login");
    }
  }, [unit_data, profile, navigate]);
  if (invData.isLoading) {
    return <div>Loading...</div>;
  }

  const ticketColumns = [
    {
      name: "Job Title",
      selector: (row: StockIssueQueryData) => row.job.job,
      sortable: true,
    },
    {
      name: "Create Date",
      selector: (row: StockIssueQueryData) =>
        new Date(row.created_at).toLocaleDateString(),
      sortable: true,
    },
    {
      name: "Status",
      selector: (row: StockIssueQueryData) => row.status,
      sortable: true,
    },
    {
      name: "Number of Items",
      cell: (row: StockIssueQueryData) => (
        <>{row.stock_issue_details?.length}</>
      ),
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row: StockIssueQueryData) => (
        <>
          <button
            className="btn btn-sm btn-link"
            onClick={() => fireOnFulfill(row)}
          >
            View/Fulfill
          </button>
        </>
      ),
    },
  ];

  const fireOnFulfill = (row: StockIssueQueryData) => {
    window.location.href = `/ticket/${row.id}/`;
  };

  const fireOnReplenish = (row: StockQueryData) => {
    setStockInitialValues({
      id: Number(row.id),
      stock_category: row.stock_category,
      stock_disposition: row.stock_disposition,
      stock_type: row.stock_type,
      stock: row.stock,
      lead_time: row.lead_time,
      taxable_ny: row.taxable_ny,
      taxable_nj: row.taxable_nj,
      quantity_on_hand: row.quantity_on_hand,
      reorder_point: row.reorder_point,
      reorder_quantity: row.reorder_quantity,
      unit_of_measure: row.unit_of_measure,
      unit_price: row.unit_price,
      vendor: row.vendor,
      active: row.active,
      serial: row.serial,
      rental_rate: row.rental_rate,
      ycc_serial: row.ycc_serial,
      make: row.make,
      model: row.model,
    });
    setShowReplenish(true);
  };

  const invColumns = [
    {
      name: "Item",
      selector: (row: StockQueryData) => row.stock,
      sortable: true,
    },
    {
      name: "Type",
      selector: (row: StockQueryData) => row.stock_type,
      sortable: true,
    },
    {
      name: "Category",
      selector: (row: StockQueryData) => row.stock_category.stock_category,
      sortable: true,
    },
    {
      name: "Quantity on Hand",
      selector: (row: StockQueryData) => row.quantity_on_hand,
      sortable: true,
    },
    {
      name: "Reorder Point",
      selector: (row: StockQueryData) => row.reorder_point,
      sortable: true,
    },
    {
      name: "Unit",
      sortable: true,
      selector: (row: StockQueryData) => {
        const unitData = units.find((unit) => {
          return unit.id === Number(row.unit_of_measure.id);
        });

        return unitData?.unit_of_measure ?? "";
      },
    },
    {
      name: "Actions",
      cell: (row: StockQueryData) => (
        <>
          <button
            className="btn btn-sm btn-link"
            onClick={fireOnReplenish.bind(this, row)}
          >
            Replenish
          </button>
        </>
      ),
    },
  ];
  const ReplenishModal = (props: ReplenishModalProps) => {
    const { onCancel, onOk, setShowReplenish, showReplenish, stocks } = props;
    //Mutation functions

    const updateStockMutationFn = async (
      params: [number, StockMutationData]
    ) => {
      const [id, data] = params;
      try {
        await updateStockMutation.mutationFn(id, data);
        onOk();
      } catch (error) {
        console.log(error);
      }
    };

    const updateStock = useMutation(updateStockMutationFn);

    const hndSubmit = async (values: StockMutationData) => {
      updateStock.mutate([values.id as number, values]);
      stocks.refetch();
    };
    const hndModalClose = () => {
      setShowReplenish(false);
      onCancel();
    };

    return (
      <Modal show={showReplenish} onCancel={hndModalClose}>
        <Modal.Header>Replenish Stock</Modal.Header>
        <ReplenishForm
          initialValues={stockInitialValues}
          onCancel={hndModalClose}
          onSubmit={hndSubmit}
        />
      </Modal>
    );
  };
  const hndModalClose = () => {
    setShowReplenish(false);
  };

  const lowStockItems = invData.data?.results
    ? invData.data.results.filter(
        (item: StockQueryData) => item.quantity_on_hand <= item.reorder_point
      )
    : [];

  return (
    <Layout>
      <Sentry.ErrorBoundary fallback={ErrorFallback}>
       
          <div className="row mb-4">
            <div className="col-md-10 offset-md-1 col-sm-12">
              <div className="border border-secondary-subtle p-0">
                <h1 className="dashboard m-0 p-2">Recent Tickets</h1>
              </div>
              <div className="p-2">
                {ticketData.data ? (
                  <DataTable
                    columns={ticketColumns}
                    data={ticketData.data.results}
                  />
                ) : null}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-10 offset-md-1 col-sm-12">
              <div className="border border-secondary-subtle p-0">
                <h1 className="dashboard m-0 p-2">Low Stock</h1>
              </div>
              <div className="p-2">
                {lowStockItems ? (
                  <DataTable columns={invColumns} data={lowStockItems} />
                ) : null}
              </div>
            </div>
          </div>
          <ReplenishModal
            show={showReplenish}
            onOk={hndModalClose}
            onCancel={hndModalClose}
            setShowReplenish={setShowReplenish}
            showReplenish={showReplenish}
            stocks={lowStockItems}
          />
        
      </Sentry.ErrorBoundary>
    </Layout>
  );
};

export default Dashboard;
