import * as Yup from "yup";
import { ErrorMessage, Field, Form, Formik, FormikHelpers } from "formik";
import type {
  CompanyQueryData,
  JobMutationData,
  SalesTaxQueryData,
  User,
} from "../apis/interfaces/common";
import { Modal } from "react-bootstrap";
import {
  companyQueryAll,
  salesTaxQueryAll,
  userQueryAll,
} from "../apis/queries/common";
import { useQuery } from "@tanstack/react-query";
import React, { useEffect } from "react";
import Select from "react-select";

interface JobFormProps {
  initialValues: JobMutationData;
  onSubmit: any;
  onCancel: any;
}

const validationSchema = Yup.object({
  job: Yup.string().required("Job name is required"),
  job_number: Yup.string().required("Job code is required"),
  sales_tax: Yup.number().required(),
});

const JobForm = (props: JobFormProps) => {
  const { initialValues, onSubmit, onCancel } = props;
  const [companies, setCompanies] = React.useState<CompanyQueryData[]>([]);
  const [taxes, setTaxes] = React.useState<SalesTaxQueryData[]>([]);
  const [users, setUsers] = React.useState<User[]>([]);
  const [selectedUsers, setSelectedUsers] = React.useState<number[]>([]);
  const [selectedCompanies, setSelectedCompanies] = React.useState<number[]>(
    []
  );

  const { data } = useQuery(companyQueryAll());
  const { data: tax_data } = useQuery(salesTaxQueryAll);
  const { data: company_data } = useQuery(companyQueryAll());
  const { data: userData } = useQuery(userQueryAll()); // Replace "userQueryAll" with the actual query function for fetching users
  useEffect(() => {
    if (company_data) {
      setCompanies(company_data);
    }
    if (tax_data) {
      setTaxes(tax_data);
    }
    if (userData) {
      setUsers(userData);
    }
    setSelectedUsers(initialValues.users ?? []);
    setSelectedCompanies(initialValues.companies ?? []);
  }, [data, tax_data, company_data, userData, initialValues]);
  console.log("SelectedUsers: ", selectedUsers);
  console.log("SelectedCompanies", selectedCompanies);
  const handleSubmit = (
    values: JobMutationData,
    { setStatus }: FormikHelpers<JobMutationData>
  ) => {
    try {
        if (values.companies.length > 0){
        values.company = values.companies[0];
      }
      if (values.users.length === 1 && values.users[0] === 0) {
        values.users = [];
      }
      validationSchema.validateSync(values, { abortEarly: false });
      onSubmit(values);
    } catch (errors) {
      setStatus("An error occurred during form submission.");
    }
  };
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({ handleChange, handleSubmit, isSubmitting, isValid }) => (
        <>
          <Form onSubmit={handleSubmit}>
            <Modal.Body>
              <div className="form-group mb-2">
                <label htmlFor="job">Job Name:</label>
                <Field
                  type="text"
                  id="job"
                  name="job"
                  className="form-control"
                  onChange={handleChange}
                />
                <ErrorMessage
                  name="job"
                  component="div"
                  className="error-message text-danger"
                />
              </div>

              <div className="form-group mb-2">
                <label htmlFor="job">Job Number:</label>
                <Field
                  type="text"
                  id="job_number"
                  name="job_number"
                  className="form-control"
                  onChange={handleChange}
                />
                <ErrorMessage
                  name="job_number"
                  component="div"
                  className="error-message text-danger"
                />
              </div>
              <div className="form-group mb-2">
                <label htmlFor="assigned_companies">Assigned Companies:</label>
                <p>
                  The first company displayed below will be set as primary for
                  reports.
                </p>
                <Field
                  as={Select}
                  id="assigned_companies"
                  name="assigned_companies"
                  options={companies.map((company) => ({
                    value: company.id,
                    label: company.company,
                  }))}
                  isMulti
                  value={selectedCompanies.map((selectedCompId) => {
                    const selectedCompany = companies.find(
                      (company) => company.id === selectedCompId
                    );
                    return selectedCompany
                      ? {
                          value: selectedCompany.id,
                          label: selectedCompany.company,
                        }
                      : null;
                  })}
                  onChange={(selectedOptions: any) => {
                    const selectedCompanies = selectedOptions
                      ? selectedOptions.map((option: any) => option.value)
                      : [];
                    setSelectedCompanies(selectedCompanies);
                    handleChange({
                      target: {
                        id: "companies",
                        name: "companies",
                        value: selectedCompanies,
                      },
                    });
                  }}
                />
                <ErrorMessage
                  name="assigned_companies"
                  component="div"
                  className="error-message text-danger"
                />
              </div>
              <div className="form-group mb-2">
                <label htmlFor="stock">Sales Tax:</label>
                <Field
                  as="select"
                  id="sales_tax"
                  name="sales_tax"
                  className="form-control"
                >
                  <option value="">Select Sales Tax</option>
                  {taxes.map((tax) => (
                    <option key={tax.id} value={tax.id}>
                      {tax.sales_tax_code +
                        ": " +
                        tax.sales_tax +
                        " (" +
                        tax.sales_tax_percentage +
                        "%)"}
                    </option>
                  ))}
                </Field>
                <ErrorMessage
                  name="company"
                  component="div"
                  className="error-message text-danger"
                />
              </div>
              <div className="form-group mb-2">
                <label htmlFor="users">Assigned Users:</label>
                <Field
                  as={Select}
                  id="users"
                  name="users"
                  options={users.map((user) => ({
                    value: user.id,
                    label: user.email,
                  }))}
                  isMulti
                  value={selectedUsers.map((selectedUserId) => {
                    const selectedUser = users.find(
                      (user) => user.id === selectedUserId
                    );
                    return selectedUser
                      ? { value: selectedUser.id, label: selectedUser.email }
                      : null;
                  })}
                  onChange={(selectedOptions: any) => {
                    const selectedUsers = selectedOptions
                      ? selectedOptions.map((option: any) => option.value)
                      : [];
                    setSelectedUsers(selectedUsers);
                    handleChange({
                      target: {
                        id: "users",
                        name: "users",
                        value: selectedUsers,
                      },
                    });
                  }}
                />

                <ErrorMessage
                  name="users"
                  component="div"
                  className="error-message text-danger"
                />
              </div>
              <div className="form-check mb-2">
                <Field
                  type="checkbox"
                  id="active"
                  name="active"
                  className="form-check-input"
                  onChange={handleChange}
                />
                <label className="form-check-label" htmlFor="active">
                  Active
                </label>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="text-right">
                <button
                  onClick={onCancel}
                  className="btn btn-sm btn-danger mx-3"
                  type="button"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-sm btn-success"
                  disabled={!(!isSubmitting && isValid)}
                >
                  Save
                </button>
              </div>
            </Modal.Footer>
          </Form>
        </>
      )}
    </Formik>
  );
};

export default JobForm;
