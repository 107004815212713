import React, { useEffect, useState } from "react";
import { apiClient } from "../../apis/client";
import Layout from "../../components/Layout";
import BasicFilterTable from "../../components/BasicFilterTable";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

interface ReportData {
  id: number;
  stock: string;
}

function StockItemIndex() {
  const [reportData, setReportData] = useState<ReportData[] | null>(null);

  useEffect(() => {
    const getReport = async () => {
      try {
        const response = await apiClient.get(
          "/invitems/reports/StockItemIndex/generate/",
          {
            headers: {
              Accept: "application/json",
            },
          }
        );
        setReportData(response.data);
      } catch (error) {
        console.error("Error fetching form data:", error);
      }
    };

    getReport();
  }, []);
  const filterFn = (keyword: string, item: ReportData) => {
    const lowercaseKeyword = keyword.toLowerCase();
    const itemValues = Object.values(item);

    for (const value of itemValues) {
      if (typeof value === "string") {
        const lowercaseValue = value.toLowerCase();

        if (lowercaseValue.includes(lowercaseKeyword)) {
          return true;
        }
      }
    }

    return false;
  };
  const handleExportExcel = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      const response = await apiClient.get(
        "/invitems/reports/StockItemIndex/export_excel/",
        { responseType: "blob" } // Set the responseType to 'blob' to receive a Blob object in the response
      );
      const blob = await response.data;
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = `Stock Item Index ${new Date().toLocaleString()}.xlsx`;
      link.click();
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error fetching report data:", error);
      toast.error("An error occurred generating the report");
    }
  };

  const filterButtons = (
    <>
      <button
        type="submit"
        className="btn btn-link"
        onClick={handleExportExcel}
      >
        Export to Excel
      </button>
    </>
  );
  const columns = [
    {
      name: "Item ID",
      sortable: true,
      selector: (row: ReportData) => row.id,
    },
    {
      name: "Item Description",
      sortable: true,
      selector: (row: ReportData) => row.stock,
    },
  ];
  return (
    <div>
      <Layout>
      <div id="report-title-container" className="hide-printable">

        <ToastContainer />
        <h2>Stock Item Index Report</h2>
        <br></br>
        </div>

        <div className="report-data-printable">
        {reportData && (
          <BasicFilterTable
            tableKey="StockIndex"
            title="Stock Item Index Results"
            dataSource={reportData as ReportData[]}
            columns={columns}
            filterFn={filterFn}
            filterButtons={filterButtons}
            exportFileName={"Stock Item Index Export Data.csv"}
          />
        )}
        </div>
      </Layout>
    </div>
  );
}

export default StockItemIndex;
