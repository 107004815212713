interface Fallback{
  error: any;
}
// Your error fallback component
function ErrorFallback({ error }: Fallback) {
  return (
    <div className="d-flex w-100">
      <div className="card w-33 mx-auto">
        <div className="card-body text-center">
          <p>An error has occurred:</p>
          <pre>{error.message}</pre>
          <button onClick={() => window.location.reload()}>
            Try again
          </button>{" "}
        </div>
      </div>
    </div>
  );
}

export default ErrorFallback;
