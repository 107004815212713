import { useState } from "react";
import { Modal } from "react-bootstrap";
import type {
  StockIssueDetailQueryData,
  StockIssueDetailQueryWithVendorData,
} from "../apis/interfaces/tickets";

import "react-toastify/dist/ReactToastify.css";

import { useQuery } from "@tanstack/react-query";

// section costcode picker
import { costCodeQueryAll } from "../apis/queries/common";
import { CostCodeQueryData } from "../apis/interfaces/common";
import CostCodeSelector from "./CostCodeSelector";
import { updateSIDCostCodeMutation } from "../apis/mutations/tickets";
import { toast } from "react-toastify";

interface CostCodeReassignmentModalProps {
  jobId: number;
  stockIssueDetail:
    | StockIssueDetailQueryData
    | StockIssueDetailQueryWithVendorData;
  show: boolean;
  onChange: Function;
  onCancel: Function;
}

const CostCodeReassignmentModal = (props: CostCodeReassignmentModalProps) => {
  const { jobId, stockIssueDetail, show, onChange, onCancel } = props;

  const costcodes = useQuery(
    costCodeQueryAll(
      -1,
      -1,
      undefined,
      undefined,
      undefined,
      Number(jobId).toString()
    )
  );
  const [newCostCode, setNewCostCode] = useState<CostCodeQueryData | null>(
    null
  );

  const hndModalClose = () => {
    onCancel();
  };
  const updateCostCode = updateSIDCostCodeMutation.mutationFn;
  const hndModalSubmit = async () => {
    if (newCostCode) {
      try {
        await updateCostCode(
          Number(stockIssueDetail.id),
          Number(newCostCode.id)
        );

        toast.success(`Cost code updated for ${stockIssueDetail.stock.stock}`);
        onChange();
        onCancel(); 
      } catch (e) {
        toast.error("Error occurred while submitting");
      }
    }
  };

  const hndCodeChange = (codeId: number) => {
    setNewCostCode(
      costcodes.data.find((elem: CostCodeQueryData) => elem.id === codeId)
    );
  };

  return (
    costcodes.data && (
      <>
        <Modal show={show} onCancel={hndModalClose}>
          <Modal.Body>
            <p>Select a new cost code for {stockIssueDetail.stock.stock}:</p>

            <CostCodeSelector
              selectedJob={jobId}
              onChange={hndCodeChange}
              defaultValue={undefined}
            />
          </Modal.Body>
          <Modal.Footer>
            <div className="text-right">
              <button
                onClick={hndModalClose}
                className="btn btn-sm btn-danger mx-3"
                type="button"
              >
                Cancel
              </button>

              <button
                type="submit"
                className="btn btn-sm btn-success"
                disabled={newCostCode === null}
                onClick={hndModalSubmit}
              >
                Save
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      </>
    )
  );
};
export default CostCodeReassignmentModal;
