import * as Yup from "yup";
import { ErrorMessage, Field, Form, Formik, FormikHelpers } from "formik";
import type { VendorMutationData } from "../apis/interfaces/common";
import { Modal } from "react-bootstrap";

//Good
interface VendorFormProps {
  initialValues: VendorMutationData;
  onSubmit: any;
  onCancel: any;
}

const validationSchema = Yup.object({
  vendor_name: Yup.string().required("Vendor name is required."),
  address: Yup.string().nullable(),
  city: Yup.string().nullable(),
  state: Yup.string().nullable(),
  zip_code: Yup.string().nullable(),
  country: Yup.string().nullable(),
  fax: Yup.string().nullable(),
  telephone: Yup.string().nullable(),
  contact: Yup.string().nullable(),
  notes: Yup.string().nullable(),
});

const VendorForm = (props: VendorFormProps) => {
  const { initialValues, onSubmit, onCancel } = props;
  const handleSubmit = (
    values: VendorMutationData,
    { setStatus }: FormikHelpers<VendorMutationData>
  ) => {
    try {
      onSubmit(values);
    } catch (errors) {
      setStatus("An error occurred during form submission.");
    }
  };
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({
        handleChange,
        handleSubmit,
        isSubmitting,
        isValid,
        status
      }) => (
        <>
          <Form onSubmit={handleSubmit}>
            <Modal.Body>
              {status}
              <div className="form-group mb-2">
                <label htmlFor="vendor">Vendor Name:</label>
                <Field
                  type="text"
                  id="vendor_name"
                  name="vendor_name"
                  className="form-control"
                  onChange={handleChange}
                />
                <ErrorMessage
                  name="vendor_name"
                  component="div"
                  className="error-message text-danger"
                />
              </div>

              <div className="form-group mb-2">
                <label htmlFor="vendor">Address:</label>
                <Field
                  type="text"
                  id="address"
                  name="address"
                  className="form-control"
                  onChange={handleChange}
                />
                <ErrorMessage
                  name="address"
                  component="div"
                  className="error-message text-danger"
                />
              </div>
              <div className="form-group mb-2">
                <label htmlFor="vendor">City:</label>
                <Field
                  type="text"
                  id="city"
                  name="city"
                  className="form-control"
                  onChange={handleChange}
                />
                <ErrorMessage
                  name="city"
                  component="div"
                  className="error-message text-danger"
                />
              </div>
              <div className="form-group mb-2">
                <label htmlFor="vendor">State:</label>
                <Field
                  type="text"
                  id="state"
                  name="state"
                  className="form-control"
                  onChange={handleChange}
                />
                <ErrorMessage
                  name="state"
                  component="div"
                  className="error-message text-danger"
                />
              </div>
              <div className="form-group mb-2">
                <label htmlFor="vendor">Zip Code:</label>
                <Field
                  type="text"
                  id="zip_code"
                  name="zip_code"
                  className="form-control"
                  onChange={handleChange}
                />
                <ErrorMessage
                  name="zip_code"
                  component="div"
                  className="error-message text-danger"
                />
              </div>
              <div className="form-group mb-2">
                <label htmlFor="vendor">Country:</label>
                <Field
                  type="text"
                  id="country"
                  name="country"
                  className="form-control"
                  onChange={handleChange}
                />
                <ErrorMessage
                  name="country"
                  component="div"
                  className="error-message text-danger"
                />
              </div>
              <div className="form-group mb-2">
                <label htmlFor="vendor">Fax:</label>
                <Field
                  type="text"
                  id="fax"
                  name="fax"
                  className="form-control"
                  onChange={handleChange}
                />
                <ErrorMessage
                  name="fax"
                  component="div"
                  className="error-message text-danger"
                />
              </div>
              <div className="form-group mb-2">
                <label htmlFor="vendor">Telephone:</label>
                <Field
                  type="text"
                  id="telephone"
                  name="telephone"
                  className="form-control"
                  onChange={handleChange}
                />
                <ErrorMessage
                  name="telephone"
                  component="div"
                  className="error-message text-danger"
                />
              </div>
              <div className="form-group mb-2">
                <label htmlFor="vendor">Contact:</label>
                <Field
                  type="text"
                  id="contact"
                  name="contact"
                  className="form-control"
                  onChange={handleChange}
                />
                <ErrorMessage
                  name="contact"
                  component="div"
                  className="error-message text-danger"
                />
              </div>
              <div className="form-group mb-2">
                <label htmlFor="vendor">Notes:</label>
                <Field
                  type="text"
                  id="notes"
                  name="notes"
                  className="form-control"
                  onChange={handleChange}
                />
                <ErrorMessage
                  name="notes"
                  component="div"
                  className="error-message text-danger"
                />
              </div>
              <div className="form-check mb-2">
                <Field
                  type="checkbox"
                  id="active"
                  name="active"
                  className="form-check-input"
                  onChange={handleChange}
                />
                <label className="form-check-label" htmlFor="active">
                  Active
                </label>
                <ErrorMessage
                  name="active"
                  component="div"
                  className="error-message text-danger"
                />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="text-right">
                <button
                  onClick={onCancel}
                  className="btn btn-sm btn-danger mx-3"
                  type="button"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-sm btn-success"
                  disabled={!(!isSubmitting && isValid)}
                >
                  Save
                </button>
              </div>
            </Modal.Footer>
          </Form>
        </>
      )}
    </Formik>
  );
};

export default VendorForm;
