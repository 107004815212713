// Meta routes for the app

import TicketDisplay from "../pages/Tickets/TicketDisplay";
import TicketIndex from "../pages/Tickets/TicketIndex";

const ticketRoutes = [
  {
    path: "/ticket/:id",
    element: <TicketDisplay />,
  },
  {
    path: "/tickets",
    element: <TicketIndex />,
  },
];

export default ticketRoutes;
