import { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Modal } from "react-bootstrap";
import type {
  JobQueryData,
  LocationMutationData,
  LocationQueryData,
} from "../../apis/interfaces/common";
import { jobQueryAll, locationQueryAll } from "../../apis/queries/common";
import {
  createLocationMutation,
  updateLocationMutation,
} from "../../apis/mutations/common";
import LocationForm from "../../forms/LocationForm";

import Layout from "../../components/Layout";
import Loader from "../../components/Loader";
import BasicFilterTable from "../../components/BasicFilterTable";
import { toast, ToastContainer } from "react-toastify";
import ErrorFallback from "../../components/Error";
import * as Sentry from "@sentry/react";

interface LocationTableProps {
  onAddNew: any;
  onEdit: any;
  locations: Array<LocationQueryData>;
  setLocationInitialValues: any;
  setShowLocationModal: any;
  edit: boolean;
  setEdit: any;
}

interface LocationModalProps {
  show: boolean;
  onCancel: Function;
  onOk: Function;
  initialValues: LocationMutationData;
  setShowLocationModal: any;
  setLocationInitialValues: any;
  edit: boolean;
  setEdit: any;
}

const LocationModal = (props: LocationModalProps) => {
  const { show, onCancel, onOk, initialValues, setEdit, edit } = props;
  const queryClient = useQueryClient();
  const createLocation = useMutation({
    ...createLocationMutation,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["locationQueryAll"] });
      onOk();
    },
  });
  const updateLocationMutationFn = async (
    params: [number, LocationMutationData]
  ) => {
    const [id, data] = params;
    try {
      await updateLocationMutation.mutationFn(id, data);
      onOk();
    } catch (error) {
      // Handle error
    }
  };

  const updateLocation = useMutation(updateLocationMutationFn);

  const hndSubmit = async (values: LocationMutationData) => {
    try {
      if (!values.id) {
        await createLocation.mutateAsync(values);
        toast.success("Location created");
      } else {
        await updateLocation.mutateAsync([values.id as number, values]);
        setEdit(false);
        toast.success("Location updated");
      }
    } catch (err) {
      console.log(err);
      toast.error("An error occurred while updating the location");
    }
  };
  const handleModalClose = () => {
    setEdit(false);
    onCancel();
  };

  return (
    <Modal show={show} onCancel={handleModalClose}>
      {edit && (
        <Modal.Header closeButton onClick={handleModalClose}>
          Edit Location
        </Modal.Header>
      )}
      {!edit && (
        <Modal.Header closeButton onClick={handleModalClose}>
          Add Location
        </Modal.Header>
      )}
      <LocationForm
        initialValues={initialValues}
        onCancel={handleModalClose}
        onSubmit={hndSubmit}
      />
    </Modal>
  );
};

const LocationTable = (props: LocationTableProps) => {
  const {
    onAddNew,
    locations,
    setShowLocationModal,
    setLocationInitialValues,
    setEdit,
  } = props;
  const [jobs, setJobs] = useState<JobQueryData[]>([]);

  const fireOnEdit = (row: LocationQueryData) => {
    setLocationInitialValues({
      id: row.id,
      location_title: row.location_title,
      location_description: row.location_description,
      address: row.address,
      address2: row.address2,
      city: row.city,
      state: row.state,
      zip_code: row.zip_code,
      country: row.country,
      active: row.active,
      job: row.job,
    });
    setEdit(true);
    setShowLocationModal(true);
  };
  const { data } = useQuery(jobQueryAll());

  useEffect(() => {
    if (data) {
      setJobs(data);
    }
  }, [data]);

  const columns = [
    {
      name: "Name",
      sortable: true,
      selector: (row: LocationQueryData) => row.location_title,
      cell: (row: LocationQueryData) => {
        let locationTitle = "";
        let locationDescription = "";

        // add location title
        if (row.location_title) {
          locationTitle = row.location_title;
        }

        // add location description
        if (row.location_description) {
          locationDescription = row.location_description;
        }

        return (
          <div>
            <strong>{locationTitle}</strong>
            <div>{locationDescription}</div>
          </div>
        );
      },
    },
    {
      name: "Job",
      sortable: true,
      selector: (row: LocationQueryData) => {
        const jobData = jobs.find((job) => job.id === row.job);
        return jobData?.job || "";
      },
    },
    {
      name: "Address",
      sortable: true,
      sortFunction: (a: LocationQueryData, b: LocationQueryData) => {
        const getAddressString = (row: LocationQueryData) => {
          return [
            row.address,
            row.address2,
            `${row.city ? row.city + "," : ""} ${
              row.state ? row.state + " " : ""
            }${row.zip_code ? row.zip_code : ""}`.trim(),
          ].join(" ");
        };
        return getAddressString(a).localeCompare(getAddressString(b));
      },
      cell: (row: LocationQueryData) => {
        let addressParts = [];

        if (row.address) {
          addressParts.push(row.address);
        }

        if (row.address2) {
          addressParts.push(row.address2);
        }

        if (row.city || row.state || row.zip_code) {
          addressParts.push(
            `${row.city ? row.city + "," : ""} ${
              row.state ? row.state + " " : ""
            }${row.zip_code ? row.zip_code : ""}`.trim()
          );
        }

        return (
          <div>
            {addressParts.map((part, index) => (
              <div key={index}>{part}</div>
            ))}
          </div>
        );
      },
    },
    {
      name: "Active",
      sortable: true,
      selector: (row: LocationQueryData) => row.active,
      cell: (row: LocationQueryData) => (
        <>
          {row.active ? (
            <span className="text-success">&#x2714;</span> // Checkmark symbol
          ) : (
            <span className="text-danger">&#x2718;</span> // Cross symbol
          )}
        </>
      ),
    },
    {
      name: "Actions",
      cell: (row: LocationQueryData) => (
        <>
          <button
            className="btn btn-sm btn-link"
            onClick={fireOnEdit.bind(this, row)}
          >
            Edit
          </button>
        </>
      ),
    },
  ];
  const filterFn = (keyword: string, item: LocationQueryData) => {
    const lowercaseKeyword = keyword.toLowerCase();
    const itemValues = Object.values(item);

    for (const value of itemValues) {
      if (typeof value === "string") {
        const lowercaseValue = value.toLowerCase();

        if (lowercaseValue.includes(lowercaseKeyword)) {
          return true;
        }
      }
    }

    return false;
  };

  const filterButtons = (
    <>
      <button className="btn btn-link" onClick={onAddNew}>
        Add New
      </button>
    </>
  );
  return (
    <div className="row">
      <div className="col-12">
        <div className="border border-secondary-subtle p-0 d-flex">
          <div className="align-self-center mr-2"></div>
        </div>
        <div className="p-2">
          <BasicFilterTable
            tableKey="locations"
            title="Locations"
            dataSource={locations}
            columns={columns}
            filterFn={filterFn}
            filterButtons={filterButtons}
            exportFileName={"Location Export Data.csv"}
          />
        </div>
      </div>
    </div>
  );
};

const blankLocationForm = () => {
  return {
    location_title: "",
    location_description: "",
    address: "",
    address2: "",
    city: "",
    state: "",
    zip_code: "",
    country: "",
    job: -1,
    active: true,
  };
};

const Location = () => {
  const [showLocationModal, setShowLocationModal] = useState(false);
  const [locationInitialValues, setLocationInitialValues] = useState(
    blankLocationForm()
  );
  const [edit, setEdit] = useState(false);

  const locations = useQuery(locationQueryAll());

  // Sort locations so that active ones come first
  let sortedLocations = locations.data;
  if (sortedLocations) {
    sortedLocations = [...sortedLocations].sort((a, b) => b.active - a.active);
  }

  const hndAddNew = () => {
    setLocationInitialValues(blankLocationForm());
    setShowLocationModal(true);
  };

  const hndEdit = () => {
    setEdit(true);
    setShowLocationModal(true);
    setLocationInitialValues({
      ...locationInitialValues,
      location_title: "",
      location_description: "",
      address: "",
      address2: "",
      city: "",
      state: "",
      zip_code: "",
      country: "",
      job: 0,
    });
  };

  const hndModelClose = () => {
    setEdit(false);
    setShowLocationModal(false);
    locations.refetch();
  };

  return (
    <Sentry.ErrorBoundary fallback={ErrorFallback}>
      <Layout>
        <Loader isLoading={locations.isLoading && locations.data !== undefined}>
          <LocationTable
            onAddNew={hndAddNew}
            onEdit={hndEdit}
            locations={sortedLocations}
            setLocationInitialValues={setLocationInitialValues}
            setShowLocationModal={setShowLocationModal}
            edit={edit}
            setEdit={setEdit}
          />
        </Loader>
        <ToastContainer />
        <LocationModal
          show={showLocationModal}
          onOk={hndModelClose}
          onCancel={hndModelClose}
          initialValues={locationInitialValues}
          setShowLocationModal={setShowLocationModal}
          setLocationInitialValues={setLocationInitialValues}
          edit={edit}
          setEdit={setEdit}
        />
      </Layout>
    </Sentry.ErrorBoundary>
  );
};

export default Location;
