import React, { useState } from "react";
import { apiClient } from "../../apis/client";
import Layout from "../../components/Layout";
import BasicFilterTable from "../../components/BasicFilterTable";
import { toast, ToastContainer } from "react-toastify";
import CostCodeReassignmentModal from "../../components/CostCodeReassignmentModal";
import "react-toastify/dist/ReactToastify.css";
import "../../styles/print.css";
import { StockIssueDetailQueryData } from "../../apis/interfaces/tickets";

const { DateTime } = require("luxon");

interface ReportData {
  id: number;
  stock_issue_id: number;
  quantity: number;
  stock_stock: any;
  job_id: number;
  job_number: any;
  job_name: any;
  date_created: Date;
  date_delivered: Date;
  cost_code: string;
}

function CostCodeReassignment() {
  const [reportData, setReportData] = useState<ReportData[] | null>(null);
  const [monthEnding, setMonthEnding] = useState(new Date());
  const [showCodeReassignModal, setShowCodeReassignModal] = useState(false);
  const [codeReassignJob, setCodeReassignJob] = useState<number | null>(null);
  const [stockIssueID, setStockIssueID] = useState<number | null>(null);
  const [codeReassignStockIssueDetail, setCodeReassignStockIssueDetail] =
    useState<StockIssueDetailQueryData | null>(null);
  async function fireCostCodeReassign(row: ReportData) {
    console.log("fireCostCodeReassign called"); // Add this line
    setStockIssueID(row.id);
    setCodeReassignJob(row.job_id);
    const data = await fetchStockIssueDetail({
      queryKey: ["stockIssueDetail", row.id],
    });
    setCodeReassignStockIssueDetail(data as StockIssueDetailQueryData);
    setShowCodeReassignModal(true);
  }

  function hndChange(sid: number) {
    console.log("removing row from dataset");
    const updatedReportData = reportData?.filter((r) => r.id !== sid) || null;
    setReportData(updatedReportData);
    setCodeReassignStockIssueDetail(null);
    setStockIssueID(null);
  }
  async function fetchStockIssueDetail({
    queryKey,
  }: {
    queryKey: [string, number];
  }) {
    const [, SID] = queryKey;
    const response = await fetch(`/api/tickets/stock_issue_detail/${SID}`);

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return response.json();
  }

  const handleSubmit = async (event: React.FormEvent) => {
    toast.info("Generating report");
    event.preventDefault();
    const yyyy = monthEnding.getFullYear();
    const mm = String(monthEnding.getMonth() + 1).padStart(2, "0"); // Month is zero-based, so add 1
    const dd = String(monthEnding.getDate()).padStart(2, "0");

    const monthEndingStr = `${yyyy}-${mm}-${dd}`;
    try {
      const response = await apiClient.get(
        `/tickets/v1/unassigned_costcodes/${monthEndingStr}/`
      );
      setReportData(response.data);
      toast.success("Report loaded.");
    } catch (error) {
      console.error("Error submitting report:", error);
    }
  };

  const filterFn = (keyword: string, item: ReportData) => {
    const lowercaseKeyword = keyword.toLowerCase();
    const itemValues = Object.values(item);

    for (const value of itemValues) {
      if (typeof value === "string") {
        const lowercaseValue = value.toLowerCase();

        if (lowercaseValue.includes(lowercaseKeyword)) {
          return true;
        }
      }
    }

    return false;
  };

  const filterButtons = <></>;
  const columns = [
    {
      name: "Job",
      sortable: true,
      selector: (row: ReportData) => <>{row.job_number}<br />{row.job_name}</>,
    },
    {
      name: "Issue#",
      width: "9%",
      sortable: true,
      selector: (row: ReportData) => row.stock_issue_id,
    },
    {
      name: <>Date<br />Delivered</>,
      width: "9%",
      sortable: true,
      selector: (row: ReportData) => row.date_delivered?.toString().slice(0, 10),
    },
    {
      name: "Qty",
      width: "7%",
      sortable: true,
      selector: (row: ReportData) => row.quantity,
    },
    {
      name: "Item",
      sortable: true,
      selector: (row: ReportData) => row.stock_stock,
    },
    {
      name: <>Current<br />Code</>,
      width: "12%",
      sortable: true,
      selector: (row: ReportData) => row.cost_code,
    },
    {
      name: "Actions",
      sortable: false,
      width: "15%",
      cell: (row: ReportData) => (
        <>
          <button
            className="btn btn-sm btn-light ml-auto ticket-button whitespace-nowrap"
            onClick={() => fireCostCodeReassign(row)}
          >
            Add Cost Code
          </button>
        </>
      ),
    },
  ];

  return (
    <div id="divToPrint">
      <Layout>
      <div id="report-title-container" className="hide-printable">
        <ToastContainer />
        <h2>Items without Cost Codes</h2>
        <br></br>
        <h4>REPORT PARAMETERS</h4>
        <form onSubmit={handleSubmit}>
          <label>Last Date:</label> <br />
          <input
            name="month_ending"
            type="date"
            className="form-control"
            required={true}
            defaultValue={DateTime.local().endOf("month").toISODate()}
            onChange={(e) => setMonthEnding(new Date(e.target.value))}
          ></input>
          <button
            type="submit"
            className="btn btn-sm btn-success mt-2 button-margin"
          >
            Generate Filtered Report
          </button>
          <button
            className="btn btn-sm btn-success mt-2  button-margin"
            onClick={() => window.print()}
          >
            Print/Export PDF
          </button>
        </form>

        <br />
        <br />
      </div>
      <div className="report-data-printable">

        {reportData && (
          <div>
            <BasicFilterTable
              tableKey="CostCodeReassignment"
              title="Items without Cost Codes"
              dataSource={reportData as ReportData[]}
              columns={columns}
              filterFn={filterFn}
              filterButtons={filterButtons}
              exportFileName={"Items without Cost Codes.csv"}
            />
          </div>
        )}
        {showCodeReassignModal &&
          codeReassignStockIssueDetail &&
          codeReassignJob && (
            <CostCodeReassignmentModal
              jobId={codeReassignJob}
              stockIssueDetail={codeReassignStockIssueDetail}
              show={showCodeReassignModal}
              onChange={() => hndChange(Number(stockIssueID))}
              onCancel={() => {
                setShowCodeReassignModal(false);
                setCodeReassignStockIssueDetail(null);
                setStockIssueID(null);
              }}
            />
          )}
      </div>
      </Layout>
    </div>
  );
}

export default CostCodeReassignment;
