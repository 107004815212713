import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { useParams } from "react-router-dom";
import Layout from "../../components/Layout";
import "../../styles/print.css";
import BasicFilterTable from "../../components/BasicFilterTable";
import type {
  StockChargeQueryData,
  StockIssueDetailQueryData,
  StockIssueQueryData,
} from "../../apis/interfaces/tickets";
import { StockQueryData } from "../../apis/interfaces/invitems";
import isEqual from "lodash/isEqual";
import {
  ChargeQueryData,
  DepartmentQueryData,
  JobQueryData,
} from "../../apis/interfaces/common";
import Loader from "../../components/Loader";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  useChargeQueryAll,
  useCreateCharge,
  useDeleteMiscChargeMutation,
  useDepartmentQueryAll,
  useJobQueryAll,
  useMarkItemAsReadyOrDelivered,
  useReturnItemMutation,
  useStockIssueQuery,
  useUpdateMiscCharge,
  useUpdateStockMutation,
  useGenerateStockIssueCharges,
} from "./Logic/MutationsAndQueries";
import useTransferItem from "./Logic/TransferLogic";
import { useQuery, useMutation } from "@tanstack/react-query";
import { profileQuery } from "../../apis/queries/authentication";
import AddItemToTicketForm from "../../forms/AddItemToTicketForm";
import ErrorFallback from "../../components/Error";
import { miscChargeLookup } from "../../apis/queries/tickets";
import * as Sentry from "@sentry/react";
import { stockSimilarQuery } from "../../apis/queries/invitems";
import Select from "react-select";

// section costcode picker
import CostCodeReassignmentModal from "../../components/CostCodeReassignmentModal";
// end section

const { DateTime } = require("luxon");

interface TicketDisplayBodyProps {
  stockIssueId: number;
}

// section costcode picker

// end section

const TicketDisplayBody = (props: TicketDisplayBodyProps) => {
  const { stockIssueId } = props;
  const { transferItem } = useTransferItem();
  const user = useQuery(profileQuery);
  // #region Mutations & Queries
  const stockIssue = useStockIssueQuery(stockIssueId);
  const createCharge = useCreateCharge();
  const updateMiscCharge = useUpdateMiscCharge();
  const { data: stockChargeQuery, refetch } = useQuery(
    miscChargeLookup(stockIssueId)
  );
  const markItemAsReadyOrDelivered = useMarkItemAsReadyOrDelivered();
  const returnItem = useReturnItemMutation();
  const deleteMiscCharge = useDeleteMiscChargeMutation();
  const updateStock = useUpdateStockMutation();
  const { data: charges } = useChargeQueryAll();
  const { data: departments } = useDepartmentQueryAll();
  const { data: jobs } = useJobQueryAll();
  // #endregion
  // #region States & UseEffect
  const [showReceivedDialog, setShowReceivedDialog] = useState(false);
  const [showMiscChargeDialog, setShowMiscChargeDialog] = useState(false);
  const [showReturnItemsDialog, setShowReturnItemsDialog] = useState(false);
  const [showAddItemDialog, setShowAddItemDialog] = useState(false);
  const [showMarkReadyDialog, setShowMarkReadyDialog] = useState(false);
  const [showTransferItemDialog, setShowTransferItemDialog] = useState(false);
  const [showDeliverItemDialog, setShowDeliverItemDialog] = useState(false);
  const [qtyReturnInput, setQtyReturnInput] = useState("");
  const [returnDate, setReturnDate] = useState("");
  const [editMiscCharge, setEditMiscCharge] = useState(false);
  const [showMarkMultipleReadyDialog, setShowMarkMultipleReadyDialog] =
    useState(false);
  const [showReturnSingleDialog, setShowReturnSingleDialog] = useState(false);
  const [miscChargeFormData, setMiscChargeFormData] = useState({
    id: 0,
    charge_amount: "",
    taxable: true,
    nontaxable_amount: "",
    charge_description: "",
    charge_status: "",
    received_by: "",
    charge_type: 0,
    department: 0,
    charge_date: DateTime.now(),
  });
  const [selectedTicketItem, setSelectedTicketItem] =
    useState<StockIssueDetailQueryData | null>(null);

  const [relatedCharges, setRelatedCharges] = useState<StockChargeQueryData[]>(
    []
  );
  const [selectedRows, setSelectedRows] = useState<StockIssueDetailQueryData[]>(
    []
  );

  const generateRentalCharges = useGenerateStockIssueCharges();

  // cost code reassign modal
  const [showCodeReassignModal, setShowCodeReassignModal] = useState(false);
  const [codeReassignStockIssueDetail, setCodeReassignStockIssueDetail] =
    useState<StockIssueDetailQueryData | null>(null);
  // end cost code reassign modal

  const [resetTicketView, setResetTicketView] = useState(false);

  const prevStockIssueRef = useRef<any>();

  useEffect(() => {
    if (!isEqual(prevStockIssueRef.current, stockIssue)) {
      prevStockIssueRef.current = stockIssue;
    }
  }, [stockIssue]);

  useEffect(() => {
    if (stockChargeQuery && stockIssue.data?.id) {
      const currentIssueId = Number(stockIssue.data.id);

      const filteredCharges = stockChargeQuery.filter(
        (charge: StockChargeQueryData) => {
          return Number(charge.stock_issue?.id) === currentIssueId;
        }
      );

      setRelatedCharges(filteredCharges);
    }
  }, [stockChargeQuery, stockIssue.data?.id]);

  // #endregion
  // #region Table Columns & Row Select

  const hndRowSelect = (row: StockIssueDetailQueryData) => {
    const updatedRows = selectedRows.includes(row)
      ? selectedRows.filter((selectedRow) => selectedRow.id !== row.id)
      : [...selectedRows, row];
    setSelectedRows(updatedRows);
  };
  const ticketFilterButtons = (
    <>
      {selectedRows.length > 1 && (
        <button
          className="btn btn-link"
          onClick={() => setShowReturnItemsDialog(true)}
        >
          Return Multiple
        </button>
      )}
      {selectedRows.length > 1 && (
        <button
          className="btn btn-link"
          onClick={() => setShowMarkMultipleReadyDialog(true)}
        >
          Mark Multiple Ready
        </button>
      )}
      {selectedRows.length === 1 &&
        !selectedRows[0].delivered &&
        user.data?.data.is_superuser && (
          <button
            className="btn btn-link"
            onClick={() => removeItem(selectedRows[0])}
          >
            Remove Item
          </button>
        )}
    </>
  );

  const ticketFilterFn = (keyword: string, item: StockIssueDetailQueryData) => {
    const lowercaseKeyword = keyword.toLowerCase();
    return Object.values(item.stock).some((value) => {
      if (typeof value === "string") {
        return value.toLowerCase().includes(lowercaseKeyword);
      }
      return false;
    });
  };
  const columns = [
    {
      name: "Select",
      cell: (row: StockIssueDetailQueryData) => (
        <input
          type="checkbox"
          checked={selectedRows.includes(row)}
          onChange={() => hndRowSelect(row)}
        />
      ),
      minWidth: "40px",
      grow: 0.3,
    },
    {
      name: "Qty Requested",
      sortable: true,
      selector: (row: StockIssueDetailQueryData) => row.quantity,
      center: true,
      minWidth: "50px",
      grow: 0.7,
    },
    {
      name: "Item",
      sortable: true,
      cell: (row: StockIssueDetailQueryData) => (
        <div>
          <div style={{ fontWeight: "bold" }}>{row.stock.stock}</div>
          <div>{row.stock.stock_type}</div>
          <div>{row.stock.ycc_serial ?? ""}</div>
          {row.stock.stock_category ? <div>
            {row.stock.stock_category.parent_category && row.stock.stock_category.parent_category.stock_category ? <>{row.stock.stock_category.parent_category.stock_category} - </> : null}
            {row.stock.stock_category.stock_category}
            
            </div>: null}
          {row.cost_code ? (
            <div>
              Code {row.cost_code.cost_code} {row.cost_code.cost_type}
            </div>
          ) : null}
        </div>
      ),
      wrap: true,
      grow: 1.8,
    },
    {
      name: "Notes",
      sortable: true,
      selector: (row: StockIssueDetailQueryData) => row.note,
      center: true,
      minWidth: "75px",
      grow: 0.8,
      wrap: true,
    },
    {
      name: "Price",
      sortable: true,
      selector: (row: StockIssueDetailQueryData) =>
        row.stock.stock_type === 'small-tool' ? `$${row.stock.rental_rate * 8}/day` : `$${row.unit_price}/${row.stock.unit_of_measure.unit_of_measure}`,
      center: true,
      minWidth: "75px",
      grow: 0.8,
    },
    {
      name: "Qty Delivered",
      sortable: true,
      selector: (row: StockIssueDetailQueryData) => row.qty_delivered,
      center: true,
      minWidth: "50px",
      grow: 0.8,
    },
    {
      name: "Received By",
      sortable: true,
      selector: (row: StockIssueDetailQueryData) => row.received_by,
      center: true,
      minWidth: "50px",
      grow: 0.8,
    },
    {
      name: "Date Delivered",
      sortable: true,
      selector: (row: StockIssueDetailQueryData) =>
        row.date_delivered?.toLocaleString().slice(0, 10) ?? "",
      center: true,
      minWidth: "50px",
      grow: 0.8,
    },
    {
      name: "Qty Returned",
      sortable: true,
      selector: (row: StockIssueDetailQueryData) => row.qty_returned,
      center: true,
      minWidth: "50px",
      grow: 0.8,
    },

    {
      name: "Status",
      sortable: true,
      selector: (row: StockIssueDetailQueryData) => {
        if (row.stock.stock_disposition === 'missing') {
          return "Missing/Destroyed"
        }

        if (row.qty_ready && row.quantity > row.qty_ready) {
          return "Partially Ready";
        } else if (
          row.ready &&
          !row.delivered &&
          !row.returned &&
          !row.transferred
        ) {
          return "Ready";
        } else if (
          row.delivered &&
          row.quantity > Number(row.qty_delivered ?? 0)
        ) {
          return "Partially Delivered";
        } else if (row.delivered && !row.transferred) {
          return "Delivered";
        } else if (row.transferred) {
          return "Transferred";
        } else if (row.returned) {
          return <>Returned<br />{row.date_returned?.toLocaleString().slice(0, 10) ?? ""}</>;
        } else {
          return "Needs Fulfillment";
        }
      },
    },
    {
      name: "",
      cell: (row: StockIssueDetailQueryData) => (
        <>
          <div className="d-flex flex-wrap">
            <div className="">
              {(row.stock.stock_disposition === "missing" || (Number(row.quantity) > Number(row.stock.quantity_on_hand) &&
                !row.ready)) && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width="25"
                    height="25"
                    viewBox="0 0 48 48"
                  >
                    <title>Item marked as missing/destroyed or not enough inventory available.</title>
                    <path
                      fill="#F44336"
                      d="M21.2,44.8l-18-18c-1.6-1.6-1.6-4.1,0-5.7l18-18c1.6-1.6,4.1-1.6,5.7,0l18,18c1.6,1.6,1.6,4.1,0,5.7l-18,18C25.3,46.4,22.7,46.4,21.2,44.8z"
                    ></path>
                    <path
                      fill="#FFF"
                      d="M21.6,32.7c0-0.3,0.1-0.6,0.2-0.9c0.1-0.3,0.3-0.5,0.5-0.7c0.2-0.2,0.5-0.4,0.8-0.5s0.6-0.2,1-0.2s0.7,0.1,1,0.2c0.3,0.1,0.6,0.3,0.8,0.5c0.2,0.2,0.4,0.4,0.5,0.7c0.1,0.3,0.2,0.6,0.2,0.9s-0.1,0.6-0.2,0.9s-0.3,0.5-0.5,0.7c-0.2,0.2-0.5,0.4-0.8,0.5c-0.3,0.1-0.6,0.2-1,0.2s-0.7-0.1-1-0.2s-0.5-0.3-0.8-0.5c-0.2-0.2-0.4-0.4-0.5-0.7S21.6,33.1,21.6,32.7z M25.8,28.1h-3.6L21.7,13h4.6L25.8,28.1z"
                    ></path>
                  </svg>
                )}
              {(!row.ready || Number(row?.qty_ready) < Number(row.quantity)) &&
                !row.transferred &&
                !row.returned && (
                  <button
                    className="btn btn-sm mr-auto ml-auto btn-light ticket-button text-nowrap"
                    onClick={() => fireMarkReadyDialog(row)}
                  >
                    Mark Ready
                  </button>
                )}
              {!row.returned &&
                row.ready &&
                !row.transferred &&
                (!row.delivered ||
                  row.quantity > Number(row.qty_delivered ?? 0)) && (
                  <button
                    className="btn btn-sm btn-light mr-auto ml-auto ticket-button whitespace-nowrap"
                    onClick={() => fireDeliverItem(row)}
                  >
                    Deliver
                  </button>
                )}
              {row.stock.stock_disposition !== "missing" && !row.returned && row.ready && !row.transferred && (
                <button
                  className="btn btn-sm mr-auto ml-auto btn-light ticket-button text-nowrap"
                  onClick={() => fireReturnSingleDialog(row)}
                >
                  Return/Mark Lost
                </button>
              )}

              {row.stock.stock_disposition !== "missing" && !row.returned &&
                row.ready &&
                !row.transferred &&
                row.stock.stock_type === "small-tool" && (
                  <button
                    className="btn btn-sm btn-light ml-auto ticket-button whitespace-nowrap"
                    onClick={() => fireTransferItem(row)}
                  >
                    Transfer
                  </button>
                )}
              {row.returned && row.ready && (
                <label className="btn btn-sm disabled ticket-button whitespace-nowrap">
                  {row.qty_returned} returned{" "}
                  {row.date_returned?.toLocaleString().slice(0, 10)}
                </label>
              )}
              {row.transferred && row.ready && (
                <label className="btn btn-sm disabled ticket-button whitespace-nowrap">
                  Transferred{" "}
                  {row.date_transferred?.toLocaleString().slice(0, 10)}
                </label>
              )}

              {!row.date_charged && (
                <>
                  <button
                    className="btn btn-sm btn-light ml-auto ticket-button whitespace-nowrap"
                    onClick={() => fireCostCodeReassign(row)}
                  >
                    Change Cost Code
                  </button>
                </>
              )}
            </div>
          </div>
        </>
      ),
    },
  ];
  const chargeColumns = [
    {
      name: "",
      cell: (row: StockChargeQueryData) => (
        <>
          <input type="checkbox" name={`checkbox-ticket-item-${row.id}`} />
        </>
      ),
      width: "20px",
    },
    {
      name: "Date",
      sortable: true,
      selector: (row: StockChargeQueryData) =>
        row.charge_date.toLocaleString().slice(0, 10),
      width: "100px",
    },
    {
      name: "Description",
      sortable: true,
      selector: (row: StockChargeQueryData) => row.comments,
      wrap: true,
      width: "50rem",
    },
    {
      name: <>Amount <br/>(Taxable)</>,
      sortable: true,
      selector: (row: StockChargeQueryData) =>
        new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(row.charge_amount - row.nontaxable - row.charge_tax),
        width: "7rem",
    },
    {
      name: <>Amount<br />(Non-Taxable)</>,
      sortable: true,
      selector: (row: StockChargeQueryData) =>
        new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(row.nontaxable),
      width: "7rem",
    },
    {
      name: "Tax",
      sortable: true,
      selector: (row: StockChargeQueryData) =>
        new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(row.charge_tax),
      width: "7rem",
    },
    {
      name: "Total",
      sortable: true,
      selector: (row: StockChargeQueryData) =>
        new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(row.charge_amount),
        width: "7rem",
    },
    {
      name: "",
      cell: (row: StockChargeQueryData) => (
        <>
          <button
            className="btn btn-sm btn-link ticket-button"
            onClick={fireEditMiscCharge.bind(this, row)}
          >
            Edit
          </button>
          <br />
          <button
            className="btn btn-sm btn-link ticket-button"
            onClick={() => hndRemoveMiscCharge(row)}
          >
            Remove
          </button>
        </>
      ),
    },
  ];
  // #endregion

  function fireCostCodeReassign(row: StockIssueDetailQueryData) {
    setCodeReassignStockIssueDetail(row);
    setShowCodeReassignModal(true);
  }

  // #region Transfer Item Logic
  function fireTransferItem(row: StockIssueDetailQueryData) {
    setSelectedTicketItem(row);
    setShowTransferItemDialog(true);
    return fireTransferItem;
  }

  const hndTransferItem = async (event: React.FormEvent<HTMLFormElement>) => {
    setShowTransferItemDialog(false);
    await transferItem(event, selectedTicketItem as StockIssueDetailQueryData);
    await stockIssue.refetch();
  };
  // #endregion
  // #region Misc Charge Logic
  const fireEditMiscCharge = (row: StockChargeQueryData) => {
    const theData = {
      id: Number(row.id),
      charge_amount: (row.charge_amount - row.charge_tax - row.nontaxable)
        .toFixed(2)
        .toString(),
      taxable: row.taxable,
      nontaxable_amount: row.nontaxable.toString(),
      charge_description: row.comments,
      charge_status: row.status,
      received_by: row.received_by,
      charge_type: Number(row.charge?.id ?? 0),
      department: Number(row.department?.id ?? 0),
      charge_date: DateTime.fromISO(row.charge_date).toISODate(),
    };
    console.log("misc charge stuff", theData);
    setMiscChargeFormData(theData);
    setShowMiscChargeDialog(true);
    setEditMiscCharge(true);
  };
  const hndRemoveMiscCharge = async (row: StockChargeQueryData) => {
    try {
      await deleteMiscCharge.mutateAsync(Number(row.id));

      toast.success("Charge removed from ticket.");
    } catch (err) {
      toast.error("An error has occurred. Please try again.");
    }
    await refetch();
  };
  const resetMiscChargeForm = () => {
    setMiscChargeFormData({
      id: 0,
      charge_amount: "",
      taxable: true,
      nontaxable_amount: "",
      charge_description: "",
      charge_status: "",
      received_by: "",
      charge_type: 0,
      department: 0,
      charge_date: DateTime.now(),
    });
  };
  const hndCreateMiscCharge = async (event: React.FormEvent) => {
    setShowMiscChargeDialog(false);
    event.preventDefault();
    const stockIssueData: StockIssueQueryData = stockIssue.data;
    const jobInfo: JobQueryData = stockIssue.data.job;
    const data = {
      charge_amount: parseFloat(
        (
          Number(miscChargeFormData.charge_amount ?? 0) +
          Number(miscChargeFormData.charge_amount ?? 0) * Number(stockIssueData?.sales_tax ?? 0) +
          Number(miscChargeFormData.nontaxable_amount)
        ).toFixed(2) ?? 0
      ),
      sales_tax: jobInfo?.sales_tax,
      charge_date: miscChargeFormData.charge_date ? miscChargeFormData.charge_date : DateTime.now(),
      charge: Number(miscChargeFormData.charge_type),
      companies: stockIssueData.job.companies,
      company: stockIssueData.job.companies[0],
      comments: miscChargeFormData.charge_description,
      department: Number(miscChargeFormData.department),
      job: Number(stockIssue.data.job.id),
      taxable: miscChargeFormData.taxable,
      stock_issue: Number(stockIssueData.id),
      status: "",
      deleted: false,
      nontaxable: Number(miscChargeFormData.nontaxable_amount),
      received_by: miscChargeFormData.received_by,
      updated_status: "",
      charge_tax:
        parseFloat(
          Number(
            Number(stockIssueData?.sales_tax ?? 0) * Number(miscChargeFormData.charge_amount)
          ).toFixed(2)
        ) ?? 0,
    };

    console.log("update charge payload", data);

    if (!editMiscCharge) {
      const response = await createCharge.mutateAsync(data);
      if (response.status === 201) {
        toast.success("Misc Charge Created.");
      } else if (response.status >= 400 || response.status >= 500) {
        toast.error("An error has occurred. Please try again.");
      }
      resetMiscChargeForm();
    } else if (editMiscCharge) {
      updateMiscCharge.mutate([miscChargeFormData.id, data]);
      resetMiscChargeForm();
    }
    await refetch();
  };

  const hndMiscChargeInputChange = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    const { name, value, type } = event.target;

    let newValue: any;
    if (type === "checkbox") {
      newValue = (event.target as HTMLInputElement).checked;
    } else {
      newValue = value;
    }

    setMiscChargeFormData((prevFormData) => ({
      ...prevFormData,
      [name]: newValue,
    }));
  };

  const chargeFilterFn = (keyword: string, item: StockChargeQueryData) => {
    const lowercaseKeyword = keyword.toLowerCase();
    const itemValues = Object.values(item);

    for (const value of itemValues) {
      if (typeof value === "string") {
        const lowercaseValue = value.toLowerCase();

        if (lowercaseValue.includes(lowercaseKeyword)) {
          return true;
        }
      }
    }

    return false;
  };
  // #endregion
  const fireDeliverItem = (row: StockIssueDetailQueryData) => {
    setSelectedTicketItem(row);
    if (row.stock.stock_type === "small-tool") {
      stockSimilarMutation.mutate(Number(row.stock.id));
    }
    setShowDeliverItemDialog(true);
  };

  async function hndDeliverItem(event: React.FormEvent) {
    event.preventDefault();
    setShowDeliverItemDialog(false);
    const form = event.target as HTMLFormElement;
    const formData = new FormData(form);
    const qtyDelivered = formData.get("qty-delivered");
    const dateDelivered = DateTime.fromISO(formData.get("delivery-date")).setZone("system") || DateTime.now();
    const recipient = formData.get("recipient");
    const finalize = formData.get("finalized");
    let quantity: number;
    if (finalize) {
      quantity = Number(qtyDelivered);
    } else {
      quantity = Number(selectedTicketItem?.quantity);
    }
    if (selectedTicketItem?.stock.stock_type === "consumable") {
      await markItemAsReadyOrDelivered.mutateAsync([
        Number(selectedTicketItem?.id),
        {
          quantity: quantity,
          stock_issue: Number(selectedTicketItem?.stock_issue),
          qty_delivered: Number(qtyDelivered),
          qty_ready: Number(qtyDelivered),
          date_delivered: dateDelivered,
          delivered: true,
          ready: true,
          stock: {
            ...selectedTicketItem?.stock,
            quantity_on_hand: Number(selectedTicketItem?.stock?.quantity_on_hand ?? 0) - quantity,
          },
          received_by: recipient?.toString(),
        },
      ]);
      await updateStock.mutateAsync([
        Number(selectedTicketItem?.stock?.id),
        {
          quantity_on_hand:
            Number(selectedTicketItem?.stock?.quantity_on_hand ?? 0) -
            Number(qtyDelivered ?? 0),
          active: true,
        },
      ]);
    } else if (selectedTicketItem?.stock.stock_type === "small-tool") {
      const oldStock = selectedTicketItem;
      await markItemAsReadyOrDelivered.mutateAsync([
        Number(selectedTicketItem?.id),
        {
          quantity: quantity,
          stock_issue: Number(selectedTicketItem?.stock_issue),
          qty_delivered: Number(qtyDelivered),
          qty_ready: Number(qtyDelivered),
          date_delivered: dateDelivered,
          delivered: true,
          ready: true,
          stock: selectedSerial as StockQueryData,
          received_by: recipient?.toString(),
        },
      ]);
      if (selectedSerial?.id !== oldStock.stock.id) {
        await updateStock.mutateAsync([
          Number(oldStock?.stock.id),
          {
            quantity_on_hand:
              Number(oldStock.stock.quantity_on_hand ?? 0) +
              Number(oldStock.qty_ready ?? 0),
            active: true,
          },
        ]);
      }
        
      await updateStock.mutateAsync([
        Number(selectedSerial?.id),
        {
          quantity_on_hand:
            Number(selectedSerial?.quantity_on_hand ?? 0) -
            Number(qtyDelivered ?? 0),
          active: true,
        },
      ]);
    }

    await stockIssue.refetch();
  }

  const hndDeliverMultiple = async (event: React.FormEvent) => {
    setShowReceivedDialog(false);
    event.preventDefault();
    const form = event.target as HTMLFormElement;
    const formData = new FormData(form);
    const receiver = formData.get("receiver");
    for (const detail of stockIssue.data.stock_issue_details) {
      if (
        !detail.delivered &&
        !detail.transferred &&
        !detail.returned &&
        detail.ready
      ) {
        await markItemAsReadyOrDelivered.mutateAsync([
          Number(detail?.id),
          {
            stock_issue: Number(detail?.stock_issue),
            qty_delivered: Number(detail.qty_ready),
            qty_ready: Number(detail.qty_ready),
            date_delivered: DateTime.now(),
            delivered: true,
            ready: true,
            stock: detail.stock,
            received_by: receiver?.toString(),
          },
        ]);
        let oldQuantity = detail?.stock.quantity_on_hand;
        await updateStock.mutateAsync([
          Number(detail?.stock.id),
          {
            quantity_on_hand: Number(oldQuantity) - Number(detail.qty_ready),
            active: true,
          },
        ]);
      }
    }
    await stockIssue.refetch();
  };
  const removeItem = async (row: StockIssueDetailQueryData) => {
    setSelectedTicketItem(row);
    await markItemAsReadyOrDelivered.mutateAsync([
      Number(row?.id),
      {
        deleted_at: new Date(),
        active: false,
      },
    ]);
    setSelectedRows([]);
    await stockIssue.refetch();
  };
  // #region Mark Ready Logic
  const fireMarkReadyDialog = (row: StockIssueDetailQueryData) => {
    setSelectedTicketItem(row);
    setShowMarkReadyDialog(true);
  };

  const hndMarkReady = async (event: React.FormEvent) => {
    event.preventDefault();
    setShowMarkReadyDialog(false);
    const form = event.target as HTMLFormElement;
    const formData = new FormData(form);
    const qtyReady = formData.get("qty-delivered");
    const fulfillNotes = formData.get("fulfill-notes");
    const finalize = formData.get("finalized");
    let quantity: number;
    if (finalize) {
      quantity = Number(qtyReady);
    } else {
      quantity = selectedTicketItem?.quantity ?? 0;
    }

    await markItemAsReadyOrDelivered.mutateAsync([
      Number(selectedTicketItem?.id),
      {
        stock_issue: Number(selectedTicketItem?.stock_issue),
        quantity: Number(quantity),
        stock: selectedTicketItem?.stock,
        unit_price: Number(selectedTicketItem?.unit_price ?? 0),
        note: String(fulfillNotes?.toString()),
        sales_tax_code: selectedTicketItem?.sales_tax_code,
        sales_tax_percentage: selectedTicketItem?.sales_tax_percentage ?? 0,
        state: selectedTicketItem?.state,
        sales_tax_stored: DateTime.now(),
        ready: true,
        returned: false,
        qty_returned: null,
        qty_ready: Number(qtyReady),
      },
    ]);

    setSelectedTicketItem(null);
    await stockIssue.refetch();
  };

  const hndMultipleReady = async (event: React.FormEvent) => {
    event.preventDefault();
    setShowMarkMultipleReadyDialog(false);
    const table = document.getElementById("ready-table");

    if (table && table.getElementsByTagName("tbody").length > 0) {
      const rows = Array.from(
        table.getElementsByTagName("tbody")[0].getElementsByTagName("tr")
      );

      for (const row of rows) {
        const cells = row.getElementsByTagName("td");
        const item = Number(row.getAttribute("id"));
        const note = String(cells[3].querySelector("input")?.value);
        const qtyReady = cells[2].querySelector("input")?.value;

        const stock = stockIssue.data.stock_issue_details.find(
          (detail: StockIssueDetailQueryData) => Number(detail.id) === item
        );

        if (stock) {
          const readyData = {
            stock_issue: Number(stockIssueId),
            stock: stock.stock,
            qty_ready: Number(qtyReady),
            ready: true,
            note: note,
          };

          await markItemAsReadyOrDelivered.mutateAsync([item, readyData]);
        }

        setSelectedTicketItem(null);
      }
    }

    setSelectedRows([]);
    await stockIssue.refetch();
  };

  // #endregion
  // #region Return Item Logic
  function calcRentalPeriod(selectedTicketItem: StockIssueDetailQueryData, returnDate: Date|undefined = undefined) {
    let today;
    const dateDelivered = DateTime.fromISO(selectedTicketItem.date_delivered);
    // Added this so that the transfers could be factored in.
    if (
      selectedTicketItem.transferred ||
      selectedTicketItem.date_transferred != null
    ) {
      today = DateTime.fromISO(selectedTicketItem.date_transferred);
      console.log("Transfer Date: ", today);
    } else {
      today = returnDate ? returnDate : DateTime.now();
    }
    return today.diff(dateDelivered, "days").days;
  }
  const calcTicketCost = (items: Array<StockIssueDetailQueryData>) => {
    return items
      .filter((item: StockIssueDetailQueryData) => item.active)
      .reduce((accumulator: number, row: StockIssueDetailQueryData) => {
        return row.stock.stock_type === 'consumable' ? (
          accumulator + Number(row.unit_price ?? 0) * Number(row.quantity ?? 0)
        ) : accumulator;
      }, 0);
  };
  const calcPerDayTicketCost = (items: Array<StockIssueDetailQueryData>) => {
    return items
      .filter((item: StockIssueDetailQueryData) => item.active)
      .reduce((accumulator: number, row: StockIssueDetailQueryData) => {
        return row.stock.stock_type === 'small-tool' ? (
          accumulator + (Number(row.stock.rental_rate ?? 0) * Number(row.quantity ?? 0) * 8)
        ) : accumulator;
      }, 0);
  };
  const calcMiscChargeCost = (items: Array<StockChargeQueryData>) => {
    // First filter out items with deleted set to true
    const filteredItems = items.filter((item) => item.charge && item.charge.charge_number !== "RENT" && !item.deleted);

    // Then perform the calculations
    return filteredItems.reduce(
      (accumulator, row) => {
        return {
          miscChargeCost:
            accumulator.miscChargeCost +
            (Number(row.charge_amount ?? 0) -
              Number(row.nontaxable ?? 0) -
              Number(row.charge_tax ?? 0)),
          nontaxable: accumulator.nontaxable + Number(row.nontaxable ?? 0),
        };
      },
      {
        miscChargeCost: 0,
        nontaxable: 0,
      }
    );
  };
  const calcRentChargeCost = (items: Array<StockChargeQueryData>) => {
    // First filter out items with deleted set to true
    const filteredItems = items.filter((item) => item.charge && item.charge.charge_number === "RENT" && !item.deleted);

    // Then perform the calculations
    return filteredItems.reduce(
      (accumulator, row) => {
        return {
          miscChargeCost:
            accumulator.miscChargeCost +
            (Number(row.charge_amount ?? 0) -
              Number(row.nontaxable ?? 0) -
              Number(row.charge_tax ?? 0)),
          nontaxable: accumulator.nontaxable + Number(row.nontaxable ?? 0),
        };
      },
      {
        miscChargeCost: 0,
        nontaxable: 0,
      }
    );
  };

  const fireReturnSingleDialog = (row: StockIssueDetailQueryData) => {
    setSelectedTicketItem(row);
    setShowReturnSingleDialog(true);
  };

  const handleReturnStatusChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value;
    if (selectedValue === "Missing/Lost/Destroyed") {
      setQtyReturnInput("0");
    } else {
      setQtyReturnInput("1");
    }
  };

  const handleReturnDateChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedValue = event.target.value;
    const newReturnDate = DateTime.fromISO(selectedValue).setZone("system");

    setReturnDate(newReturnDate);
    console.log(newReturnDate);
  };

  const hndReturnItems = async (event: React.FormEvent) => {
    event.preventDefault();
    setShowReturnItemsDialog(false);
    const table = document.getElementById("items-table");
    let shouldGenerateCharges = false;

    if (table && table.getElementsByTagName("tbody").length > 0) {
      const rows = Array.from(
        table.getElementsByTagName("tbody")[0].getElementsByTagName("tr")
      );

      for (const row of rows) {
        const cells = row.getElementsByTagName("td");
        const item = Number(row.getAttribute("id"));
        const disposition = String(cells[3].querySelector("select")?.value);
        let qtyReturned = cells[2].querySelector("input")?.value;
        let dateReturned = cells[4].querySelector("input") ? DateTime.fromISO(cells[4].querySelector("input")?.value) : undefined;

        console.log(`item ${item} disposition ${disposition} dateReturned ${dateReturned}`);

        const stockDetail = stockIssue.data.stock_issue_details.find(
          (detail: StockIssueDetailQueryData) => Number(detail.id) === item
        );

        if (!stockDetail) continue;

        const type = stockDetail.stock.stock_type;
        if (type === "small-tool" && disposition === "Returned") {
          qtyReturned = "1";
        } else if (
          type === "small-tool" &&
          disposition === "Missing/Lost/Destroyed"
        ) {
          qtyReturned = "0";
        }
        if (
          disposition === "Returned" ||
          ((disposition === "None" || disposition === null) &&
            qtyReturned === "0")
        ) {
          const returnData = {
            stock_issue: Number(stockIssueId),
            stock: stockDetail.stock,
            returned: true,
            date_returned: dateReturned ? dateReturned : DateTime.now(),
            qty_returned: Number(qtyReturned ?? 0),
          };

          await returnItem.mutateAsync([item, returnData]);

          let updatedQty =
            Number(stockDetail.stock.quantity_on_hand) +
            Number(qtyReturned ?? 0);

          if (type === "small-tool") {
            updatedQty = 1;
          }

          const updatedStockData = {
            quantity_on_hand: updatedQty,
          };

          await updateStock.mutateAsync([
            stockDetail.stock.id,
            updatedStockData,
          ]);

          if (type === "small-tool") {
            console.log(stockDetail);
            shouldGenerateCharges = true;
            // const rentalLength = calcRentalPeriod(stockDetail, dateReturned);
            // const rentalTotal =
            //   Number(stockDetail.stock.rental_rate ?? 0) *
            //     8 *
            //     Number(Math.abs(Math.floor(rentalLength ?? 0))) ?? 0;
          } else if (type === "consumable") {
            //Refund any returned consumables
            const charge_amt = (
              Number(qtyReturned ?? 0) *
              Number(stockDetail.stock.unit_price ?? 0)
            ).toFixed(2);

            try {
              const chargeType = charges.find((charge: ChargeQueryData) => charge.charge_number === "CRED");

              await createCharge.mutateAsync({
                charge: chargeType,
                charge_amount: parseFloat(
                  (
                    Number(charge_amt ?? 0) +
                    Number(charge_amt ?? 0) *
                      Number(stockDetail.sales_tax?.sales_tax_percentage ?? 0) *
                      -1
                  ).toFixed(2) ?? 0
                ),
                charge_date: DateTime.now(),
                companies: stockIssue.data.job.companies,
                company: stockIssue.data.job.companies[0] ?? 0,
                comments: `Returned ${stockIssue.data.stock.id}-${stockDetail.stock.stock}`,
                deleted: false,
                charge_tax: Number(
                  Number(charge_amt ?? 0) *
                    Number(stockDetail.sales_tax?.sales_tax_percentage ?? 0) *
                    -1
                ),
                department: Number(stockDetail.department?.id),
                job: stockIssue.data.job.id,
                stock_issue: stockIssue.data.id,
                nontaxable: 0,
                sales_tax: stockDetail.sales_tax,
              });
            } catch (err) {
              toast.error(
                "An error occurred while generating consumable charge."
              );
            }
          }
        }

        if (disposition?.trim().toLowerCase() === "missing/lost/destroyed") {
          const returnData = {
            stock_issue: Number(stockIssueId),
            stock: stockDetail.stock,
            qty_returned: 0,
            date_returned: dateReturned ? dateReturned : DateTime.now(), // This will also serve as date lost
            returned: false,
          };

          await returnItem.mutateAsync([item, returnData]);

          const updatedStockData = {
            stock_disposition: "missing",
          };

          await updateStock.mutateAsync([
            stockDetail.stock.id,
            updatedStockData,
          ]);

          if (type === "small-tool") {
            const diffInDays = calcRentalPeriod(stockDetail, dateReturned);
            const subtotal =
              Number(selectedTicketItem?.stock.rental_rate ?? 0) *
                8 *
                Number(Math.floor(Math.abs(diffInDays ?? 0)) ?? 0) +
              Number(stockDetail.stock.unit_price ?? 0);

            try {
              const chargeTax = Number(
                stockDetail.sales_tax?.sales_tax_percentage ?? 0
              );
              const chargeAmount = parseFloat(
                (
                  Number(subtotal ?? 0) +
                  (chargeTax ?? 0 / 100) * Number(subtotal ?? 0)
                ).toFixed(2)
              );
              const chargeType = charges.find((charge: ChargeQueryData) => charge.charge_number === "REPLCMNT");
              const departmentId = departments.find((department: DepartmentQueryData) => department.department_number === "977").id || Number(stockDetail.department?.id)

              await createCharge.mutateAsync({
                charge: chargeType.id,
                charge_amount: Number(chargeAmount ?? 0),
                charge_date: DateTime.now(),
                companies: stockIssue.data.job.companies,
                company: stockIssue.data.job.companies[0] ?? 0,
                comments: `Item marked as missing/destroyed: ${stockIssue.data.stock.ycc_serial} - ${stockIssue.data.stock.stock}`,
                deleted: false,
                department: departmentId,
                job: stockIssue.data.job.id,
                stock_issue: stockIssue.data.id,
                status: "Item Lost - Charged",
                charge_tax: Number(chargeTax) * Number(subtotal),
                nontaxable: 0,
                sales_tax: stockDetail.sales_tax.id,
                received_by: "YCC Store",
              });
            } catch (err) {
              toast.error(
                `An error occurred while generating rental and loss charge. ${err}`
              );
              Sentry.captureException(err);
            }
          }
        }
      }
    }

    setSelectedRows([]);

    if (shouldGenerateCharges) {
      try {
        // await createCharge.mutateAsync({
        //   charge_amount: parseFloat(
        //     Number(rentalTotal ?? 0).toFixed(2) ?? 0
        //   ),
        //   charge_date: DateTime.now(),
        //   charge_tax: 0,
        //   companies: stockIssue.data.job.companies,
        //   company: stockIssue.data.job.companies[0] ?? 0,
        //   comments: `Rental ${stockDetail.stock.ycc_serial}-${stockDetail.stock.stock}`,
        //   deleted: false,
        //   department: Number(stockDetail.department?.id),
        //   job: stockIssue.data.job.id,
        //   stock_issue: stockIssue.data.id,
        //   nontaxable: 0,
        //   taxable: false,
        //   sales_tax: stockDetail.sales_tax.id
        // });

        await generateRentalCharges.mutateAsync([stockIssueId]);
      } catch (err) {
        toast.error("An error occurred while generating rental charge.");
        console.log("Rental charge error", err);
      }
    }

    await stockIssue.refetch();
    await refetch();
  };

  const hndReturnSingle = async (event: React.FormEvent) => {
    event.preventDefault();

    const table = document.getElementById("items-table");
    if (!table || table.getElementsByTagName("tbody").length === 0) {
      return;
    }

    const row = table
      .getElementsByTagName("tbody")[0]
      .getElementsByTagName("tr")[0];
    const cells = row.getElementsByTagName("td");
    let qtyReturned = Number(cells[2].querySelector("input")?.value ?? 0);
    const disposition = cells[3].querySelector("select")?.value ?? "None";

    if (disposition === "None") {
      toast.error("Disposition must be set.");
      return;
    }

    setShowReturnSingleDialog(false);

    if (disposition === "Returned" || (!disposition && qtyReturned === 0)) {
      const updatedQty =
        Number(selectedTicketItem?.stock?.quantity_on_hand) +
        Number(qtyReturned);
      try {
        if (selectedTicketItem?.stock.stock_type === "consumable") {
          await updateStock.mutateAsync([
            Number(selectedTicketItem?.stock.id),
            { quantity_on_hand: updatedQty },
          ]);
        } else if (selectedTicketItem?.stock.stock_type === "small-tool") {
          await updateStock.mutateAsync([
            Number(selectedTicketItem?.stock.id),
            { quantity_on_hand: 1 },
          ]);
        }
      } catch (err) {
        Sentry.captureException(err);
      }

      if (selectedTicketItem?.stock.stock_type === "consumable") {
        // Give credit for any returns
        const calcAmt =
          Number(qtyReturned ?? 0) *
          Number(selectedTicketItem.stock.unit_price ?? 0);
        const chargeAmt = !isNaN(calcAmt) ? parseFloat(calcAmt.toFixed(2)) : 0;
        const chargeType = charges.find((charge: ChargeQueryData) => charge.charge_number === "CRED")?.id;

        try {
          await createCharge.mutateAsync({
            charge_amount: parseFloat(
              (
                -1 *
                (Number(chargeAmt) +
                  Number(chargeAmt) *
                  (selectedTicketItem.sales_tax_percentage ?? 0))
              ).toFixed(2) ?? 0
            ),
            charge: chargeType,
            charge_date: DateTime.now(),
            companies: stockIssue.data.job.companies,
            company: stockIssue.data.job.companies[0] ?? 0,
            comments: `Returned ${selectedTicketItem.stock.id}-${selectedTicketItem?.stock.stock}`,
            charge_tax: Number(
              (
                Number(selectedTicketItem.sales_tax_percentage ?? 0) *
                Number(chargeAmt ?? 0) *
                -1
              ).toFixed(2)
            ),
            deleted: false,
            sales_tax: Number(selectedTicketItem.sales_tax.id),
            department: Number(selectedTicketItem.department),
            job: stockIssue.data.job.id,
            stock_issue: selectedTicketItem.stock_issue,
            nontaxable: 0,
          });
        } catch (err) {
          toast.error("An error occurred while generating consumable charge.");
          Sentry.captureException(err);
        }
      }
    } else if (disposition === "Missing/Lost/Destroyed") {
      await updateStock.mutateAsync([
        Number(selectedTicketItem?.stock.id),
        { stock_disposition: "missing" },
      ]);

      if (selectedTicketItem?.stock.stock_type === "small-tool") {
        const diffInDays = calcRentalPeriod(selectedTicketItem);
        const subtotal =
          Number(selectedTicketItem?.stock.rental_rate ?? 0) *
          8 *
          Number(Math.floor(Math.abs(diffInDays)) ?? 0) +
          Number(selectedTicketItem?.stock.unit_price ?? 0);
        const chargeType = charges.find((charge: ChargeQueryData) => charge.charge_number === "REPLCMNT");
        const departmentId = departments.find((department: DepartmentQueryData) => department.department_number === "977").id || Number(selectedTicketItem.department)

        try {
          const chargeBody = {
            charge: chargeType.id,
            charge_amount: parseFloat(
              (
                Number(subtotal ?? 0) +
                Number(subtotal ?? 0) *
                (selectedTicketItem.sales_tax_percentage ?? 0)
              ).toFixed(2) ?? 0
            ),
            sales_tax: selectedTicketItem.sales_tax && selectedTicketItem.sales_tax.id ? selectedTicketItem.sales_tax.id : null,
            charge_date: DateTime.now(),
            companies: stockIssue.data.job.companies,
            company: stockIssue.data.job.companies[0] ?? 0,
            comments: `Item marked as missing/destroyed: ${selectedTicketItem.stock.ycc_serial} - ${selectedTicketItem.stock.stock}`,
            deleted: false,
            department: departmentId,
            charge_tax:
              Number.parseFloat(
                (Number(selectedTicketItem.sales_tax_percentage ?? 0) *
                  Number(subtotal ?? 0)).toPrecision(4)
              ),
            job: stockIssue.data.job.id,
            stock_issue: selectedTicketItem.stock_issue,
            status: "Item Lost - Charged",
            nontaxable: 0,
            received_by: "YCC Store",
          };

          await createCharge.mutateAsync(chargeBody);
          qtyReturned = 0;
        } catch (err) {
          console.log(err);
          toast.error(
            `An error occurred while generating rental and loss charge. ${err}`
          );
          Sentry.captureException(err);
        }
      }
    }

    try {
      await returnItem.mutateAsync([
        Number(selectedTicketItem?.id),
        {
          stock_issue: Number(selectedTicketItem?.stock_issue),
          quantity: Number(selectedTicketItem?.quantity),
          stock: selectedTicketItem?.stock,
          unit_price: Number(selectedTicketItem?.unit_price),
          sales_tax_code: selectedTicketItem?.sales_tax_code,
          sales_tax_percentage:
            selectedTicketItem?.sales_tax?.sales_tax_percentage,
          state: selectedTicketItem?.state,
          returned: disposition === "Returned" || disposition === "None",
          qty_returned: qtyReturned,
          date_returned: disposition === "Returned" || disposition === "Missing/Lost/Destroyed" ? new DateTime(returnDate) : null,
        },
      ]);
      await generateRentalCharges.mutateAsync([stockIssueId]);
    } catch (err) {
      toast.error("An error occurred while returning item.");
      Sentry.captureException(err);
    }
    await stockIssue.refetch();
    await refetch();
  };

  const hndTicketResetViewDone = () => {
    setResetTicketView(false);
  };

  const hndAddItemDone = () => {
    setShowAddItemDialog(false);
    setResetTicketView(true);
  };

  const [qtyReady, setQtyReady] = useState(-1);
  const [qtyDelivered, setQtyDelivered] = useState(-1);
  const [dateDelivered, setDateDelivered] = useState<Date|undefined>(undefined);
  const [selectedSerial, setSelectedSerial] = useState<StockQueryData | null>(
    null
  );
  const [availableSmallTools, setAvailableSmallTools] = useState<
    StockQueryData[] | null
  >(null);
  const stockSimilarMutation = useMutation(
    (id: number) => stockSimilarQuery(id).queryFn(),
    {
      onSuccess: (data) => {
        if (data?.length > 0) {
          setAvailableSmallTools(data);
        } else {
          toast.error("Item currently out of stock or at another job.");
          console.log("No in stock items found.");
        }
      },
      onError: (error) => {
        console.error("Failed to fetch in stock items:", error);
        Sentry.captureException(error);
      },
    }
  );
  let options =
    availableSmallTools
      ?.filter((jobElem: StockQueryData) => jobElem.active)
      .map((jobElem: StockQueryData) => ({
        value: jobElem.id,
        label: `${jobElem.ycc_serial}`,
      })) ?? [];

  const newItem = {
    value: selectedTicketItem?.stock.id,
    label: String(selectedTicketItem?.stock.ycc_serial),
  };

  // Check if newItem already exists in options
  const isItemExists = options.some(
    (option) => option.value === newItem.value && option.label === newItem.label
  );

  // Concatenate newItem only if it doesn't already exist in options
  if (!isItemExists) {
    options = options.concat([newItem]);
  }

  const hndSelectSerial = (
    selectedOption: { value: number | undefined; label: string } | null
  ) => {
    const stock = selectedOption
      ? availableSmallTools
          ?.filter((stock: StockQueryData) => stock.active)
          .find(
            (jobItem: StockQueryData) => jobItem.id === selectedOption.value
          )
      : null;
    setSelectedSerial(stock as StockQueryData);
  };

  const disableMarkDeliveredButton = stockIssue.data?.stock_issue_details.reduce(
    (accumulator: number, detail: StockIssueDetailQueryData) => {
      const result = (detail.stock.stock_type === "consumable" && 
        detail.ready &&
        !(detail.delivered ||
        detail.transferred ||
        detail.returned)) ? 0 : 1;
      return accumulator + result;
    }, 0
  )

  // #endregion
  return stockIssue.data ? (
    <>
      <Sentry.ErrorBoundary fallback={ErrorFallback}>
        <div className="row">
          <div className="col-12">
            <div className="border border-secondary-subtle p-0 d-flex">
              <h1 className="dashboard m-0 p-2 flex-grow-1">
                {stockIssue.data.status !== "NULL"
                  ? stockIssue.data.status
                  : ""}{" "}
                Ticket #{stockIssue.data.id} - {stockIssue.data.job.job}
              </h1>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 p-4">
            <table className="table table-sm">
              <tbody>
                <tr>
                  <td>Opened:</td>
                  <td>
                    {DateTime.fromISO(
                      stockIssue.data.created_at
                    ).toLocaleString()}
                  </td>
                </tr>
                <tr>
                  <td>Requested By: </td>
                  <td>
                    {!stockIssue.data.created_by && "Unknown User"}
                    {stockIssue.data.created_by?.first_name}{" "}
                    {stockIssue.data.created_by?.last_name}
                  </td>
                </tr>
                <tr>
                  <td>Requested By Email:</td>
                  <td>
                    {!stockIssue.data.created_by && "Unknown User"}
                    {stockIssue.data.created_by?.email}
                  </td>
                </tr>
                <tr>
                  <td>Job:</td>
                  <td>{stockIssue.data.job.job}</td>
                </tr>
                <tr>
                  <td>Job Number:</td>
                  <td>{stockIssue.data.job.job_number}</td>
                </tr>
                <tr>
                  <td>Item Cost:</td>
                  <td>
                    $
                    {calcTicketCost(
                      stockIssue.data?.stock_issue_details
                    ).toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })} consumable items
                    {calcPerDayTicketCost(stockIssue.data?.stock_issue_details) > 0 ? <><br />{calcPerDayTicketCost(stockIssue.data?.stock_issue_details).toLocaleString("en-US", { style: "currency", currency: "USD" })} per day</> : null}
                    </td>
                </tr>
                <tr>
                  <td>Misc Charge Cost:</td>
                  <td>
                    $
                    {(() => {
                      const miscChargeCost = calcMiscChargeCost(
                        relatedCharges || []
                      );

                      const total =
                        miscChargeCost.nontaxable +
                        miscChargeCost.miscChargeCost;
                      return total.toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      });
                    })()}
                  </td>
                </tr>
                <tr>
                  <td>Rent Charge Cost:</td>
                  <td>
                    $
                    {(() => {
                      const miscChargeCost = calcRentChargeCost(
                        relatedCharges || []
                      );

                      const total =
                        miscChargeCost.nontaxable +
                        miscChargeCost.miscChargeCost;
                      return total.toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      });
                    })()}
                  </td>
                </tr>
                <tr>
                  <td>Total Tax:</td>
                  <td>
                    $
                    {(() => {
                      const stockIssueData: StockIssueQueryData =
                        stockIssue.data;

                      const ticketCost = calcTicketCost(
                        stockIssueData.stock_issue_details ?? []
                      );
                      const miscChargeCost = calcMiscChargeCost(
                        relatedCharges ?? []
                      );

                      const total =
                        Number(
                          Number(ticketCost) +
                            Number(miscChargeCost.miscChargeCost)
                        ) * Number(stockIssueData.sales_tax);
                      return total.toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      });
                    })()}
                  </td>
                </tr>
                <tr>
                  <td>Grand Total:</td>
                  <td>
                    $
                    {(() => {
                      const ticketCost = calcTicketCost(
                        stockIssue.data?.stock_issue_details || []
                      );
                      const miscChargeCost = calcMiscChargeCost(
                        relatedCharges || []
                      );
                      const grandTotal =
                        ticketCost +
                        miscChargeCost.miscChargeCost +
                        (ticketCost + miscChargeCost.miscChargeCost) *
                          stockIssue.data.sales_tax +
                        miscChargeCost.nontaxable;
                      return grandTotal.toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      });
                    })()}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            className="col-md-4 offset-md-4 p-4 text-end ticket-button"
            id="ticket-button-container"
          >
            <p>
              <button
                className="btn btn-primary me-2 ticket-button"
                onClick={() => window.print()}
              >
                Print Receipt
              </button>
              <button
                className="btn btn-primary ticket-button"
                onClick={() => setShowReceivedDialog(true)}
                disabled={disableMarkDeliveredButton > 0}
              >
                Mark as Delivered
              </button>
              <br />
            </p>

            <p>
              <button
                className="btn btn-primary me-2 ticket-button"
                onClick={() => setShowAddItemDialog(true)}
                disabled={stockIssue.data.status === "Delivered"}
              >
                Add Item
              </button>
              <button
                className="btn btn-primary me-2 ticket-button"
                onClick={() => setShowMiscChargeDialog(true)}
              >
                Add Misc Charge
              </button>
            </p>
          </div>
        </div>
      </Sentry.ErrorBoundary>
      <div className="row">
        <div className="col-md-12">
          <BasicFilterTable
            title="Ticket Items"
            tableKey="ticket-items"
            dataSource={stockIssue.data.stock_issue_details
              .filter((item: StockIssueDetailQueryData) => item.active)
              .sort((item: StockIssueDetailQueryData) => item.created_at)}
            columns={columns}
            filterFn={ticketFilterFn}
            filterButtons={ticketFilterButtons}
            exportFileName={`Ticket ${stockIssue.data.id} Items.csv`}
            resetView={resetTicketView}
            onResetDone={hndTicketResetViewDone.bind(this)}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <BasicFilterTable
            title="Misc Charges"
            tableKey="misc-charges"
            dataSource={relatedCharges.filter((charge) => charge.charge && charge.charge.charge_number != "RENT" && !charge.deleted && DateTime.fromISO(charge.charge_date) <= DateTime.now())}
            columns={chargeColumns}
            filterFn={chargeFilterFn}
            exportFileName={`Ticket ${stockIssue.data.id} Items.csv`}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <BasicFilterTable
            title="Rent Charges"
            tableKey="rent-charges"
            dataSource={relatedCharges.filter((charge) => charge.charge && charge.charge.charge_number == "RENT" && !charge.deleted && DateTime.fromISO(charge.charge_date) <= DateTime.now())}
            columns={chargeColumns}
            filterFn={chargeFilterFn}
            exportFileName={`Ticket ${stockIssue.data.id} Items.csv`}
          />
        </div>
      </div>

      {showCodeReassignModal &&
        codeReassignStockIssueDetail &&
        stockIssue.data.job && (
          <CostCodeReassignmentModal
            jobId={stockIssue.data.job.id}
            stockIssueDetail={codeReassignStockIssueDetail}
            show={showCodeReassignModal}
            onChange={() => console.log("done change")}
            onCancel={() => {
              setShowCodeReassignModal(false);
              stockIssue.refetch();
            }}
          />
        )}

      <Modal
        key="receive-items-dialog"
        show={showReceivedDialog}
        onHide={() => setShowReceivedDialog(false)}
      >
        <Modal.Header closeButton>Mark as Picked Up</Modal.Header>

        <Modal.Body>
          <form onSubmit={hndDeliverMultiple}>
            <p>
              Clicking Complete will mark ALL <strong>READY</strong> items in
              the ticket as Delivered, reduce the inventory appropriately, and
              will start rental charge calculation for any small tools on the
              ticket.
            </p>

            <div className="form-group">
              <label htmlFor="receiver">Items Received By:</label>
              <input type="text" name="receiver" className="form-control" />
            </div>
            <div className="d-flex align-items-end">
              <button className="btn btn-success mt-2" type="submit">
                Submit
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      {showTransferItemDialog && (
        <Modal
          key="transfer-item-dialog"
          show={showTransferItemDialog}
          onHide={() => setShowTransferItemDialog(false)}
        >
          <Modal.Header closeButton>Transfer Tool to New Job</Modal.Header>

          <Modal.Body>
            <form onSubmit={hndTransferItem}>
              <div className="form-group mb-2">
                <label htmlFor="new_job">New Job:</label>
                <Select 
                  name="new_job" 
                  className="form-control" 
                  required={true}
                  options={
                    jobs.filter((job: JobQueryData) => job.active).map((job: JobQueryData) => ({ value: job.id, label: `${job.job_number} ${job.job}` }))
                  }
                />
              </div>
              <div className="form-group mb-2">
                <label htmlFor="transfer_date">Transfer Date:</label>
                <input
                  type="date"
                  name="transfer_date"
                  className="form-control"
                  defaultValue={DateTime.now().toString()}
                  required
                />
              </div>
              <div className="form-group mb-2">
                <label htmlFor="transferred_to_person">Received By:</label>
                <input
                  type="text"
                  name="transferred_to_person"
                  className="form-control"
                  required
                />
              </div>
              <div className="d-flex align-items-end">
                <button className="btn btn-success mt-2" type="submit">
                  Submit
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      )}
      {showMiscChargeDialog && (
        <Modal
          key="misc-charge-dialog"
          show={showMiscChargeDialog}
          onHide={() => setShowMiscChargeDialog(false)}
        >
          <Modal.Header closeButton>Add Miscellaneous Charge</Modal.Header>

          <Modal.Body>
            <form onSubmit={hndCreateMiscCharge}>
              <div className="form-group mb-2">
                <label htmlFor="charge_date">Charge Date:</label>
                <input
                  type="date"
                  name="charge_date"
                  className="form-control"
                  defaultValue={miscChargeFormData.charge_date ? miscChargeFormData.charge_date : DateTime.now()}
                  required
                />
              </div>
              <div className="form-group mb-2">
                <label htmlFor="charge_type">Charge Type:</label>
                <select
                  name="charge_type"
                  className="form-control"
                  defaultValue={miscChargeFormData.charge_type}
                  onChange={hndMiscChargeInputChange}
                  required
                >
                  <option value="">Select a charge type</option>
                  {charges.filter(Boolean).map((item: ChargeQueryData) => (
                    <option key={item.id} value={item.id}>
                      {item.charge_number + ": " + item.charge}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group mb-2">
                <label htmlFor="department">Department:</label>
                <select
                  name="department"
                  className="form-control"
                  defaultValue={miscChargeFormData.department}
                  onChange={hndMiscChargeInputChange}
                  required
                >
                  <option value="">Select a Department</option>
                  {departments
                    .filter(Boolean)
                    .map((item: DepartmentQueryData) => (
                      <option key={item.id} value={item.id}>
                        {item.department_number + ": " + item.department}
                      </option>
                    ))}
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="charge_amount">Charge Amount:</label>
                <input
                  type="text"
                  name="charge_amount"
                  className="form-control"
                  inputMode="decimal"
                  defaultValue={miscChargeFormData.charge_amount}
                  onChange={hndMiscChargeInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="taxable">Taxable:</label>
                <input
                  type="checkbox"
                  name="taxable"
                  checked={miscChargeFormData.taxable}
                  className="form-checkbox"
                  onChange={hndMiscChargeInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="nontaxable_amount">Non-Taxable Amount:</label>
                <input
                  type="text"
                  name="nontaxable_amount"
                  defaultValue={miscChargeFormData.nontaxable_amount || 0}
                  className="form-control"
                  onChange={hndMiscChargeInputChange}
                  required
                  inputMode="decimal"
                />
              </div>
              <div className="form-group">
                <label htmlFor="charge_description">Charge Description:</label>
                <textarea
                  name="charge_description"
                  defaultValue={miscChargeFormData.charge_description}
                  className="form-control"
                  onChange={hndMiscChargeInputChange}
                  required
                ></textarea>
              </div>
              <div className="form-group">
                <label htmlFor="received_by">Received By:</label>
                <input
                  type="text"
                  name="received_by"
                  defaultValue={miscChargeFormData.received_by}
                  className="form-control"
                  onChange={hndMiscChargeInputChange}
                  required
                />
              </div>
              <div className="d-flex align-items-end">
                <button className="btn btn-success mt-2" type="submit">
                  Submit
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      )}
      <Modal
        show={showAddItemDialog}
        onHide={() => setShowAddItemDialog(false)}
        size="xl"
      >
        <Modal.Header closeButton>Add Item to Ticket</Modal.Header>
        <Modal.Body>
          <Sentry.ErrorBoundary fallback={ErrorFallback}>
            <AddItemToTicketForm
              stockIssue={stockIssue}
              setShowAddItemDialog={hndAddItemDone}
              jobId={stockIssue.data.job.id}
            />
          </Sentry.ErrorBoundary>
        </Modal.Body>
      </Modal>
      <Modal
        key="return-items-dialog"
        show={showReturnItemsDialog}
        onHide={() => setShowReturnItemsDialog(false)}
        size="xl"
      >
        <Modal.Header closeButton>Return Items</Modal.Header>

        <Modal.Body>
          <form onSubmit={hndReturnItems}>
            <table className="table table-sm table-striped" id="items-table">
              <thead>
                <tr>
                  <td>Item</td>
                  <td>Serial Number</td>
                  <td style={{ "width": "15%"}}>Quantity Returned</td>
                  <td>Disposition</td>
                  <td>Date Returned</td>
                </tr>
              </thead>
              <tbody>
                {selectedRows.map((item: StockIssueDetailQueryData) => (
                  <tr key={`return-item-id-${item.id}`} id={String(item.id)}>
                    <td>{item.stock.stock}</td>
                    <td>{item.stock.ycc_serial}</td>
                    <td>
                      {item.stock.stock_type === "small-tool" && (
                        <input
                          type="text"
                          name="qty-returned"
                          disabled
                          className="form-control"
                          value={qtyReturnInput}
                          inputMode="numeric"
                        />
                      )}
                      {item.stock.stock_type === "consumable" && (
                        <input
                          type="text"
                          name="qty-returned"
                          required
                          className="form-control"
                        />
                      )}
                    </td>

                    <td>
                      <select
                        className="form-control"
                        name="return-status"
                        onChange={handleReturnStatusChange}
                      >
                        <option>None</option>

                        <option>Returned</option>
                        {item.stock.stock_type === "small-tool" && (
                          <option>Missing/Lost/Destroyed</option>
                        )}
                      </select>
                    </td>
                    <td>
                      <input 
                        className="form-control"
                        type="date"
                        name="returned-date"
                        defaultValue={String(
                        selectedTicketItem?.date_returned ?? (DateTime.now().toISODate())
                        )} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="d-flex align-items-end">
              <button className="btn btn-success mt-2" type="submit">
                Process Items
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      <Modal
        key="return-single-item-dialog"
        show={showReturnSingleDialog}
        onHide={() => setShowReturnSingleDialog(false)}
        size="xl"
      >
        <Modal.Header closeButton>
          Return {selectedTicketItem?.stock.stock}
        </Modal.Header>

        <Modal.Body>
          <form onSubmit={hndReturnSingle}>
            <table className="table table-md table-striped" id="items-table">
              <thead>
                <tr>
                  <td>Item</td>
                  <td>Serial Number</td>
                  <td style={{ "width": "15%"}}>Quantity Returned</td>
                  <td>Disposition</td>
                  <td>Date Returned</td>
                </tr>
              </thead>
              <tbody>
                <tr
                  key={`return-item-id-${selectedTicketItem?.id}`}
                  id={String(selectedTicketItem?.id)}
                >
                  <td>{selectedTicketItem?.stock.stock}</td>
                  <td>{selectedTicketItem?.stock.ycc_serial}</td>
                  <td>
                    {selectedTicketItem?.stock.stock_type === "small-tool" && (
                      <input
                        type="number"
                        name="qty-returned"
                        disabled
                        className="form-control"
                        value={qtyReturnInput}
                      />
                    )}
                    {selectedTicketItem?.stock.stock_type === "consumable" && (
                      <input
                        type="number"
                        name="qty-returned"
                        required
                        className="form-control"
                      />
                    )}
                  </td>
                  <td>
                    <select
                      className="form-control"
                      name="return-status"
                      onChange={handleReturnStatusChange}
                    >
                      <option>None</option>
                      <option>Returned</option>
                      {selectedTicketItem?.stock.stock_type ===
                        "small-tool" && <option>Missing/Lost/Destroyed</option>}
                    </select>
                  </td>
                  <td>
                    <input 
                      className="form-control"
                      type="date"
                      name="returned-date"
                      onChange={handleReturnDateChange}
                      defaultValue={String(
                      selectedTicketItem?.date_returned ?? (DateTime.now().toISODate())
                      )} />
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="d-flex align-items-end">
              <button className="btn btn-success mt-2" type="submit">
                Process Item
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      <Modal
        key="deliver-item-dialog"
        show={showDeliverItemDialog}
        onHide={() => setShowDeliverItemDialog(false)}
      >
        <Modal.Header closeButton>Mark Item as Delivered</Modal.Header>

        <Modal.Body>
          <form onSubmit={hndDeliverItem}>
            <div className="form-group">
              <label htmlFor="item-name">Item Name:</label>
              <input
                type="text"
                disabled
                name="item-name"
                className="form-control"
                value={selectedTicketItem?.stock.stock}
              />
            </div>
            {selectedTicketItem?.stock.stock_type === "small-tool" && (
              <div className="form-group mb-2">
                <label htmlFor="serial">Select Serial:</label>
                <Select
                  name="serial"
                  className="form-control"
                  onChange={hndSelectSerial}
                  options={options}
                  isClearable
                />
              </div>
            )}
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="qty-requested">Qty Requested:</label>
                  <input
                    type="text"
                    name="qty-requested"
                    className="form-control"
                    value={selectedTicketItem?.quantity}
                    disabled
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="qty-delivered">Qty Delivered:</label>
                  <input
                    type="text"
                    name="qty-delivered"
                    required
                    className="form-control"
                    defaultValue={String(
                      selectedTicketItem?.qty_delivered ?? ""
                    )}
                    inputMode="numeric"
                    onChange={(e) => setQtyDelivered(Number(e.target.value))}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label htmlFor="delivery-date">Delivery Date:</label>
                  <input
                    type="date"
                    name="delivery-date"
                    required
                    className="form-control"
                    defaultValue={String(
                      selectedTicketItem?.date_delivered ?? (DateTime.now().toISODate())
                      )}
                    inputMode="numeric"
                    onChange={(e) => setDateDelivered((DateTime.fromISO(e.target.value).setZone("system")) )}
                  />
                </div>
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="recipient">Received By:</label>
              <input
                name="recipient"
                className="form-control"
                type="text"
                required
              ></input>
            </div>
            <div className="form-group">
              {qtyDelivered !== -1 &&
                qtyDelivered !== Number(selectedTicketItem?.quantity) && (
                  <div className="form-check">
                    <input
                      name="finalized"
                      type="checkbox"
                      className="form-check-input"
                      id="finalized"
                    />
                    <label className="form-check-label" htmlFor="finalized">
                      Finalize Qty Delivered?
                    </label>
                  </div>
                )}
            </div>
            <div className="d-flex align-items-end">
              <button className="btn btn-success mt-2 mt-2" type="submit">
                Complete
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      <Modal
        key="mark-ready-dialog"
        show={showMarkReadyDialog}
        onHide={() => setShowMarkReadyDialog(false)}
      >
        <Modal.Header closeButton>Mark Item as Ready</Modal.Header>

        <Modal.Body>
          <form onSubmit={hndMarkReady}>
            <div className="form-group">
              <label htmlFor="item-name">Item Name:</label>
              <input
                type="text"
                disabled
                name="item-name"
                className="form-control"
                value={selectedTicketItem?.stock.stock}
              />
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="qty-requested">Qty Requested:</label>
                  <input
                    type="text"
                    name="qty-requested"
                    className="form-control"
                    value={selectedTicketItem?.quantity}
                    disabled
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="qty-delivered">Qty Fulfilled:</label>
                  <input
                    type="text"
                    name="qty-delivered"
                    required
                    className="form-control"
                    defaultValue={String(
                      selectedTicketItem?.qty_delivered ?? ""
                    )}
                    inputMode="numeric"
                    onChange={(e) => setQtyReady(Number(e.target.value))}
                  />
                </div>
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="fulfill-notes">
                Notes/Reason for Fulfillment Qty:
              </label>
              <textarea
                name="fulfill-notes"
                className="form-control"
              ></textarea>
            </div>
            <div className="form-group">
              {qtyReady !== -1 &&
                qtyReady !== Number(selectedTicketItem?.quantity) && (
                  <div className="form-check">
                    <input
                      name="finalized"
                      type="checkbox"
                      className="form-check-input"
                      id="finalized"
                    />
                    <label className="form-check-label" htmlFor="finalized">
                      Finalize Qty Ready?
                    </label>
                  </div>
                )}
            </div>

            <div className="d-flex align-items-end">
              <button className="btn btn-success mt-2" type="submit">
                Complete
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      <Modal
        key="multiple-mark-ready-dialog"
        show={showMarkMultipleReadyDialog}
        onHide={() => setShowMarkMultipleReadyDialog(false)}
      >
        <Modal.Header closeButton>Mark Multiple Items as Ready</Modal.Header>

        <Modal.Body>
          <form onSubmit={hndMultipleReady}>
            <table className="table table-sm table-striped" id="ready-table">
              <thead>
                <tr>
                  <td>Item</td>
                  <td>Requested Quantity:</td>
                  <td>Quantity Fulfilled:</td>
                  <td>Note:</td>
                </tr>
              </thead>
              <tbody>
                {selectedRows.map((item: StockIssueDetailQueryData) => (
                  <tr key={`return-item-id-${item.id}`} id={String(item.id)}>
                    <td>{item.stock.stock}</td>
                    <td>{item.quantity}</td>
                    <td>
                      <input
                        type="text"
                        name="qty-fulfilled"
                        required
                        className="form-control"
                        defaultValue={String(item?.qty_delivered ?? "")}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        name="fulfill-note"
                        className="form-control"
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="d-flex align-items-end">
              <button className="btn btn-success mt-2" type="submit">
                Process Items
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  ) : null;
};

const TicketDisplay = () => {
  const { id } = useParams();

  return (
    <Layout className="navbar">
      <Loader isLoading={!id}>
        <ToastContainer />

        {id === undefined ? null : (
          <Sentry.ErrorBoundary fallback={ErrorFallback}>
            <TicketDisplayBody stockIssueId={parseInt(id)} />
          </Sentry.ErrorBoundary>
        )}
      </Loader>
    </Layout>
  );
};
export default TicketDisplay;
