/* eslint-disable jsx-a11y/anchor-is-valid */
import { useQuery } from "@tanstack/react-query";
import { profileQuery } from "../apis/queries/authentication";
import { Link, Navigate, useLocation } from "react-router-dom";

interface AdminNavbarProps {
  user: any;
}

const AdminNavbar = (props: AdminNavbarProps) => {
  const { user } = props;
  const location = useLocation();

  const classNames = (tab: string) => {
    if (tab === location.pathname) {
      return "nav-item px-2 active";
    }

    return "nav-item px-2";
  };

  //console.log(user);

  return user.data.is_superuser ? (
    <nav className="navbar navbar-expand-lg flex-column flex-sm-row bg-ycc-mustard">
      <ul className="nav flex-row flex-grow-1">
        <li className={classNames("/")}>
          <Link className="nav-link" to="/">
            Home
          </Link>
        </li>
        <li className="nav-item px-2">
          <Link className="nav-link" to="/tickets">
            Tickets
          </Link>
        </li>
        <li className="nav-item px-2">
          <Link className="nav-link" to="/inventory">
            Inventory
          </Link>
        </li>
        <li className="nav-item px-2">
          <Link className="nav-link" to="/jobs">
            Jobs
          </Link>
        </li>
        <li className="nav-item px-2 dropdown">
          <a
            className="nav-link dropdown-toggle"
            href="#"
            role="button"
            data-bs-toggle="dropdown"
          >
            Admin
          </a>
          <ul className="dropdown-menu">
            <li>
              <Link to="/meta/charge" className="dropdown-item">
                Charges
              </Link>
            </li>
            <li>
              <Link to="/meta/company" className="dropdown-item">
                Companies
              </Link>
            </li>
            <li>
              <Link to="/meta/department" className="dropdown-item">
                Departments
              </Link>
            </li>
            <li>
              <Link to="/meta/location" className="dropdown-item">
                Locations
              </Link>
            </li>
            <li>
              <Link to="/meta/stock_category" className="dropdown-item">
                Stock Categories
              </Link>
            </li>
            <li>
              <Link to="/meta/stock_order" className="dropdown-item">
                Stock Orders
              </Link>
            </li>
            <li>
              <Link to="/meta/salestax" className="dropdown-item">
                Tax Rates
              </Link>
            </li>
            <li>
              <Link to="/meta/cost_code" className="dropdown-item">
                Cost Codes
              </Link>
            </li>

            <li>
              <Link to="/meta/vendor" className="dropdown-item">
                Vendors
              </Link>
            </li>
          </ul>
        </li>
        <li className="nav-item px-2 dropdown">
          <a
            className="nav-link dropdown-toggle"
            href="#"
            role="button"
            data-bs-toggle="dropdown"
          >
            Reporting
          </a>

          <ul className="dropdown-menu">
            <li>
              <Link
                to="/reports/gl_report/"
                className="dropdown-item"
              >
                General Ledger Report
              </Link>
            </li>
            <li>
              <Link
                to="/reports/monthly_issues_and_credit_detail_report/"
                className="dropdown-item"
              >
                Monthly Issues and Credits Detail Report
              </Link>
            </li>
            <li>
              <Link
                to="/reports/monthly_issues_and_credit_summary_report"
                className="dropdown-item"
              >
                Monthly Issues and Credits Summary Report
              </Link>
            </li>
            <li>
              <Link
                to="/reports/monthly_sales_tax_report"
                className="dropdown-item"
              >
                Monthly Sales Tax Report
              </Link>
            </li>
            <li>
              <Link
                to="/reports/monthly_stock_issue_usage/"
                className="dropdown-item"
              >
                Monthly Stock Issue Usage Report
              </Link>
            </li>
          <li>
              <Link
                to="/reports/stock_issue_register_report"
                className="dropdown-item"
              >
                Monthly Stock Issue Register Report
              </Link>
            </li>
            <li>
              <Link
                to="/reports/stock_issue_jtd_report/"
                className="dropdown-item"
              >
                Monthly JTD Value Report
              </Link>
            </li>




            <li>
              <Link to="/reports/active_jobs_report" className="dropdown-item">
                Active Jobs Report
              </Link>
            </li>
            <li>
              <Link to="/reports/items_missing_cc" className="dropdown-item">
                Cost Code Reconciliation
              </Link>
            </li>
            <li>
              <Link
                to="/reports/inventory_valuation_report"
                className="dropdown-item"
              >
                Inventory Valuation Report
              </Link>
            </li>
            <li>
              <Link
                to="/reports/order_existing_report"
                className="dropdown-item"
              >
                Order Existing Report
              </Link>
            </li>
            <li>
              <Link
                to="/reports/reorder_required_report"
                className="dropdown-item"
              >
                Reorder Required Report
              </Link>
            </li>
            <li>
              <Link
                to="/reports/stock_item_index_report"
                className="dropdown-item"
              >
                Stock Item Index Report
              </Link>
            </li>
            <li>
              <Link
                to="/reports/stocked_items_report"
                className="dropdown-item"
              >
                Stocked Items Report
              </Link>
            </li>
            
            <li>
              <Link
                to="/reports/stock_out_report/"
                className="dropdown-item"
              >
                Small Tools by Job
              </Link>
            </li>
            <li>
              <Link
                to="/reports/daily_ticket_report/"
                className="dropdown-item"
              >
                Daily Ticket Report
              </Link>
            </li>
            <li>
              <Link
                to="/reports/job_summary_report/"
                className="dropdown-item"
              >
                Job Summary Report
              </Link>
            </li>
          </ul>
        </li>
        <li className="nav-item px-2 dropdown">
          <a
            className="nav-link dropdown-toggle"
            href="#"
            role="button"
            data-bs-toggle="dropdown"
          >
            Account
          </a>
          <ul className="dropdown-menu">
            <li>
              <Link to="/profile" className="dropdown-item">
                Profile
              </Link>
            </li>
            <li>
              <Link to="/logout" className="dropdown-item">
                Logout
              </Link>
            </li>
          </ul>
        </li>
      </ul>
    </nav>
  ) : (
    <nav className="navbar navbar-expand-lg flex-column flex-sm-row bg-ycc-mustard">
      <ul className="nav flex-row flex-grow-1">
        <li className={classNames("/staff-dashboard/")}>
          <Link className="nav-link" to="/staff-dashboard/">
            Home
          </Link>
        </li>
        <li className="nav-item px-2 dropdown">
          <a
            className="nav-link dropdown-toggle"
            href="#"
            role="button"
            data-bs-toggle="dropdown"
          >
            Account
          </a>
          <ul className="dropdown-menu">
            <li>
              <Link to="/profile" className="dropdown-item">
                Profile
              </Link>
            </li>
            <li>
              <Link to="/logout" className="dropdown-item">
                Logout
              </Link>
            </li>
          </ul>
        </li>
      </ul>
    </nav>
  );
};

const Layout = (props: any) => {
  const profile = useQuery(profileQuery);

  if (profile.status === "success") {
    return (
      <>
        <AdminNavbar user={profile.data} />

        <div className="container-fluid mt-3">{props.children}</div>
      </>
    );
  } else if (profile.status === "error" && profile.data?.status === 403) {
    return <Navigate to="/login"></Navigate>;
  }

  return null;
};

export default Layout;
