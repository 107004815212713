import { ReactElement, useState, useEffect } from "react";
import BasicFilterTable from "../components/BasicFilterTable";

import type { CostCodeQueryData } from "../apis/interfaces/common";

import ErrorFallback from "../components/Error";
import * as Sentry from "@sentry/react";

interface CostCodeTableProps {
    onAddNew: any;
    onEdit: any;
    costcodes: any;
    setCostCodeInitialValues: any;
    setShowCostCodeModal: any;
    edit: boolean;
    setEdit: any;
    tableCount: number;
    limit: number;
    offset: number;
    onShowFilter: Function;
    onSetLimit: Function;
    onSetOffset: Function;
    onSetSort: Function;
    onSetSearch: Function;
    filterExplainer: ReactElement | undefined;
}

const CostCodeTable = (props: CostCodeTableProps) => {
    const {
        onAddNew,
        costcodes,
        setShowCostCodeModal,
        setCostCodeInitialValues,
        setEdit,
        limit,
        offset,
        onShowFilter,
        onSetLimit,
        onSetOffset,
        onSetSort,
        onSetSearch,
        filterExplainer,
    } = props;

    const [displayData, setDisplayData] = useState<CostCodeQueryData[]>([]);
    const [ apiCount, setApiCount ] = useState<number>(0);

    const fireOnEdit = (row: CostCodeQueryData) => {
        console.log(row.id);
        setCostCodeInitialValues({
            id: row.id,
            cost_code: row.cost_code,
            cost_type: row.cost_type,
            description: row.description,
            short_name: row.short_name,
            job: row.job,
            active: row.active,
        });
        setEdit(true);
        setShowCostCodeModal(true);
    };

    const columns = [
        {
            name: "Cost Code",
            width: "16%",
            sortable: true,
            selector: (row: CostCodeQueryData) => row.cost_code,
            sortField: "cost_code",
        },
        {
            name: "Type",
            width: "7%",
            sortable: true,
            sortField: "cost_type",
            selector: (row: CostCodeQueryData) => row.cost_type,
        },
        {
            name: "Display Name",
            width: "15%",
            sortable: true,
            sortField: "short_name",
            selector: (row: CostCodeQueryData) => row.short_name,
        },
        {
            name: "Active",
            width: "7.5%",
            sortable: true,
            sortField: "active",
            selector: (row: CostCodeQueryData) => row.active,
            cell: (row: CostCodeQueryData) => (
                <>
                    {row.active ? (
                        <span className="text-success">&#x2714;</span> // Checkmark symbol
                    ) : (
                        <span className="text-danger">&#x2718;</span> // Cross symbol
                    )}
                </>
            ),
        },
        {
            name: "Job",
            sortable: true,
            sortField: "job__job",
            selector: (row: CostCodeQueryData) => `${row.job.job_number} - ${row.job.job}`
        },
        {
            name: "Actions",
            width: "7%",
            cell: (row: CostCodeQueryData) => (
                <>
                    <button
                        className="btn btn-sm btn-link"
                        onClick={fireOnEdit.bind(this, row)}
                    >
                        Edit
                    </button>
                </>
            ),
        },
    ];

    const hndShowFilter = () => {
        onShowFilter();
    };

    const filterButtons = (
        <>
            <button className="btn btn-link" onClick={onAddNew}>
                Add New
            </button>
            <button className="btn btn-link" onClick={hndShowFilter}>
                Show Filter
            </button>
        </>
    );

    useEffect(() => {
        if (costcodes.data && costcodes.data.results) {
            setDisplayData([]);
            setDisplayData(costcodes.data.results);
            setDisplayData(costcodes.data.results);
            setApiCount(costcodes.data.count ? costcodes.data.count : costcodes.data.length);
        }
    }, [ costcodes ]);

    return costcodes.data.results ?
        <div className="row">
            <div className="col-12">
                <div className="border border-secondary-subtle p-0 d-flex">
                    <div className="align-self-center mr-2"></div>
                </div>
                <div className="p-2">
                    <Sentry.ErrorBoundary fallback={ErrorFallback}>
                        <BasicFilterTable
                            tableKey="costcodetable"
                            title="Cost Codes"
                            dataSource={displayData}
                            columns={columns}
                            filterHnd={onSetSearch}
                            filterButtons={filterButtons}
                            filterExplainer={filterExplainer}
                            exportFileName={"Cost Code Export Data.csv"}
                            tableCount={apiCount}
                            limit={limit}
                            offset={offset}
                            onChangeLimit={onSetLimit}
                            onChangeOffset={onSetOffset}
                            onChangeSort={onSetSort}
                        />
                    </Sentry.ErrorBoundary>
                </div>
            </div>
        </div>
    : null;
};

export default CostCodeTable;
