import { useState, useEffect } from "react";
import * as Yup from "yup";
import { ErrorMessage, Field, Form, Formik, FormikHelpers } from "formik";
import type { StockCategoryMutationData, StockCategoryQueryData } from "../apis/interfaces/invitems";
import { Modal } from "react-bootstrap";

interface StockCategoryFormProps {
  initialValues: StockCategoryMutationData;
  onSubmit: any;
  onCancel: any;
  stockCategories: any;
}

const validationSchema = Yup.object({
  stock_category: Yup.string().required("StockCategory name is required"),
});

const StockCategoryForm = (props: StockCategoryFormProps) => {
  const { initialValues, onSubmit, onCancel, stockCategories } = props;
  const [catOpts, setCatOpts] = useState<Array<JSX.Element>>([]);

  const handleSubmit = (
    values: StockCategoryMutationData,
    { setStatus }: FormikHelpers<StockCategoryMutationData>
  ) => {
    try {
      validationSchema.validateSync(values, { abortEarly: false });
      onSubmit(values);
    } catch (errors) {
      setStatus("An error occurred during form submission.");
    }
  };

  useEffect(() => {
    const tmpCatOpts: Array<JSX.Element> = [];

    stockCategories.forEach((cat: StockCategoryQueryData) => {
      if (cat.active && !cat.parent_category) {
        tmpCatOpts.push((<option key={cat.id} value={cat.id}>{cat.stock_category}</option>));

        stockCategories.forEach((subcat: StockCategoryQueryData) => {
          if (subcat.active && subcat.parent_category && subcat.parent_category.id === cat.id) {
            tmpCatOpts.push((<option key={subcat.id} value={subcat.id}>&nbsp; &nbsp;-- {subcat.stock_category}</option>));
          }
        });
      }
    });

    setCatOpts(tmpCatOpts);
  }, [ stockCategories ])

  console.log(initialValues);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({
        handleChange,
        handleSubmit,
        isSubmitting,
        isValid,

      }) => (
        <>
          <Form onSubmit={handleSubmit}>
            <Modal.Body>
              <div>
                <label>
                  Stock categories may only be nested one layer deep, but may
                  have multiple subcategories with items underneath.
                  <br />
                  For Example:
                </label>
                <ul>
                  <li>
                    PPE (parent)
                    <ul>
                      <li>
                        Gloves (subcategory)
                        <ul>
                          <li>Item 1</li>
                          <li>Item 2</li>
                        </ul>
                      </li>
                      <li>
                        Eyewear (subcategory)
                        <ul>
                          <li>Item 3</li>
                          <li>Item 4</li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
              <div className="form-group mb-2">
                <label htmlFor="stock_category">StockCategory Name:</label>
                <Field
                  type="text"
                  id="stock_category"
                  name="stock_category"
                  className="form-control"
                  onChange={handleChange}
                />
                <ErrorMessage
                  name="stock_category"
                  component="div"
                  className="error-message text-danger"
                />
              </div>

              <div className="form-group mb-2">
                <label htmlFor="stock_category">Parent Category:</label>
                <Field
                  as="select"
                  id="parent_category"
                  name="parent_category"
                  className="form-control"
                >
                  <option value="">No Parent (or, select a parent)</option>
                  {catOpts}
                </Field>
                <ErrorMessage
                  name="parent_category"
                  component="div"
                  className="error-message text-danger"
                />
              </div>

              <div className="form-check mb-2">
                <Field
                  type="checkbox"
                  id="active"
                  name="active"
                  className="form-check-input"
                  onChange={handleChange}
                />
                <label className="form-check-label" htmlFor="active">
                  Active
                </label>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="text-right">
                <button
                  onClick={onCancel}
                  className="btn btn-sm btn-danger mx-3"
                  type="button"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-sm btn-success"
                  disabled={!(!isSubmitting && isValid)}
                >
                  Save
                </button>
              </div>
            </Modal.Footer>
          </Form>
        </>
      )}
    </Formik>
  );
};

export default StockCategoryForm;
