import * as Yup from "yup";
import { ErrorMessage, Field, Form, Formik, FormikHelpers } from "formik";
import type {
  CostCodeMutationData,
  JobQueryData,
} from "../apis/interfaces/common";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import { useEffect, useState } from "react";
import { useJobQueryAll } from "../pages/Tickets/Logic/MutationsAndQueries";

interface CostCodeFormProps {
  initialValues: CostCodeMutationData;
  onSubmit: any;
  onCancel: any;
}

const validationSchema = Yup.object({
  cost_code: Yup.string()
    .required("Code name (format xxxxx.yy.zzz) is required")
    .matches(/\d{5}\.\d{2}\.\d{3}/, { excludeEmptyString: true }),
  cost_type: Yup.string().required("Code type is required"),
  description: Yup.string().nullable(),
  short_name: Yup.string().required("Short name is required"),
  active: Yup.boolean().optional(),
});

const CostCodeForm = (props: CostCodeFormProps) => {
  const { initialValues, onSubmit, onCancel } = props;
  const [jobs, setJobs] = useState<JobQueryData[]>([]);
  const [job, setJob] = useState<number>(initialValues.job?.id || 0);
  const jobQuery = useJobQueryAll();

  const handleSubmit = (
    values: CostCodeMutationData,
    { setStatus }: FormikHelpers<CostCodeMutationData>
  ) => {
    try {
      values.job_id = job; 
      validationSchema.validateSync(values, { abortEarly: false });
      onSubmit(values);
    } catch (errors) {
      setStatus("An error occurred during form submission.");
    }
  };

  const options = jobs
    ?.filter((jobElem: JobQueryData) => jobElem.active)
    .map((jobElem: JobQueryData) => ({
      value: jobElem.id,
      label: `${jobElem.job_number} - ${jobElem.job}`,
    })) || [];

  const hndSelectJob = (
    selectedOption: { value: number | undefined; label: string } | null
  ) => {
    // Safely handle null or undefined selectedOption
    setJob(selectedOption?.value ?? 0);
  };

  function customFilter(option: any, rawInput: any) {
    const searchTerm = rawInput.toLowerCase();
    return option.label.toLowerCase().includes(searchTerm);
  }

  useEffect(() => {
    if (!jobQuery.isLoading) {
      setJobs(jobQuery.data);
      // Set job to a valid job id or the first active one as default
      const defaultJobId = initialValues.job?.id || jobQuery.data.find((j: JobQueryData) => j.active)?.id || 0;
      setJob(defaultJobId);
    }
  }, [jobQuery.data, jobQuery.isLoading, initialValues.job]);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({ handleChange, handleSubmit, isSubmitting, isValid }) => (
        <>
          <Form onSubmit={handleSubmit}>
            <Modal.Body>
              <div className="form-group mb-2">
                <label htmlFor="cost_code">
                  Code Name (in format xxxxx.yy.zzz):
                </label>
                <Field
                  type="text"
                  id="cost_code"
                  name="cost_code"
                  className="form-control"
                  onChange={handleChange}
                />
                <ErrorMessage
                  name="cost_code"
                  component="div"
                  className="error-message text-danger"
                />
              </div>

              <div className="form-group mb-2">
                <label htmlFor="cost_type">Code Type:</label>
                <Field
                  as="select"
                  id="cost_type"
                  name="cost_type"
                  className="form-control"
                  maxLength={1}
                  onChange={handleChange}
                >
                  <option value="0">Select a type...</option>
                  <option value="O">O</option>
                  <option value="E">E</option>
                  <option value="M">M</option>
                </Field>
                <ErrorMessage
                  name="cost_type"
                  component="div"
                  className="error-message text-danger"
                />
              </div>

              <div className="form-group mb-2">
                <label htmlFor="description">Description:</label>
                <Field
                  type="text"
                  id="description"
                  name="description"
                  className="form-control"
                  onChange={handleChange}
                />
                <ErrorMessage
                  name="description"
                  component="div"
                  className="error-message text-danger"
                />
              </div>

              <div className="form-group mb-2">
                <label htmlFor="short_name">Short/Display Name:</label>
                <Field
                  type="text"
                  id="short_name"
                  name="short_name"
                  className="form-control"
                  onChange={handleChange}
                />
                <ErrorMessage
                  name="short_name"
                  component="div"
                  className="error-message text-danger"
                />
              </div>
              <div className="form-group mb-2">
                <label>Job:</label> <br />
                <Select
                  name="job"
                  className="form-control"
                  onChange={hndSelectJob}
                  options={options}
                  value={options.find(option => option.value === job)}
                  isClearable
                  filterOption={customFilter}
                />
              </div>

              <div className="form-check mb-2">
                <Field
                  type="checkbox"
                  id="active"
                  name="active"
                  className="form-check-input"
                  onChange={handleChange}
                />
                <label className="form-check-label" htmlFor="active">
                  Active
                </label>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="text-right">
                <button
                  onClick={onCancel}
                  className="btn btn-sm btn-danger mx-3"
                  type="button"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-sm btn-success"
                  disabled={!(!isSubmitting && isValid)}
                >
                  Save
                </button>
              </div>
            </Modal.Footer>
          </Form>
        </>
      )}
    </Formik>
  );
};

export default CostCodeForm;
